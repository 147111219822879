import { get, post } from "../APIGateWay";

export async function updateContactUser(data) {
    return await post(data, '/parent/change-contact')
}

export async function updateAvatar(data) {
    return await post(data, '/user/update')
}

export async function updateProfile(data) {
    return await post(data, '/user/change-info')
}

export async function updatePassword(data) {
    return await post(data, '/password')
}

export async function getProfile(params = {}) {
    return await get(params, '/user/info')
}

export async function getAvatars() {
    return await get({}, '/user/avatar')
}
export async function getCustomAvatars() {
    return await get({}, '/user/list-personal-avatar')
}
export async function buyCustomAvatar(data) {
    return await post(data, '/user/personal-avatar/buy')
}
export async function saveCustomAvatar(data) {
    return await post(data, '/user/personal-avatar/save')
}

export async function getConfig() {
    return await get({}, '/get-config-v2')
}

export async function updateLanguage(data) {
    return await post(data, '/user/update-language')
}

export async function changeGrade(params) {
    return await get(params, '/course/change-grade')
}
export async function generateOTP(data) {
    return await post(data, '/user/generate-otp')
}
export async function verifyOTP(data) {
    return await post(data, '/user/verify-otp')
}