import {PAGE_LIMIT} from '../constant'
import {get, post} from "../APIGateWay";

export async function getInboxs(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/inbox/list')

}

export async function readInbox(data) {
    return await post(data, '/inbox/read')
}