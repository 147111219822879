<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/profile.css?v=6">
        <div class="container">
            <div id="page-header-fixed" class="mb-25">
                <div class="container p-0">
                    <div id="page-header">
                        <div class="tools">
                            <nav class="ito-tabs">
                                <a :class="{ active: tab == 'phone' }" @click="changeTab('phone')">
                                    <i class="ito-icon-checked-white-sm me-2" :class="{ disable: tab != 'phone' }"
                                        v-if="user_info.phone_verified"></i>
                                    {{ $t('tab_verify_phone') }}
                                </a>
                                <a :class="{ active: tab == 'email' }" @click="changeTab('email')">
                                    <i class="ito-icon-checked-white-sm me-2" :class="{ disable: tab != 'email' }"
                                        v-if="user_info.email_verified"></i>
                                    {{ $t('tab_verify_email') }}
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body" v-if="tab == 'phone'">
                    <h5 class="card-title" v-if="user_info.phone_verified">
                        <i class="ito-icon-checked me-2"></i>
                        <span class="title">{{ $t('phone_verified') }}</span>
                    </h5>
                    <h5 class="card-title" v-else>
                        <i class="ito-icon-warning-md me-2"></i>
                        <span class="title">{{ $t('phone_not_verified') }}</span>
                    </h5>
                    <div class="card-content d-flex justify-content-center mb-5" v-if="!user_info.phone_verified">
                        <div id="verify-phone">
                            <div class="row">
                                <div class="col-12 p-3 verify-top">
                                    <img src="/themes/ito/images/mascot/mascot-notify.svg" alt="">
                                    <div class="verify-guide">
                                        <strong>{{ $t('phone_verify_guideline') }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 justify-content-center" v-if="tab == 'phone' && step == 1">
                                <div class="col-8 text-center">
                                    <div class="form-group">
                                        <label for="phoneInput">{{ $t('enter_phone_to_receive_OTP') }}</label>
                                        <input type="tel"
                                            class="form-control text-bold text-center centered-placeholder"
                                            :class="validate.phone.data.length ? validate.phone.error ? 'is-invalid' : 'is-valid' : ''"
                                            id="phoneInput" aria-describedby="phoneHelp"
                                            :placeholder="$t('insert_phone')" v-model="validate.phone.data"
                                            @input="phoneValidator()" autofocus>
                                        <div class="invalid-feedback" v-if="validate.phone.error">{{
                                    $t(validate.phone.validFeedback) }}</div>
                                    </div>
                                    <button type="submit" class="ito-btn ito-btn-block ito-btn-primary mt-3"
                                        :disabled="validate.phone.error" @click="getOTP('sms')">{{ $t('continue_txt')
                                        }}</button>
                                </div>
                            </div>
                            <div class="row mt-4 justify-content-center" v-else-if="tab == 'phone' && step == 2">
                                <div class="col-8 text-center">
                                    <div class="form-group">
                                        <label for="phoneOtpInput"
                                            v-html="reFormatTranslate($t('otp_sent_to_your_phone'), validate.phone.data)"></label>
                                        <input type="text"
                                            class="form-control text-bold text-center centered-placeholder"
                                            :class="validate.otpCode.data.length ? validate.otpCode.error ? 'is-invalid' : 'is-valid' : ''"
                                            id="phoneOtpInput" aria-describedby="phoneOtpHelp"
                                            :placeholder="$t('insert_otp')" v-model="validate.otpCode.data"
                                            @input="otpValidator()" autofocus>
                                        <div class="invalid-feedback" v-if="validate.otpCode.error">{{
                                    $t(validate.otpCode.validFeedback) }}</div>
                                    </div>
                                    <button type="submit" class="ito-btn ito-btn-block ito-btn-primary my-3"
                                        :disabled="validate.otpCode.error" @click="checkOTP('sms')">{{ $t('confirm')
                                        }}</button>
                                    <span v-if="sms_limited">{{ $t('verify_error_07') }}</span>
                                    <template v-else>
                                        <div class="cooldown" v-if="coolingDown">{{ $t('wait') }} <span>{{ timeLeft
                                                }}</span> {{
                                    $t('to_resend_otp') }}</div>
                                        <a class="text-decoration-underline text-bold ito-text-primary" href="#" v-else
                                            @click="getOTP('sms')">{{ $t('btn_resend_otp') }}</a>
                                    </template>

                                    <button class="ito-btn ito-btn-block ito-btn-outline-primary my-3"
                                        @click="gotoStep(1)">{{ $t('back') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-content d-flex justify-content-center flex-column align-items-center my-5" v-else>
                        <img src="/themes/ito/images/image01/phone_verified.svg" alt="">
                        <p class="text-bold mt-3">{{ $t('phone_verified_success') }}</p>
                    </div>
                </div>



                <div class="card-body" v-else-if="tab == 'email'">
                    <h5 class="card-title" v-if="user_info.email_verified">
                        <i class="ito-icon-checked me-2"></i>
                        <span class="title">{{ $t('email_verified') }}</span>
                    </h5>
                    <h5 class="card-title" v-else>
                        <i class="ito-icon-warning-md me-2"></i>
                        <span class="title">{{ $t('email_not_verified') }}</span>
                    </h5>
                    <div class="card-content d-flex justify-content-center mb-5" v-if="!user_info.email_verified">
                        <div id="verify-email">
                            <div class="row">
                                <div class="col-12 p-3 verify-top">
                                    <img src="/themes/ito/images/mascot/mascot-notify.svg" alt="">
                                    <div class="verify-guide">
                                        <strong>{{ $t('email_verify_guideline') }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 justify-content-center" v-if="tab == 'email' && step == 1">
                                <div class="col-8 text-center">
                                    <div class="form-group">
                                        <label for="emailInput">{{ $t('enter_email_to_receive_OTP') }}</label>
                                        <input type="email"
                                            class="form-control text-bold text-center centered-placeholder"
                                            :class="validate.email.data.length ? validate.email.error ? 'is-invalid' : 'is-valid' : ''"
                                            id="emailInput" aria-describedby="emailHelp"
                                            :placeholder="$t('insert_email')" v-model="validate.email.data"
                                            @input="emailValidator()" autofocus>
                                        <div class="invalid-feedback" v-if="validate.email.error">{{
                                    $t(validate.email.validFeedback) }}</div>
                                    </div>
                                    <button type="submit" class="ito-btn ito-btn-block ito-btn-primary mt-3"
                                        :disabled="validate.email.error" @click="getOTP('email')">{{ $t('continue_txt')
                                        }}</button>
                                </div>
                            </div>
                            <div class="row mt-4 justify-content-center" v-else-if="tab == 'email' && step == 2">
                                <div class="col-8 text-center">
                                    <div class="form-group">
                                        <label for="emailOtpInput"
                                            v-html="reFormatTranslate($t('otp_sent_to_your_email'), validate.email.data)"></label>
                                        <input type="text"
                                            class="form-control text-bold text-center centered-placeholder"
                                            :class="validate.otpCode.data.length ? validate.otpCode.error ? 'is-invalid' : 'is-valid' : ''"
                                            id="emailOtpInput" aria-describedby="emailOtpHelp" placeholder="Nhập mã"
                                            v-model="validate.otpCode.data" @input="otpValidator()" autofocus>
                                        <div class="invalid-feedback" v-if="validate.otpCode.error">{{
                                    $t(validate.otpCode.validFeedback) }}</div>
                                    </div>
                                    <button type="submit" class="ito-btn ito-btn-block ito-btn-primary my-3"
                                        :disabled="validate.otpCode.error" @click="checkOTP('email')">{{ $t('confirm')
                                        }}</button>
                                    <span v-if="email_limited">{{ $t('verify_error_07') }}</span>

                                    <template v-else>
                                        <div class="cooldown" v-if="coolingDown">{{ $t('wait') }} <span>{{ timeLeft
                                                }}</span> {{
                                    $t('to_resend_otp') }}</div>
                                        <a class="text-decoration-underline text-bold ito-text-primary" href="#" v-else
                                            @click="getOTP('email')">{{ $t('btn_resend_otp') }}</a>
                                    </template>
                                    <button class="ito-btn ito-btn-block ito-btn-outline-primary my-3"
                                        @click="gotoStep(1)">{{
                                    $t('back') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-content d-flex justify-content-center flex-column align-items-center my-5" v-else>
                        <img src="/themes/ito/images/image01/email_verified.svg" alt="">
                        <p class="text-bold mt-3">{{ $t('email_verified_success') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <claim-reward-dialogue ref="claimRewardDialogue"></claim-reward-dialogue>
    </div>
</template>

<script>
import axios from "axios";
import { soundClick, soundGetReward, soundClaimKey } from '../../utils/utils';
import { generateOTP, verifyOTP } from '../../models/profile'
import {
    ClaimRewardDialogue,
    ExtensionResultDialog,
    ConfirmDialogue
} from "../modal";


let counter;

export default {
    name: "AccountVerify",
    title: 'Account Verify',
    components: {
        ClaimRewardDialogue,
        ExtensionResultDialog,
        ConfirmDialogue
    },
    data() {
        const user = this.$store.state.user_info;
        return {
            tab: this.$route.params.tab,
            step: 1,
            timeRemaining: 0,
            timeLeft: 0,
            coolingDown: true,
            sms_limited: false,
            email_limited: false,
            validate: {
                phone: {
                    data: '',
                    error: true,
                    validFeedback: '',
                },
                email: {
                    data: '',
                    error: true,
                    validFeedback: '',
                },
                otpCode: {
                    data: '',
                    error: true,
                    validFeedback: '',
                }
            },
            user: user,
        };
    },

    computed: {
        user_info() {
            const user = this.$store.state.user_info;
            this.user.phone_verified = user.phone_verified;
            this.user.email_verified = user.email_verified;
            return this.$store.state.user_info
        }
    },

    created() {

    },

    watch: {

    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
        getSoundClick() {
            soundClick();
        },
        getSoundGetReward() {
            soundGetReward();
        },
        changeTab(tab) {
            if (this.tab != tab) {
                this.getSoundClick()
                this.tab = tab;
                this.step = 1
                this.$router.push({
                    name: 'account_verify',
                    params: {
                        tab: tab
                    }
                })
            }
            return;
        },
        phoneValidator() {
            const self = this;
            var result = false;
            var validFeedback = ''
            if (self.validate.phone.data.length) {
                result = /0+([0-9]{9})\b/g.test(self.validate.phone.data);
            }
            if (!result) {
                validFeedback = 'verify_error_02';
            }
            self.validate.phone.validFeedback = validFeedback;
            self.validate.phone.error = !result
            return
        },
        emailValidator() {
            const self = this;
            const email = self.validate.email.data
            var result = false;
            var validFeedback = ''
            if (email.length) {
                result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
            }
            if (!result) {
                validFeedback = 'verify_error_09';
            }
            self.validate.email.validFeedback = validFeedback;
            self.validate.email.error = !result
            return
        },
        otpValidator() {
            const self = this;
            var result = false;
            var validFeedback = ''
            if (self.validate.otpCode.data.length == 6) {
                result = true;
            }
            if (!result) {
                validFeedback = 'verify_error_03';
            }
            self.validate.otpCode.validFeedback = validFeedback;
            self.validate.otpCode.error = !result
            return
        },
        getOTP(channel) {
            const that = this;
            const params = {
                channel: channel,
                verify_info: channel == 'sms' ? this.validate.phone.data : this.validate.email.data
            }
            that.$store.commit("setLoading", true);
            axios.all([
                generateOTP(params),
            ]).then(axios.spread((response) => {
                if (response.data.code == 0) {
                    this.timeRemaining = response.data.results.next_otp_time;
                    if (counter) {
                        counter.drop();
                    }
                    this.timerCountdown(this.timeRemaining)
                    that.$store.commit("setLoading", false);
                    if (this.step != 2) {
                        this.gotoStep(2)
                    }
                    return;
                }

                else {
                    if (response.data.error_code == 'otp_err_107') {
                        this.timeRemaining = response.data.results.next_otp_time;
                        if (counter) {
                            counter.drop();
                        }
                        this.timerCountdown(this.timeRemaining)
                        that.$store.commit("setLoading", false);
                        if (this.step != 2) {
                            this.gotoStep(2)
                        }
                        return;
                    }
                    if (response.data.error_code == 'verify_error_07') {
                        if (this.tab == 'phone') {
                            this.sms_limited = true;
                        } else if (this.tab == 'email') {
                            this.email_limited = true;
                        }
                    }

                    if (this.tab == 'phone') {
                        this.validate.phone.error = true;
                        this.validate.phone.validFeedback = response.data.error_code;
                    } else if (this.tab == 'email') {
                        this.validate.email.error = true;
                        this.validate.email.validFeedback = response.data.error_code;
                    }
                    that.$store.commit("setLoading", false);
                    return;
                }
            }));
        },
        checkOTP(channel) {
            const that = this;
            const params = {
                channel: channel,
                otp: that.validate.otpCode.data
            }
            that.$store.commit("setLoading", true);
            axios.all([
                verifyOTP(params),
            ]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    that.validate.otpCode.error = true;
                    that.validate.otpCode.validFeedback = response.data.error_code;
                    that.$store.commit("setLoading", false);
                    return;
                } else {
                    let user = this.$store.state.user_info;
                    switch (channel) {
                        case 'sms':
                            user.phone_verified = 1;
                            user.phone = that.validate.phone.data;
                            break;
                        case 'email':
                            user.email_verified = 1;
                            user.email = that.validate.email.data;
                            break;
                        default:
                            break;
                    }
                    that.$store.commit("setLoading", false);
                    const dialog = this.$refs.claimRewardDialogue
                    const params = {
                        title: this.tab == 'phone' ? this.$t('phone_verified_success') : this.$t('email_verified_success'),
                        reward: response.data.results.reward_gems,
                        okButton: this.$t('ok'),
                    }
                    this.showDialog(dialog, params).then((confirm) => {
                        if (confirm) {
                            let user = this.$store.state.user_info;
                            user.gem += parseInt(response.data.results.reward_gems)
                            user.gem_month += parseInt(response.data.results.reward_gems)
                            this.getSoundGetReward();
                        }
                    })

                }
            }));
        },
        resendOtp() {
            this.timerCountdown(this.timeRemaining)
        },
        gotoStep(step) {
            this.step = step;
        },
        timerCountdown(time_remaining) {
            const self = this;
            self.coolingDown = true;
            counter = new Counter(time_remaining + 1, 1000);
            counter.onTick(function (time) {
                self.timeLeft = time.seconds
            });
            counter.onEnd(function () {
                self.coolingDown = false
            })
            counter.start();
        },
        async showDialog(dialog, params) {
            const cfm = await dialog.show(params);
            return cfm;
        },
        reFormatTranslate(key, data) {
            var result;
            if (data) {
                result = key.replace('%s', '<strong>' + data + '</strong>');
            }
            else {
                result = key;
            }
            return result;
        },
        async showDialog(dialog, params) {
            const cfm = await dialog.show(params);
            return cfm;
        },
    }
}
</script>

<style scoped>
#verify-phone,
#verify-email {
    width: 500px;
    font-size: 12px;
    font-family: Nunito;
    font-size: 12px;
    line-height: 16px;
}

.verify-top {
    display: flex;
    align-items: center;
    border: 1px solid #E4E4E4;
    background-color: #FFFFFF;
    border-radius: 100px 5px 5px 100px;
    gap: 10px;
}

input.centered-placeholder::placeholder {
    text-align: center;
    font-weight: normal;
}

input.centered-placeholder::-webkit-input-placeholder {
    text-align: center;
    font-weight: normal;
}

input.centered-placeholder:-moz-placeholder {
    text-align: center;
    font-weight: normal;
}

input.centered-placeholder::-moz-placeholder {
    text-align: center;
    font-weight: normal;
}

input.centered-placeholder:-ms-input-placeholder {
    text-align: center;
    font-weight: normal;
}

.cooldown span {
    font-weight: 700;
    color: #01A3FF
}
</style>