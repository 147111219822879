<template>
    <div id="blank-page">
        <router-view></router-view>
    </div>
</template>

<script>
    import FooterElement from "../FooterElement";
    import NavBarBlank from "../NavBarBlank";

    export default {
        name: "Empty",

        data() {
            return {};
        },
        components: {

        },
        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            user_info() {
                return this.$store.state.user_info;
            }
        },

        methods: {
            fetch() {

            },
        }
    }
</script>

<style scoped>
    #blank-page {

    }
    #content-blank{
        width: 100%;
        background: url(/public/themes/ito/images/background.jpg) no-repeat;
        background-size: 100%;
        -webkit-background-size: 100%;
        -o-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        background-attachment: fixed;
        background-position: center;
        /*padding: 30px;*/
        text-align: justify;
        padding-bottom: 100px;
    }
    @media screen and (min-device-width: 768px) {
        #landing-page {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 40px 94px 65px 94px;
            background-image: url("/public/themes/ito/images/mobile-bg-1.png");
        }

        #tablet {
            display: block;
        }

        #mobile {
            display: none !important;
        }

        .tablet-logo {
            width: 175px;
            height: 95px;
            margin: 0 auto;
            display: block;
        }

        .tablet-desktop {
            width: 262px;
            height: 114px;
            margin: 42px auto 18px auto;
            display: block;
        }

        .tablet-desktop-desc-1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #070A28;
            text-align: center;
        }

        .tablet-desktop-desc-2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #070A28;
            margin-top: 60px;
            text-align: center;
        }

        h3.tablet-title {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #070A28;
            margin-top: 48px;
        }

        .tablet-desc {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: rgba(7, 10, 40, 0.8);
            margin: 18px auto 12px auto;
        }

        .tablet-buttons {
        }

        .tablet-button-android {
            margin-right: 35px;
            display: inline-block;
        }

        .tablet-button-ios {
            margin-right: 35px;
            display: inline-block;
        }

        .tablet-bottom {
            text-align: center;
        }
    }
</style>