<template>
    <div id="content">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{ $t('there_are') }} {{ length_unit }} {{ $t('search_result_for') }}
                            '{{ search }}'</span>
                    </h5>
                    <div class="card-content">
                        <div v-if="units.length != 0">
                            <template v-for="(unit, index) in units">
                                <div v-if="unit.lesson">
                                    <router-link
                                        :to="{ path: '/lesson/' + unit.subject_id + '-' + unit.id + '-' + unit.lesson.id }">
                                        <div class="col-md-12">
                                            <div class="lesson-item-search d-flex">
                                                <div class="img-search">
                                                    <img :src="unit.image" />
                                                </div>
                                                <div class="content-list-search">
                                                    <h4 class="title">{{ unit.lesson.title }}</h4>
                                                    <h5 class="content">{{ unit.name }} - {{ unit.lesson.name }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div v-else>
                                    <router-link :to="{ path: '/unit/' + unit.subject_id + '/' + unit.id + '/1', }">
                                        <div class="col-md-12">
                                            <div class="lesson-item-search d-flex">
                                                <div class="img-search">
                                                    <img :src="unit.image" />
                                                </div>
                                                <div class="content-list-search">
                                                    <h4 class="title">{{ unit.title }}</h4>
                                                    <h5 class="content">{{ unit.name }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </template>
                        </div>
                        <div class="d-flex justify-content-center py-4" v-else>
                            <img src="/themes/ito/images/isaac-searchresult.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
import { getListSearch } from "../models/dashboard";

export default {
    name: "Search",
    title: 'search in ismart',

    data() {
        return {
            search: '',
            length_unit: 0,
            units: [],
        };
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },

    created() {
    },
    methods: {
        doSearch() {
            const that = this;
            that.length_unit = 0;
            that.$store.commit("setLoading", true);
            const query = this.$route;
            that.search = query.query.keyword ? query.query.keyword : '';
            let params = {
                'keyword': that.search,
                'grade': localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade
            }
            axios.all([
                getListSearch(params),
            ]).then(axios.spread((list_search_response) => {
                that.$store.commit("setLoading", false);
                if (list_search_response.data.code != 0) {
                    return;
                }
                else {
                    if (list_search_response.data.results) {
                        that.units = list_search_response.data.results ? list_search_response.data.results.units : [];
                        that.length_unit = list_search_response.data.results.total_result;
                    }
                }
            }));
        }
    },

    watch: {
        '$route.query.keyword'(item) {
            this.doSearch();
            this.search = item;
        }
    },

    mounted() {
        this.doSearch();
    },
}
</script>

<style scoped>
.card-content {
    padding: 30px;
}

.lesson-item-search {
    padding: 12px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 12px;
}

.img-search {
    width: 84px;
    height: 52px;
    border-radius: 5px;
    overflow: hidden;
}

.img-search img {
    width: 100%;
}

.content-list-search {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-list-search h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    margin-bottom: 0;
}

.content-list-search h5 {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #070A28;
    margin-bottom: 0;
}
</style>