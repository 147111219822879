<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/parents.css">

        <div class="container">
            <div id="page-header-fixed" class="mb-25">
                <div id="page-header">
                    <div class="tools">
                        <nav class="ito-tabs">
                            <router-link :to="{ name: 'your_space_trophies' }"
                                @click.native="getSoundClick">Trophies</router-link>
                            <router-link :to="{ name: 'your_space_badges' }" class="active"
                                @click.native="getSoundClick">Badges</router-link>
                            <router-link :to="{ name: 'your_space_certificates' }"
                                @click.native="getSoundClick">Certificates</router-link>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="card card-parents">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">Badges</span>
                    </h5>
                    <div class="your_space_content d-flex flex-column align-items-center pb-5">
                        <div class="your_space_row badges mt-5" v-for="items in entries">
                            <template v-for="item in items">
                                <div class="your_space_item badges_item"
                                    v-bind:style="item.icon != '' ? { backgroundImage: 'url(' + item.icon + ')' } : ''">
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { soundClick } from '../../utils/utils'
import { mapGetters } from 'vuex'

export default {
    name: "YourSpaceBadges",
    data() {

        return {
            entries: [],
        };
    },

    created() {
        this.$store.commit('setMenuActive', 'your_space');
        this.fetch();
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },
    mounted() {
        $('.your_space_item').click(this.getSoundClick);
    },
    methods: {
        fetch() {
            const that = this;
            var newEnries = []
            const emptyEntries = {
                id: '',
                icon: '',
                checked: false
            }
            for (var i = 0; i <= 9; i++) {
                newEnries.push(emptyEntries)
            }
            that.entries = this.sortItems(newEnries) || [];
        },

        sortItems(arr) {
            var result = arr.map(function (x, i) {
                return i % 5 === 0 ? arr.slice(i, i + 5) : NaN;
            }).filter(n => n);
            return result;
        },
        getSoundClick() {
            soundClick();
        },
    }
}
</script>

<style scoped></style>
