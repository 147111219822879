<template>
    <div class="challenge-item" :class="{
        disabled: challenge.status == 4,
        opened: challenge.status == 2 || challenge.status == 3
    }">
        <div class="row-1">
            {{ challenge.key_open }} <i class="ito-icon-lock"></i>
        </div>
        <div class="row-2">
            <i class="" :class="{
                'ito-icon-box-disabled': challenge.status == 4,
                'ito-icon-box-close': challenge.status == 0 || challenge.status == 1,
                'ito-icon-box-opened': challenge.status == 2 || challenge.status == 3
            }"></i>
        </div>
        <div class="row-3">
            <button class="ito-btn ito-btn-sm ito-btn-primary" @click="claim"
                v-if="challenge.status == 1 || challenge.status == 0" :disabled="challenge.status == 0">
                {{ $t('claim') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import { authLogin } from "../../models/auth";
import { claimChestDailyChallenge } from "../../models/reward";
import { soundClick, soundGetReward, soundClaimKey } from '../../utils/utils';

export default {
    name: "DailyChallengeItem",
    props: ['challenge'],
    data() {
        return {};
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        })
    },

    created() {

    },

    methods: {
        claim() {
            const that = this;

            that.getSoundClick();

            claimChestDailyChallenge({
                id: that.challenge.id,
                //is_raw: true
            }).then((response) => {
                if (response.data.code != 0) {
                    return;
                }
                const new_gem = response.data.results.update_gem || 0;
                const new_key_collect = response.data.results.key_collect || 0;
                const new_challenges = response.data.results.challenge || [];
                const gem_reward = response.data.results.gem_reward || [];
                that.user_info.dailychallenge_noti = response.data.results.dailychallenge_noti || 0;
                that.user_info.gem = new_gem;
                this.$emit('updateNewChallenge', new_key_collect, new_challenges)
                const gem_txt = gem_reward > 0 ? '+' + gem_reward : gem_reward;
                $('#congratulation_gem').text(gem_txt);
                that.getSoundClaimKey();
                $('#getRewardedModal').modal('show');
            }).catch(({ response }) => {
                console.error(response);
            });
        },

        getSoundClick() {
            soundClick();
        },

        getSoundGetReward() {
            soundGetReward();
        },

        getSoundClaimKey() {
            soundClaimKey();
        }
    }
}
</script>

<style scoped></style>
