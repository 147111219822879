<template>
    <div>
        <div class="modal fade overflow-hidden" id="uploadAvatarModal" tabindex="-1" aria-labelledby="uploadAvatarModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content overflow-hidden border-0 p-0">
                    <div class="modal-body d-flex p-0">
                        <div class="avatar-preview">
                            <div class="d-flex flex-column align-items-center">
                                <h3 class="title">{{ $t('student_profile') }}</h3>
                                <!-- <div class="avatar" v-if="tab == 'custom'" data-bs-toggle="modal" data-bs-target="#previewCustomavatar" data-bs-dismiss="modal"> -->
                                <div class="avatar" v-if="tab == 'custom'">
                                    <div class="avatar-wrapper" id="customAvatar">
                                        <img ref="avatar_url" class="w-100 img-fluid" :style="{ 'z-index': layer.order }" :src="layer.url" v-for="layer in customAvatar">
                                    </div>
                                </div>
                                <div class="avatar" v-else>
                                    <div class="avatar-wrapper">
                                        <img ref="avatar_url" class="w-100 img-fluid" :src="user_update_info.avatar_url">
                                    </div>
                                    <img ref="emoticon_url" class="emoticon" :src="user_update_info.emoji_url" alt="" v-if="user_update_info.emoji_url">
                                </div>
                                <div class="control">
                                    <button class="ito-btn ito-btn-secondary" @click="saveCustomAvatar()" :disabled="!is_custom_avatar_changed" v-if="tab == 'custom'">
                                        {{ $t('save') }}
                                    </button>
                                    <button class="ito-btn ito-btn-secondary" @click="saveUpdate()" :disabled="!is_changed" v-else>
                                        {{ $t('save') }}
                                    </button>
                                    <button class="ito-btn ito-btn-outline-primary" data-bs-dismiss="modal" aria-label="Close">
                                        {{ $t('cancel') }}</button>
                                </div>
                            </div>
                        </div>

                        <div class="avatar-edit">
                            <ul id="main-nav" class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" @click="tab = 'home'" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        {{ $t('avatar') }}
                                    </button>
                                </li>
                                <!-- <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="custom-avatar-tab" @click="tab = 'custom'" data-bs-toggle="tab" data-bs-target="#custom-avatar" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        {{ $t('custom_avatar') }}
                                    </button>
                                </li> -->
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" @click="tab = 'emoji'" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        {{ $t('emoticon') }}
                                    </button>
                                </li>
                            </ul>
                            <div id="main-tab-content" class="tab-content">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="nano">
                                        <div class="nano-content">
                                            <div class="avatar-list py-3">
                                                <div class="avatar-item">
                                                    <label class="avatar-item-upload" for="uploadAvatar">
                                                        <img src="/themes/ito/images/avatar-0.svg?v=2" alt="">
                                                    </label>
                                                    <input type="file" id="uploadAvatar" class="d-none" name="avatar" accept="image/*" @change="uploadAvatar" />
                                                </div>
                                                <div class="avatar-item" :class="{ 'active': user_update_info.avatar_id == avatar.id, 'current': avatar.id == user_info.avatar_id }" v-for="avatar in avatars" @click="selectAvatar(avatar)">
                                                    <div class="avatar-item-wrapper">
                                                        <img :src="avatar.url" alt="" v-if="avatar.url">
                                                        <div class="spinner-border spinner-border-sm" role="status" v-else></div>
                                                    </div>
                                                    <button class="ito-btn ito-btn-sm ito-btn-primary" v-if="avatar.price > 0">{{ avatar.price }} <i class="ito-icon-gem"></i></button>
                                                    <button class="ito-btn ito-btn-sm ito-btn-success" v-if="avatar.id == user_info.avatar_id && avatar.price <= 0"><i class="ito-icon-checked-white-nb"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="custom-avatar" role="tabpanel" aria-labelledby="custom-avatar-tab">
                                    <div class="custom-avatar-tabs">
                                        <ul id="custom-avatar-nav" class="nav nav-tabs d-flex flex-nowrap" role="tablist">
                                            <template v-for="(category, index) in objects">
                                                <li class="nav-item" role="presentation" v-if="category.linked_object_id == 0">
                                                    <button class="nav-link text-capitalize" :class="{ active: index == 0 }" data-bs-toggle="tab" :data-bs-target="'#tab-' + category.id" type="button" role="tab" aria-controls="home"
                                                        aria-selected="true">
                                                        {{ category.name }}
                                                    </button>
                                                </li>
                                            </template>
                                        </ul>
                                        <div id="custom-avatar-tab-content" class="tab-content">
                                            <template v-for="(category, index) in objects">
                                                <div class="tab-pane fade" :class="{ active: index == 0, show: index == 0 }" :id="'tab-' + category.id" role="tabpanel" aria-labelledby="home-tab" v-if="category.linked_object_id == 0">
                                                    <div class="avatar-list py-3" v-if="category.shape_details">
                                                        <div class="avatar-item" :class="{ current: false }" @click="removeShape(category)" v-if="category.is_required != 1">
                                                            <div class="avatar-item-wrapper">
                                                                <img src="/themes/ito/images/open-house/no_shape.png" :alt="category.name + '-no-shape'">
                                                            </div>
                                                            <button class="ito-btn ito-btn-sm ito-btn-success" v-if="!customAvatar[category.id]"><i class="ito-icon-checked-white-nb"></i></button>
                                                        </div>
                                                        <template v-for="shape in category.shape_details">
                                                            <div class="avatar-item" :class="{ current: isSelectedShape(category, shape) }" @click="selectShape(category, shape, true)">
                                                                <div class="avatar-item-wrapper">
                                                                    <img :src="shape.thumbnail_url ? shape.thumbnail_url : shape.image_url" :alt="category.name + '-' + shape.id" v-if="shape.image_url || shape.thumbnail_url">
                                                                    <div class="spinner-border spinner-border-sm" role="status" v-else></div>
                                                                </div>
                                                                <button class="ito-btn ito-btn-sm ito-btn-primary" v-if="shape.status == 'inactivated'" @click="buyShape(category, shape)">{{ shape.price }} <i class="ito-icon-gem"></i></button>
                                                                <button class="ito-btn ito-btn-sm ito-btn-success" v-if="isSelectedShape(category, shape) && shape.status == 'activated'"><i class="ito-icon-checked-white-nb"></i></button>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <template v-if="category.colors">
                                                        <ul id="colors-nav" class="nav nav-tabs d-flex" role="tablist">
                                                            <li class="nav-item" role="presentation" v-for="(color, index2) in category.colors">
                                                                <button class="nav-link text-capitalize" :class="{ active: index2 == 0 }" data-bs-toggle="tab" :data-bs-target="'#color-' + category.id + '-' + color.id" type="button" role="tab"
                                                                    aria-controls="home" aria-selected="true">
                                                                    <div :style="{ 'background-color': '#' + color.code }" :title="color.name"></div>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div id="colors-tab-content" class="tab-content">
                                                            <template v-for="(color, index2) in category.colors">
                                                                <div class="tab-pane fade" :class="{ active: index2 == 0, show: index2 == 0 }" :id="'color-' + category.id + '-' + color.id" role="tabpanel" aria-labelledby="home-tab">
                                                                    <div class="avatar-list py-3">
                                                                        <div class="avatar-item" :class="{ current: false }" @click="removeShape(category)" v-if="category.is_required != 1">
                                                                            <div class="avatar-item-wrapper">
                                                                                <img src="/themes/ito/images/open-house/no_shape.png" :alt="category.name + '-no-shape'">
                                                                            </div>
                                                                            <button class="ito-btn ito-btn-sm ito-btn-success" v-if="!customAvatar[category.id]"><i class=" ito-icon-checked-white-nb"></i></button>
                                                                        </div>
                                                                        <template v-for="shape in color.shape_details">
                                                                            <div class="avatar-item" :class="{ current: isSelectedShape(category, shape) }" @click="selectShape(category, shape, true)">
                                                                                <div class="avatar-item-wrapper">
                                                                                    <img :src="shape.thumbnail_url ? shape.thumbnail_url : shape.image_url" :alt="category.name + '-' + shape.id" v-if="shape.image_url || shape.thumbnail_url">
                                                                                    <div class="spinner-border spinner-border-sm" role="status" v-else></div>
                                                                                </div>
                                                                                <button class="ito-btn ito-btn-sm ito-btn-primary" v-if="shape.status == 'inactivated'" @click="buyShape(category, shape)">{{ shape.price }} <i
                                                                                        class="ito-icon-gem"></i></button>
                                                                                <button class="ito-btn ito-btn-sm ito-btn-success" v-if="isSelectedShape(category, shape) && shape.status == 'activated'"><i class="
                                                                                    ito-icon-checked-white-nb"></i></button>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="avatar-list py-3">
                                        <div class="col-2 avatar-item" :class="{ 'active': user_update_info.emoji_id == emoticon.id, 'current': emoticon.id == user_info.emoji_id }" v-for="emoticon in emoticons" @click="selectEmoticon(emoticon)">
                                            <div class="avatar-item-wrapper">
                                                <img :src="emoticon.url" alt="">
                                            </div>
                                            <button class="ito-btn ito-btn-sm ito-btn-primary" v-if="emoticon.price > 0">{{ emoticon.price }} <i class="ito-icon-gem"></i></button>
                                            <button class="ito-btn ito-btn-sm ito-btn-success" v-if="emoticon.id == user_info.emoji_id"><i class="ito-icon-checked-white-nb"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="original" class="avatar_container">
                <img class="img-fluid" :style="{ 'z-index': layer.order }" :src="layer.url" v-for="layer in customAvatar">
            </div>
        </div>

        <div class="modal fade" id="cropAvatarModal" tabindex="-1" aria-labelledby="cropAvatarModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content pb-2">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('edit_image') }}</h3>

                    <div class="modal-body">
                        <div class="image-crop-content">
                            <img id="image-crop" :src="avatar_crop_data">
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <button class="ito-btn ito-btn-primary" @click="cropSave">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="changeAvatarSuccessModal" tabindex="-1" aria-labelledby="changeAvatarSuccessModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('has_success') }}</h3>
                    <div class="modal-body text-center" style="padding: 0">
                        <div class="message">{{ $t('avatar_has_been_updated_successfully') }}</div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="errorModalProfile" tabindex="-1" aria-labelledby="errorModalProfile" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title">{{ $t('has_error') }}</h3>

                    <div class="modal-body text-center">
                        <div>
                            <img src="/themes/ito/images/image01/icon-error.svg" alt="">
                        </div>

                        <div class="message mt-3">{{ error_message }}</div>

                        <div class="d-flex justify-content-center mt-3">
                            <button class="ito-btn ito-btn-primary" data-bs-target="#uploadAvatarModal" data-bs-toggle="modal" data-bs-dismiss="modal">{{ $t('back') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="previewCustomavatar" tabindex="-1" aria-labelledby="previewCustomavatar" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="close-popup" data-bs-target="#uploadAvatarModal" data-bs-toggle="modal" data-bs-dismiss="modal"></div>
                    <div class="modal-body d-flex justify-content-center">
                        <div class="ratio-1x1" style="width: 100%; aspect-ratio: 1;">
                            <template v-for="layer in customAvatar">
                                <img ref="avatar_url" class="w-100 img-fluid position-absolute" :style="{ 'z-index': layer.order, 'left': 0 }" :src="layer.url">
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="confirmBuyItem" tabindex="-1" aria-labelledby="errorModalProfile" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content p-0">
                    <div class="modal-header border-0">
                        <h5 class="modal-title w-100 text-center ms-5">{{ $t('buy_item') }}</h5>
                        <div class="close-popup" data-bs-dismiss="modal"></div>

                    </div>
                    <div class="modal-body text-center">
                        <div class="message mt-3" v-html="$t('gems_to_buy', { gem: selectedShape.price })"></div>
                    </div>
                    <div class="modal-footer justify-content-center border-0">
                        <button class="ito-btn ito-btn-outline-primary" data-bs-target="#uploadAvatarModal" data-bs-toggle="modal" data-bs-dismiss="modal">{{ $t('no') }} </button>
                        <button class="ito-btn ito-btn-primary" data-bs-dismiss="modal" @click="buyItem()">{{ $t('yes') }} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getAvatars, updateAvatar, getCustomAvatars, buyCustomAvatar, saveCustomAvatar } from '../models/profile';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { domToDataUrl } from 'modern-screenshot'

export default {
    name: "AvatarEdit",

    data() {
        return {
            tab: 'home',
            avatars: [],
            curentCustomavatar: {},
            customAvatar: {},
            avatar: '',
            avatar_crop_data: '',
            emoticons: [],
            user_update_info: {},
            avatar_is_loading: false,
            is_changed: false,
            is_custom_avatar_changed: false,
            error_message: '',
            objects: [],
            selectedShape: {},
            selectedCategory: {},
        };
    },

    computed: {
        user_info() {
            return this.$store.state.user_info
        },
        customAvatarUpdated() {
            var customAvatar = {}
            this.curentCustomavatar.forEach(async (item) => {
                customAvatar[item.object_id] = {
                    id: '',
                    shape_id: item.shape_id,
                    shape_detail_id: item.shape_detail_id,
                    object_id: item.object_id,
                    color_id: item.color_id,
                    order: item.order,
                    url: item.image_url,
                    status: undefined
                }
            })
            return customAvatar
        }

    },

    created() {
        this.user_update_info = this.user_info
        this.fetch();
    },
    mounted() {
        this.$nextTick(function () {
            const that = this;
            $('#cropAvatarModal').on('shown.bs.modal', function () {
                that.cropper = new Cropper(
                    document.getElementById('image-crop'), {
                    aspectRatio: 1,
                    crop(event) {
                    }
                });
            });
            $('#cropAvatarModal').on('hiden.bs.modal', function () {
                that.cropper.destroy();
            })
        });
    },

    updated() {
        const slider = document.querySelector('#custom-avatar-nav');
        let isDown = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;
        if (slider) {
            slider.addEventListener("wheel", (e) => {
                e.preventDefault();
                const walk = e.deltaY * 0.1;
                slider.scrollLeft += walk;
            })
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });

            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const y = e.pageY - slider.offsetTop;
                const walk = (x - startX) * 1;
                const walktop = (y - startY) * 1;
                slider.scrollLeft = scrollLeft - walk;
                slider.scrollTop = scrollTop - walktop;
            });
        }
    },

    methods: {
        fetch() {
            this.getCustomAvatar();
            this.getAvatar();
        },

        getAvatar() {
            const that = this;
            axios.all([getAvatars()]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    return;
                }
                const items = response.data.results.items || [];
                var avatars = [], emoticons = [];
                items.forEach(function (item) {
                    if (item.type == 0) {
                        avatars.push(item)
                    }
                    if (item.type == 1) {
                        emoticons.push(item)
                    }
                });
                that.avatars = avatars;
                that.emoticons = emoticons;
            }));
        },

        selectAvatar(avatar) {
            this.user_update_info.avatar_url = avatar.url;
            this.user_update_info.avatar_id = avatar.id;
            if (this.user_info.avatar_id != avatar.id) {
                this.user_update_info.change_avatar = 1;
            }
            else {
                delete this.user_update_info.change_avatar
            }
            this.is_avatar_updated();
        },

        selectEmoticon(emoticon) {
            this.user_update_info.emoji_url = emoticon.url;
            this.user_update_info.emoji_id = emoticon.id;
            if (this.user_info.emoji_id != emoticon.id) {
                this.user_update_info.change_emoji = 1;
            }
            else {
                delete this.user_update_info.change_emoji
            }
            this.is_avatar_updated();
        },

        is_avatar_updated() {
            if (this.user_update_info.avatar_id != this.user_info.avatar_id || this.user_update_info.emoji_id != this.user_info.emoji_id) {
                this.is_changed = true
            } else {
                this.is_changed = false
            }
        },

        uploadAvatar({ target }) {
            const that = this;
            switch (target.name) {
                case 'avatar':
                    const reader = new FileReader();
                    const images = target.files;
                    if (images.length == 0) {
                        break;
                    }
                    const image = images[0];
                    if (image.size / (1024 * 1000) >= 10) {
                        this.showError('Kích thước ảnh tối đa cho phép tải lên là 10Mb. Vui lòng chọn ảnh khác.')
                        break;
                    }
                    that.avatar = image;
                    reader.onload = function (e) {
                        that.avatar_crop_data = e.target.result;
                    };

                    if (/\.(jpe?g|png|gif)$/i.test(image.name)) {
                        reader.readAsDataURL(image);
                    }
                    $('#uploadAvatarModal').modal('hide');
                    $('#cropAvatarModal').modal('show');
                    break;
            }
        },

        cropSave() {
            const that = this;
            that.$store.commit("setLoading", true);
            if (that.cropper) {
                const croppedImageDataURL = that.cropper.getCroppedCanvas().toDataURL("image/png");
                that.user_update_info.change_avatar = 1;
                updateAvatar({
                    avatar_id: -1,
                    avatar_img: croppedImageDataURL,
                    extension_file: 'png',
                }).then((response) => {
                    that.$store.commit("setLoading", false);
                    $('#cropAvatarModal').modal('hide');
                    $('#changeAvatarSuccessModal').modal('show');
                    that.cropper.destroy();
                    if (response.data.code != 0) {
                        that.showError(response.data.msg)
                        return;
                    }
                    that.user_info.avatar_id = response.data.results.avatar_id;
                    that.user_info.avatar_url = response.data.results.avatar_url ? response.data.results.avatar_url : "/themes/ito/images/avatar-default-orange.svg";
                    that.user_info.emoji_id = response.data.results.emoji_id;
                    that.user_info.emoji_url = response.data.results.emoji_url;
                    this.fetch();
                    this.$forceUpdate()
                }).catch((response) => {
                    that.$store.commit("setLoading", false);
                    $('#cropAvatarModal').modal('hide');
                    that.cropper.destroy();
                });
            } else {
                that.$store.commit("setLoading", false);
                console.error('Error. No image.')
            }

        },

        saveUpdate() {
            const that = this;
            that.$store.commit("setLoading", true);
            if (this.user_update_info.change_avatar != 0 && this.user_update_info.change_emoji != 0) {
                this.user_update_info.type = 2;
            } else if (this.user_update_info.change_avatar != 0) {
                this.user_update_info.type = 0;
            } else {
                this.user_update_info.type = 1;
            }
            updateAvatar({
                avatar_id: this.user_update_info.avatar_id == 0 ? -1 : this.user_update_info.avatar_id,
                avatar_img: this.user_update_info.avatar_img,
                emoji_id: this.user_update_info.emoji_id ? this.user_update_info.emoji_id : 0,
                type: this.user_update_info.type,
                extension_file: this.user_update_info.extension_file,
            }).then((response) => {
                that.$store.commit("setLoading", false);
                that.is_changed = false;
                if (response.data.code != 0) {
                    this.showError(response.data.msg)
                    return;
                }
                that.user_info.avatar_id = response.data.results.avatar_id;
                that.user_info.avatar_url = response.data.results.avatar_url ? response.data.results.avatar_url : "/themes/ito/images/avatar-default-orange.svg";
                that.user_info.emoji_id = response.data.results.emoji_id;
                that.user_info.emoji_url = response.data.results.emoji_url;
                this.fetch();
                $('#uploadAvatarModal').modal('hide');
                $('#changeAvatarSuccessModal').modal('show');
                this.$forceUpdate()
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                that.is_changed = false;
                console.error(response)
            });
        },

        showError(message) {
            this.error_message = message;
            $('.modal').modal('hide');
            $('#errorModalProfile').modal('show');
        },

        getCustomAvatar() {
            axios.all([getCustomAvatars()]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    return;
                }
                const objects = response.data.results.objects;
                const current_object = response.data.results.student_object
                this.curentCustomavatar = current_object

                if (objects) {
                    objects.forEach(object => {
                        if (current_object) {
                            var customAvatar = {}
                            current_object.forEach(async (item) => {
                                customAvatar[item.object_id] = {
                                    id: '',
                                    shape_id: item.shape_id,
                                    shape_detail_id: item.shape_detail_id,
                                    object_id: item.object_id,
                                    color_id: item.color_id,
                                    order: item.order,
                                    url: item.image_url,
                                    status: undefined
                                }
                            })
                            this.customAvatar = customAvatar
                        } else {
                            if (object.is_required == 1) {
                                if (object.shape_details) {
                                    const shape = object.shape_details[0]
                                    if (shape.status == 'activated') {
                                        this.selectShape(object, shape)
                                    }
                                }
                                else if (object.colors) {
                                    const color = object.colors[0]
                                    const shape = color.shape_details[0]
                                    if (shape.status == 'activated') {
                                        this.selectShape(object, shape)
                                    }
                                }
                            }
                        }
                    })
                }
                this.objects = objects;
            }));
        },

        async selectShape(category, shape, click = false) {

            if (shape.image_url) {
                let dataUri = shape.image_url;
                if (category && shape) {
                    this.customAvatar[category.id] = {
                        shape_id: shape.shape_id,
                        shape_detail_id: shape.id,
                        object_id: category.id,
                        color_id: shape.color_id,
                        order: category.order,
                        url: dataUri,
                        status: shape.status
                    }
                }
                const linked_object = this.objects.find((e) => e.linked_object_id != 0 && e.linked_object_id == category.id) || undefined;
                var linked_object_color, linked_object_shape;
                if (linked_object) {
                    if (linked_object.colors) {
                        linked_object_color = linked_object.colors.find((e) => e.id == shape.color_id)
                        linked_object_shape = linked_object_color.shape_details.find((e) => e.shape_id == shape.shape_id)
                    } else if (linked_object.shape_details) {
                        linked_object_shape = linked_object_color.shape_details.find((e) => e.shape_id == shape.shape_id)
                    }
                    if (linked_object_shape.image_url) {
                        let dataUri = linked_object_shape.image_url;

                        this.customAvatar[linked_object.id] = {
                            shape_id: linked_object_shape.shape_id,
                            shape_detail_id: linked_object_shape.id,
                            object_id: linked_object.id,
                            color_id: linked_object_shape.color_id,
                            order: linked_object.order,
                            url: dataUri,
                            status: linked_object_shape.status
                        }
                    }
                    else {
                        this.removeShape(linked_object)
                    }
                }
                this.is_custom_avatar_changed = click;

            }
            this.$forceUpdate()
        },

        removeShape(category) {
            delete this.customAvatar[category.id]
            this.$forceUpdate()
        },

        buyShape(category, shape) {
            this.selectedShape = shape
            this.selectedCategory = category
            $('#uploadAvatarModal').modal('hide')
            $('#confirmBuyItem').modal('show')
        },

        buyItem() {
            const that = this
            that.$store.commit("setLoading", true);
            buyCustomAvatar({
                shape_id: this.selectedShape.shape_id,
            }).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    this.error_message = response.data.msg
                    $('#errorModalProfile').modal('show')
                    return;
                }
                that.getCustomAvatar();
                this.$store.state.user_info.gem -= this.selectedShape.price
                this.selectShape(this.selectedCategory, this.selectedShape, true)
                $('#confirmBuyItem').modal('hide')
                $('#uploadAvatarModal').modal('show')
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                that.is_changed = false;
                console.error(response)
            });
        },

        async saveCustomAvatar() {
            const that = this;
            that.$store.commit("setLoading", true);
            const element = document.querySelector('#original')
            var formdata = new FormData;
            var customAvatar = Object.values(this.customAvatar)
            var student_object = customAvatar.map((item) => {
                return {
                    shape_id: item.shape_id,
                    shape_detail_id: item.shape_detail_id,
                    object_id: item.object_id,
                    color_id: item.color_id
                }
            })
            domToDataUrl(element).then(dataUrl => {
                formdata.append('student_object', JSON.stringify(student_object));
                formdata.append('avatar_img', dataUrl);
                formdata.append('extension', 'png');
                saveCustomAvatar(formdata).then((response) => {
                    that.$store.commit("setLoading", false);
                    const data = response.data
                    if (data.code != 0) {
                        this.showError(response.data.msg)
                        return;
                    } else {
                        this.$store.state.user_info.avatar_url = data.results.avatar_url
                        this.user_update_info.avatar_id = -1
                        this.fetch();
                        $('#uploadAvatarModal').modal('hide')
                        $('#changeAvatarSuccessModal').modal('show');
                    }

                }).catch((response) => {
                    that.is_custom_avatar_changed = false;
                    console.error(response)
                });
            })
        },

        isSelectedShape(category, shape) {
            var customAvatar = this.customAvatar[category.id]
            if (customAvatar) {
                if (customAvatar.shape_detail_id == shape.id) {
                    return true
                }
            }
            return false
        },

        isCustomavatarEdited() {
            var result = false;
            return result;
        },
    }
}
</script>

<style scoped>
#uploadAvatarModal .modal-dialog {
    max-width: 941px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

#uploadAvatarModal .modal-content {
    box-shadow: inset 0px 4px 0px rgba(231, 231, 231, 0.25);
    border-radius: 16px;
}

#uploadAvatarModal .avatar-preview {
    padding: 20px 35px;
    background-color: #ff7800;
    height: 440px;
    flex: 0 0 auto;
    width: 216px;
}

#uploadAvatarModal .avatar-preview .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}

#uploadAvatarModal .avatar-preview .avatar {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#uploadAvatarModal .avatar-preview .avatar .emoticon {
    background: #FFFFFF;
    box-shadow: 2px 2px 0px rgb(0 0 0 / 7%);
    width: 38px !important;
    height: 38px !important;
    border-radius: 100%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    border: solid 2px #fff;
}

#uploadAvatarModal .avatar-preview .avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 120px;
    padding: 0;
    margin: 0 auto;
    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 90em;
    box-shadow: none;
    background: #fff;
    overflow: hidden;
}

#customAvatar,
#original {
    position: relative;
    overflow: hidden;
}

#customAvatar img,
#original img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.avatar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 160px;
}



#uploadAvatarModal .avatar-preview .control {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.avatar-edit {
    width: 725px;
    height: 440px;
    max-height: 440px;
    flex: 0 0 auto;
}

#main-nav {
    border-bottom: 1px solid rgba(42, 57, 86, .2);
    margin: 0 106px 0 30px;
    height: 50px;
}

#main-nav .nav-item .nav-link {
    background-color: #fff;
    padding: 20px 20px 8px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: rgba(42, 57, 86, .5);
    border-top: none;
    border-left: none;
    border-right: none;
    transition: all .3s;
}

#main-nav .nav-item .nav-link:focus,
#main-nav .nav-item .nav-link:hover {
    border-bottom: solid 3px;
}

#main-nav .nav-item .nav-link.active {
    color: #F04B25;
    background-color: #fff;
    border-bottom: solid 3px #F04B25;
}

#main-tab-content {
    width: 100%;
    height: 390px;
    padding: 0 10px 10px 10px;
    overflow-y: auto
}

.avatar-list {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 20px;
}

.avatar-list .avatar-item {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.avatar-list .avatar-item .avatar-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    background: #F6F6F6;
    border-style: solid;
    border-width: 2px 2px 4px 2px;
    border-color: #DBDBDB;
    transition: all .5s;
    overflow: hidden;
}


.avatar-list .avatar-item.active>.avatar-item-wrapper,
.avatar-list .avatar-item:hover>.avatar-item-wrapper {
    border-color: var(--secondary);
}

.avatar-list .avatar-item.current>.avatar-item-wrapper {
    border-color: var(--success);

}

.avatar-list .avatar-item img,
.avatar-list .avatar-item-upload {
    width: 100%;
    height: auto;
    cursor: pointer;
}



#custom-avatar-nav {
    margin: 0 20px;
    border: none;
    gap: 4px;
    padding: 10px 0 5px 0;
    overflow-y: auto;
    position: sticky;
    top: 0;
    background: #FFFFFF;
    z-index: 1;

}

#custom-avatar-nav::-webkit-scrollbar-track {
    visibility: hidden;
}

#custom-avatar-nav::-webkit-scrollbar {
    width: 5px;
}

#custom-avatar-nav::-webkit-scrollbar-thumb {
    visibility: hidden;
}

#custom-avatar-nav:hover::-webkit-scrollbar-track,
#custom-avatar-nav:hover::-webkit-scrollbar-thumb {
    visibility: visible;

}


#custom-avatar-nav .nav-item .nav-link {
    background-color: #fff;
    color: var(--primary);
    border: 1px solid #FF7800;
    border-color: var(--primary);
    border-radius: 60px;
    padding: 4px 10px;
    text-wrap: nowrap;
}

#custom-avatar-nav .nav-item .nav-link.active {
    color: white;
    border-color: white;
    background-color: var(--primary);
}

/* #custom-avatar-tab-content {
    height: calc(100% - 53px);
} */


#colors-nav {
    gap: 12px;
    margin: 0 20px;
    border: none;
    padding: 10px 0;
}

#colors-nav .nav-item .nav-link {
    background-color: #fff;
    color: var(--primary);
    border-width: 2px 2px 4px 2px;
    border-style: solid;
    border-color: #E5E5E5;
    border-radius: 8px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#colors-nav .nav-item .nav-link div {
    width: 31px;
    height: 31px;
    border-radius: 8px;

}

#colors-nav .nav-item .nav-link.active {
    color: white;
    background-color: white;
    border-color: var(--success);
}

/* #colors-tab-content {
    height: calc(100% - 70px);
} */
</style>