import {PAGE_LIMIT } from '../constant'
import {get, post, post_1, get_1} from "../APIGateWay";

export async function getAllLastest(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-all-latest-lesson')
}

// export async function getOnlineTestData(params = {}) {
//     params.page = params.page || 1;
//     params.limit = params.limit || PAGE_LIMIT;
//     return await get(params, '/ot/examinations')
// }

export async function getOnlineTestData(data) {
    return await get_1(data, '/ot/examinations')
}

export async function getLastest(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-latest-lesson')
}

export async function getLiveClass(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/live_class/class-upcomming')
}

export async function getAllUnit(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, `/course/get-all-unit`)
}

export async function getListUnit(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-unit')
}

export async function getListLesson(params = {}) {
    params.page = params.page || 1;
    return await get(params, `/course/get-list-lesson`)
}

export async function getUnitLessonDetail(subject_id, unit_id, lesson_id) {
    const params = {
        subject_id: subject_id,
        unit_id: unit_id,
        lesson_id: lesson_id,
    }
    return await get(params, '/course/get-item')
}

export async function getUnitLessonItem(item_id, program) {
    // lang = lang || "vi"
    const params = {
        item_id: item_id,
        program: program,
    }
    return await get(params, '/course/get-item-by-id')
}

export async function getItemInfo(params = {}) {
    const programData = localStorage.getItem('program');
    const program = {
        id: 1,
        code: programData || "ismart",
        name: "iSMART",
    };
    params.program = program.code;
    return await get(params, '/item-info')

}

export async function getListSearch(params = {}) {
    params.page = params.page || 1;
    return await get(params, '/course/search/list')

}

export async function getReportUnit(params = {}) {
    params.page = params.page || 1;
    return await get(params, '/course/get-report-unit')
}

export async function getDiscussions(params = {}) {
    return await get(params, '/course/get-discuss')
}

export async function sendDiscuss(params) {
    return await post(params, '/course/send-discuss')
}
export async function deleteComment(params) {
    return await post(params, '/course/delete-discuss')
}

export async function editComment(params) {
    return await post(params, '/course/edit-discuss')
}

export function getEbook(params) {
    return get(params, '/course/get-ebook', params);
}

export function getEbookHome(params) {
    return get(params, '/ebook/ebook-home', params);
}
export function getEvents(params) {
    return get(params, '/battle-game/event', params);
}
export function getBanners(params) {
    return get(params,'/notification/get-banner',params);
}