<template>
    <modal-dialog ref="popup">
        <div class="reward-modal pyro">
            <a href="javascript:void(0)" class="close-modal" @click="closeModal">
                <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
            </a>
            <h2 v-if="title">{{ title }}</h2>
            <img class="reward-image" :src="image? image: '/themes/ito/images/isaac-rewards.svg'" alt="">
            <div id="congratulation_gem" v-if="reward">+{{ reward | toCommas }}</div>
            <div v-if="message" v-html="message"></div>
            <div class="btns">
                <button class="ito-btn ito-btn-default px-5" @click="_cancel" v-if="cancelButton">{{ cancelButton
                    }}</button>
                <button class="ito-btn ito-btn-primary px-5" @click="_confirm" v-if="okButton">{{ okButton }}</button>
            </div>
        </div>
    </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import { soundGetReward } from '../../utils/utils';

export default {
    name: "ClaimRewardDialogue",
    components: { ModalDialog },
    data: () => ({
        closeImg: '/images/close_btn.png',
        // Parameters that change depending on the type of dialogue
        title: undefined,
        image: undefined,
        reward: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: undefined, // text for cancel button

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),
    methods: {
        getSoundGetReward() {
            soundGetReward();
        },
        show(opts = {}) {
            this.title = opts.title
            if (opts.image) {
                this.image = opts.image
            }
            this.reward = opts.reward
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            // let user = this.$store.state.user_info;
            // user.gem += parseInt(this.reward)
            // user.gem_month += parseInt(this.reward)
            // this.getSoundGetReward();
            this.$refs.popup.close()
            this.resolvePromise(true)
            // window.location.reload()
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
        closeModal() {
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
.pyro:before,
.pyro:after {
    left: 0;
}
.window {
    padding: 0;
}

.reward-modal {
    padding: 20px 60px;
    border-radius: 16px;
    background-size: contain !important;
}
.reward-image{
    width: 65%;
    height: auto;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}

.btns {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 15px;
}

.cancel-btn {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background: linear-gradient(180deg, #CBCBCB 0%, #B3B3B3 100%);
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.07), inset 0 2px 0 rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 30px;
    padding: 6px 20px;
    font-size: 16px;
}

.ok-btn {
    text-decoration: none;
    padding: 6px 20px;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(180deg, #45BCFF 0%, #00A3FF 100%);
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.07), inset 0 2px 0 rgba(255, 255, 255, 0.2);
    border-radius: 30px;
}

.close-modal {
    position: absolute;
    right: 9px;
    top: 8px;
}

.close-modal img {
    max-width: 100%;
}
</style>