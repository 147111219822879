<template>
    <div>
        <template v-if="leader_board.length">
            <table class="ranking-board">
                <tbody>
                    <tr :class="{ active: user.student_id == user_info.user_id }" v-for="(user, index) in leader_board">
                        <td>
                            <img class="number" :src="'/themes/ito/images/lb-level-' + user.rank + '.svg'" alt=""
                                v-if="user.rank <= 3" />
                            <span class="number" v-else>{{ user.rank }}</span>
                        </td>

                        <td>
                            <div class="avatar">
                                <img :src="user.student_avatar" alt="" v-if="user.student_avatar" />
                                <img src="/themes/ito/images/avatar-default.svg" alt="" v-else />
                                <div class="emotion" v-if="user.student_emoji">
                                    <img :src="user.student_emoji" alt="">
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="name">
                                {{ user.full_name || user.username }}
                                <small>{{ user.username }}</small>
                            </div>
                        </td>

                        <td>
                            <div class="point">
                                <span v-html="convertPoint(user.point)"></span>
                                <small>{{ $t('battle_point') }}</small>
                            </div>
                        </td>

                        <td>
                            <div class="tropphies">
                                {{ user.trophy }}
                                <img :src="user.trophy_url" alt="" v-if="user.trophy_url">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28"
                                    fill="none" v-else>
                                    <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.9216 3.73828V2.34881H20.4126H20.418C20.7037 2.34881 20.9379 2.12077 20.9379 1.83002V0.518791C20.9379 0.233741 20.7094 0 20.418 0H20.4126H19.2183H4.58171C4.29606 0 4.06183 0.233741 4.06183 0.518791V1.83002C4.06183 2.11507 4.29606 2.34881 4.58171 2.34881H5.07912V3.73828H1.00527C0.496811 3.73828 0.0683331 4.13165 0.0226287 4.63904C0.0112026 4.73025 -0.171616 6.91374 0.731046 9.34806C1.55411 11.5734 3.45445 14.42 7.82851 15.5162C8.50626 16.0651 9.28275 16.4972 10.1271 16.7818L10.552 17.4812C11.0205 18.2508 11.2718 19.1743 11.2718 20.1207C11.2718 20.6965 11.1804 21.2723 10.9976 21.8082L10.7356 22.5781H9.40431C8.62162 22.5781 7.99318 23.2109 7.99318 23.9863V25.2871H6.02747C5.82751 25.2871 5.66184 25.4524 5.66184 25.652V27.6359C5.66184 27.8355 5.82751 28.0008 6.02747 28.0008H17.8431H18.9733H18.9743C19.1743 28.0008 19.3399 27.8355 19.3399 27.6359V25.652C19.3399 25.4524 19.1743 25.2871 18.9743 25.2871H18.9733H17.8431H17.0141V23.9863C17.0141 23.2052 16.3799 22.5781 15.603 22.5781H15.5972H14.4889H14.2652L14.0033 21.8082C13.8204 21.2723 13.729 20.7022 13.729 20.1207C13.729 19.7582 13.7659 19.399 13.8371 19.0509C13.9528 18.4896 14.1592 17.9561 14.4483 17.4812H14.4432L14.8666 16.7841C15.7138 16.4996 16.4929 16.0665 17.1726 15.516C21.5459 14.4195 23.4459 11.5732 24.2689 9.34806C25.1716 6.91374 24.9888 4.73025 24.9774 4.63904C24.9316 4.13165 24.5032 3.73828 23.9947 3.73828H19.9216ZM5.07912 9.76349C5.07912 10.7138 5.25883 11.6225 5.58617 12.4576C4.2158 11.5792 3.22001 10.3307 2.61064 8.72665C2.24501 7.76889 2.09075 6.84532 2.0222 6.1384C1.99935 5.91036 2.18216 5.71083 2.41068 5.71083H5.07912V9.76349ZM19.9216 9.76349V5.71083H22.5893C22.8178 5.71083 23.0006 5.91036 22.9778 6.1384C22.9092 6.84532 22.755 7.76889 22.3893 8.72665C21.7801 10.3303 20.7847 11.5785 19.4149 12.4569C19.742 11.622 19.9216 10.7135 19.9216 9.76349Z"
                                        fill="url(#paint0_linear_3851_105402)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_3851_105402" x1="4.37357" y1="0.350007"
                                            x2="18.9408" y2="27.7915" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.00273632" stop-color="#FFD800" />
                                            <stop offset="1" stop-color="#FFB000" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                        </td>

                        <td>
                            <div class="gem me-3" v-if="user.reward_gems > 0">
                                {{ user.reward_gems }}
                                <img class="icon-gem-bag" :src="user.thumbnail_url" alt="" v-if="user.thumbnail_url">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end gap-2 mt-3" v-if="leader_board.length">
                <button class="ito-btn ito-btn-primary" @click="rankingPrevpage" :disabled="page <= 1"><i
                        class="icon-prev-circle"></i></button>
                <button class="ito-btn ito-btn-primary" @click="rankingNextpage"
                    :disabled="page >= Math.ceil(totalrows / 10)"><i class="icon-next-circle"></i></button>
                <template v-if="my_rank.student_id">
                    <button class="ito-btn ito-btn-secondary" @click="fetch(1)" :disabled="page == 1"
                        v-if="my_rank.my_rank_index_page == page">{{
            $t('show_top') }}</button>
                    <button class="ito-btn ito-btn-secondary" @click="fetch(my_rank.my_rank_index_page)" v-else>{{
            $t('show_my_rank') }}</button>
                </template>
                <template v-else>
                    <button class="ito-btn ito-btn-secondary" @click="fetch(1)" :disabled="page == 1"
                        v-if="page != 1">{{
            $t('show_top') }}</button>
                </template>
            </div>
        </template>
        <h2 class="text-center m-5" v-else> {{ $t('no_leaderboard_record') }}
        </h2>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getRankings } from "../../models/events";

export default {
    name: "Rankings",
    props: ['eventId'],
    data() {
        return {
            my_rank: {},
            leader_board: [],
            page: 1,
            totalrows: undefined,
        };
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        }),
    },
    created() {
        this.fetch(this.page);
    },
    methods: {
        fetch(page) {
            const that = this;
            const params = {
                event_id: this.eventId,
                page: page ? page : that.page
            }
            that.page = params.page;
            this.$store.commit("setLoading", true);
            getRankings(params).then((response) => {
                if (response.data.code == 0 && response.data.results.leader_board.length) {
                    const rankings = response.data.results
                    const my_rank = rankings.my_rank
                    my_rank.my_rank_index_page = rankings.my_rank_index_page
                    this.my_rank = my_rank
                    this.leader_board = rankings.leader_board
                    this.totalrows = rankings.leader_board[0].totalrow
                }
                this.$store.commit("setLoading", false);
            })
        },

        rankingNextpage() {
            if (this.page > 0) {
                this.page += 1;
                this.fetch(this.page)
            }
        },

        rankingPrevpage() {
            if (this.page > 0) {
                this.page -= 1;
                this.fetch(this.page)
            }
        },

        convertPoint(point) {
            point = (point.toFixed(2) + "").split(".");
            var point_text = point[0] + ".<small>" + point[1] + "</small>"
            return point_text
        },
    },
};
</script>
<style scoped>
.ranking-board {
    border-radius: 8px;
    width: 100%;
    gap: 2px;
    overflow-y: auto;
    margin-top: 1rem;
    border-collapse: collapse;
    text-align: left;
}

.ranking-board tr::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
}

.ranking-board tr {
    min-height: 60px;
    border: 1px solid transparent;
    color: #14233F;
    font-size: 14px;
    font-weight: 700;
    position: relative;
}

.ranking-board tr.active::after {
    background: #98C923;
}

.ranking-board tr:first-child td:first-child {
    border-radius: 5px 0 0 5px;
}

.ranking-board tr:first-child td:last-child {
    border-radius: 0 5px 5px 0;
}

.ranking-board tr .number {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ranking-board tr .avatar {
    position: relative;
    width: 44px;
    height: 44px;
    box-shadow: none;
}

.ranking-board tr .avatar img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.ranking-board tr .avatar .emotion {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 20px;
    height: 20px;
}

.ranking-board tr .avatar .emotion img {
    border-radius: 50%;
    border: solid 2px #FFF;
    width: 100%;
    height: 100%;
}

.ranking-board tr .name {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
}

.ranking-board tr .point {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

.ranking-board tr .point span {
    font-size: 20px;
}

.ranking-board tr .point span small {
    font-weight: 700 !important;
}

.ranking-board tr .point small {
    font-size: 14px;
}


.ranking-board tr .tropphies {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

.ranking-board tr .tropphies img {
    width: 25px;
    height: 28px;
}

.ranking-board tr .gem {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #14233F;
    text-align: right;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.4px;
}

.ranking-board tr td {
    position: relative;
    z-index: 2;
    padding: 8px 12px;
}

.ranking-board tr td:last-child {
    width: 20% !important;
}

.ranking-board tr .gem img {
    width: 52px;
    height: 52px;
}

.icon-gem-bag {
    width: 52px;
    height: 52px;
}

.icon-next-circle {
    content: url('../../../public/themes/ito/images/battlegame/next-circle.svg');
    width: 24px;
    height: 24px;
}

.icon-prev-circle {
    content: url('../../../public/themes/ito/images/battlegame/next-circle.svg');
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
}
</style>