import {get, post, authHeader, post_1} from "../APIGateWay";

export async function getParentInfo(params = {}) {
    return await get(params, '/parent/parents-profile')
}

export async function getAcademicReport(data) {
    return await post_1(data, process.env.VUE_APP_ECO_URL + '/api_eco.php/getclasslist')
}
export async function getCertificateReport(data) {
    return await post_1(data, process.env.VUE_APP_ECO_URL + '/api_eco.php/getschoolyearcc')
}

export async function getEbookReport(params = {}) {
    return await get(params, '/ebook/report')
}

export async function getParentReportGrade(params = {}) {
    return await get(params, '/parent/grade-report')
}

export async function getParentReportMonth(params = {}) {
    return await get(params, '/parent/month-report')
}

export async function getParentReport(params = {}) {
    return await get(params, '/parent/parents-report')
}

export async function getCountry(params = {}) {
    return await get(params, '/parent/list-country')
}

export async function getProvince(params = {}) {
    return await get(params, '/parent/list-province')
}

export async function getDistrict(params = {}) {
    return await get(params, '/parent/list-district')
}