<template>
    <section class="four-zero-four">
        <div class="four-zero-four-container">
            <div class="error-code">404</div>
            <div class="error-message">Không tìm thấy trang theo yêu cầu</div>
        </div>
    </section>
</template>

<script>
    export default {
        created() {
            //this.$store.commit('SET_LAYOUT', 'simple-layout')
            //this.$store.commit('SET_LAYOUT', 'app-layout');
        }

    }
</script>

<style scoped>

</style>
