<template>
    <div id="content">
        <div class="container">
            <ul class="breadcrumb">
                <!--<li class="icon"><img src="/themes/ito/images/icon-profile.svg" alt=""></li>-->
                <router-link class="icon" :to="{ path: '/' }"><img src="/themes/ito/images/icon-profile.svg" alt="">
                </router-link>
                <li class="active">{{ $t('profile') }}</li>
            </ul>

            <div class="card card-profile">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{ $t('basic_info') }}</span>
                        <button type="button" class="ito-btn ito-btn-sm ito-btn-primary" @click="cfLogout">
                            {{ $t('log_out') }}
                            <i class="ito-icon ito-logout"></i>
                        </button>
                    </h5>
                    <div class="card-content d-flex justify-content-between gap-5">
                        <div class="profile-show">
                            <div class="profile-avatar-upload">
                                <div class="profile-avatar-upload-c" :style="{ backgroundImage: `url(${user_info.avatar_url})` }"></div>

                                <a href="#" class="upload-btn" data-bs-toggle="modal" data-bs-target="#uploadAvatarModal">
                                    <img src="/themes/ito/images/icon-camera-upload.svg" alt="" />
                                </a>

                                <div class="emoticon" v-if="user_info.emoji_url">
                                    <img src="/themes/ito/images/icon-emoticon-2.svg" :src="user_info.emoji_url" alt="" />
                                </div>
                            </div>

                            <div class="profile-avatar-info">
                                <div class="profile-info-item">{{ $t('user_id') }}: <span class="value">{{
                    user_info.user_id
                }}</span></div>
                                <div class="profile-info-item" v-show="user_info.student_type != 2">{{ $t('total') }}:
                                    <div class="profile-info-item-gem">
                                        <span class="value">{{ user_info.gem | toCommas }}</span><i class="ito-icon-1"></i>
                                    </div>
                                    <div class="profile-info-item-crown">
                                        <span class="value">{{ user_info.crown | toCommas }}</span><i class="ito-icon-2"></i>
                                    </div>
                                </div>
                                <div class="profile-info-item" v-show="user_info.student_type != 2">{{ $t('this_month')
                                    }}:
                                    <div class="profile-info-item-gem">
                                        <span class="value">{{ user_info.gem_month | toCommas }}</span><i class="ito-icon-1"></i>
                                    </div>
                                    <div class="profile-info-item-crown">
                                        <span class="value">{{ user_info.crown_month | toCommas }}</span><i class="ito-icon-2"></i>
                                    </div>

                                </div>
                            </div>

                            <div class="d-flex justify-content-center mt-3" v-if="user.student_type != 2">
                                <button class="ito-btn ito-btn-outline-primary" @click="changePassword()">{{
                    $t('change_password') }}
                                </button>
                            </div>
                        </div>
                        <div class="profile-update">
                            <form>
                                <div class="row">
                                    <label class="col-sm-12 col-form-label">{{ $t('full_name') }}</label>
                                    <div class="col-sm-12">
                                        <input type="text" name="fullname" class="form-control" v-model="user.fullname" :placeholder="$t('full_name')" @input="eventValid" :disabled="user_info.student_type == 1">
                                        <div class="error-message has-form" v-if="errors.fullname">{{ errors.fullname
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="user_info.student_type != 2">
                                    <div class="col-sm-6">
                                        <label class="col-form-label">{{ $t('birthday') }}</label>
                                        <div class="">
                                            <input readonly="true" type="text" name="birthday" class="form-control date datepicker birthday" v-model="user.birthday" autocomplete="off" :placeholder="$t('birthday')"
                                                :disabled="user_info.student_type == 1" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="col-form-label">{{ $t('gender') }}</label>
                                        <div class="">
                                            <select class="form-control" name="gender" v-model="user.gender" :disabled="user_info.student_type == 1" @change="eventValid">
                                                <option value="">{{ $t('select_gender') }}</option>
                                                <option value="male" selected>{{ $t('male') }}</option>
                                                <option value="female">{{ $t('female') }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="user_info.student_type != 2">
                                    <div class="col-sm-6" v-if="user_info.student_type == 1">
                                        <label class="col-form-label">{{ $t('class') }}</label>
                                        <div class="">
                                            <input type="text" name="class" class="form-control" :value="user_info.class" disabled autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="col-form-label">{{ $t('grade') }}</label>
                                        <div style="position: relative">
                                            <input type="text" name="grade" class="form-control" :value="user.grade" disabled autocomplete="off">
                                            <i :class="user_info.placement_test != 0 ? 'ito-icon-checked' : 'ito-icon-checked-disable'" style="position: absolute;top: calc(50% - 10px);right: 10px;"></i>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="user_info.student_type == 0 && user_info.placement_test == 0">
                                        <button type="button" style="margin-top: 40px;" class="ito-btn ito-btn-primary text-nowrap" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#placementModal">{{ $t('placement_test')
                                            }}</button>
                                    </div>
                                </div>
                                <div class="row" v-if="user_info.student_type == 2">
                                    <div class="col-sm-6">
                                        <label class="col-form-label">{{ $t('grade') }}</label>
                                        <div style="position: relative">
                                            <input type="text" name="grade" class="form-control" :value="user.grade" disabled autocomplete="off">
                                            <i v-if="(user_info.grade != 1 && user_info.placement_test != 0) || user_info.grade != 1" :class="user_info.placement_test != 0 ? 'ito-icon-checked' : 'ito-icon-checked-disable'"
                                                style="position: absolute;bottom: 12px;right: 10px;"></i>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="user_info.student_type == 2 && user_info.placement_test == 0 && user_info.grade != 1">
                                        <button type="button" style="margin-top: 40px;" class="ito-btn ito-btn-primary text-nowrap" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#placementModal">{{ $t('placement_test')
                                            }}</button>
                                    </div>
                                </div>
                                <div class="row" v-if="user_info.account_type != 1">
                                    <div class="col-sm-12">
                                        <label class="col-form-label">{{ $t('phone') }}</label>
                                        <div class="position-relative d-flex gap-3">
                                            <input type="text" name="grade" class="form-control custom-input  mb-0" :class="user.phone_verified ? 'is-valid' : 'is-warning'" :value="user.phone" disabled autocomplete="off">
                                            <router-link :to="{ name: 'account_verify', params: { tab: 'phone' } }" class="ito-btn ito-btn-warning ms-3" @click.native="getSoundClick" v-if="!user.phone_verified">
                                                {{ $t('btn_verify') }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="user_info.account_type != 1">
                                    <div class="col-sm-12">
                                        <label class="col-form-label">{{ $t('email') }}</label>
                                        <div class="position-relative d-flex gap-3">
                                            <input type="text" name="grade" class="form-control custom-input mb-0" :class="user.email_verified ? 'is-valid' : 'is-warning'" :value="user.email" disabled autocomplete="off">
                                            <router-link :to="{ name: 'account_verify', params: { tab: 'email' } }" class="ito-btn ito-btn-warning ms-3" @click.native="getSoundClick" v-if="!user.email_verified">
                                                {{ $t('btn_verify') }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="card-bottom d-flex justify-content-end" v-if="user_info.student_type == 0">
                        <button class="ito-btn ito-btn-primary" @click="onSubmit" :disabled="user_info.ismart_student == 1 || !is_valid">
                            {{ $t('save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="ChangePasswordPopup" tabindex="-1" role="dialog" aria-labelledby="ChangePasswordPopup" aria-hidden="true" data-backdrop="static" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered ito-popup forgot-popup" role="document">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <div class="forgot-popup-header">
                        <h3 class="popup-title">{{ $t('change_password') }}</h3>
                    </div>
                    <template>
                        <div class="forgot-popup-content">
                            <form method="post" action="#">
                                <p v-html="$t('password_description')"></p>
                                <div class="form-group">
                                    <label class="label-input" style="display: block">{{ $t('current_password') }}</label>
                                    <div class="input-pass">
                                        <input :type="showPass ? 'text' : 'password'" class="ito-input" name="current_password" :placeholder="$t('placeholder_current_password')" v-model="current_password" autocomplete="false">
                                        <span :class="[showPass ? 'icon-eye eye-show' : 'icon-eye eye-hide']" @click="showPass = !showPass"></span>
                                    </div>
                                    <div class="error-message text-start" v-if="errors.current_password != ''">
                                        {{ errors.current_password }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="label-input" style="display: block">{{ $t('new_password') }}</label>
                                    <div class="d-flex">
                                        <div class="input-pass">
                                            <input :type="showNewPass ? 'text' : 'password'" class="ito-input" name="new_password" :placeholder="$t('placeholder_new_password')" v-model="new_password" autocomplete="false">
                                            <span :class="[showNewPass ? 'icon-eye eye-show' : 'icon-eye eye-hide']" @click="showNewPass = !showNewPass"></span>
                                        </div>
                                        <div class="validate-new-pass" :class="classStrength" v-if="new_password">
                                            <div class="progres-status-pw d-flex">
                                                <div :class="strength > 0 ? 'progres-status-pw-item active' : 'progres-status-pw-item'">
                                                </div>
                                                <div :class="strength > 1 ? 'progres-status-pw-item active' : 'progres-status-pw-item'">
                                                </div>
                                                <div :class="strength > 2 ? 'progres-status-pw-item active' : 'progres-status-pw-item'">
                                                </div>
                                            </div>
                                            <div class="progres-text-pw">
                                                {{ $t('password_strength_' + textStrength) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="error-message text-start" v-if="errors.new_password != ''">
                                        {{ errors.new_password }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="label-input" style="display: block">{{ $t('confirm_password') }}</label>
                                    <div class="input-pass">
                                        <input :type="showConfirmPass ? 'text' : 'password'" class="ito-input" name="confirm_password" :placeholder="$t('placeholder_confirm_password')" v-model="confirm_password" autocomplete="false">
                                        <span :class="[showConfirmPass ? 'icon-eye eye-show' : 'icon-eye eye-hide']" @click="showConfirmPass = !showConfirmPass"></span>
                                    </div>
                                    <div class="error-message text-start" v-if="errors.confirm_password != ''">{{ errors.confirm_password }}</div>
                                </div>
                            </form>
                        </div>

                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center">
                                <div :class="(current_password != '' && new_password != '' && confirm_password != '' && current_password != new_password && errors.confirm_password == '' && errors.new_password == '') ? 'ito-btn-small active' : 'ito-btn-small'"
                                    @click="(current_password != '' && new_password != '' && confirm_password != '' && current_password != new_password && errors.confirm_password == '' && errors.new_password == '') ? changeNewPass() : ''">
                                    {{ $t('save') }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="modal fade" id="changePassSuccess" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered ito-popup" role="document" data-backdrop="static" data-keyboard=false>
                <div class="modal-content">

                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <div class="modal-success">
                        <h4 class="title">Password updated</h4>

                        <img src="/themes/ito/images/get-rewarded.svg" alt="">

                        <h6>Password has been updated successfully.</h6>
                    </div>
                </div>
            </div>
        </div>

        <avatar-edit></avatar-edit>

        <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" style="width: 380px;">
                <div class="modal-content">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('has_success') }}</h3>
                    <div class="modal-body text-center" style="padding: 0">
                        <div class="message">{{ $t('profile_update_success') }}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { updateProfile } from '../../models/profile'
import { changeNewPassword } from '../../models/auth'
import '/public/themes/ito/css/profile.css';
import AvatarEdit from "../AvatarEdit";


export default {
    name: "Profile",
    components: {
        AvatarEdit
    },
    data() {
        return {
            errors: {
                address: '',
                fullname: '',
                current_password: '',
                new_password: '',
                confirm_password: '',
            },
            user: {
                fullname: '',
                birthday: '',
                gender: '',
                grade: 0,
                type: 2,
                avatar_id: -1,
                avatar_id_before: -1,
                avatar_img: '',
                emoji_id: 0,
                emoji_id_before: 0,
                extension_file: 'png',
                student_type: 0,
                user_name: '',
                change_avatar: 0,
                change_emoji: 0,
                phone: '',
                email: '',
                phone_verified: 0,
                email_verified: 0,
            },
            showPass: false,
            showNewPass: false,
            showConfirmPass: false,
            avatar: '',
            avatars: [],
            emoticons: [],
            current_password: '',
            new_password: '',
            confirm_password: '',
            strength: 0,
            classStrength: '',
            textStrength: '',
            avatar_crop_data: '',
            is_show_button_update_avatar: true,
            disable_button_update_avatar: true,
            disable_button_update_emoticon: true,
            is_show_button_update_emoticon: false,
            cropper: null,
            error_message: '',
            is_valid: false,
        };
    },

    computed: {
        user_info() {
            const user = this.$store.state.user_info;

            this.user.fullname = user.fullname;
            this.user.birthday = user.birthday;
            this.user.gender = user.gender;
            this.user.grade = user.grade;
            this.user.avatar_id = user.avatar_id;
            this.user.avatar_id_before = user.avatar_id;
            this.user.emoji_id = user.emoji_id;
            this.user.emoji_id_before = user.emoji_id;
            this.user.emoji_url = user.emoji_url;
            this.user.avatar_img = user.avatar_url;
            this.user.student_type = user.student_type;
            this.user.user_name = user.username;
            this.user.phone = user.phone;
            this.user.phone_verified = user.phone_verified;
            this.user.email = user.email;
            this.user.email_verified = user.email_verified;
            this.user.change_avatar = 0;
            this.user.change_emoji = 0;
            //this.$store.state.user_info.student_type = 1;

            return this.$store.state.user_info
        }
    },
    mounted() {
    },
    created() {
        this.fetch();
    },
    watch: {
        'new_password': function (newVal, oldVal) {
            if (newVal == this.current_password) {
                this.errors.new_password = this.$i18n.t('new_password_validate_current_password');
            } else if (newVal == this.user.user_name) {
                this.errors.new_password = this.$i18n.t('new_password_validate_username');
            } else {
                this.errors.new_password = "";
            }
            this.strengPassword(newVal);
            this.errors.confirm_password = (newVal != this.confirm_password) ? this.$i18n.t('password_and_confirmation_password_do_not_match') : '';
        },
        'strength': function (newVal, oldVal) {
            if (newVal == 1) {
                this.classStrength = 'pw-weak';
                this.textStrength = this.$i18n.t('weak');
            } else if (newVal == 2) {
                this.classStrength = 'pw-normal';
                this.textStrength = this.$i18n.t('good');
            } else if (newVal == 3) {
                this.classStrength = 'pw-strong';
                this.textStrength = this.$i18n.t('strong');
            } else {
                this.classStrength = '';
                this.textStrength = '';
            }
        },
        'confirm_password': function (newVal, oldVal) {
            this.errors.confirm_password = (newVal != this.new_password) ? this.$i18n.t('password_and_confirmation_password_do_not_match') : '';
        }
    },
    methods: {
        fetch() {
            const that = this;
            this.$nextTick(function () {
                $('.birthday').datepicker({
                    dateFormat: 'dd/mm/yy',
                    changeMonth: true,
                    changeYear: true,
                    showOtherMonths: true,
                    selectOtherMonths: false,
                    yearRange: '1900:+0',
                    maxDate: new Date(),
                    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                }).on('change', (e) => {
                    this.searchEvent(e);
                });

                initNanoScroller();
                $('button[data-bs-toggle="tab"]').on('show.bs.tab', function (e) {
                    initNanoScroller();
                });
                $('button[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
                    if (e.currentTarget.id == 'home-tab') {
                        that.is_show_button_update_avatar = true;
                        that.is_show_button_update_emoticon = false;
                    }
                    if (e.currentTarget.id == 'profile-tab') {
                        that.is_show_button_update_avatar = false;
                        that.is_show_button_update_emoticon = true;
                    }

                    initNanoScroller();
                });
                $('#uploadAvatarModal').on('shown.bs.modal', function () {
                    initNanoScroller();
                });
            })
        },

        showError(message) {
            this.error_message = message;

            $('.modal').modal('hide');

            $('#errorModalProfile').modal();
        },

        searchEvent({ target }) {
            this.user[target.name] = target.value;

            this.is_valid = this.isChangeForm();
        },

        onSubmit(e) {
            const that = this;
            if (that.is_valid == false) {
                return false;
            }
            const formData = new FormData();

            formData.append('fullname', this.user.fullname);
            formData.append('birthday', moment(this.user.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            formData.append('gender', this.user.gender);
            //formData.append('grade', this.user.grade);

            that.$store.commit("setLoading", true);

            updateProfile(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    alert(response.data.msg)
                    return;
                }
                that.user_info.fullname = response.data.results.fullname;
                $('#successModal').modal('show');
                that.is_valid = false;
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });

            e.preventDefault();
        },

        cfLogout() {
            $('#confirmLogout').modal('show');
        },

        changePassword() {
            $('#ChangePasswordPopup').modal('show');
        },

        changeNewPass() {
            const that = this;
            const formData = new FormData();

            if (that.new_password.length < 8) {
                that.errors.new_password = 'The new password must be at least 8 characters.';
                return false;
            }
            formData.append('old_pass', that.current_password)
            formData.append('new_pass', that.new_password);
            formData.append('confirm_pass', that.confirm_password);

            that.$store.commit("setLoading", true);

            changeNewPassword(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    that.errors.current_password = "Your current password your entered is invalid.";
                    return;
                }
                $('#ChangePasswordPopup').modal('hide');
                $('#changePassSuccess').modal('show');
                that.resetPasswordState();
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },

        resetPasswordState() {
            this.current_password = '';
            this.new_password = '';
            this.confirm_password = '';
        },

        strengPassword(password) {
            let str1 = 1;
            let str2 = 0;
            let str3 = 0;
            (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) ? str2 = 1 : str2 = 0;
            (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) ? str3 = 1 : str3 = 0;
            this.strength = str1 + str2 + str3;
        },

        eventValid({ target }) {
            this.is_valid = this.isChangeForm();
            switch (target.name) {
                case 'fullname':
                    this.errors.fullname = '';
                    if (this.user.fullname.length > 50) {
                        this.errors.fullname = "Fullname max 50 characters.";
                        this.is_valid = false;
                    }
                    if (this.regexSpecialChar(this.user.fullname)) {
                        this.errors.fullname = "Fullname cannot contain special characters";
                        this.is_valid = false;
                    }

                    break;
            }
            return this.is_valid;
        },

        isChangeForm() {
            if (this.user_info.fullname != this.user.fullname
                || this.user_info.gender != this.user.gender
                || this.user_info.birthday != this.user.birthday) {
                return true;
            }

            return false;
        },

        regexSpecialChar(txt) {
            var specialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
            if (txt.match(specialRegex)) {
                return true;
            }
            return false;
        },
    }
}
</script>

<style scoped>

.icon-eye {
    top: 14px;
}

.form-control[readonly]:disabled {
    background: #EEF0F7;
}

.ito-input {
    width: 100%;
}

.form-group {
    margin-bottom: 0;
    margin-top: 10px;
}

p {
    margin-bottom: 0;
}

.label-input {
    margin-bottom: 0;
}

.error-message {
    margin-top: 5px;
}

/*only this page*/

.forgot-popup {
    width: 550px;
    max-width: 550px;
}

.forgot-popup .modal-content {
    position: relative;
    height: 470px;
    padding: 0px;
}

.forgot-popup-header {
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 0;
}

.forgot-popup-content {
    position: relative;
    height: 320px;
    padding: 20px 50px;
}

.forgot-popup-footer {
    border-top: 1px solid #E5E5E5;
    padding: 20px 0;
}

.popup-title {
    margin-bottom: 0;
}

.forgot-popup-content {
    font-size: 14px;
    line-height: 19px;
    margin: 0;
}

/*validate*/

.validate-new-pass {
    display: flex;
    height: 40px;
    margin-left: 10px;
    flex-direction: column;
    justify-content: space-evenly;
}

.progres-status-pw-item {
    width: 18px;
    height: 8px;
    border: 1px solid #000;
    border-radius: 3px;
    margin-right: 4px;
}

.progres-text-pw {
    display: block;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 700;
}

.pw-weak .progres-status-pw-item.active {
    background: #FA450F;
    border-color: #FA450F;
}

.pw-normal .progres-status-pw-item.active {
    background: #FFA800;
    border-color: #FFA800;
}

.pw-strong .progres-status-pw-item.active {
    background: #98C93C;
    border-color: #98C93C;
}

.pw-weak .progres-text-pw {
    color: #FA450F;
}

.pw-normal .progres-text-pw {
    color: #FFA800;
}

.pw-strong .progres-text-pw {
    color: #98C93C;
}

.modal-success {
    text-align: center;
}

.modal-success .title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.modal-success h6 {
    margin: 15px 0px 0px;
    font-size: 14px;
    line-height: 19px;
}

/* .input-pass {
    width: 280px;
} */

.cropper {
    height: 215px;
    background: #DDD;
}

.form-control[readonly] {
    background-color: #ffffff;
}

.error-message.has-form {
    margin-bottom: 10px;
    margin-top: -10px;
}

.ito-btn-small {
    border: 1px solid transparent;
}

.emo_show {
    opacity: 1;
}

.emo_hide {
    opacity: 0;
}

.profile-update .col-form-label {
    text-align: left;
}

.test_button {
    color: #40c0f6;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 40px;
    font-size: 15px;
}

.custom-input.is-warning {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7965 10.748L8.29845 1.24805C8.16655 1.02053 7.97718 0.831676 7.74932 0.70039C7.52145 0.569104 7.26309 0.5 7.00011 0.5C6.73713 0.5 6.47877 0.569104 6.2509 0.70039C6.02304 0.831676 5.83367 1.02053 5.70177 1.24805V1.24853L0.203722 10.748C0.071719 10.976 0.00208671 11.2347 0.00183176 11.4982C0.00157681 11.7616 0.0707082 12.0205 0.20227 12.2487C0.333832 12.4769 0.523184 12.6665 0.751272 12.7983C0.979361 12.9301 1.23814 12.9995 1.50157 12.9995H12.4986C12.7621 12.9995 13.0209 12.9301 13.2489 12.7983C13.477 12.6665 13.6664 12.4769 13.7979 12.2487C13.9295 12.0205 13.9986 11.7616 13.9984 11.4982C13.9981 11.2347 13.9285 10.976 13.7965 10.748ZM6.49962 5.5C6.49962 5.36739 6.5523 5.24021 6.64607 5.14645C6.73984 5.05268 6.86701 5 6.99962 5C7.13223 5 7.25941 5.05268 7.35318 5.14645C7.44694 5.24021 7.49962 5.36739 7.49962 5.5V8C7.49962 8.13261 7.44694 8.25978 7.35318 8.35355C7.25941 8.44732 7.13223 8.5 6.99962 8.5C6.86701 8.5 6.73984 8.44732 6.64607 8.35355C6.5523 8.25978 6.49962 8.13261 6.49962 8V5.5ZM6.99999 11.0002C6.85165 11.0002 6.70665 10.9562 6.58331 10.8738C6.45997 10.7914 6.36384 10.6742 6.30708 10.5372C6.25031 10.4001 6.23546 10.2493 6.2644 10.1039C6.29334 9.95838 6.36477 9.82474 6.46966 9.71985C6.57455 9.61496 6.70818 9.54353 6.85367 9.51459C6.99916 9.48565 7.14995 9.5005 7.287 9.55727C7.42404 9.61404 7.54118 9.71017 7.62359 9.8335C7.706 9.95684 7.74999 10.1018 7.74999 10.2502C7.74998 10.4491 7.67096 10.6398 7.53031 10.7805C7.38966 10.9211 7.1989 11.0002 6.99999 11.0002Z" fill="%23FA450F"/></svg>');
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-input.is-valid {
    border-color: rgba(228, 228, 228, 0.6);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.7682 6.64018C14.1218 6.21591 14.0645 5.58534 13.6402 5.23178C13.2159 4.87821 12.5853 4.93554 12.2318 5.35982L8.63383 9.67736C8.28711 10.0934 8.09219 10.3238 7.93658 10.4653L7.93063 10.4707L7.92422 10.4659C7.75643 10.339 7.54146 10.1272 7.1585 9.74428L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5.74428 11.1585L5.78511 11.1993L5.78512 11.1993C6.11161 11.526 6.4257 11.8402 6.71794 12.0611C7.0453 12.3087 7.47402 12.5415 8.02343 12.5165C8.57284 12.4916 8.97872 12.221 9.28233 11.9448C9.55337 11.6983 9.83772 11.3569 10.1333 11.0021L10.1333 11.0021L10.1703 10.9577L13.7682 6.64018Z" fill="%2398C93C"/></svg>');
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

@media (min-width: 768px) {
    .card.card-profile>.card-body>.card-content {
        padding: 30px 50px;
    }
}
</style>