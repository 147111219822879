<template>
    <div class="collection-item me-3">
        <div class="collection-desc">
            <p :title="$t(mission.desc)">{{ $t(mission.desc) }}</p>
        </div>
        <div class="collection-progress">
            <div class="progress">
                <div class="progress-process" :style="{ 'width': (100 * mission.process / mission.max_process) + '%' }">
                </div>
                <span>{{ mission.process }}/{{ mission.max_process }}</span>
            </div>
            <button class="ito-btn ito-btn-sm w-100" :class="mission.status == 1 ? 'ito-btn-primary' : mission.status == 2 ? 'ito-btn-success' : ''" @click="claim" :disabled="mission.status == 0">
                {{ mission.status == 2 ? $t('claimed') : $t('claim') }} {{ mission.key_reward }} <i class="ito-icon-lock-white"></i>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { claimKeyDailyChallenge } from "../../models/reward";
import { soundClick, soundGetReward, soundClaimKey } from '../../utils/utils';

export default {
    name: "DailyChallengeMissionItem",
    props: ['mission'],
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        })
    },

    data() {
        return {};
    },

    created() {

    },

    methods: {
        claim() {
            const that = this;
            that.user_info.dailychallenge_noti = 1;
            that.getSoundClick();
            this.handleGACustomEvent('ITC_W_0001');
            claimKeyDailyChallenge({
                id: that.mission.id,
            }).then((response) => {
                if (response.data.code != 0) {
                    return;
                }
                const new_key_collect = response.data.results.key_collect || 0;
                const new_key_reward = response.data.results.key_reward || 0;
                const new_mission_id = response.data.results.id || that.mission.id;
                const new_mission_status = response.data.results.status || that.mission.status;
                const new_challenges = response.data.results.challenge || [];
                that.user_info.dailychallenge_noti = response.data.results.dailychallenge_noti || 0;
                this.$emit('updateNewMission', new_key_collect, new_mission_id, new_mission_status, new_key_reward, new_challenges)
                const gem_txt = new_key_reward > 0 ? '+' + new_key_reward : new_key_reward;
                $('#congratulation_lock').text(gem_txt);
                that.getSoundClaimKey();
                $('#getRewardedLockModal').modal();
            }).catch(({ response }) => {
                console.error(response);
            });
        },

        getSoundClick() {
            soundClick();
        },

        getSoundGetReward() {
            soundGetReward();
        },

        getSoundClaimKey() {
            soundClaimKey();
        },
        handleGACustomEvent(element) {
            var user_id = this.user_info.user_id || 0;
            var school_id_ref = parseInt(this.user_info.school_id_ref) || 0;
            var params = {
                'w_user_id': user_id,
                'school_id': school_id_ref
            };
            params[element] = user_id + '|' + school_id_ref;
            gtag('event', element, params);
        },
    }
}
</script>

<style scoped>
.collection-item {
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* width: 430px; */
    height: 76px;
    background: #fff;
    border: 2px solid #E9E9E9;
    border-radius: 7px;
    margin: 10px 0;
}

.collection-item .collection-desc {
    /* width: 250px; */
    overflow: hidden;
}

.collection-item .collection-desc p {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #1e263c;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.collection-item .collection-progress {
    /* width: 100px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
</style>