import {get, post} from "../APIGateWay";

export async function getTutorings(params = {}) {
    return await get(params, '/tutoring/list')
}
export async function joinLiveClass(params = {}) {
    return await get(params, '/live_class/join')
}
export async function getClassess(params = {}) {
    return await get(params, '/live_class/list')
}

export async function tutoringSubscribe(data) {
    return await post(data, '/tutoring/subscribe')
}

export async function joinClass(params = {}) {
    return await get(params, '/tutoring/join')
}