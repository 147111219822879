<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/dashboard.css">
        <link rel="stylesheet" href="/themes/ito/css/unit-detail.css?v=1">
        <div class="container">
            <template v-if="isLoading == true">
                <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                </content-placeholders>
            </template>
            <template v-else>
                <h2 v-if="units.length == 0"></h2>
                <div v-if="units.length != 0">
                    <div id="page-header-fixed">
                        <div class="container">
                            <div id="page-header">
                                <ul class="breadcrumb">
                                    <li class="icon">
                                        <a @click="$router.go(-1)">
                                            <img src="/themes/ito/images/back-to.svg" alt="">
                                        </a>
                                    </li>
                                    <li class="active">{{ units.name }} - {{ units.title }}</li>
                                </ul>
                                <div class="tools">
                                    <nav class="ito-tabs">
                                        <a :class="tab == 'overview' ? 'active' : ''" @click="changeTab('overview')">
                                            {{ $t('overview') }}
                                        </a>
                                        <!-- <a :class="tab == 'discuss' ? 'active' : ''" @click="changeTab('discuss')"
                                            v-if="user_info.account_type != 1">{{ $t('discussion') }}</a> -->
                                        <a :class="tab == 'report' ? 'active' : ''" @click="changeTab('report')">
                                            {{ $t('report') }}
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab == 'overview'">
                        <div class="card card-daily-challenge box-shadow-main">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <span class="title">{{ units.title }}</span>
                                </h5>
                                <div class="card-content">
                                    <div class="d-flex flex-row gap-3">
                                        <div class="unit-thumb">
                                            <img :src="units.image" alt="" width="210px">
                                        </div>
                                        <div class="info-content d-flex flex-column justify-content-between">
                                            <div class="desc">
                                                {{ units.desc }}
                                            </div>
                                            <div class="d-flex align-items-center mt-3" v-if="user_info.student_type == 1 && units.ebook != ''">
                                                <router-link @click.native="handleGACustomEvent('ITC_W_2000', units.id)" class="ito-btn ito-btn-primary" :to="{ path: '/ebook/' + units.id }" target="_blank">
                                                    {{ $t('ebook_digital') }}
                                                    <img class="ms-1" src="/themes/ito/images/icons/ebook1.svg">
                                                </router-link>
                                                <span style="margin-left: 8px; font-size: 12px;">
                                                    (page {{ rebuildEbookPage(units.ebook_page_range) }})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="unit-detail" class="card card-daily-challenge mt-3">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <span class="title">{{ $t('curriculum') }} <span>({{ units.total }} {{ units.total > 1 ?
                                        $t('lessons') : $t('lesson') }})</span></span>
                                    <!-- <span class="title">{{ $t('curriculum') }}</span> -->
                                    <div class="tools">
                                        <nav class="ito-tabs">
                                            <a :class="type == 1 ? 'active' : ''" @click="changeType(1)" style="cursor: pointer">{{ $t('lessons') }}</a>
                                            <a :class="type == 2 ? 'active' : ''" @click="changeType(2)" style="cursor: pointer">{{ $t('extension') }}</a>
                                        </nav>
                                    </div>
                                </h5>
                                <div class="card-content" v-if="type == 1">
                                    <div class="row">
                                        <template v-for="(lesson, index) in units.lessons">
                                            <div class="col-md-4" v-if="lesson.status == 0">
                                                <router-link :to="{ path: '/lesson/' + subject_id + '-' + units.id + '-' + lesson.id }">
                                                    <div class="lesson-item">
                                                        <div class="lesson-item-body">
                                                            <h4 class="title" style="color: #AAABB7; font-size: 16px; line-height: 19px">
                                                                Lesson {{ index + 1 }}</h4>
                                                            <div class="desc">{{ lesson.title }}</div>
                                                        </div>
                                                        <div class="lesson-item-bottom" v-bind:class="lesson.process == lesson.total && lesson.total != 0 ? 'complete' : ''">
                                                            <div class="flex">
                                                                <template v-for="(item, i) in lesson.items">
                                                                    <i class="ito-icon-checked" :title="item.name" v-if="item.complete == 1 && item.type != 'handouts'"></i>
                                                                    <i class="ito-icon-checked-disable" :title="item.name" v-if="item.complete == 0 && item.type != 'handouts'"></i>
                                                                </template>
                                                            </div>
                                                            <div> {{ $t('complete_of', { process: lesson.process, total: lesson.total }) }} </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                            <div class="col-md-4" v-else>
                                                <div class="lesson-item lock-unit" style="overflow: hidden;" @click="clickLockedItem(lesson, index)">
                                                    <img class="lock-image" src="/themes/ito/images/icons/icon-lock-new.svg" />
                                                    <div class="lesson-item-body">
                                                        <h4 class="title" style="color: #AAABB7; font-size: 16px; line-height: 19px">
                                                            {{ $t('lesson') }} {{ index + 1 }}</h4>
                                                        <div class="desc">{{ lesson.title }}</div>
                                                    </div>
                                                    <div class="lesson-item-bottom" v-bind:class="lesson.process == lesson.total ? 'complete' : ''">
                                                        <div class="flex">
                                                            <template v-for="(item, i) in lesson.items">
                                                                <i class="ito-icon-checked" :title="item.name" v-if="item.complete == 1 && item.type != 'handouts'"></i>
                                                                <i class="ito-icon-checked-disable" :title="item.name" v-if="item.complete == 0 && item.type != 'handouts'"></i>
                                                            </template>
                                                        </div>
                                                        <div>
                                                            {{ $t('complete_of', {
                                                                process: lesson.process, total:
                                                                    lesson.total
                                                            }) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="card-content" v-if="type == 2">
                                    <div class="row">
                                        <template v-for="(extension, index) in units.extentions">
                                            <div class="col-md-4" v-if="extension.status == 0">
                                                <router-link :to="{ path: '/extension/' + subject_id + '-' + units.id + '-' + extension.id }">
                                                    <div class="lesson-item">
                                                        <div class="lesson-item-body">
                                                            <h4 class="title" style="color: rgb(170, 171, 183);font-size: 16px;line-height: 19px;">
                                                                {{ extension.name }}</h4>
                                                            <div class="desc">{{ extension.title }}</div>
                                                        </div>
                                                        <div class="lesson-item-bottom" v-bind:class="extension.process == extension.total && extension.total != 0 ? 'complete' : ''">
                                                            <div class="flex">
                                                                <template v-for="(item, i) in extension.items">
                                                                    <i class="ito-icon-checked" :title="item.name" v-if="item.complete == 1"></i>
                                                                    <i class="ito-icon-checked-disable" :title="item.name" v-else></i>
                                                                </template>
                                                            </div>
                                                            <div>
                                                                {{ $t('complete_of', { process: extension.process, total: extension.total }) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                            <div class="col-md-4" v-else>
                                                <div class="lesson-item lock-unit" style="overflow: hidden;" @click="clickLockedItem(extension, index)">
                                                    <img class="lock-image" src="/themes/ito/images/icons/icon-lock-new.svg" />
                                                    <div class="lesson-item-body">
                                                        <h4 class="title" style="color: rgb(170, 171, 183);font-size: 16px;line-height: 19px;">
                                                            {{ extension.name }}</h4>
                                                        <div class="desc">{{ extension.title }}</div>
                                                    </div>
                                                    <div class="lesson-item-bottom" v-bind:class="extension.process == extension.total && extension.total != 0 ? 'complete' : ''">
                                                        <div class="flex">
                                                            <template v-for="(item, i) in extension.items">
                                                                <i class="ito-icon-checked" :title="item.name" v-if="item.complete == 1"></i>
                                                                <i class="ito-icon-checked-disable" :title="item.name" v-else></i>
                                                            </template>
                                                        </div>
                                                        <div>
                                                            {{ $t('complete_of', {
                                                                process: extension.process, total:
                                                                    extension.total
                                                            }) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div v-if="tab == 'discuss'">
                        <div class="modal fade" id="deleteConfirm" tabindex="-1" role="dialog" aria-labelledby="deleteConfirm" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                                <div class="modal-content box-shadow-main paymment-status">
                                    <div class="close-popup" data-bs-dismiss="modal"></div>
                                    <h3 class="popup-title success">{{ $t('delete_comment') }}</h3>
                                    <p class="mt-20 mb-20">{{ $t('delete_comment_confirm') }}</p>
                                    <div class="form-group d-flex justify-content-between">
                                        <div class="ito-btn-small" data-bs-dismiss="modal">{{ $t('no') }}</div>
                                        <div class="ito-btn-small active" data-bs-dismiss="modal" @click="deleteComment">
                                            {{ $t('yes') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="deleteConfirmCommenter" tabindex="-1" role="dialog" aria-labelledby="deleteConfirmCommenter" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                                <div class="modal-content box-shadow-main paymment-status">
                                    <div class="close-popup" data-bs-dismiss="modal"></div>
                                    <h3 class="popup-title success">{{ $t('delete_comment') }}</h3>
                                    <p class="mt-20 mb-20">{{ $t('delete_comment_confirm') }}</p>
                                    <div class="form-group d-flex justify-content-between">
                                        <div class="ito-btn-small" data-bs-dismiss="modal">{{ $t('no') }}</div>
                                        <div class="ito-btn-small active" data-bs-dismiss="modal" @click="deleteCommentCommenter">
                                            {{ $t('yes') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="inputComment" tabindex="-1" role="dialog" aria-labelledby="inputComment" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 550px;">
                                <div class="modal-content box-shadow-main paymment-status">

                                    <div class="form">
                                        <div class="reply-to">{{ $t('replies_to') }} {{ curUserReply }} :</div>

                                        <input v-on:keydown.enter="replyComment" type="text" :placeholder="$t('write_a_comment')" v-model="currContent" />
                                        <button v-bind:disabled="currContent === ''" v-if="inputType == 'REPLY'" class="btn btn-default" @click="replyComment">{{ $t('send') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="editConfirm" tabindex="-1" role="dialog" aria-labelledby="editConfirm" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 550px;">
                                <div class="modal-content-edit  box-shadow-main-edit paymment-status">

                                    <div class="form">
                                        <input v-on:keyup.enter="editComment" type="text" :placeholder="$t('write_a_comment')" v-model="currContent" />
                                        <button type="submit" v-bind:disabled="currContent === ''" v-if="inputType == 'EDIT'" class="btn btn-default" @click="editComment">
                                            {{ $t('send') }}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="editConfirmCommenter" tabindex="-1" role="dialog" aria-labelledby="editConfirmCommenter" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 550px;">
                                <div class="modal-content-edit  box-shadow-main-edit paymment-status">

                                    <div class="form">
                                        <input v-on:keyup.enter="editCommentCommenter" type="text" :placeholder="$t('write_a_comment')" v-model="currContent" />
                                        <button type="submit" v-bind:disabled="currContent === ''" v-if="inputType == 'EDIT'" class="btn btn-default" @click="editCommentCommenter">
                                            {{ $t('send') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="" class="card card-comments">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <span class="title">{{ $t('there_are') }} <b>{{ total_comment }}</b> {{ total_comment >
                                        1 ?
                                        $t('comments') : $t('comment') }}</span>
                                </h5>

                                <div class="card-content" style="min-height: 420px;">
                                    <div v-if="discuss.length != 0">
                                        <div class="nano" style="height: 360px;">
                                            <div class="nano-content">
                                                <template v-for="(dc, index) in discuss">
                                                    <div class="comment-item">
                                                        <div class="comment-item-avatar">
                                                            <div class="thumb">
                                                                <div class="thumb-content">
                                                                    <img :src="dc.avatar ? dc.avatar : '/themes/ito/images/avatar-default.svg'" alt="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="comment-item-info">
                                                            <div class="header" :style="{ zIndex: 100 - index }">
                                                                <div class="title">{{ dc.fullname ? dc.fullname :
                                                                    dc.username }}
                                                                    {{ user_info.username == dc.username ? '(ME)' : '' }}
                                                                </div>
                                                                <div class="time">
                                                                    <p style="margin-top: 3px;">{{ timeConverter(dc.time) }}
                                                                    </p>
                                                                    <template v-if="user_info.username != dc.username">
                                                                        <div @click="replyCommentConfirm(dc)" style="margin-left: 5px">
                                                                            <a class="reply-comment">
                                                                                <img src="/themes/ito/images/faqs/edit-2.svg" />
                                                                            </a>
                                                                        </div>
                                                                    </template>
                                                                    <div v-if="dc.can_edit == 1" style="margin-left: 5px" class="edit-cmt" v-on:click="showEdit(index)" :class="(editItem === index) ? 'active' : ''">
                                                                        <template v-if="user_info.username == dc.username">
                                                                            <a class="edit-comment">
                                                                                <!--<img src="/themes/ito/images/faqs/edit-2.svg"/>-->
                                                                                <img src="/themes/ito/images/faqs/note-2.svg" />
                                                                            </a>
                                                                            <div class="box-edit" @focusout="resetEdit" style="height: 150px;">
                                                                                <button class="edit-li" style="height: 50px; text-align: left; padding-left: 25px" @click="editCommentConfirm(dc.id, dc.content)">
                                                                                    <img src="/themes/ito/images/faqs/edit.svg" />

                                                                                    {{ $t('edit_your_comment') }}
                                                                                </button>

                                                                                <button class="delete-li" style="height: 50px; text-align: left; padding-left: 25px" @click="replyCommentConfirm(dc)">
                                                                                    <img width="24" height="24" src="/themes/ito/images/faqs/message.svg" />
                                                                                    {{ $t('reply_comment') }}
                                                                                </button>

                                                                                <button class="delete-li" style="height: 50px; text-align: left; padding-left: 25px" @click="deleteConfirm(dc.id)">
                                                                                    <img width="24" height="24" src="/themes/ito/images/faqs/trash2.svg" />
                                                                                    {{ $t('delete_your_comment') }}
                                                                                </button>

                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="desc">
                                                                {{ dc.content }}
                                                                <!--<div class="form">-->
                                                                <!--<input type="text" placeholder="Write your comment" v-model="content"/>-->

                                                                <!--<button class="btn btn-default" @click="sendComment">Send</button>-->
                                                                <!--</div>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="dc.commenter">
                                                        <div class="replies" v-if="dc.show_reply != 1">
                                                            <img class="replies-img" src="/themes/ito/images/faqs/curved-arrow.svg" />
                                                            <button class="button-replies" @click="showReply(dc)">
                                                                {{ dc.commenter.length }} {{ $t('replies') }}
                                                            </button>

                                                        </div>
                                                        <!--                                                <transition name="bounce">-->
                                                        <div class="card-content-commenter" v-if="dc.show_reply == 1">

                                                            <div class="commenter" v-if="dc.commenter" v-for="(commenter, index1) in dc.commenter">
                                                                <div class="comment-item">
                                                                    <div class="comment-item-avatar">
                                                                        <div class="thumb">
                                                                            <div class="thumb-content">
                                                                                <img :src="commenter.avatar ? commenter.avatar : '/themes/ito/images/avatar-default.svg'" alt="">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="comment-item-info">
                                                                        <div class="header" :style="{ zIndex: 100 - index1 }">
                                                                            <div class="title">
                                                                                {{ commenter.fullname ? commenter.fullname :
                                                                                    commenter.username }}
                                                                                {{ user_info.username == commenter.username
                                                                                    ?
                                                                                    '(ME)' : '' }}</div>
                                                                            <div class="time">
                                                                                <p style="margin-top: 3px;">
                                                                                    {{ timeConverter(commenter.time) }}</p>

                                                                                <div v-if="commenter.can_edit == 1" style="position: relative;margin-left: 5px" class="edit-cmt" v-on:click="showEditCommenter((index + 1) * (discuss.length) + index1)"
                                                                                    :class="(editItem === ((index + 1) * (discuss.length) + index1)) ? 'active' : ''">
                                                                                    <a class="edit-comment">
                                                                                        <img src="/themes/ito/images/faqs/note-2.svg" />
                                                                                    </a>
                                                                                    <div class="box-edit " @focusout="resetEditCommenter">
                                                                                        <div class="button-edit">
                                                                                            <button class="edit-li" @click="editCommentConfirmCommenter(commenter)">
                                                                                                <img src="/themes/ito/images/faqs/edit.svg" />
                                                                                                {{ $t('edit_your_comment')
                                                                                                }}
                                                                                            </button>
                                                                                            <button class="delete-li" @click="deleteConfirmCommenter(commenter)">
                                                                                                <img width="24" height="24" src="/themes/ito/images/faqs/trash2.svg" />
                                                                                                {{ $t('delete_your_comment')
                                                                                                }}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="desc">
                                                                            {{ commenter.content }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-bottom-commenter">

                                                                <div class="form">
                                                                    <input v-on:keyup.enter="replyComment_commenter(dc)" type="text" :placeholder="$t('write_a_comment')" v-model="dc.reply_content" />
                                                                    <button v-bind:disabled="dc.reply_content === ''" class="btn btn-default " @click="replyComment_commenter(dc)">{{ $t('send') }}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <!--                                                </transition>-->

                                                    </div>

                                                </template>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <!--<div class="card-bottom">-->
                                <!--<div class="form">-->
                                <!--<input v-on:keyup.enter="sendComment" type="text"-->
                                <!--:placeholder="$t('write_a_comment')" v-model="content"/>-->

                                <!--<button v-bind:disabled="content===''" class="btn btn-default"-->
                                <!--@click="sendComment">{{$t('send')}}-->
                                <!--</button>-->
                                <!--</div>-->
                                <!--</div>-->

                                <div class="card-bottom">
                                    <div class="title-rep" v-if="commentType == 'EDIT'">{{ $t('editing_comment_text') }} <a class="reset-commenter" @click="resetDiscuss">{{ $t('cancel') }}</a></div>
                                    <div class="title-rep" v-if="commentType == 'REPLY'">{{ $t('replies_to') }}
                                        {{ curUserReply }} <a class="reset-commenter" @click="resetDiscuss">{{ $t('cancel')
                                        }}</a></div>
                                    <div class="form">
                                        <template v-if="commentType == 'NORMAL'">
                                            <input v-on:keyup.enter="sendComment" type="text" :placeholder="$t('write_a_comment')" v-model="content" />

                                            <button v-bind:disabled="content === ''" class="btn btn-default" @click="sendComment">{{ $t('send') }}
                                            </button>
                                        </template>

                                        <template v-if="commentType == 'EDIT'">
                                            <input v-on:keyup.enter="editComment" type="text" :placeholder="$t('write_a_comment')" v-model="currContent" />
                                            <button type="submit" v-bind:disabled="currContent === ''" v-if="inputType == 'EDIT'" class="btn btn-default" @click="editComment">
                                                {{ $t('send') }}
                                            </button>
                                        </template>
                                        <template v-if="commentType == 'REPLY'">
                                            <input v-on:keydown.enter="replyComment" type="text" :placeholder="$t('write_a_comment')" v-model="currContent" />
                                            <button v-bind:disabled="currContent === ''" v-if="inputType == 'REPLY'" class="btn btn-default" @click="replyComment">{{ $t('send') }}
                                            </button>
                                        </template>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div v-if="tab == 'report'">
                        <div id="rp" class="card-report">
                            <div class="reports-content">
                                <div v-if="reports.length != 0">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <template v-for="(lesson, index) in reports.lessons">
                                                <div class="lesson-report-container">
                                                    <div class="lesson-item-lv report-items" v-on:click="itemTrigger(index)" v-bind:class="{ 'active': (currentItem === index) }">
                                                        <h4 class="title" style="letter-spacing: 0;">{{ lesson.name }}</h4>
                                                        <div class="desc">{{ lesson.title }}</div>
                                                        <div class="success">
                                                            <i :class="calcPercent(lesson.process, lesson.total) == 100 ? 'ito-icon-checked' : ''"></i>
                                                        </div>
                                                        <div v-bind:class="'c100' + ' p' + mathRound(calcPercent(lesson.process, lesson.total)) + ' blue'">
                                                            <span>{{ calcPercent(lesson.process, lesson.total) }}%</span>
                                                            <div class="slice">
                                                                <div class="bar"></div>
                                                                <div class="fill"></div>
                                                            </div>
                                                        </div>
                                                        <div class="icon-expand-right">
                                                            <img src="/themes/ito/images/icons/expand_right.svg">
                                                        </div>
                                                    </div>
                                                    <div class="report-item-childs">
                                                        <div class="report-child-items item-detail" v-for="(practice, i) in lesson.items">
                                                            <div class="report-attemps">
                                                                <div class="report-item-head" v-on:click="attempTrigger(i)" v-bind:class="{ 'active': (currentAttemp === i) }">
                                                                    <div class="report-item-title">{{ $t('practice') }}
                                                                        {{ i + 1 }} :
                                                                        {{ practice.name }}
                                                                    </div>
                                                                    <div class="report-item-head-right">
                                                                        <div>
                                                                            <div class="rating-crown">
                                                                                <img src="/themes/ito/images/icon-crown.svg" v-for="i in practice.star" class="crown-active-img">
                                                                            </div>
                                                                            <div class="report-item-temp-number">
                                                                                {{ practice.attemp ? practice.attemp.length
                                                                                    : 0 }} {{ practice.attemp &&
        practice.attemp.length > 1 ? $t('attempts')
        : $t('attempt') }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="icon-expand-right">
                                                                            <img src="/themes/ito/images/icons/expand_right.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="report-item-body" v-if="practice.attemp">
                                                                    <template v-for="(attemp, a) in practice.attemp">
                                                                        <div class="attemp-list">
                                                                            <span>
                                                                                {{ $t('attempt') }} {{ a + 1 }} <img src="/themes/ito/images/icon-crown.svg" v-for="i in attemp.crown" class="crown-active-img">
                                                                            </span>
                                                                            <div class="attemp-status">
                                                                                <b>{{ attemp.correct }}</b>/{{ attemp.total
                                                                                }}
                                                                                {{ $t('corrects') }}
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="chart-wrapper">
                                                <div class="chart-content">
                                                    <canvas id="radar_chart" height="150" width="150"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>


        </div>
        <div class="container" v-if="errors">
            <div class="card fade-down">
                <div class="card-body p-4">
                    <div class="card-content">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center">
                            <h5>{{ $t(errors) }}
                            </h5>
                            <img class="mt-3" src="/themes/ito/images/mascot/isaac-extension.svg" alt="" width="125px">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <require-upgrade-account-modal ref="requireUpgradeAccountModal"></require-upgrade-account-modal>

        <div class="modal fade" id="lessonLocked" tabindex="-1" role="dialog" aria-labelledby="lessonLocked">
            <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                <div class="modal-content box-shadow-main p-3">
                    <div class="modal-header border-0 justify-content-center">
                        <h5 class="modal-title fw-bold">{{ $t('lesson_lock') }}</h5>
                    </div>
                    <div class="modal-body">
                        <p v-html="lesson_locked_message"></p>
                    </div>
                    <div class="modal-footer border-0 justify-content-center">
                        <div class="ito-btn ito-btn-primary" data-bs-dismiss="modal">
                            {{ $t('ok') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from 'vuex';
import { toPercent, timeConverter } from '../../utils/utils'
import { getListLesson, getReportUnit, getDiscussions, sendDiscuss, deleteComment, editComment, getEbook } from "../../models/dashboard";
import { RequireUpgradeAccountModal } from "../modal";

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        this.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', this.event)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', this.event)
    },
});
export default {
    name: "UnitList",
    // props: [
    //     'user_info'
    // ],
    components: {
        RequireUpgradeAccountModal,
    },
    data() {
        return this.initState();
    },
    created() {
        this.fetch();
    },
    mounted() {
        this.type = this.$route.query.type || 1;
    },
    updated() {
        document.title = this.page_title;
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },
    watch: {
        'tab'(item) {
            if (item == 'report') {
                this.getListReports();
            } else if (item == 'discuss') {
                this.getListDiscuss();

            }
        }
    },
    methods: {
        resetDiscuss() {
            this.commentType = 'NORMAL';
            this.content = '';
            this.currId = '';
            this.currContent = '';
        },
        upgradeAccountUnitTest(package_name) {
            this.required_package_name = package_name;
            this.$refs.requireUpgradeAccountModal.show({
                title: this.$t('upgrade_account_require'),
                message: this.$t('upgrade_to_package', { package_name: package_name }),
                okButton: this.$t('upgrade_account'),
            })
        },
        showReply(dc) {
            if (dc.show_reply == 1) {
                Vue.set(dc, 'show_reply', 0);

            } else {
                Vue.set(dc, 'show_reply', 1);
            }
        },
        initState() {
            return {
                showReplies: '',
                units: [],
                subject: {},
                isLoading: false,
                tab: 'overview',
                type: 1,
                subject_id: this.$route.params.subject_id,
                reports: [],
                currentItem: -10,
                editItem: -10,
                currentAttemp: -10,
                lesson_complete: 0,
                lesson_incomplete: 0,
                content: '',
                discuss: [],
                total_comment: 0,
                comment_select: 0,
                curDc: '',
                curUserReply: '',
                currContent: '',
                inputType: '',
                curCommenter: [],
                commentType: 'NORMAL',
                ebook_link: '',
                page_title: 'Unit',
                required_package_name: '',
                errors: undefined,
                lesson_locked_message: undefined,
            };
        },
        resetState() {
            Object.assign(this.$data, this.initState());
        },
        resetSearching() {
            this.resetState();

            this.fetch();
        },
        fetch(page = 1) {
            this.getListUnits()
        },
        showEdit: function (newItem) {
            if (newItem === this.editItem) {
                this.editItem = -10
            } else {
                this.editItem = newItem
            }
        },
        showEditCommenter: function (newItem) {
            if (newItem === this.editItem) {
                this.editItem = -10
            } else {
                this.editItem = newItem
            }
        },
        resetEdit() {
            this.editItem = -10;
        },
        resetEditCommenter() {
            this.editItem = -10;
        },

        getListUnits() {
            const that = this;
            that.$store.commit("setLoading", true);
            const params = this.$route.params;
            axios.all([
                getListLesson(params),
                getEbook(params)
            ]).then(axios.spread((list_unit_response, ebook) => {
                that.$store.commit("setLoading", false);
                if (list_unit_response.data.code != 0) {
                    this.errors = list_unit_response.data.msg;
                    return;
                }
                if (ebook.data.code == 0) {
                    that.ebook_link = ebook.data.results.item.content || '';
                }
                this.errors = undefined;
                that.units = list_unit_response.data.results.units || [];
                that.subject = list_unit_response.data.results.subject || {};
                that.page_title = that.units.name + ": " + that.units.title;
            }));
        },
        getListReports() {
            const that = this;
            that.$store.commit("setLoading", true);
            const params = {
                'subject_id': that.subject_id,
                'grade': localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade,
                'unit_id': that.units.id
            }
            axios.all([
                getReportUnit(params),
            ]).then(axios.spread((list_report_response) => {
                that.$store.commit("setLoading", false);
                if (list_report_response.data.code != 0) {
                    return;
                }
                that.reports = list_report_response.data.results.units || [];
                // that.lesson_complete = that.reports.process/that.reports.total * 100;
                that.lesson_complete = that.reports.process / that.reports.total * 100;
                that.lesson_incomplete = (that.reports.total - that.reports.process) / that.reports.total * 100;
                this.$nextTick(function () {
                    new Chart(document.getElementById("radar_chart").getContext("2d"), that.getChartJs(that.lesson_incomplete.toFixed(2), that.lesson_complete.toFixed(2)));
                })
            }));
        },

        getListDiscuss() {

            const that = this;
            that.$store.commit("setLoading", true);
            const params = {
                'subject': that.subject_id,
                'grade': localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade,
                'unit_id': that.units.id
            }

            axios.all([
                getDiscussions(params),
            ]).then(axios.spread((list_discuss_response) => {
                that.$store.commit("setLoading", false);
                if (list_discuss_response.data.code != 0) {
                    return;
                }
                that.discuss = list_discuss_response.data.results.discuss || [];
                that.total_comment = list_discuss_response.data.results.total_comment;

                that.content = '';

                that.$nextTick(function () {
                    var initNanoScroller = function () {
                        if ($('.nano').length > 0) {
                            $(".nano").nanoScroller();
                        }
                    }
                    initNanoScroller();
                })
            }));
        },

        sendComment() {
            const that = this;

            if (that.content == '') {

                return false;
            }
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('subject', that.subject_id);
            formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
            formData.append('unit_id', that.units.id);
            formData.append('content', that.content);
            sendDiscuss(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.discuss = response.data.results.discuss || [];
                that.total_comment = response.data.results.total_comment;

                that.content = '';
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },

        replyComment_commenter(dc) {
            const that = this;
            if (dc.reply_content == '') {

                return false;
            }
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('subject', that.subject_id);
            formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
            formData.append('unit_id', that.units.id);
            formData.append('content', dc.reply_content);
            formData.append('discuss_id', dc.id);
            sendDiscuss(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                let resData = response.data.results.discuss || [];
                let curDc = resData.filter(e => e.id == dc.id);
                let countCommentReply = curDc[0].commenter.length;
                dc.commenter.push(curDc[0].commenter[countCommentReply - 1]);
                that.total_comment = response.data.results.total_comment;
                dc.reply_content = '';
                that.currContent = '';
                that.discuss = response.data.results.discuss || [];
                that.content = '';
                that.commentType = "NORMAL";
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        replyComment() {
            const that = this;
            if (that.currContent == '') {

                return false;
            }
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('subject', that.subject_id);
            formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
            formData.append('unit_id', that.units.id);
            formData.append('content', that.currContent);
            formData.append('discuss_id', that.curDc.id);
            sendDiscuss(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }

                let resData = response.data.results.discuss || [];
                let curDc = resData.filter(e => e.id == that.curDc.id);
                let countCommentReply = curDc[0].commenter.length;
                if (that.curDc.commenter) {
                    that.curDc.commenter.push(curDc[0].commenter[countCommentReply - 1]);
                }
                that.total_comment = response.data.results.total_comment;
                that.discuss = response.data.results.discuss || [];
                that.content = '';
                that.commentType = "NORMAL";
                that.currContent = '';
                // $('#inputComment').modal('hide');
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        deleteConfirm(id) {
            this.comment_select = id;
            $('#deleteConfirm').modal('show');
        },
        deleteConfirmCommenter(commenter) {
            this.curCommenter = commenter;
            this.comment_select = commenter.id;
            $('#deleteConfirmCommenter').modal('show');
        },
        deleteComment(dc) {
            const that = this;
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('id', that.comment_select);
            deleteComment(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.discuss = response.data.results.discuss || [];
                that.total_comment = response.data.results.total_comment;
                that.content = '';
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        deleteCommentCommenter() {
            const that = this;
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('id', that.comment_select);
            deleteComment(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.discuss = response.data.results.discuss || [];
                that.total_comment = response.data.results.total_comment;
                that.content = '';

            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        editCommentConfirm(id, content) {
            const that = this;
            that.currId = id;
            that.curUserReply = '';
            that.currContent = content;
            that.inputType = "EDIT";
            that.commentType = "EDIT";
            // $('#editConfirm').modal('show');
        },
        editCommentConfirmCommenter(commenter) {
            const that = this;
            this.curCommenter = commenter;
            that.currId = commenter.id;
            that.curUserReply = '';
            that.currContent = commenter.content;
            that.inputType = "EDIT";
            that.commentType = "EDIT";
            // $('#editConfirmCommenter').modal('show');
        },
        replyCommentConfirm(dc) {
            const that = this;
            that.curDc = dc;
            that.currContent = '';
            that.curUserReply = dc.fullname;
            that.inputType = "REPLY";
            that.commentType = "REPLY";
            // $('#inputComment').modal('show');

            initNanoScroller();
        },
        editComment() {
            const that = this;
            if (that.currContent == '') {

                return false;
            }
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('id', that.currId);
            formData.append('content', that.currContent);
            editComment(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.discuss = response.data.results.discuss || [];
                that.total_comment = response.data.results.total_comment;
                that.content = '';
                that.commentType = "NORMAL";
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
            // $('#editConfirm').modal('hide');
        },
        editCommentCommenter(dc) {
            const that = this;
            if (that.currContent == '') {
                return false;
            }
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('id', that.currId);
            formData.append('content', that.currContent);
            editComment(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.curCommenter.content = that.currContent;
                that.total_comment = response.data.results.total_comment;
                that.content = '';
                that.commentType = "NORMAL";
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
            // $('#editConfirmCommenter').modal('hide');
        },

        // getListDiscuss() {
        //
        //     const that = this;
        //     that.$store.commit("setLoading", true);
        //     const params = {
        //         'subject': that.subject_id,
        //         'grade': localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade,
        //         'unit_id': that.units.id
        //     }
        //     axios.all([
        //         getDiscussions(params),
        //     ]).then(axios.spread((list_discuss_response) => {
        //         that.$store.commit("setLoading", false);
        //         if (list_discuss_response.data.code != 0) {
        //             return;
        //         }
        //         that.discuss = list_discuss_response.data.results.discuss || [];
        //         that.total_comment = list_discuss_response.data.results.total_comment;
        //
        //
        //         that.content = '';
        //     }));
        // },
        // sendComment() {
        //     const that = this;
        //     if (that.content == '') {
        //         return false;
        //     }
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('subject', that.subject_id);
        //     formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
        //     formData.append('unit_id', that.units.id);
        //     formData.append('content', that.content);
        //     sendDiscuss(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         that.discuss = response.data.results.discuss || [];
        //         that.total_comment = response.data.results.total_comment;
        //         that.content = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        // },
        // replyComment_commenter(dc) {
        //     const that = this;
        //     if (dc.reply_content == '') {
        //
        //         return false;
        //     }
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('subject', that.subject_id);
        //     formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
        //     formData.append('unit_id', that.units.id);
        //     formData.append('content', dc.reply_content);
        //     formData.append('discuss_id', dc.id);
        //     sendDiscuss(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         let resData = response.data.results.discuss || [];
        //         let curDc = resData.filter(e => e.id == dc.id);
        //         let countCommentReply = curDc[0].commenter.length;
        //         dc.commenter.push(curDc[0].commenter[countCommentReply - 1]);
        //         that.total_comment = response.data.results.total_comment;
        //         dc.reply_content = '';
        //         that.currContent = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        // },
        // replyComment() {
        //
        //     const that = this;
        //     if (that.currContent == '') {
        //         return false;
        //     }
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('subject', that.subject_id);
        //     formData.append('grade', localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade);
        //     formData.append('unit_id', that.units.id);
        //     formData.append('content', that.currContent);
        //     formData.append('discuss_id', that.curDc.id);
        //     sendDiscuss(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         if (!that.curDc.commenter) {
        //             that.curDc.commenter = [];
        //         }
        //         let resData = response.data.results.discuss || [];
        //         let curDc = resData.filter(e => e.id == that.curDc.id);
        //         let countCommentReply = curDc[0].commenter.length;
        //         that.curDc.commenter.push(curDc[0].commenter[countCommentReply - 1]);
        //         that.total_comment = response.data.results.total_comment;
        //         that.currContent = '';
        //         $('#inputComment').modal('hide');
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        // },
        // deleteConfirm(id) {
        //     this.comment_select = id;
        //     $('#deleteConfirm').modal('show');
        // },
        // deleteConfirmCommenter(commenter) {
        //     this.comment_select = commenter.id;
        //     $('#deleteConfirmCommenter').modal('show');
        // },
        // deleteComment(dc) {
        //     const that = this;
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('id', that.comment_select);
        //     deleteComment(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         that.discuss = response.data.results.discuss || [];
        //         that.total_comment = response.data.results.total_comment;
        //         that.content = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        // },
        // deleteCommentCommenter() {
        //     const that = this;
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('id', that.comment_select);
        //     deleteComment(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         that.discuss = response.data.results.discuss || [];
        //         that.total_comment = response.data.results.total_comment;
        //         that.content = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        // },
        // editCommentConfirm(id, content) {
        //     const that = this;
        //     that.currId = id;
        //     that.curUserReply = '';
        //     that.currContent = content;
        //     that.inputType = "EDIT";
        //     $('#editConfirm').modal('show');
        // },
        // editCommentConfirmCommenter(commenter) {
        //     const that = this;
        //     this.curCommenter = commenter;
        //     that.currId =commenter.id;
        //     that.curUserReply = '';
        //     that.currContent =commenter.content;
        //     that.inputType = "EDIT";
        //     $('#editConfirmCommenter').modal('show');
        // },
        // replyCommentConfirm(dc) {
        //     const that = this;
        //     that.curDc = dc;
        //     that.currContent = '';
        //     that.curUserReply = dc.fullname;
        //     that.inputType = "REPLY";
        //     $('#inputComment').modal('show');
        // },
        // editComment() {
        //     const that = this;
        //     if (that.currContent == '') {
        //
        //         return false;
        //     }
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('id', that.currId);
        //     formData.append('content', that.currContent);
        //     editComment(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         that.discuss = response.data.results.discuss || [];
        //         that.total_comment = response.data.results.total_comment;
        //         that.content = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        //     $('#editConfirm').modal('hide');
        // },
        // editCommentCommenter() {
        //     const that = this;
        //     if (that.currContent == '') {
        //         return false;
        //     }
        //     that.$store.commit("setLoading", true);
        //     const formData = new FormData();
        //     formData.append('id', that.currId);
        //     formData.append('content', that.currContent);
        //     editComment(formData).then((response) => {
        //         that.$store.commit("setLoading", false);
        //         if (response.data.code != 0) {
        //             return;
        //         }
        //         that.curCommenter.content = that.currContent;
        //         that.total_comment = response.data.results.total_comment;
        //         that.content = '';
        //     }).catch((response) => {
        //         that.$store.commit("setLoading", false);
        //         console.error(response)
        //     });
        //     $('#editConfirmCommenter').modal('hide');
        // },
        calcPercent(process, total) {
            return toPercent(process, total);
        },
        mathRound(number) {
            return Math.round(number)
        },
        changeType(layout) {
            this.type = layout;
            this.$router.push({ path: this.$route.path, query: { type: layout } });
            if (layout == 2) {
                this.handleGACustomEvent('ITC_W_2003', this.units.id)
            }
        },
        changeTab(tab) {
            this.tab = tab;
            if (tab == 'discuss') {
                this.handleGACustomEvent('ITC_W_2001', this.units.id)
            } else if (tab == 'report') {
                this.handleGACustomEvent('ITC_W_2002', this.units.id)
            }
        },

        clickLockedItem(item, index) {
            if (this.type == 1) { //type = 1:lesson; type = 2: extension
                const prevLesson = index > 0 ? this.units.lessons[index - 1].name : this.$i18n.t('previous_lesson')
                if (item.status == 2) { //lesson locked by previous lesson
                    this.lesson_locked_message = this.$i18n.t('lesson_locked_by_previous_lesson', { previous_lesson: prevLesson, this_lesson: item.name })
                    $('#lessonLocked').modal('show')
                } else if (item.status == 3) { //lesson locked by time
                    this.lesson_locked_message = this.$i18n.t('lesson_locked_by_time', { time: this.timeConverter(item.start_time) })
                    $('#lessonLocked').modal('show')
                } else { //other locked status
                    if (this.user_info.student_type != 2) { //free trial account
                        this.upgradeAccountUnitTest(item.package_name)
                    }
                }
            } else {
                if (this.user_info.student_type != 2) { //free trial account
                    this.upgradeAccountUnitTest(item.package_name)
                }
            }

        },

        itemTrigger: function (newItem) {
            this.currentAttemp = -10;
            if (newItem === this.currentItem) {
                this.currentItem = -10
            } else {
                this.currentItem = newItem
            }
        },
        attempTrigger: function (newItem) {
            if (newItem === this.currentAttemp) {
                this.currentAttemp = -10
            } else {
                this.currentAttemp = newItem
            }
        },

        getChartJs(data1, data2) {
            return {
                // type: 'pie',
                type: 'doughnut',
                data: {
                    labels: [this.$i18n.t('incomplete'), this.$i18n.t('complete')],
                    datasets: [{
                        label: "Chart",
                        fill: true,
                        data: [data1, data2],
                        pointBorderWidth: 10,
                        borderWidth: 1,
                        backgroundColor: ['#EEEEEE', '#3164CC']
                    }]
                },
                options: {
                    responsive: true,
                    cutoutPercentage: 20,
                    title: {
                        display: true,
                        text: this.$i18n.t('lesson_progress')
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
                            }
                        }
                    },

                    plugins: {
                        datalabels: {
                            display: true,
                            backgroundColor: '#ccc',
                            borderRadius: 3,
                            formatter: (value, ctx) => {
                                const datapoints = ctx.chart.data.datasets[0].data
                                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
                                const percentage = value / total * 100
                                return percentage.toFixed(2) + "%";
                            },
                            font: {
                                color: 'red',
                                weight: 'bold',
                            }
                        },
                    },

                    legend: {
                        display: true, // show legend (true/false)
                        position: 'bottom',//legend positon (top/bottom/left/right)
                        labels: {
                            usePointStyle: true,//choose legend shape.(true/false)
                            boxWidth: 20, // width of coloured box(default=40). If you use {usePointStyle: true}, you can not this option.
                            fontSize: 13,
                            fontColor: '#000000', // legend font color
                            padding: 30, // Padding between labels.
                        }
                    },
                }
            }
        },
        timeConverter(UNIX_timestamp) {
            var a = new Date(UNIX_timestamp * 1000);
            var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time;
            if (hour > 0 && min > 0 && sec > 0) {
                time = this.addZero(date) + '/' + this.addZero(month) + '/' + this.addZero(year) + ' - ' + this.addZero(hour) + ':' + this.addZero(min) + ':' + this.addZero(sec);
            } else {
                time = this.addZero(date) + '/' + this.addZero(month) + '/' + this.addZero(year);
            }
            return time;
        },
        addZero(num) {
            return (num < 10) ? '0' + num : num.toString();
        },
        rebuildEbookPage(ebook) {
            let step1 = ebook.replace('[', '');
            let step2 = step1.replace(']', '');
            let step3 = step2.replace(',', ' - ');
            return step3;
        },
        handleGACustomEvent(element, unit_id) {
            var params = {
                'w_user_id': this.user_info.user_id,
                'unit_id': unit_id
            }
            params[element] = this.user_info.user_id + '|' + unit_id
            gtag('event', element, params)
        }
    }
}
</script>

<style scoped>
.commenter {
    margin-bottom: 15px;
}

.thumb-comment img {
    filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.07));
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.thumb-comment {
    margin-right: 15px;
}

.list-reply {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15px;
}

@-webkit-keyframes cssProgressActive {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 35px 35px;
    }
}

@keyframes cssProgressActive {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 35px 35px;
    }
}

@-webkit-keyframes cssProgressActiveRight {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -35px -35px;
    }
}

@keyframes cssProgressActiveRight {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -35px -35px;
    }
}

.progress {
    opacity: 1;
}

.progress-load {
    background-color: #FFC700 !important;
    -webkit-animation: cssProgressActive 3s linear infinite;
    animation: cssProgressActive 3s linear infinite;
    transition: none 0s ease 0s;
}

#unit-detail .lesson-item-bottom>.progress>.progress-load {
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
    background-size: 15px 15px;
}

#unit-detail .lesson-item-bottom>.progress>span {
    padding-left: 20px;
    padding-top: 0px;
}

#unit-detail .lesson-item-bottom {
    align-items: center;
}

.lesson-item-lv.report-items {
    padding: 4px 20px;
    margin-bottom: 0;
}

.report-items .c100>span {
    font-size: 11px;
}

.lesson-report-container {
    background: #F6F6F6;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 8px;
}

.report-child-items {
    padding: 6px 20px;
}

.report-child-items:first-child {
    padding-top: 12px;
}

.report-attemps {
    background: #FFFFFF;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px 20px;
}

.chart-wrapper {
    background: #FFFFFF;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 8px;
}

.icon-expand-right {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.active .icon-expand-right img {
    transform: rotate(90deg);
}

.report-item-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

.report-item-head-right {
    display: flex;
    align-items: center;
    text-align: right;
}

.report-item-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    color: #1E263C;
}

.report-item-temp-number {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: rgba(30, 38, 60, 0.5);
}

.report-item-childs,
.report-item-body {
    display: none;
    -webkit-animation: fadeout 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s;
    /* Firefox < 16 */
    -ms-animation: fadeout 1s;
    /* Internet Explorer */
    -o-animation: fadeout 1s;
    /* Opera < 12.1 */
    animation: fadeout 1s;
}

.active+.report-item-childs,
.active+.report-item-body {
    display: block;
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
}

.report-item-body {
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-top: 8px;
    padding: 0;
    overflow: hidden;
}

.attemp-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px;
    font-size: 13px;
    line-height: 16px;
}

.attemp-list:not(last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.attemp-list .crown-active-img {
    width: 14px;
    margin-top: -5px;
}

.report-item-head-right .crown-active-img {
    width: 15px;
}

.ebook-link {
    display: inline-block;
    box-shadow: 0px 2px 0px 0px #FFFFFF33 inset;
    box-shadow: 4px 4px 0px 0px #0000000D;
    background: linear-gradient(180deg, #45BCFF 0%, #00A3FF 100%);
    margin-top: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFF;
    padding: 8px 12px;
    width: fit-content;
    border-radius: 30px;

}

.form {
    background: #FFFFFF;
    border: 2px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 4px 6px;
    display: flex;
}

.form>input[type=text] {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    flex: 1;
    border: none;

}

.form>button {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #40C0F6;
    box-shadow: inset 0px 2px 0px rgb(255 255 255 / 20%);
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 7px 15px;
    width: 70px;
}

#inputComment .modal-content {
    padding: 0;
    border-radius: 30px
}

.button-replies {
    color: #bfa6a6;
    bottom: 0%;
    width: 96%;
    text-align: left;
    background: #FFFFFF;
    border: 1.5px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 8px;

}

.reply-to {
    margin-block: 5px;
    font-weight: bold;
}

.modal-content1 {
    padding: 0;
    border-radius: 30px;
}


.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0.99);
    }

    100% {
        transform: scale(1);
    }
}

.modal-content-edit {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

.box-shadow-main-edit {
    box-shadow: 4px 4px rgb(0 0 0 / 7%);
    border-radius: 20px;
    box-shadow: 4px 4px 0px rgb(0 0 0 / 7%), inset 0px 4px 0px rgb(231 231 231 / 25%);
}

.comment-item:first-child {
    margin-top: 0;
}

.card-content-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 100%;
    background: rgba(51, 122, 183, 0.75);
    top: 0;
}

.comment-item-reply::after {
    content: '';
    display: block;
    position: absolute;
    left: -23px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin-top: -6px;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.commenter:first-child::before {
    content: '';
    display: block;
    position: absolute;
    left: -17px;
    width: 3px;
    height: 50%;
    background: #fff;
    top: 0px;
}

.card-bottom-commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    margin-top: -6px;
    border-radius: 100%;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.card-bottom-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 50%;
    background: #fff;
    bottom: 0;
}

.card-content-commenter {
    margin-left: 52px;
}

.title-rep {
    color: #070A28;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: left;
}

.card-bottom a.reset-commenter {
    font-size: 12px;
    color: #E84343;
    font-weight: 700;
    text-decoration: underline;
    margin-left: 15px;
}

.edit-li {
    color: #fd9301;
}

.delete-li img {
    margin-top: -5px;
    margin-left: 5px;
}

.card-bottom-commenter,
.card-content-commenter,
.commenter {
    position: relative;
}

.card-content-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 100%;
    background: rgba(51, 122, 183, 0.75);
    top: 0;
}

.commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin-top: -6px;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.commenter:first-child .comment-item-reply::before {
    content: '';
    display: block;
    position: absolute;
    left: -17px;
    width: 1px;
    height: 50%;
    background: #fff;
    top: 0px;
}

.card-bottom-commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    margin-top: -6px;
    border-radius: 100%;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.card-bottom-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 50%;
    background: #fff;
    bottom: 0;
}

.lock-unit .lock-image {
    right: 10px;
    left: auto;
    top: 5px;
    width: 28px;
}
</style>