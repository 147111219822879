<template>
  <div class="container">
    <div id="registerBox" :class="step == 1 ? 'fade-down' : 'd-none'">
      <div class="login-body" style="min-height: 335px;">
        <a class="close-popup" style="z-index: 10" href="/login"></a>
        <h3 class="popup-title mb-20">{{ $t('create_new_account') }}</h3>
        <div class="px-5">
          <div class="form-group row">
            <div class="col-12">
              <label class="label-input text-bold">{{ $t('full_name') }}<span class="red">*</span></label>
              <input type="text" class="ito-input" :class="errors.fullname ? 'has-err' : ''" name="fullname" :placeholder="$t('enter_your_full_name')" @input="addEvent1" v-model="user.fullname" @keyup.enter="valid_1 ? checkUserExist() : ''"
                tabindex="1" autofocus />
              <span class="error-message" v-if="errors.fullname">{{ errors.fullname }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6 pe-1">
              <label class="label-input text-bold">{{ $t('grade') }}<span class="red">*</span></label>
              <select class="ito-input" :class="errors.grade ? 'has-err' : ''" name="grade" v-model="user.grade" :title="$t('choose_a_grade')" @change="addEvent1" @keyup.enter="valid_1 ? checkUserExist() : ''" tabindex="2">
                <option value="">
                  {{ $t('choose_a_grade') }}
                </option>
                <template v-for="grade in grades">
                  <option :value="grade">{{ $t('grade') + ' ' + grade }}</option>
                </template>
              </select>
              <span class="error-message" v-if="errors.grade">{{ errors.grade }}</span>

            </div>
            <div class="col-6 ps-1">
              <label class="label-input text-bold">{{ $t('phone_name') }}<span class="red">*</span></label>
              <input type="text" class="ito-input" :class="errors.phone ? ' has-err' : ''" name="phone" :placeholder="$t('enter_your_phone_number')" @input="addEvent1" v-model="user.phone" id="phone" @keyup.enter="valid_1 ? checkUserExist() : ''"
                tabindex="3" />
              <span class="error-message" v-if="errors.phone">{{ errors.phone }}</span>

            </div>

          </div>
          <div class="form-group row">
            <div class="col-12">
              <label class="label-input text-bold">{{ $t('e_mail') }}<span class="red">*</span></label>
              <input type="email" :class="[$route.query.token ? 'readonly ' : '', errors.email ? 'has-err ' : '', 'ito-input']" name="email" :placeholder="$t('enter_your_e_mail')" @input="addEvent1" v-model="user.email"
                @keyup.enter="valid_1 ? checkUserExist() : ''" :readonly="$route.query.token" tabindex="4" />
              <span class="error-message" v-if="errors.email">{{ errors.email }}</span>

            </div>
          </div>
          <div class="form-group row">
            <div class="col-6 pe-1">
              <label class="label-input text-bold">{{ $t('gender') }}<span class="red">*</span></label>
              <select class="ito-input" :class="errors.gender ? 'has-err' : ''" name="gender" v-model="user.gender" title="Choose your gender" @change="addEvent1" tabindex="5">
                <option value="">
                  {{ $t('choose_a_gender') }}
                </option>
                <option value="male">
                  {{ $t('male') }}
                </option>
                <option value="female">
                  {{ $t('female') }}
                </option>
              </select>
              <span class="error-message" v-if="errors.gender">{{ errors.gender }}</span>

            </div>
            <div class="col-6 ps-1">
              <label class="label-input text-bold">{{ $t('date_of_birth') }}</label>
              <input readonly="true" type="text" class="date datepicker birthday ito-input" :class="errors.birthday ? 'has-err' : ''" name="birthday" :placeholder="$t('birthday')" v-model="user.birthday" @keyup.enter="valid_1 ? checkUserExist() : ''"
                tabindex="6" />
              <span class="error-message" v-if="errors.birthday">{{ errors.birthday }}</span>
            </div>
          </div>
          <div class="form-check ito-form-check mt-3">
            <input type="checkbox" class="form-check-input" :class="errors.confirm_term ? 'has-err' : ''" id="confirm_term" name="confirm_term" v-model="confirm_term" @change="addEvent1" tabindex="7">
            <label class="form-check-label" for="confirm_term">{{ $t('accept_terms_and_policies') }}
              <a href="/general-principle" target="_blank">{{ $t('terms') }}</a>
              {{ $t('and') }}
              <a href="/general-principle" target="_blank">{{ $t('policies') }}</a>.</label>
            <span class="error-message" v-if="errors.confirm_term">{{ errors.confirm_term }}</span>

          </div>
          <div class="form-group row mt-3">
            <div class="col-12 d-flex justify-content-end">
              <button type="button" class="ito-btn ito-btn-primary" @click="eventValid1()" :disabled="!valid_1" tabindex="8">
                {{ $t('next') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="receiveMethod" :class="step == 2 ? 'fade-down' : 'd-none'">
      <div class="login-body">
        <div class="close-popup" @click="gotoStep(1)"></div>
        <h3 class="popup-title payment-title">{{ $t('get_info_method_title') }}</h3>
        <div class="px-5 mt-3">
          <!-- <div class="form-check ito-form-check">
            <input class="form-check-input" type="radio" name="receiveMethod" id="method1" value="zns" tabindex="1" v-model="user.receive_method">
            <label class="form-check-label" for="method1">{{ $t('get_info_zalo') }}</label>
            <p class="form-check-desc mt-2">{{ $t('get_info_zalo_desc') }}</p>
            <div class="qr-code">
              <img class="" src="/images/qr-zalo.png" alt="">
            </div>
          </div> -->
          <div class="form-check ito-form-check mt-3">
            <input class="form-check-input" type="radio" name="receiveMethod" id="method2" value="sms" tabindex="2" v-model="user.receive_method">
            <label class="form-check-label" for="method2">{{ $t('get_info_sms') }}</label>
            <p class="form-check-desc mt-2">{{ $t('get_info_sms_desc') }}: {{ user.phone }}</p>
          </div>
          <div class="form-check ito-form-check mt-3">
            <input class="form-check-input" type="radio" name="receiveMethod" id="method3" value="email" tabindex="3" v-model="user.receive_method">
            <label class="form-check-label" for="method3">{{ $t('get_info_email') }}</label>
            <p class="form-check-desc mt-2">{{ $t('get_info_desc') }}: {{ user.email }}</p>
          </div>
          <div class="d-flex mt-3 gap-3 justify-content-center">
            <button type="button" class="ito-btn ito-btn-primary" @click="onSubmit()" tabindex="4" :disabled="!user.receive_method">{{ $t('continue_txt') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div id="upgrade-detail" class="card box-shadow-main" :class="step == 3 ? 'fade-down' : 'd-none'">
      <div class="card-body">
        <h5 class="card-title">
          <span class="title">
            {{ $t('select_package') }}
          </span>
        </h5>
        <div class="card-content list_package px-0">
          <div class="swiper packageSwiper position-relative" v-if="packages.length">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="pkgs in packages">
                <div class="select-upgrade-type d-flex justify-content-center mb-20">
                  <template v-for="pkg in pkgs">
                    <template v-if="pkg">
                      <div class="package" :class="{ 'has_sale': pkg.id == 30 }">
                        <div class="sale-off" v-if="pkg.id == 30"><span>-50%</span></div>
                        <h5 class="title">{{ pkg.name }}</h5>
                        <div class="price"><span v-if="pkg.id == 30">{{ (pkg.price * 2) | toCommas }}
                          </span> {{ pkg.price | toCommas }}&#8363;</div>
                        <button type="button" @click="changePackage(pkg)" class="ito-btn ito-btn-lg ito-btn-block ito-btn-primary mt-20">{{ $t('buy_now') }}</button>
                        <div class="content mt-3">
                          <template v-for="desc in pkg.description">
                            <div class="items d-flex align-items-start mt-3">
                              <img :src="desc.bullet_icon ? desc.bullet_icon : '/themes/ito/images/payment-check-icon.svg'">
                              <div class="items-text ms-2">{{ $t(desc.content) }}</div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <div class="swiper-prev"></div>
            <div class="swiper-next"></div>
          </div>
          <div class="no_packages" v-else>
            <h3 class="text-muted">{{ $t('no_packages') }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div id="package_guide" class="card box-shadow-main mt-20" :class="step == 3 ? 'fade-up' : 'd-none'">
      <div class="card-body">
        <div class="card-content">
          <h5 class="title">{{ $t('purchase_title') }}:</h5>
          <div class="row">
            <div class="col-4">
              <div class="row">
                <div class="col-12 justify-content-center d-flex">
                  <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-1.png" alt="">
                </div>
              </div>
              <div class="row desc">
                <h5>{{ $t('purchase_1_title') }}</h5>
                <p>{{ $t('purchase_1_content') }}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12 justify-content-center d-flex">
                  <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-2.png" alt="">
                </div>
              </div>
              <div class="row desc">
                <h5>{{ $t('purchase_2_title') }}</h5>
                <p>{{ $t('purchase_2_content') }}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12 justify-content-center d-flex">
                  <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-3.png" alt="">
                </div>
              </div>
              <div class="row desc">
                <h5>{{ $t('purchase_3_title') }}</h5>
                <p>{{ $t('purchase_3_content') }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div id="product-list" class="card card-comments" :class="step == 4 ? 'fade-down' : 'd-none'">
      <div class="card-body">
        <h5 class="card-title">
          <span class="title">
            {{ $t('select_package') }} >> {{ selected_package.name }}
          </span>
        </h5>
        <div class="card-content">
          <p class="label-upgrade">1. {{ $t("please_select_how_you_would_like_to_pay") }}</p>
          <div class="row justify-content-center">
            <table class="package-table mt-3 w-75">
              <tr v-for="product in products">
                <td class="w-100">
                  <input :id="'product_' + product.product_id" class="ito-pkg" name="product" type="radio" v-model="product_id" :value="product.product_id" @change="changeProduct(product)" />
                  <label class="selection d-flex slect-package justify-content-between" :for="'product_' + product.product_id">
                    <div class="slect-name">
                      <p class="pk-name">{{ product.product_name }}</p>
                      <p class="pk-price">{{ product.price | toCommas }} &#8363;</p>
                    </div>
                    <div class="slect-cost">{{ Math.round(product.price / product.duration * 30) | toCommas }} &#8363;/{{ $t('month') }}
                      <span v-if="product.desc.price_save && product.desc.price_save > 0">{{ $t('discount') }} {{ product.desc.price_save | toCommas }}%</span>
                    </div>
                  </label>
                </td>
              </tr>
            </table>
            <span class="w-75 package-note" v-html="$t('payment_warning')"></span>
          </div>
          <div :class="use_liveclass && live_classes != '' ? 'fade-down' : 'd-none'">
            <p class="label-upgrade mt-3">2. {{ $t("please_select_schedule") }}</p>
            <div class="ito-table-wrapper">
              <table class="ito-table ito-table-fixed">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("schedule_no") }}</th>
                    <th>{{ $t("schedule_subject") }}</th>
                    <th>{{ $t("schedule_session") }}</th>
                    <th>{{ $t("schedule_date") }}</th>
                    <th>{{ $t("schedule_duration") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody class="ito-tbody" v-for="(live_class, index) in live_classes" v-if="live_classes != []">
                  <template v-for="(live_class_item, i) in live_class.live_class_detail">
                    <tr v-if="i == 0">
                      <td class="text-center" :rowspan="live_class.live_class_detail.length">{{ index + 1 }}</td>
                      <td>{{ live_class_item.subject }}</td>
                      <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                      <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                      <td :rowspan="live_class.live_class_detail.length">{{ live_class.start_date }} - {{
      live_class.end_date
    }}</td>
                      <td :rowspan="live_class.live_class_detail.length">
                        <button class="ito-btn ito-btn-outline-primary" v-if="live_class.id == live_class_id">{{
      $t('selected') }}</button>
                        <button class="ito-btn ito-btn-success" @click="selectLiveClass(live_class.id)" v-else>{{
      $t('select') }}</button>
                      </td>
                    </tr>
                    <tr v-else>
                      <td>{{ live_class_item.subject }}</td>
                      <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                      <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-center gap-3 mt-3">
            <button type="button" class="ito-btn ito-btn-lg ito-btn-outline-primary" @click="gotoStep(3)">{{
      $t('back') }}</button>
            <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(5)" :disabled="use_liveclass == 1 ? (!package_id || !product_id || !live_class_id) : (!package_id || !product_id)">{{
      $t('continue_txt') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="payment-method" class="card card-comments" :class="step == 5 ? 'fade-down' : 'd-none'">
      <div class="card-body">
        <h5 class="card-title">
          <span class="title">
            {{ $t('select_package') }} >> {{ selected_package.name }} >> {{ $t('payment') }}
          </span>
        </h5>
        <div class="card-content px-5">
          <div class="px-5">
            <div class="d-flex justify-content-between gap-4">
              <div class="label text-start text-break text-wrap">
                <span>{{ $t("selected_package") }}</span>
                <strong>
                  {{ selected_product.product_name }}
                </strong>
              </div>
              <div class="text-bold text-end" v-if="promotion_code_is_valid">
                <span class="text-decoration-line-through opacity-25">{{ selected_product.price | toCommas }}đ</span>
                {{ promotion | toCommas }}đ
                ({{ Math.round(promotion / selected_product.duration * 30) | toCommas }}đ/{{ $t('month')
                }})
              </div>
              <p class="text-bold text-end" v-else>
                {{ selected_product.price | toCommas }}đ
                ({{ Math.round(selected_product.price / selected_product.duration * 30) | toCommas }}đ/{{ $t('month')
                }})
              </p>
            </div>
            <div class="d-flex gap-3 mt-3">
              <p class="mt-2">{{ $t('promotion_code') }}:</p>
              <div class="position-relative">
                <input type="text" class="form-control m-0" v-model="promotion_code" @input="promotion_code_error = null, promotion_code_is_valid = false" :placeholder="$t('enter_the_promotion_code')" />
                <small class="text-danger" v-if="promotion_code_error">{{ promotion_code_error }}</small>
                <a href="#" class="clear-text" v-if="promotion_code" @click="promotion_code = promotion_code_error = null, promotion_code_is_valid = false">Clear</a>
              </div>
              <button type="button" class="ito-btn ito-btn-primary" :disabled="!promotion_code" @click="checkPromotionCode()">{{ $t('apply_code') }}</button>
            </div>
            <div class="d-flex slect-method gap-3 justify-content-center mt-3">
              <template v-for="(gate, index) in gates">
                <input :id="'method_' + index" class="ito-pkg" name="radio-select" type="radio" v-model="method" :value="gate.method" />
                <label :for="'method_' + index" class="payment-method-item">
                  <div class="img-gate-box">
                    <img :src="gate.icon">
                  </div>
                  <div class="payment-method-text">{{ gate.name }}</div>
                </label>
              </template>
            </div>
            <div class="d-flex w-100 justify-content-center gap-3 mt-3">
              <button type="button" class="ito-btn ito-btn-outline-primary" @click="gotoStep(4)">{{ $t('back')
                }}</button>
              <button type="button" class="ito-btn ito-btn-primary" :disabled="!product_id || !method" @click="paymentMethod()">{{ $t('pay_now') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <validate-modal :err_code="error_code" />

    <div class="modal fade payment-popup" id="confirmRegister" tabindex="-1" role="dialog" aria-labelledby="confirmRegister" data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
        <div class="modal-content box-shadow-main">
          <div class="close-popup" data-bs-dismiss="modal"></div>
          <h3 class="popup-title payment-title">{{ $t('config_account_info_title') }}</h3>
          <div class="modal-body text-center">
            <p>{{ $t('config_account_info_content') }}</p>
          </div>
          <div class="d-flex mt-3 gap-3 justify-content-center">
            <button type="button" class="ito-btn ito-btn-outline-primary" data-bs-dismiss="modal">{{
      $t('btn_recheck_account_info') }}</button>
            <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" @click="gotoStep(2)">{{
      $t('btn_confirm_account_info') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade payment-popup" id="PopupPaymentTransfer" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentTransfer" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
        <div class="modal-content box-shadow-main">
          <div class="close-popup" data-bs-dismiss="modal"></div>
          <h3 class="popup-title payment-title">{{ $t('how_to_use_bank_transfer_method') }}</h3>
          <div class="modal-body text-start" style="font-size: 14px;">
            <p>{{ $t('bank_transfer_instruction') }}</p>
            <p class="title my-2" style="text-decoration: underline;">{{ $t('bank_transfer_account_info') }}:</p>
            <p>{{ $t('bank_transfer_username') }}: <b>{{ user.fullname }}</b></p>
            <p>{{ $t('bank_transfer_new_package') }}:
              <b>
                {{ selected_product ? selected_product.product_name : '' }}
              </b>
            </p>
            <p>{{ $t('bank_transfer_expire_date') }}: <b>{{ expire_date | formatDate('DD/MM/YYYY') }}</b></p>

            <p>{{ $t('bank_transfer_instruction2') }}</p>

            <div class="d-flex mt-3 justify-content-center gap-3">
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" @click="Payment()">{{ $t('continue_txt') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade payment-popup" id="PopupPaymentTransfer2" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentTransfer2" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
        <div class="modal-content box-shadow-main">
          <div class="close-popup" data-bs-dismiss="modal"></div>
          <h3 class="popup-title payment-title">{{ $t('bank_transfer_method') }}</h3>
          <div class="modal-body text-start">
            <div class="d-flex mt-3 mb-3 justify-content-start gap-3">
              <button type="button" :class="banking_method == 'manual' ? 'ito-btn ito-btn-outline-primary' : 'ito-btn ito-btn-primary'" @click="banking_method = 'manual'">{{ $t('btn_bank_transfer_info') }}</button>
              <button type="button" :class="banking_method == 'qrcode' ? 'ito-btn ito-btn-outline-primary' : 'ito-btn ito-btn-primary'" @click="qrGenerate()" title="Beta">{{ $t('btn_scan_qrcode') }}</button>
            </div>
            <template v-if="banking_method == 'qrcode'">
              <img class="img-fluid" :src="qrcode" alt="">
            </template>
            <template v-else>
              <p class="title my-2" style="text-decoration: underline;">{{ $t('bank_transfer_detail_title') }}:</p>
              <div v-html="$t('bank_transfer_detail_content', { total: toComma(selected_product.price), transfer_content: transfer_content, })">
              </div>
            </template>
            <div class="d-flex justify-content-center">
              <button class="ito-btn ito-btn-primary px-4 mt-3" data-bs-toggle="modal" data-bs-target="#RequestSent" data-bs-dismiss="modal">{{ $t('done') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade payment-popup" id="RequestSent" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentSuccess" data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 707px;">
        <div class="modal-content box-shadow-main paymment-status">
          <h3 class="popup-title">{{ $t('request_sent') }}</h3>
          <p class="mt-3">{{ $t('bank_transfer_request_sent_content') }}</p>
          <div class="d-flex justify-content-center">
            <router-link class="ito-btn ito-btn-primary px-4 mt-3" data-bs-dismiss="modal" :to="{ name: 'login' }">{{
      $t('back_to_home') }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="RegisterSuccessPopup" tabindex="-1" role="dialog" aria-labelledby="RegisterSuccessPopup" aria-hidden="true" data-backdrop="static" data-keyboard=false>
      <div class="modal-dialog modal-dialog-centered ito-popup" role="document">
        <div class="modal-content box-shadow-main">
          <h3 class="popup-title mb-20">{{ $t('congratulation') }}</h3>
          <h5 class="text-resgister-success">
            {{ $t('registered') }}
          </h5>
          <div class="d-flex flex-column form-group gap-3">
            <a class="ito-btn ito-btn-primary" href="/upgrade-account">{{ $t('continue_txt') }}</a>
          </div>
        </div>
      </div>
    </div>

    <claim-reward-dialogue ref="claimRewardDialogue"></claim-reward-dialogue>

  </div>

</template>

<script>
import axios from "axios";
import { authRegister, authAnonymous, authCheckUser } from '../../models/auth'
import ValidateModal from '../modal/ValidateModal.vue';
import { mapGetters } from 'vuex'
import { getConfigs, getLanguage, soundClick, toCommas } from '../../utils/utils'
import { getConfig } from "../../models/profile";
import { getPackagesTmp, postPaymentTmp, applyVoucherTmp, qrGenerator } from "../../models/payment";
import { ClaimRewardDialogue } from "../modal";



export default {
  name: "Register",
  components: {
    ClaimRewardDialogue,
    ValidateModal
  },
  data() {
    const configs = getConfigs();
    const grades = configs.grades || [];
    const weekDays = $.datepicker.regional[getLanguage()].dayNames
    const token = this.$route.query.token;
    let responsePayload = token ? this.decodeJwtResponse(token) : {};
    let fullname = responsePayload.name ? responsePayload.name : '';
    let email = responsePayload.email ? responsePayload.email : '';
    return {
      confirm_term: false,
      locate: getLanguage(),
      grades: grades.sort(function (a, b) { return a - b }),
      token,
      errors: {
        fullname: '',
        grade: '',
        phone: '',
        email: '',
        gender: '',
        birthday: '',
      },
      error_message: '',
      student_id: '',
      user: {
        fullname: fullname,
        phone: '',
        email: email,
        gender: '',
        birthday: '',
        grade: '',
        receive_method: '',
      },
      showPass: false,
      showConfirm: false,
      // screen: 1,
      valid_1: false,
      error_code: '',

      packages: [],
      package_id: '',
      selected_package: '',

      products: [],
      product_id: '',
      selected_product: '',

      use_liveclass: '',
      live_classes: [],
      live_class_id: '',

      gates: [],

      method: '',
      step: 1,
      weekDays: weekDays,
      expire_date: '',
      qrcode: '',
      banking_method: 'manual',
      promotion: null,
      promotion_code: null,
      promotion_code_is_valid: false,
      promotion_code_error: null,
      transfer_content: ''
    };
  },

  created() {
  },
  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      const lang = localStorage.getItem('lang');
      if (!lang) {
        localStorage.setItem('lang', 'vi');
      }
      this.$store.commit("setLoading", true);
      axios.all([getConfig()]).then(axios.spread((response) => {
        this.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          return;
        }
        const configs = response.data.results || {};
        this.$store.commit("setConfigs", configs);
        localStorage.setItem('configs', JSON.stringify(configs));
        const localize = configs.localize
        localize.forEach(element => {
          if (element.lang === 'vi') {
            $.getJSON(element.link, function (json) {
              localStorage['vi'] = JSON.stringify(json);
            });
          } else if (element.lang === 'en') {
            $.getJSON(element.link, function (json) {
              localStorage['en'] = JSON.stringify(json);
            });
          }
        });
      }));

      this.$nextTick(function () {
        var fullmonth_array = this.locate == 'en' ? $.datepicker.regional[this.locate].monthNames : $.datepicker.regional[this.locate].monthNamesShort;
        $('.birthday').datepicker({
          dateFormat: 'dd/mm/yy',
          monthNamesShort: fullmonth_array,
          changeMonth: true,
          changeYear: true,
          yearRange: '1900:+0',
          maxDate: new Date(),
        }).on('change', (e) => {
          this.addEvent1(e);
        });
      });
      if (this.user_info && this.user_info.student_type == 2) {
        this.user.grade = this.user_info.grade;
        this.student_id = this.user_info.user_id;
      }
      const student = localStorage.getItem('student');
      if (student) {
        var parsedStudent = JSON.parse(student);
        this.user.fullname = parsedStudent.full_name;
        this.user.phone = parsedStudent.phone;
        this.user.email = parsedStudent.email;
        this.user.gender = parsedStudent.gender;
        this.user.birthday = parsedStudent.birthday;
        this.user.grade = parsedStudent.grade;
        this.student_id = parsedStudent.id;
        const dataTmp = {
          os: 'web',
          student_id: this.student_id,
        }
        this.getPackage(dataTmp)
        this.step = 3;
      }
    },

    formatDate(input) {
      return input.split("/").reverse().join("-")
    },

    addEvent1({ target }) {
      switch (target.name) {
        case 'fullname':
          this.user.fullname = target.value;
          break;
        case 'phone':
          this.user.phone = target.value;
          break;
        case 'email':
          this.user.email = target.value;
          break;
        case 'gender':
          this.user.gender = target.value;
          break;
        case 'birthday':
          this.user.birthday = target.value;
          break;
        case 'grade':
          this.user.grade = target.value;
          break;
      }
      this.errors[target.name] = false;
      var is_valid = '';
      if (this.user.fullname == '' || this.user.grade == '' || this.user.phone == '' || this.user.email == '' || this.user.gender == '' || !this.confirm_term) {
        is_valid = false
      } else {
        is_valid = true
      }
      this.valid_1 = is_valid;
    },

    eventValid1() {
      if (this.user.fullname == '') {
        this.errors.fullname = this.$i18n.t('fullname_cannot_be_empty');
      } else {
        this.errors.fullname = false;
      }
      if (this.regexSpecialChar(this.user.fullname)) {
        this.errors.fullname = this.$i18n.t('fullname_cannot_contain_special_characters');
      } else {
        this.errors.fullname = false;
      }
      if (this.user.grade == '') {
        this.errors.grade = this.$i18n.t('grade_cannot_be_empty');
      } else {
        this.errors.grade = false;
      }
      if (this.user.phone == '') {
        this.errors.phone = this.$i18n.t('phone_number_is_not_empty');
      } else {
        this.errors.phone = false;
      }
      if (this.isVietNamesePhone(this.user.phone) == false && this.user.phone != '') {
        this.errors.phone = this.$i18n.t('wrong_phone_format');
      } else {
        this.errors.phone = false;
      }
      if (!this.validateEmail(this.user.email)) {
        this.errors.email = this.$i18n.t('email_address_incorrect');
      } else {
        this.errors.email = false;
      }
      if (this.user.gender == '') {
        this.errors.gender = this.$i18n.t('choose_a_gender');
      } else {
        this.errors.gender = false;
      }
      if (!this.confirm_term) {
        this.errors.confirm_term = this.$i18n.t('please_check_terms_and_conditions');
      } else {
        this.errors.confirm_term = false;
      }


      if (!Object.keys(this.errors).find(key => this.errors[key])) {
        this.checkUserExist()
      }
    },

    isVietNamesePhone(value) {
      return /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(value);
    },

    validateEmail(value) {
      let is_valid = false;
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        is_valid = true;
      }
      return is_valid;
    },

    switchModal(current, target) {
      if (current != '') {
        $('#' + current).modal('hide');
      }
      if (target != '') {
        $('#' + target).modal('show');
      }
    },
    gotoStep(step) {
      this.step = step;
      if (step == 3) {
        this.$nextTick(function () {
          this.initSwiper();
        })
      }
    },

    checkUserExist() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData
      formData.append('phone', this.user.phone);
      formData.append('email', this.user.email);
      authCheckUser(formData).then((data) => {
        that.$store.commit("setLoading", false);
        const result = data.data.results
        if (data.data.code == 0) {
          if (result.is_exist_email || result.is_exist_phone) {
            if (result.is_exist_phone) {
              this.errors.phone = this.$i18n.t('phone_number_already_exists');
            }
            if (result.is_exist_email) {
              this.errors.email = this.$i18n.t('email_exits');
            }
            this.valid_1 = false;
            var showError = Object.values(this.errors);
            var errors = showError.filter(n => n);
            this.error_message = errors.length ? this.$i18n.t(errors[0]) : ''
          } else {
            this.switchModal('RegisterPopup', 'confirmRegister')
          }
        } else {
          that.error_code = data.data.msg;
          $('#getValidateModal').modal('show');
        }
      });

    },

    onSubmit(e) {
      localStorage.removeItem('student')
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('fullname', this.user.fullname);
      formData.append('phone', this.user.phone);
      formData.append('email', this.user.email);
      formData.append('gender', this.user.gender);
      formData.append('birthday', this.user.birthday ? this.formatDate(this.user.birthday) : '');
      formData.append('grade', this.user.grade);
      formData.append('receive_method', this.user.receive_method);

      if (that.user_info && that.user_info.student_type == 2) {
        formData.append('username', this.user_info.username);
        authAnonymous(formData).then((data) => {
          that.$store.commit("setLoading", false);
          if (data.data.code == 0) {
            $('#RegisterPopup').modal('hide');
            that.$store.commit('setAuth', true);
            localStorage.removeItem('checkedSubjects');
            const dialog = this.$refs.claimRewardDialogue
            const params = {
              title: this.$t('congratulation'),
              reward: 1000,
              message: this.$t('get_gems_created_account'),
              okButton: this.$t('ok'),
            }
            this.showDialog(dialog, params).then((confirm) => {
              if (confirm) {
                window.location.href = '/upgrade-account'
              }
            })
          } else {
            that.error_code = data.data.msg;
            $('#getValidateModal').modal('show');
          }
        }).catch(({ response }) => {
          that.$store.commit("setLoading", false);
          // showDialog('error', 'Error', response.data.results);
          that.error_code = data.data.msg;
          $('#getValidateModal').modal('show');
        });
      } else {
        this.$store.commit("setLoading", true);
        authRegister(formData).then((data) => {
          that.$store.commit("setLoading", false);
          if (data.data.code == 0) {
            const dialog = this.$refs.claimRewardDialogue
            const params = {
              title: this.$t('congratulation'),
              reward: 1000,
              message: this.$t('get_gems_created_account'),
              okButton: this.$t('ok'),
            }
            this.showDialog(dialog, params).then((confirm) => {
              if (confirm) {
                const student = data.data.results
                localStorage.setItem('student', JSON.stringify(student))
                this.user.fullname = student.full_name;
                this.user.phone = student.phone;
                this.user.email = student.email;
                this.user.gender = student.gender;
                this.user.birthday = student.birthday;
                this.user.grade = student.grade;
                this.student_id = student.id;
                const dataTmp = {
                  os: 'web',
                  student_id: this.student_id,
                }
                this.getPackage(dataTmp)
                that.step = 3;
                localStorage.removeItem('checkedSubjects');
              }
            })
          } else {
            that.error_code = data.data.error_code;
            if (that.token) {
              that.error_code = 'Tên đăng nhập đã tồn tại.';
            }
            $('#getValidateModal').modal('show');
          }
        }).catch(({ response }) => {
          that.$store.commit("setLoading", false);
          that.error_code = data.data.msg;
          $('#getValidateModal').modal('show');
        });
      }
    },

    regexSpecialChar(txt) {
      var specialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
      if (txt.match(specialRegex)) {
        return true;
      }
      return false;
    },

    decodeJwtResponse(token) {
      let base64Url = token.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    getPackage(data) {
      const that = this;
      that.$store.commit("setLoading", true);
      axios.all([getPackagesTmp(data),]).then(axios.spread((package_response) => {
        that.$store.commit("setLoading", false);

        if (package_response.data.code != 0) {
          return;
        }
        var response = package_response.data.results.packages || [];
        var packages = [];
        for (var i = 0; i < response.length; i += 2) {
          packages.push([response[i] ? response[i] : '', response[i + 1]])
        }
        that.packages = packages
        that.gates = package_response.data.results.gate || [];
        this.$nextTick(function () {
          that.initSwiper();
        });
        that.$store.commit("setLoading", false);
      }));
      $('.modal').modal('hide');
      $('#UpgradeAccountPopup').modal('show');
    },

    changePackage(selected_package) {
      this.selected_package = selected_package;
      this.package_id = selected_package.id;
      this.use_liveclass = selected_package.use_liveclass == 1 ? true : false;
      this.products = this.selected_package.products
      this.changeProduct(this.products[0])
      this.step = 4;
    },

    changeProduct(product) {
      this.selected_product = product
      this.product_id = this.selected_product.product_id
      this.live_classes = product.live_class ? product.live_class : [];
      this.live_class_id = '';
    },

    selectLiveClass(id) {
      this.live_class_id = id;
    },
    checkPromotionCode() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('product_id', this.product_id);
      formData.append('student_id', this.student_id);
      formData.append('voucher', this.promotion_code);
      applyVoucherTmp(formData).then((data) => {
        that.$store.commit("setLoading", false);
        let results = data.data.results;
        if (results.status === 0 || results.status === 5) {
          this.promotion_code_error = null;
          this.promotion_code_is_valid = true;
          if (results.type === 'percent') {
            this.promotion = this.selected_product.price - (this.selected_product.price / 100 * results.value);
          }

        } else {
          this.promotion_code_error = this.$i18n.t('invalid_promotion_code')
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        that.promotion_code_error = this.$i18n.t('invalid_promotion_code');
      });
    },
    paymentMethod() {
      const that = this;
      var today = new Date();
      that.expire_date = that.addDays(today, that.selected_product.duration)
      if (that.method == 'bank_transfer') {
        $('#PopupPaymentTransfer').modal('show');
      } else {
        that.Payment()
      }

    },

    Payment() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('product_id', this.product_id);
      formData.append('live_class_id', this.live_class_id);
      formData.append('voucher', this.promotion_code_is_valid ? this.promotion_code : null);
      formData.append('student_id', this.student_id);
      formData.append('method', this.method);
      postPaymentTmp(formData).then((data) => {
        that.$store.commit("setLoading", false);
        if (data.data.code == 0) {
          if (this.method == 'bank_transfer') {
            this.transfer_content = 'iTouch ' + this.user.fullname + ' ' + this.user.phone + ' ' + this.selected_product.product_name
            this.switchModal('PopupPaymentTransfer', 'PopupPaymentTransfer2')
          } else {
            window.location.href = data.data.results.url;
          }
        } else {
          $('#PopupPaymentFail').modal('show');
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        $('#PopupPaymentFail').modal('show');
      });
    },

    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    initSwiper() {
      new Swiper(".packageSwiper", {
        slidesPerView: 1,
        mousewheel: true,
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
      });
    },
    toComma(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return false;
    },
    // viet qr
    qrGenerate() {
      var params = {
        amount: this.selected_product.price,
        addInfo: this.transfer_content,
      }
      this.$store.commit("setLoading", true);
      qrGenerator(params).then((data) => {
        if (data.data.code == '00') {
          this.$store.commit("setLoading", false);
          var result = data.data.data;
          this.qrcode = result.qrDataURL;
          this.banking_method = 'qrcode'
        } else {
          console.error(data.data.desc)
        }
      })
    },
    async showDialog(dialog, params) {
      const cfm = await dialog.show(params);
      return cfm;
    },
  }
}
</script>

<style scoped>
.readonly {
  background-color: #dddddd;
}

.ito-input {
  width: 100%;
}

.ito-input.has-err:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 6, 6, 0.25);
}

.screen-show {
  display: block;
}

.screen-hide {
  display: none;
}

.form-control[readonly] {
  background-color: #ffffff;
}

#RegisterPopup,
#UpgradeAccountPopup {
  z-index: 1;
}

.register-popup {
  width: 592px !important;
}

.ito-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 105, 6, .25);
}

.ito-popup-large {
  max-width: 940px;
}

.popup-title {
  color: #000;
  font-size: 24px;
}

.ito-pkg[type="radio"]:not(:checked),
.ito-pkg[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.slect-method [type="radio"]+label:before,
.slect-method [type="radio"]+label:after {
  display: none;
}

.slect-method [type=radio]:checked+label.payment-method-item {
  opacity: 1;
  border: 2px solid #40C0F6;
}

.radio[type="radio"]:checked

/* ,[type="radio"]:not(:checked) */
  {
  position: absolute;
  left: -9999px;
}

.radio[type="radio"]:checked+label,
.radio[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
}

.radio-title,
.radio[type="radio"]:checked+label,
.radio[type="radio"]:not(:checked)+label {
  color: #000;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.radio[type="radio"]:checked+label:before,
.radio[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #FF7800;
  border-radius: 100%;
  background: #fff;
}

.radio[type="radio"]:checked+label:after,
.radio[type="radio"]:not(:checked)+label:after {
  content: url('../../../public/themes/ito/images/icons/icon-checked-orange.svg');
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.package-content-1 img {
  filter: grayscale(1);
}

.select-upgrade-type [type=radio]:checked+label.package .package-content-1 img {
  filter: grayscale(0);
}

.slect-method label {
  display: block;
}

.payment-popup .title {
  font-size: 16px;
  font-weight: 700;
}


fieldset {
  overflow: hidden
}

.term-and-condition-popup {
  max-width: 760px !important;
}

.term-and-condition-popup .modal-content {
  padding: 50px 50px 30px 50px;
}

.term-and-condition {
  height: 528px;
  border: 2px solid #E9E9E9;
  border-radius: 8px;
  overflow: auto;
}

.payment-popup .title {
  font-size: 16px;
  font-weight: 700;
}

.ito-form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #FF7800;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input.has-err {
  border-color: #FF000099
}

.ito-form-check .form-check-input:checked {
  background-color: #FF7800;
  border-color: #FF7800;
}

.ito-form-check .form-check-input:checked[type=radio] {
  background-color: #ffffff;
  background-image: url('/public/images/radio-dot.svg');
}

.ito-form-check .form-check-input:focus,
.ito-btn-primary:focus {
  border-color: var(--primary);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 105, 6, .25);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-desc {
  color: #141821;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.ito-form-check label {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
}

.ito-form-check label a {
  color: var(--primary);
  font-weight: 700;
  text-decoration: underline;
}

#receiveMethod .ito-form-check label {
  color: var(--primary);
  font-size: 14px;
  font-weight: 700;
}

.qr-code {
  width: 100px;
  height: 100px;
  overflow: hidden;
}


.form-group {
  position: relative;
}

.ito-input {
  width: 100%;
}

#registerBox,
#receiveMethod {
  position: relative;
  width: 592px;
  /* min-height: 362px; */
  background: #ffffff;
  margin: auto;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 4px 0px rgba(231, 231, 231, 0.25);
  border-radius: 16px;
  overflow: hidden;
}

#registerBox .form-group {
  position: relative;
}

.login-body {
  padding: 20px;
}

.no_packages {
  margin-bottom: 125px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}


.clear-text {
  cursor: pointer;
  position: absolute;
  color: #555;
  font-size: 28px;
  top: 11px;
  right: 10px;
  content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 11.4142L7.37377 14.0404L5.95956 12.6262L8.58579 10L5.95956 7.37377L7.37377 5.95956L10 8.58579L12.6262 5.95956L14.0404 7.37377L11.4142 10L14.0404 12.6262L12.6262 14.0404L10 11.4142Z" fill="url(%23paint0_linear_6727_34925)"/><defs><linearGradient id="paint0_linear_6727_34925" x1="10.4643" y1="2.662" x2="10.4643" y2="17.119" gradientUnits="userSpaceOnUse"><stop stop-color="%23FF3E12"/><stop offset="1" stop-color="%23FF7D16" stop-opacity="0.9"/></linearGradient></defs></svg>');
}
</style>