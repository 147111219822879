import {get, post} from "../APIGateWay";

export async function getFinalTest(params = {}) {
    return await get(params, '/final-test')
}

export async function startFinalTest(data) {
    return await post(data, '/final-test/start')
}

export async function resultFinalTest(data) {
    return await post(data, '/final-test/result')
}
