<template>
    <section class="four-zero-four">
        <div class="text-center">
            <!--            <p>
                            <img src="https://storage.googleapis.com/npay/assets/static/landing/logo.svg" height="50" alt="">
                        </p>-->
            <!--<h4>404. That an error.</h4>-->
            <!--<p>The requested URL was notfound on this server.</p>-->
            <div class="l-body__wrapper">
                <div class="l-body__content">
                    <div class="l-404 text-center c-card">
                        <div class="l-404__wrapper">
                            <div class="l-404__image"></div>
                            <div class="l-404__message">
                                <h2 class="message-title">Error 404</h2>
                                <div class="message-subtitle">Sorry, page cannot be found!</div>
                            </div>
                            <div class="l-404__action">
                                <a href="javascript:history.back();" class="btn btn--default btn-sm"><i
                                        class="fas fa-angle-left h-me-12"></i>Back</a>
                                <a href="/home" class="btn btn--primary btn-sm"><i
                                        class="fas fa-home h-me-12"></i>Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import NavBar from '../NavBar'
    import SideBar from '../SideBar'

    export default {
        name: "ErrorLayout",

        components: {
            NavBar,
            SideBar
        },

        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            apiLoading() {
                return this.$store.getters.apiLoading
            },
            alert() {
                return this.$store.getters.alert
            }
        },

        created() {
            //this.$store.dispatch("SET_USER_INFO");
            //this.$store.commit("setLoading", true);
        },
    }
</script>
<style scoped>
    .c-card {
        display: block;
        margin: 0 auto 24px;
        padding: 24px;
        background-color: #fff;
        border-radius: 16px;
    }

    .l-404__wrapper {
        width: 100%;
    }

    .l-404 {
        min-height: calc(100vh - 112px);
        display: flex;
        align-items: center;
        padding-bottom: 48px;
        margin: 0;
    }

    .l-404__image {
        margin: 0 auto;
        width: 100%;
        max-width: 800px;
        height: 200px;
        background-image: url('https://online.ismart.edu.vn/assets/img/404.gif');
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .l-404__message {
        margin: -32px 0 0;
        position: relative;
    }

    .l-404__message .message-title {
        font-size: 28px;
        margin: 0;
        font-family: "Alfa Slab One", cursive;
    }

    .l-404__message .message-subtitle {
        margin: 8px 0 0;
        font-family: "Pangolin", cursive;
        font-size: 16px;
        color: #757575;
    }

    .l-404__action {
        margin: 32px 0 0;
    }

    .btn.btn-sm {
        padding: 6px 18px;
        line-height: 24px;
        font-size: 16px;
        border-radius: 14px;
    }

    .l-404__action .btn {
        margin: 8px;
    }

    .btn {
        padding: 8px 20px;
        line-height: 28px;
        font-size: 18px;
        border-radius: 16px;
        font-weight: 600;
        text-align: center;
        border: 2px solid #fff;
        color: #424242;
        background-image: linear-gradient(to top, #e0e0e0, #eeeeee);
        box-shadow: 0 1px 0 3px #eee;
        -moz-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .1s ease-in-out, opacity .2s ease-in-out, -moz-transform .1s ease-in-out;
        -o-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .1s ease-in-out, opacity .2s ease-in-out, -o-transform .1s ease-in-out;
        -webkit-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .1s ease-in-out, opacity .2s ease-in-out, -webkit-transform .1s ease-in-out;
        transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .1s ease-in-out, opacity .2s ease-in-out, transform .1s ease-in-out;
    }

    .btn.c-button--primary, .btn-primary, .btn--primary {
        color: #fff;
        background-image: linear-gradient(to top, #e84e1c, #ec6b41);
        box-shadow: 0 1px 0 3px rgb(232 78 28 / 20%);
    }

    @media (min-width: 992px) {
        .l-body__content {
            padding: 24px 48px;
        }

        .l-404__image {
            height: 400px;
        }
    }

    @media (min-width: 576px) {
        .l-404__message .message-title {
            font-size: 24px;
        }
    }
</style>
