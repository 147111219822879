var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"challenge-item",class:{
    disabled: _vm.challenge.status == 4,
    opened: _vm.challenge.status == 2 || _vm.challenge.status == 3
}},[_c('div',{staticClass:"row-1"},[_vm._v(" "+_vm._s(_vm.challenge.key_open)+" "),_c('i',{staticClass:"ito-icon-lock"})]),_c('div',{staticClass:"row-2"},[_c('i',{class:{
            'ito-icon-box-disabled': _vm.challenge.status == 4,
            'ito-icon-box-close': _vm.challenge.status == 0 || _vm.challenge.status == 1,
            'ito-icon-box-opened': _vm.challenge.status == 2 || _vm.challenge.status == 3
        }})]),_c('div',{staticClass:"row-3"},[(_vm.challenge.status == 1 || _vm.challenge.status == 0)?_c('button',{staticClass:"ito-btn ito-btn-sm ito-btn-primary",attrs:{"disabled":_vm.challenge.status == 0},on:{"click":_vm.claim}},[_vm._v(" "+_vm._s(_vm.$t('claim'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }