import { get, get_2, post, vietqr } from "../APIGateWay";

export async function getPackages(params = { 'os': 'web' }) {
    return await get(params, '/package/all')
}
export async function getPackagesTmp(params) {
    return await get_2(params, '/package/all/tmp')
}

export async function postPayment(data) {
    return await post(data, '/payment')
}
export async function postPaymentTmp(data) {
    return await post(data, '/payment/tmp')
}

export async function paymentDetail(params) {
    return await get(params, '/payment/info')
}
export async function applyVoucher(params) {
    return await post(params, '/payment/voucher')
}
export async function applyVoucherTmp(params) {
    return await post(params, '/payment/voucher/tmp')
}

export async function qrGenerator(params) {
    return await vietqr(params)
}