<template>
  <div id="content">
    <div class="container">
      <div id="page-header">
        <div class="tools">
          <nav class="ito-tabs">
            <router-link class="align-items-center gap-1" :to="{ path: '/daily-challenge' }"
              @click.native="getSoundClick">
              {{ $t('daily_challenge') }}
              <div class="noti-alert" v-if="user_info.dailychallenge_noti != 0"></div>
            </router-link>
            <router-link class="align-items-center gap-1" :to="{ path: '/daily-bonus' }" @click.native="getSoundClick">
              {{ $t('daily_bonus') }}
              <div class="noti-alert" v-if="user_info.dailybonus_gemreward != 0"></div>
            </router-link>
            <router-link class="active align-items-center gap-1" :to="{ path: '/mission' }" @click.native="getSoundClick">
              {{ $t('menu_item_achievements') }}
              <div class="noti-alert" v-if="user_info.count_achieve_unread != 0"></div>
            </router-link>
          </nav>
        </div>
      </div>
      <div id="achievement" class="card mt-3">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('menu_item_achievements') }}</span>
          </h5>
          <div class="card-content p-0">
            <div class="description">
              {{ $t('complete_all_the_achievements_to_get_rewards') }}
            </div>
            <div id="achievement-content">
              <div class="achievement-list">
                <template v-for="(achievement, index) in achievements">
                  <achievement-item :achievement="achievement" @updateMission="updateMission" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAchievements } from "../../models/achievement";
import AchievementItem from "./AchievementItem";

export default {
  name: "Achievement",
  components: { AchievementItem },

  data() {
    return {
      achievements: [],
    };
  },

  computed: {
    user_info() {
      return this.$store.state.user_info
    }
  },

  created() {
    this.$store.commit('setMenuActive', 'achievement');

    this.fetch();
  },

  methods: {
    fetch() {
      const that = this;

      axios.all([
        getAchievements(),
      ]).then(axios.spread((response) => {
        that.isLoading = false;

        if (response.data.code != 0) {
          return;
        }

        that.achievements = response.data.results.achieve || [];

        this.$nextTick(function () {
          initNanoScroller();
        })


        that.user_info.count_achieve_unread = response.data.results.count_achieve_unread || 0;
      }));
    },

    updateMission(data) {
      this.fetch();
    },

  }
}
</script>

<style scoped>
#achievement-content {
  min-height: 310px;
  max-height: calc(100vh - 390px);
  overflow: auto;
}
</style>
