<template>
    <nav id="nav-bar" class="d-flex">
        <a href="/home" class="logo"></a>
        <div class="menu-item select-lang" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#selectLangModal"
            style="position: absolute; right: 175px;">
            <!--<span class="menu-text">{{ $t('language') }}</span>-->
            <img class="flag" :src="locate_info.img_flag" alt="" />
        </div>
        <!--        <a href="/" class="help-button"><img src="/themes/ito/images/faqs/return.png" style="margin-right: 5px">{{$t('return_to_ito')}}</a>-->
    </nav>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { getConfigs, getLanguage, soundClick } from "../utils/utils";

export default {
    name: "NavBarBlank",

    data() {
        const configs = getConfigs();
        const locate = getLanguage();
        const localizes = configs.localize || [];

        let locate_info = {};
        localizes.forEach(item => {
            if (item.lang == locate) {
                locate_info = item;
            }
        });
        return {
            user: { name: 'Guest' },
            // locate: 'vi',
            locate: getLanguage(),
            locate_info: locate_info,
            configs: configs,
            localizes: localizes,
        };
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },

    created() {
        this.fetch();
        this.locate = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi';
    },
    methods: {
        fetch() {

        },

        setLocate(e) {
            localStorage.setItem('lang', e);
            this.locate = e;
            // document.location = "/";
            location.reload();
            //this.$store.dispatch('setLang', lang);
            // e.preventDefault();
        }
    }
}
</script>

<style scoped>
#nav-bar {
    justify-content: space-between;
    align-items: center;
}

.help-button {
    /*background: linear-gradient(180deg, #20C997 0%, #28A745 100%);*/
    background: #FFF;
    border: 1px solid #20C997;
    color: #20C997;
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 4px 0px rgba(231, 231, 231, 0.25);
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
}
</style>
