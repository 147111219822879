<template>
    <div class="ebooks" style="padding-bottom: 0;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12" style="height: 100vh">
                    <iframe :src="ebook_link"
                            frameborder="0" allowfullscreen width="100%" height="100%" referrerpolicy="origin"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {getEbook} from "../../models/dashboard";
    import axios from "axios";
    import {soundClick, soundGetReward, soundClaimTreasure} from '../../utils/utils';

    export default {
        name: "Ebook",
        data() {
            return this.initState();
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch(){
                this.getEbooks()
            },
            initState() {
                return {
                    ebook_link: '',
                };
            },
            getEbooks() {
                const that = this;
                that.$store.commit("setLoading", true);
                const params = this.$route.params;
                axios.all([
                    getEbook(params)
                ]).then(axios.spread((ebook) => {
                    that.$store.commit("setLoading", false);
                    if (ebook.data.code == 0){
                        that.ebook_link = ebook.data.results.item.content || '';
                    }
                }));
            },
        }
    }
</script>

<style scoped>
</style>
