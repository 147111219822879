<template>
    <div id="content">
        <div class="container">
            <div id="page-header-fixed">
                <div class="container">
                    <div id="page-header">
                        <ul class="breadcrumb">
                            <li class="icon">
                                <a @click="$router.go(-1)">
                                    <img src="/themes/ito/images/back-to.svg" alt="">
                                </a>
                            </li>
                            <li class="active">{{ unit_name }} - {{ lesson_name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <!--          <h5 class="card-title">
                                <span class="title">Vocabulary</span>
                              </h5>
                              <div class="card-content">
                                {{item.content}}
                              </div>-->

                    <div class="card-content" style="height: calc(100vh - 285px); padding: 0;min-height: 550px;">
                        <!--<iframe src="https://lms-module.ismart.edu.vn/mcqgame"-->
                        <!--frameborder="0" allowfullscreen width="100%" height="100%"></iframe>-->
                        <iframe :src="item.content" frameborder="0" allowfullscreen width="100%" height="100%"
                            scrolling="no"></iframe>

                    </div>
                </div>
            </div>
            <!--<div class="card mt-20">-->
            <!--<div class="card-body">-->
            <!--<h5 class="card-title">-->
            <!--<span class="title">Practice</span>-->
            <!--</h5>-->
            <!--<div class="card-content" style="height: calc(100vh - 333px);">-->
            <!--<iframe :src="item.content"-->
            <!--frameborder="0" allowfullscreen width="100%" height="100%"></iframe>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <div class="modal fade reward-popup" id="rewardPopup" style="display: none;" data-backdrop="static"
                data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                    <div class="modal-content box-shadow-main paymment-status">
                        <div class="close-popup" data-bs-dismiss="modal"></div>
                        <h3 class="popup-title success">Completed practice</h3>
                        <div class="list-item-result-popup">
                            <div class="item-result-popup" :class="crown > 0 ? 'active' : ''"></div>
                            <div class="item-result-popup" :class="crown > 1 ? 'active' : ''"></div>
                            <div class="item-result-popup" :class="crown > 2 ? 'active' : ''"></div>
                        </div>
                        <div class="box-result-popup">
                            <div class="result-txt">You answered correctly</div>
                            <div class="result-score"><span class="score">{{ correct }}</span>/<span class="total">{{
                                    total }}</span> questions</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { getItemInfo, getUnitLessonDetail, getUnitLessonItem } from "../../models/dashboard";
import LessonDetailItem from "./LessonDetailItem";

export default {
    name: "LessonPractice",
    data() {
        return {
            isLoading: false,
            item: {},
            unit_name: '',
            lesson_name: '',
            correct: 0,
            total: 0,
            crown: 0,
        };
    },

    created() {
        //window.addEventListener('message', this.handleMessage, false);

        const { item_id } = this.$route.params;
        const that = this;
        const f = function (event) {
            switch (event.data.type) {
                case 'update_item_info':
                    axios.all([
                        getItemInfo({
                            id: item_id,
                            item_type: 'practice',
                        }),
                    ]).then(axios.spread((response) => {
                        if (response.data.code != 0) {
                            return;
                        }
                        that.correct = response.data.results.current_result.correct_quest;
                        that.total = response.data.results.current_result.total_quest;
                        that.crown = response.data.results.current_result.crown;
                        let user = this.$store.state.user_info;
                        user.crown += parseInt(response.data.results.current_result.crown)
                        user.crown_month += parseInt(response.data.results.current_result.crown)
                        $('#rewardPopup').modal({ backdrop: 'static', keyboard: false });
                    }));
                    break;
                case 'quit_item':
                    break;

            }
            window.removeEventListener("message", f, false);
        }
        window.addEventListener("message", f);

        this.getUnitLessonItem()
    },

    methods: {
        handleMessage(event) {
            const { item_id } = this.$route.params;
            const that = this;
            switch (event.data.type) {
                case 'update_item_info':
                    // getItemInfo({
                    //     id: item_id,
                    //     item_type: 'practice',
                    // });
                    axios.all([
                        getItemInfo({
                            id: item_id,
                            item_type: 'practice',
                        }),
                    ]).then(axios.spread((response) => {
                        if (response.data.code != 0) {
                            return;
                        }
                        that.correct = response.data.results.current_result.correct_quest;
                        that.total = response.data.results.current_result.total_quest;
                        that.crown = response.data.results.current_result.crown;
                        let user = this.$store.state.user_info;
                        user.crown += parseInt(response.data.results.current_result.crown)
                        user.crown_month += parseInt(response.data.results.current_result.crown)
                        $('#rewardPopup').modal({ backdrop: 'static', keyboard: false });
                    }));
                    break;
                case 'quit_item':
                    break;
            }
        },

        getUnitLessonItem() {
            const that = this;

            const { item_id } = that.$route.params;

            that.isLoading = true;

            axios.all([
                getUnitLessonItem(item_id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.item = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
            }));
        },
    }
}
</script>

<style scoped>
.card-content {
    padding: 30px;
}

.vocabulary-wrapper {
    padding-left: 10px;
    border-radius: 8px;
    background: #F3F3F3;
}

.list-of-lesson {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-of-lesson li {
    height: 65px;
    line-height: 65px;
    display: flex;
    align-items: center;
}

.list-of-lesson li.active {
    background: #FFFFFF;
}

.vocabulary-left {
    width: 367px;
    background: #F3F3F3;
}

.list-of-lesson .ito-icon-checked {
    width: 41px;
    height: 41px;
    background-size: 100%;
    margin: 0 10px;
}

.list-of-lesson .ito-icon-checked-disable {
    width: 41px;
    height: 41px;
    background-size: 100%;
    margin: 0 10px;
}

.vocabulary-right {
    position: relative;
    width: calc(100% - 367px);
    background: #FFFFFF;
    box-shadow: 0px 4px 0px 0px #E7E7E740 inset;
    box-shadow: 4px 4px 0px 0px #00000012;
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    padding: 20px;
}

.name-of-lesson {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: #1E263C;
}

.lesson-des-text {
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
}

.img-preview {
    width: 100%;
    margin: 20px auto;
}

.img-preview img {
    width: 100%;
}

.player-button {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 42px;
    height: 42px;
    background: url(/public/themes/ito/images/sound.svg) no-repeat center;
    background-size: 100%;
}

.result-score {
    text-align: center;
}

.box-result-popup {
    background: rgba(242, 242, 242, 1);
    width: 100%;
    padding: 7px 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}
</style>