<template>
    <div id="content">
        <div class="container">
            <div class="card fade-down card-payment">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title" v-if="payment_status == 0">Payment unsuccessfully</span>
                        <span class="title" v-if="payment_status == 1">Payment successfully</span>
                    </h5>

                    <div class="card-content">
                        <div class="payment-status payment-fail" v-if="payment_status == 0">
                            <h3><img src="/themes/ito/images/emoticon-24.svg" alt=""></h3>
                            <p>Your payment has failed.</p>
                            <p>Please make sure that your payment details are correct.</p>
                            <div class="d-flex justify-content-center mt-3">
                                <a class="ito-btn ito-btn-default" href="/register">Home</a>
                            </div>
                        </div>

                        <div class="payment-status payment-fail" v-if="payment_status == 1">
                            <h3><img src="/themes/ito/images/get-rewarded.svg" alt=""></h3>
                            <p v-html="$t('payment_success_noti')"></p>
                            <div class="ito-btn ito-btn-primary  mt-3" @click="goHome" style="margin: 10px auto">
                                {{ isMobile ? 'Back to app' : $t('back_to_home') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { updateProfile } from '../../models/profile'
import { showDialog } from '../../utils/dialog'
import { mapGetters } from 'vuex'
import axios from "axios";
import { paymentDetail } from "../../models/payment";
import { soundClick } from "../../utils/utils";

export default {
    name: "PaymentDetail",
    data() {
        const invoice = this.$route.query.invoice || '';
        return {
            invoice: invoice,
            payment_status: '',
            isMobile: false
        };
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }
            return this.user
        })
    },

    created() {
        this.fetch();
    },

    mounted() {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    },
    watch: {},

    methods: {
        fetch() {
            const that = this;
            axios.all([
                paymentDetail({
                    invoice: that.invoice,
                }),
            ]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    return;
                }
                that.payment_status = response.data.results.status
                if (that.payment_status === 1) {
                    //post mess to app
                    if (appHandler) {
                        appHandler.postMessage('payment-done');
                    }
                }
            }));
        },

        getSoundClick() {
            soundClick();
        },

        goHome() {
            if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
                window.location.href = '/home'
            } else {
                window.location = 'ismart://openApp?data=payment-success&id=0'
            }
        }
    }
}
</script>

<style scoped>
.card-payment .card-content {
    text-align: center;
    padding: 60px;
}

.payment-status p {
    font-size: 14px;
    line-height: 19px;
}

.btn-action-fail {
    width: 230px;
    margin: auto;
    justify-content: space-between;
    margin-top: 10px;
}

.btn-action-fail a,
.btn-action-fail a:hover {
    color: #fff;
}

@media (max-width: 1024px) {
    .card-payment .card-content {
        text-align: center;
        padding: 30px;
    }
}
</style>
