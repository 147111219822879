<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/daily-challenge.css?v=4">

    <div class="container">

      <div id="page-header-fixed">
        <div class="container">
          <div id="page-header">
            <div class="tools">
              <nav class="ito-tabs">
                <router-link class="active align-items-center gap-1" :to="{ path: '/daily-challenge' }" @click.native="getSoundClick">
                  {{ $t('daily_challenge') }}
                  <div class="noti-alert" v-if="user_info.dailychallenge_noti != 0"></div>
                </router-link>
                <router-link class="align-items-center gap-1" :to="{ path: '/daily-bonus' }" @click.native="getSoundClick">
                  {{ $t('daily_bonus') }}
                  <div class="noti-alert" v-if="user_info.first_login == 1"></div>
                </router-link>
                <router-link class="align-items-center gap-1" :to="{ path: '/mission' }" @click.native="getSoundClick">
                  {{ $t('menu_item_achievements') }}
                  <div class="noti-alert" v-if="user_info.count_achieve_unread > 0"></div>
                </router-link>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-daily-challenge mt-3">
        <div class="card-body">
          <h5 class="card-title d-flex justify-content-between">
            <span class="title">{{ $t('daily_challenge') }}</span>
            <div class="title-desc">
              <p class="title-desc-key">{{ $t('key_collected') }}<span class="ms-1">{{ key_collect }}</span><i class="ito-icon-lock"></i></p>
              <p class="title-desc-time">{{ $t('reset_in') }}<span class="ms-1">{{ time_remain_txt }}</span></p>
            </div>

          </h5>

          <div class="card-content">
            <div id="daily-challenge" class="d-flex w-100">
              <div class=" challenges col-6 overflow-hidden">
                <div class="challenge-list p-3">
                  <template v-for="challenge in challenges">
                    <daily-challenge-item :challenge="challenge" @updateNewChallenge="updateNewChallenge" />
                  </template>
                </div>
              </div>
              <div class="col-6 px-3 overflow-hidden position-relative">
                <div class="nano" style="min-height: 500px;">
                  <div class="nano-content">
                    <template v-for="mission in missions">
                      <daily-challenge-mission-item :mission="mission" @updateNewMission="updateNewMission" />
                    </template>
                  </div>
                </div>
                <!-- <div class="swiper swiper2">
                  <div class="swiper-wrapper">
                    <template v-for="mission in missions">
                      <daily-challenge-mission-item :mission="mission" @updateNewMission="updateNewMission" />
                    </template>
                  </div>
                  <div class="swiper-scrollbar"></div>
                </div> -->

              </div>

            </div>
          </div>
        </div>
      </div>

      <get-reward-modal :reward="key_collect" />
      <get-reward-lock-modal :reward="mission_collect" />
      <reward-modal :title="$t('claim_success')" image="/themes/ito/images/mascot/isaac-key.svg" :reward="30" type="gem" />

    </div>

  </div>
</template>

<script>
import { getDailyChallenge } from '../../models/reward'
import { showDialog } from '../../utils/dialog'
import { mapGetters } from 'vuex'
import { pushQueries, soundClick, convertSecondToText } from "../../utils/utils";
import axios from "axios";
import { getInboxs } from "../../models/inbox";
import DailyChallengeItem from "./DailyChallengeItem";
import DailyChallengeMissionItem from "./DailyChallengeMissionItem";
import GetRewardModal from "../modal/GetRewardModal";
import GetRewardLockModal from "../modal/GetRewardLockModal";
import RewardModal from "../modal/RewardModal";

export default {
  name: "DailyChallenge",
  components: { GetRewardModal, DailyChallengeMissionItem, DailyChallengeItem, GetRewardLockModal, RewardModal },
  data() {
    return {
      challenges: [],
      missions: [],
      key_collect: 0,
      mission_collect: 0,
      time_remain: 0,
      time_remain_txt: '00:00:00',
      isLoading: true
    };
  },

  created() {
    this.$store.commit('setMenuActive', 'achievement');
    this.fetch();
  },

  watch: {
    time_remain: {
      handler(value) {

        if (value > 0) {
          setTimeout(() => {
            this.time_remain--;

            this.time_remain_txt = convertSecondToText(this.time_remain) || '';
          }, 1000);
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },

  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },

  methods: {
    fetch() {
      const that = this;

      //that.user_info.dailychallenge_noti = 1;

      axios.all([
        getDailyChallenge(),
      ]).then(axios.spread((response) => {
        that.isLoading = false;

        if (response.data.code != 0) {
          return;
        }

        that.challenges = response.data.results.challenge || [];
        that.missions = response.data.results.mission || [];
        that.key_collect = response.data.results.key_collect || 0;
        that.time_remain = response.data.results.time_remain || 0;
        this.$nextTick(function () {
          if ($('.nano').length > 0) {
        $(".nano").nanoScroller({ sliderMinHeight: 40 });
    }
        })
        // this.$nextTick(function () {
        //   that.initSwiper1();
        //   that.initSwiper2();
        // });

      }));
    },

    getSoundClick() {
      soundClick();
    },


    updateNewMission(new_key_collect, new_mission_id, new_mission_status, new_mission_collect, new_challenges) {
      this.updateNewChallenge(new_mission_collect, new_challenges);
      this.key_collect = new_key_collect;
      this.missions.forEach(element => {
        if (element.id == new_mission_id) {
          element.status = new_mission_status;
          this.mission_collect = new_mission_collect;
        }
      });
    },

    updateNewChallenge(key_collect, challenges) {
      this.challenges = challenges;
      this.key_collect = key_collect;
    },

    initSwiper1() {
      return new Swiper(".swiper1", {
        freeMode: true,
        spaceBetween: 10,
        slidesPerView: 5,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true
        },
        mousewheel: true
      });
    },
    initSwiper2() {
      return new Swiper(".swiper2", {
        direction: 'vertical',
        freeMode: true,
        spaceBetween: 10,
        slidesPerView: 5,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true
        },
        mousewheel: true
      });
    }
  }
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.card-daily-challenge .card-title .title-desc {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.card-daily-challenge .card-title .title-desc p {
  display: flex;
  align-items: center;
  color: #000;
}

.card-daily-challenge .card-title .title-desc .title-desc-key {
  font-size: 16px;
  font-weight: 700;
}

.card-daily-challenge .card-title .title-desc .title-desc-key span {
  color: #070A28;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.card-daily-challenge .card-title .title-desc .title-desc-time {
  font-size: 16px;
  font-weight: 400;
}

.card-daily-challenge .card-title .title-desc .title-desc-time span {
  font-size: 16px;
  font-weight: 700;
}
</style>