import { render, staticRenderFns } from "./AchievementItem.vue?vue&type=template&id=06e550d8&scoped=true"
import script from "./AchievementItem.vue?vue&type=script&lang=js"
export * from "./AchievementItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e550d8",
  null
  
)

export default component.exports