<template>
    <div id="blank-page">
        <nav-bar-blank/>
        <div class="modal fade" id="selectLangModal" tabindex="-1" role="dialog" aria-labelledby="selectLangModal"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('choose_language') }}</h3>
                    <div class="select-lang">

                        <div :class="locate == localize.lang?'select-lang-item active':'select-lang-item'"
                             @click="setLocate(localize)" v-for="(localize, index) in configs.localize">
                            <div class="lang-icon">
                                <img :src="localize.img_flag" alt="">
                            </div>
                            <div class="lang-text">
                                {{ $t(localize.lang) }}
                            </div>
                            <div class="lang-checked" v-if="locate == localize.lang">
                                <i class="ito-icon-checked-white"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="ito-btn ito-btn-primary mt-20" @click="updateLocate()">
                            {{ $t('confirm') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper d-flex align-items-stretch">
        <div id="content-blank">
            <router-view></router-view>
            <footer-element/>
        </div>
        </div>
        <!--<div id="mobile">-->
        <!--<img class="mobile-logo" src="/themes/ito/images/logo-mobile.svg" alt="">-->

        <!--<h3 class="mobile-title">Tải ứng dụng</h3>-->

        <!--<div class="mobile-description">-->
        <!--Ứng dụng tương thích trên mọi điện thoại thông minh và máy tính bảng, giúp học viên có thể học tập mọi lúc mọi-->
        <!--nơi.-->
        <!--</div>-->

        <!--<div class="mobile-buttons">-->
        <!--<div class="mobile-button-android">-->
        <!--<a href="/">-->
        <!--<img src="/themes/ito/images/googleplay.svg" alt="">-->
        <!--</a>-->
        <!--</div>-->
        <!--<div class="mobile-button-ios">-->
        <!--<a href="itms-services://?action=download-manifest&url=https://ismart-online-testing.s3.ap-southeast-1.amazonaws.com/v3/builds/app.plist" target="_blank">-->
        <!--<img src="/themes/ito/images/appstore.svg" alt="">-->
        <!--</a>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="mobile-bottom">-->
        <!--<img src="/themes/ito/images/mobile-phone.svg" alt="">-->
        <!--</div>-->
        <!--</div>-->
        <!--<div id="tablet">-->
        <!--<img class="tablet-logo" src="/themes/ito/images/logo-mobile.svg" alt="">-->

        <!--<div class="tablet-bottom">-->
        <!--<h3 class="tablet-title">Tải Ứng dụng</h3>-->
        <!--<div class="tablet-desc">Ứng dụng tương thích trên mọi điện thoại thông minh và máy tính bảng, giúp học-->
        <!--viên có thể học tập mọi lúc mọi nơi.-->
        <!--</div>-->
        <!--<div class="tablet-buttons">-->
        <!--<a href="itms-services://?action=download-manifest&url=https://ismart-online-testing.s3.ap-southeast-1.amazonaws.com/v3/builds/app.plist" target="_blank">-->
        <!--<img class="tablet-button-android" src="/themes/ito/images/appstore.svg" alt="">-->
        <!--</a>-->
        <!--<a href="/">-->
        <!--<img class="tablet-button-ios" src="/themes/ito/images/googleplay.svg" alt="">-->
        <!--</a>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

    </div>
</template>

<script>
    import FooterElement from "../FooterElement";
    import NavBarBlank from "../NavBarBlank";
    import {getConfigs, getLanguage, soundClick} from "../../utils/utils";
    import {updateLanguage} from '../../models/profile';

    export default {
        name: "Blank",

        data() {
            const grade_default = localStorage.getItem('grade');
            //const configs = getConfigs();
            //const localizes = configs.localize || [];

            const configs = this.$store.getters.configs;

            return {
                locate: getLanguage(),
                locate_obj: null,
                user: {
                    grade: 1
                },
                grade_selected: grade_default || 1,
                placement_test: {
                    id: 0,
                    condition_pass: 0,
                    time: 0,
                    total_question: 0,
                },
                //configs: configs,
                //localizes: localizes,
                localizes: configs?configs.localize:[],
            };
        },
        components: {
            NavBarBlank,
            FooterElement,
        },
        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            user_info() {
                return this.$store.state.user_info;
            },
            configs() {
                const configs = this.$store.getters.configs;
                if(configs){
                    return configs;
                }

                return {
                    localize: []
                }
            },
        },

        methods: {
            fetch() {

            },
            getSoundClick() {
                soundClick();
            },

            setLocate(e) {
                this.getSoundClick();

                this.locate_obj = e;
                this.locate = e.lang;

                //localStorage.setItem('lang', e);
            },

            updateLocate() {
                const that = this;

                this.getSoundClick();

                localStorage.setItem('lang', this.locate);

                if (that.locate_obj != null) {
                    that.$store.commit("setLoading", true);

                    updateLanguage({
                        id: that.locate_obj.id,
                        is_raw: true
                    }).then((response) => {
                        that.$store.commit("setLoading", false);

                        if (response.data.code != 0) {
                            return;
                        }
                    }).catch(({response}) => {
                        that.$store.commit("setLoading", false);

                        console.error(response);
                    });
                }

                location.reload();
            }
        }
    }
</script>

<style scoped>
    #content-blank{
        width: 100%;
        background: #f8cb64;
        background-image: url(/public/themes/ito/images/background.png);
        background-size: 100% auto;
        -webkit-background-size: 100% auto;
        -o-background-size: 100% auto;
        -moz-background-size: 100% auto;
        -ms-background-size: 100% auto;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: bottom;
        text-align: center;
        padding-bottom: 100px;
    }
    @media screen and (min-device-width: 1024px) {
        #landing-page {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 40px 94px 65px 94px;
            background-image: url("/public/themes/ito/images/mobile-bg-1.png");
        }

        #tablet {
            display: block;
        }

        #mobile {
            display: none !important;
        }

        .tablet-logo {
            width: 175px;
            height: 95px;
            margin: 0 auto;
            display: block;
        }

        .tablet-desktop {
            width: 262px;
            height: 114px;
            margin: 42px auto 18px auto;
            display: block;
        }

        .tablet-desktop-desc-1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #070A28;
            text-align: center;
        }

        .tablet-desktop-desc-2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #070A28;
            margin-top: 60px;
            text-align: center;
        }

        h3.tablet-title {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #070A28;
            margin-top: 48px;
        }

        .tablet-desc {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: rgba(7, 10, 40, 0.8);
            margin: 18px auto 12px auto;
        }

        .tablet-buttons {
        }

        .tablet-button-android {
            margin-right: 35px;
            display: inline-block;
        }

        .tablet-button-ios {
            margin-right: 35px;
            display: inline-block;
        }

        .tablet-bottom {
            text-align: center;
        }
    }
</style>