<template>
    <div id="content">
        <div class="container">
            <div id="page-header-fixed">
                <div class="container">
                    <div id="page-header">
                        <ul class="breadcrumb">
                            <li class="icon">
                                <router-link :to="{ path: '/' }">
                                    <img src="/themes/ito/images/back-home.svg" alt="">
                                </router-link>
                            </li>
                            <li class="active">{{ $t('back_to_home') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <template v-if="placement_test">
                <div class="mark-lesson" v-if="screen == 'test'"></div>
                <div class="card fade-down" :class="{ testing: screen == 'test', 'box-leson': screen == 'test', 'mt-20': screen == 'start_test' }">
                    <div class="card-body" v-if="screen == 'start_test'">
                        <h5 class="card-title">
                            <span class="title">{{ $t('do_final_test') }}</span>
                        </h5>
                        <div class="card-content">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <div class="d-flex justify-content-center">
                                    <img src="/themes/ito/images/reading.svg">
                                </div>
                                <div class="test-condition-wrapper box-shadow-main mt-3">
                                    <div class="test-condition-left d-flex flex-fill justify-content-center align-items-center">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td class="text-end title">{{ $t('total') }}:</td>
                                                    <td class="text-start content">{{ placement_test.total_question + ' ' + $t('scores') }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-end title">{{ $t('time_allowance') }}</td>
                                                    <td class="text-start content">{{ placement_test.time + ' ' + $t('minutes') }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="test-condition-right">
                                        <p class="text-center" v-html="$t('must_correct_answer_more_than') + ' ' + placement_test.condition_pass + ' ' + $t('corrects')" v-if="placement_test.pass_type == 0"></p>
                                        <p class="text-center" v-html="$t('must_correct_answer_more_than') + ' ' + placement_test.condition_pass + '%'" v-else></p>
                                    </div>
                                </div>

                                <div class="ito-btn ito-btn-primary mt-3" @click="placementTest()">
                                    {{ $t('start') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-if="screen == 'test'">
                        <div class="lesson-action-button" @click="screen = 'start_test'"><span>+</span></div>
                        <div class="card-content" style="height: 100%; padding: 0;min-height: 550px;">
                            <iframe :src="item.content" frameborder="0" allowfullscreen width="100%" height="100%"></iframe>
                        </div>

                    </div>
                </div>
            </template>
            <div class="card mt-20 fade-down" v-if="errors">
                <div class="card-body p-4">
                    <div class="card-content">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center">
                            <h5>{{ $t('sorry_no_search_result') }}
                            </h5>
                            <img class="mt-3" src="/themes/ito/images/mascot/isaac-extension.svg" alt="" width="125px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { getPlacementTest, startPlacementTest, resultPlacementTest } from "../../models/placement_test";


export default {
    name: "PlacementTest",
    props: [
        'user_info'
    ],
    data() {
        return {
            test_id: '',
            item: {},
            screen: 'start_test',
            placement_test: '',
            errors: undefined
        };
    },

    created() {
        const { item_id } = this.$route.params;
        this.test_id = item_id;
        this.getPlacementItem()
    },
    mounted() {
        window.addEventListener('message', this.handleMessage)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage)
    },
    methods: {
        getPlacementItem() {
            const that = this;
            const { item_id } = that.$route.params;
            that.$store.commit("setLoading", true);
            getPlacementTest({
                grade_id: item_id
            }).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                const result = response.data.results
                if (result) {
                    this.placement_test = result[0]
                    this.errors = false
                } else {
                    this.errors = true
                }
                $('#updateGradeModal').modal("hide");
            }).catch(({ response }) => {
                that.$store.commit("setLoading", false);
                console.error(response);
            });
        },

        placementTest() {
            const that = this;
            that.$store.commit("setLoading", true);
            startPlacementTest({
                id: this.placement_test.id
            }).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                that.item = response.data.results;
                that.screen = 'test';
            }).catch(({ response }) => {
                that.$store.commit("setLoading", false);
                console.error(response);
            });
        },

        handleMessage(event) {
            switch (event.data.action) {
                case 'test_done':
                    axios.all([
                        resultPlacementTest({
                            id: this.placement_test.id,
                        }),
                    ]).then(axios.spread((response) => {
                        if (response.data.code != 0) {
                            return;
                        }
                        if (response.data.results.pass_test == 1) {
                            localStorage.setItem('grade', response.data.results.grade);
                        }
                    }));
                    break;
                case 'test_close':
                    window.location.href = '/home';
                    break;

            }
        },
    }
}
</script>

<style scoped>
#page-header .breadcrumb {
    margin-bottom: 0;
}

.test-condition-wrapper {
    display: flex;
    background: #3164CC;
    padding: 10px;
    gap: 30px;
}

.test-condition-left .title {
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    opacity: .6;
}

.testing {
    z-index: 11;
    overflow: hidden;
}

.test-condition-left .content {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
}

.test-condition-right {
    display: flex;
    font-size: 12px;
    min-height: 66px;
    background: #FFFFFF;
    color: #141821;
    border-radius: 8px;
    box-shadow: 4px 4px 0px 0px #00000012;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}
</style>