<template>
    <div>
        <nav-bar v-on:show_error="showError" />
        <div id="wrapper" class="wrapper d-flex align-items-stretch">
            <div class="left-corner"></div>
            <div class="right-corner"></div>
            <side-bar />
            <router-view></router-view>
            <footer-element />
            <div id="noti_icon" class="noti-hidden" @click="toggleBanner(false)" title="click to show banner"
                v-if="banners.length"></div>
        </div>

        <div class="modal fade" id="selectLangModal" tabindex="-1" role="dialog" aria-labelledby="selectLangModal"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('choose_language') }}</h3>
                    <div class="select-lang">

                        <div :class="locate == localize.lang ? 'select-lang-item active' : 'select-lang-item'"
                            @click="setLocate(localize)" v-for="(localize, index) in localizes">
                            <div class="lang-icon">
                                <img :src="localize.img_flag" alt="">
                            </div>
                            <div class="lang-text">
                                {{ $t(localize.lang) }}
                            </div>
                            <div class="lang-checked" v-if="locate == localize.lang">
                                <i class="ito-icon-checked-white"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="ito-btn ito-btn-primary mt-20" @click="updateLocate()">
                            {{ $t('confirm') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <loading-layout />
        <upgrade-account-modal />

        <div class="modal fade" id="placementModal" tabindex="-1" role="dialog" aria-labelledby="placementModal"
            aria-hidden="true" data-backdrop="static" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 657px;">
                <div class="modal-content box-shadow-main" style="padding-top: 65px;">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <div class="img-title">
                        <img src="/themes/ito/images/image01/grade.svg" alt="">
                    </div>
                    <h3 class="popup-title-verify">{{ $t('choose_grade_3') }}</h3>
                    <h3 class="popup-title">{{ $t('choose_grade_1') }}</h3>
                    <div class="grade-select-body d-flex flex-column gap-3 mt-3">
                        <div class="d-flex flex-column align-items-start" v-if="grades.primary.length">
                            <h5 class="grade-title">{{ $t('primary') }}:</h5>
                            <div class="grade-select-list flex-wrap justify-content-start">
                                <template v-for="grade in grades.primary">
                                    <div @click="selectGrade(grade)" class="grade-select-item"
                                        :class="{ active: grade == grade_selected }">{{ grade }}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-start" v-if="grades.secondary.length">
                            <h5 class="grade-title">{{ $t('secondary') }}:</h5>
                            <div class="grade-select-list flex-wrap justify-content-start">
                                <template v-for="grade in grades.secondary">
                                    <div @click="selectGrade(grade)" class="grade-select-item"
                                        :class="{ active: grade == grade_selected }">{{ grade }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-3">
                        <button class="ito-btn ito-btn-primary" @click="confirmPlacementTest">
                            {{ $t('do_placement') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testPlacementModal" tabindex="-1" role="dialog" aria-labelledby="testPlacementModal"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 650px;">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('placement_test') }}</h3>
                    <div class="d-flex quick-test-box text-center">
                        <img src="/themes/ito/images/reading.svg" style="width: 150px; margin: 0 auto;">
                    </div>
                    <div class="quick-test-bottom box-shadow-main d-flex mt-20">
                        <div class="quick-test-bottom-text">
                            <div class="txt-2-rows">
                                <div class="text-right d-inline-block left-title">{{ $t('total') }}</div>
                                <div class="d-inline-block right-text">{{ placement_test.total_question }}
                                    {{ $t('scores') }}
                                </div>
                            </div>
                            <div class="txt-2-rows">
                                <div class="text-right d-inline-block left-title">{{ $t('time_allowance') }}</div>
                                <div class="d-inline-block right-text">{{ placement_test.time }} {{ $t('minutes') }}
                                </div>
                            </div>
                        </div>
                        <div class="ito-btn ito-btn-primary mt-3" @click="placementTest">
                            {{ $t('start') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="confirmLogout" class="modal fade modal-flex" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3 class="popup-title mb-20">{{ $t('logout') }}</h3>
                        <p class="txt-popup">{{ $t('logout_confirm') }}</p>
                    </div>
                    <i class="close-popup" data-bs-dismiss="modal" aria-label="Close"></i>
                    <div class="d-flex justify-content-center gap-3 mt-3">
                        <div class="ito-btn ito-btn-primary px-4" data-bs-dismiss="modal">
                            {{ $t('no') }}
                        </div>
                        <div class="ito-btn ito-btn-outline-primary px-4" @click="onLogout()">
                            {{ $t('yes') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <daily-bonus-modal v-if="user_info.first_login == 1" :user="user_info" @updateFirstLogin="updateFirstLogin" />
        <get-reward-modal :reward="key_collect" />
        <image-modal :banners="banners" @toggleBanner="toggleBanner" v-if="banners.length" />



        <div id="headerErr" class="modal fade headerErr" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3 class="popup-title mb-20">{{ $t('error') }}</h3>
                        <p class="txt-popup text-center">{{ sl_grade_err.message }}</p>
                    </div>
                    <i class="close-popup" data-bs-dismiss="modal" aria-label="Close"></i>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import FooterElement from "../FooterElement";
import { getConfigs, getLanguage, soundClick, soundBackground } from "../../utils/utils";
import { getPlacementTest, startPlacementTest } from "../../models/placement_test";
import { getBanners } from "../../models/dashboard";
import { UpgradeAccountModal, ImageModal } from "../modal";
import { updateLanguage, changeGrade } from '../../models/profile';
import router from "../../router";
import DailyBonusModal from '../modal/DailyBonusModal.vue';
import GetRewardModal from "../modal/GetRewardModal";
import LoadingLayout from '../layout/LoadingLayout.vue';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";

export default {
    name: "AppLayout",

    data() {
        const grade_default = localStorage.getItem('grade');
        const configs = getConfigs();
        const localizes = configs.localize || [];
        var grades = {
            primary: [],
            secondary: [],
        };
        var get_grades = configs.grades || [];
        if (get_grades.length) {
            get_grades.forEach(grade => {
                if (grade <= 5) {
                    grades["primary"].push(grade);
                } else {
                    grades["secondary"].push(grade);
                }
            });
        }
        return {
            locate: getLanguage(),
            locate_obj: null,
            user: {
                grade: 1
            },
            grades: grades,
            grade_selected: grade_default || 1,
            placement_test: {
                id: 0,
                condition_pass: 0,
                time: 0,
                total_question: 0,
            },
            configs: configs,
            localizes: localizes,
            sl_grade_err: '',
            key_collect: '',
            banners: [],
            banner_mkt: [],
        };
    },

    components: {
        UpgradeAccountModal,
        FooterElement,
        NavBar,
        SideBar,
        DailyBonusModal,
        LoadingLayout,
        GetRewardModal,
        ImageModal

    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading
        },
        user_info() {
            return this.$store.state.user_info;
            ;
        },
    },

    mounted() {
        // soundBackground()
        this.grade_selected = localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade;

        // // 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
        // const firebaseConfig = {
        //     apiKey: "AIzaSyAOn-4r9KDUi3zNM0J-c8UJQ6HXoDeiv-8",
        //     authDomain: "ismart-touch.firebaseapp.com",
        //     projectId: "ismart-touch",
        //     storageBucket: "ismart-touch.appspot.com",
        //     messagingSenderId: "347862001235",
        //     appId: "1:347862001235:web:4883eaa496054c93dfc8a6",
        //     measurementId: "G-SZERWN0HVJ"
        // };

        // const app = initializeApp(firebaseConfig);
        // const messaging = getMessaging(app);

        // if ('serviceWorker' in navigator) {
        //     console.log('serviceWorker is available');
        //     // window.addEventListener('load', function () {
        //     console.log('register serviceworker');
        //     navigator.serviceWorker.register('/firebase-messaging-sw.js').then(registration => {
        //         Notification.requestPermission().then((permission) => {
        //             if (permission === 'granted') {
        //                 console.log('Notification permission granted.');
        //                 getToken(messaging, { vapidKey: 'BL7a5zx2zN1iAPA9j22HnqyHaNYeS1N9jSUH1IdY3ZTdVMVBTqS1q6gMEydpZH8pIZQl2xNNVpxo5LyxB-_q2pY' }).then((currentToken) => {
        //                     if (currentToken) {
        //                         window.localStorage.setItem('firebaseToken', currentToken);
        //                     } else {
        //                         console.log('No registration token available. Request permission to generate one.');
        //                     }
        //                 }).catch((err) => {
        //                     console.log('An error occurred while retrieving token. ', err);
        //                 });
        //                 navigator.serviceWorker.ready.then(p => {
        //                     p.pushManager.getSubscription().then(subscription => {
        //                         if (subscription === null) {
        //                             let re = p.pushManager.subscribe({
        //                                 userVisibleOnly: true
        //                             })

        //                         }
        //                     })

        //                 })

        //             } else {
        //                 console.log('Unable to get permission to notify.');
        //             }
        //         });
        //     })
        //     // })
        // }
        // // 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
        this.fetch();
    },
    updated() {
        this.$nextTick(() => {
            $('#notification').on('hidden.bs.modal', function (e) {
                $("#noti_icon").fadeIn();
            })
            $('#notification').on('shown.bs.modal', function (e) {
                $("#noti_icon").fadeOut();
            })
        })
    },

    methods: {
        fetch() {
            this.getBanner();

            this.handleGACustomEvent();
        },

        refreshData() {
            if (this.$refs.audio) {
                this.$refs.audio.play();

                document.getElementById('footer').click();
            }
        },

        updateFirstLogin(status, reward) {
            if (status == 0) {
                this.user_info.first_login = status;
                this.key_collect = reward;
                $('#getRewardedModal').modal('show');
            }
            else {
                var error = {
                    message: this.$i18n.t(reward)
                }
                this.showError(error);
            }
        },

        getSoundClick() {
            soundClick();
        },

        confirmPlacementTest() {
            const that = this;

            that.getSoundClick();

            // if (this.user_info.grade >= this.grade_selected) {
            //     localStorage.setItem('grade', this.grade_selected);
            //     $('#placementModal').modal("hide");
            //     window.location.href = '/'
            // } else {
            that.$store.commit("setLoading", true);
            const path = '/placement-test/' + that.grade_selected
            if (this.$route.path !== path) {
                this.$router.push(path)
                $('#placementModal').modal("hide");
            } else {
                that.$store.commit("setLoading", false);
                $('#placementModal').modal("hide");
            }
            // }
        },

        placementTest() {
            const that = this;

            that.getSoundClick();

            that.$store.commit("setLoading", true);

            startPlacementTest({
                id: this.placement_test.id
            }).then((response) => {
                that.$store.commit("setLoading", false);

                if (response.data.code != 0) {
                    return;
                }

                $('#testPlacementModal').modal("hide");
            }).catch(({ response }) => {
                that.$store.commit("setLoading", false);

                console.error(response);
            });
        },

        selectGrade(grade) {
            this.grade_selected = grade;
        },

        onLogout(e) {
            document.cookie = `_wid=; expires=Thu, 01 Jan 1970 00:00:00 UTC,  path=/`;
            this.$store.dispatch('logout').then(() => {
                window.location.href = "/login";

                sessionStorage.removeItem('showed_incomplete_lesson_popup');
            });
            // e.preventDefault();
        },

        setLocate(e) {
            this.getSoundClick();

            this.locate_obj = e;
            this.locate = e.lang;

            //localStorage.setItem('lang', e);
        },

        updateLocate() {
            const that = this;

            this.getSoundClick();

            localStorage.setItem('lang', this.locate);

            if (that.locate_obj != null) {
                that.$store.commit("setLoading", true);

                updateLanguage({
                    id: that.locate_obj.id,
                    is_raw: true
                }).then((response) => {
                    if (response.data.code != 0) {
                        return;
                    }
                    that.$store.commit("setLoading", false);
                    location.reload();
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            }

        },

        showError(message) {
            this.sl_grade_err = message
            $('#headerErr').modal("show");
        },

        handleGACustomEvent() {
            var element = 'ITC_W_0000'
            var user_id = this.user_info.user_id || '';
            var school_id = this.user_info.school_id_ref || '';
            var grade_code = this.user_info.grade_code || '';
            var class_code = this.user_info.class_code || '';
            var params = {
                'w_user_id': user_id,
                'school_id': school_id,
                'grade_code': grade_code,
                'class_code': class_code
            };
            params[element] = user_id + (((school_id || grade_code || class_code) != '') ? '|' : '')
                + school_id + (((grade_code || class_code) != '') ? '|' : '')
                + grade_code + ((class_code != '') ? '|' : '')
                + class_code;
            gtag('event', element, params);
        },

        toggleBanner(status) {
            localStorage.setItem('hideBanner', status);
            if (status) {
                $('#notification').modal('hide');
            } else {
                $('#notification').modal('show');
            }
        },

        getBanner() {
            const that = this;
            const params = {};
            this.$store.commit("setLoading", true);
            axios.all([getBanners(params)]).then(
                axios.spread((response) => {
                    if (response.data.code != 0) {
                        this.$store.commit("setLoading", false);
                        return;
                    }
                    var banner = [];
                    var banner_mkt = [];
                    const banners = response.data.results || [];
                    if (banners.length) {
                        banners.forEach((item) => {
                            var image = this.getBannerImage(item.banner_url)
                            item.image = image
                            if (item.category == 'notify_banner') {
                                banner.push(item);
                            } else if (item.category == 'notify_banner_mkt') {
                                banner_mkt.push(item);
                            }
                        })
                        that.banners = banners
                        this.banner_mkt = banner_mkt;
                        let hideBanner = localStorage.hideBanner

                        $('#notification').modal('show');
                    }
                    this.$store.commit("setLoading", false);
                })
            );
        },
        getBannerImage(images) {
            images = JSON.parse(images);
            var lang = localStorage.getItem('lang');
            var imageStyleObject = {
                'content': 'url("' + images[lang] + '")',
            }
            return imageStyleObject
        },
        spawnNotification() {
            if (this.banners.length) {
                let banner = this.banners[0];
                let theTitle = banner.title;
                let theBody = banner.content
                let theIcon = '/themes/ito/images/menu/menu-home.svg'
                let theImage = banner.banner_url;

                const options = {
                    body: theBody,
                    icon: theIcon,
                    image: theImage,
                };
                const n = new Notification(theTitle, options);
            }
        }
    }
}
</script>
<style scoped>
.noti-hidden {
    content: url(/public/themes/ito/images/icons/noti-icon.svg);
    position: fixed;
    width: 88px;
    height: 88px;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
}

.left-corner {
    position: fixed;
    width: 13vw;
    max-width: 163px;
    z-index: 2;
    bottom: 30px;
    left: 105px;
    animation: 1s ease-out 0s 1 slideInFromLeft;
    content: var(--left-corner-image);
}

.right-corner {
    position: fixed;
    width: 13vw;
    max-width: 177px;
    top: 85px;
    right: 23px;
    animation: 1s ease-out 0s 1 slideInFromRight;
    content: var(--right-corner-image);
}

@media screen and (max-width: 1366px) {

    .left-corner,
    .right-corner {
        display: none;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
</style>