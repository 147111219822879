import Vue from 'vue'
import VueRouter from 'vue-router'
import { Forbidden, Login, NotFound, Welcome, Search, LoginAsStudent } from './components'
import { Register, ForgotPassword, RegisterAnomynous } from './components/auth'
import { Profile, AccountVerify } from './components/profile'
import { DailyChallenge, DailyBonus } from './components/reward'
import { Inbox } from './components/inbox'
import { LeaderBoard } from './components/leaderboard'
import { ExchangeGift, ExchangeHistory, BuySticker, ExchangeCollection } from './components/exchange_gift'
import { ParentFeedback, ParentReport, ParentAccount, ParentUpgradeAccount, ParentCertificate } from './components/parent'
import { UnitList, LessonDetail, LessonVocabulary, LessonLecture, LessonPractice, ExtensionDetail, PrepList } from './components/unit'
import { Tutoring } from './components/toturing'
import { LiveClass } from './components/live_class'
import { Events } from './components/events'
import { PlacementTest } from './components/placement_test'
import { FinalTest } from './components/final_test'
import { Faq } from './components/faq'
import { Ebook, EbookHome } from './components/ebook'
import { YourSpaceTrophies, YourSpaceBadges, YourSpaceCertificates } from './components/your_space'
import { OpenHouse } from './components/modal';

import { PaymentDetail, PaymentDetailTmp, UpgradeAccount } from './components/payment'

import { LandingPage, GeneralPrincipal } from './components/landing_page'


import { store } from './store'
import Achievement from "./components/achievement/Achievement";

const router = new VueRouter({
    mode: 'history',
    routes: [

        { path: '/', name: 'landing_page', component: LandingPage, meta: { layout: 'landing-page-layout' } },
        { path: '/general-principle', alias: '/privacy-policy', name: 'general_principle', component: GeneralPrincipal, meta: { layout: 'landing-page-layout', title: 'Terms & Policies' } },

        { path: '/home', name: 'home', component: Welcome, meta: { requiresAuth: true, title: 'Trang chủ' } },

        { path: '/404', name: 'not_found', component: NotFound, meta: { title: 'Trang không tồn tại' } },
        { path: '/403', name: 'forbidden', component: Forbidden, meta: { title: 'Lỗi dịch vụ' } },

        { path: '/apps', name: 'apps', meta: { layout: 'mobile-layout' } },

        { path: '/login', name: 'login', component: Login, meta: { layout: 'auth-layout', title: 'Trang đăng nhập tài khoản' } },
        { path: '/register', name: 'register', component: Register, meta: { layout: 'auth-layout', title: 'Trang đăng ký tài khoản' } },
        { path: '/register-anomynous', name: 'register_anomynous', component: RegisterAnomynous, meta: { layout: 'auth-layout', title: 'Trải nghiệm thử' } },
        { path: '/forgot-password', name: 'forgot_password', component: ForgotPassword, meta: { layout: 'auth-layout', title: 'Quên mật khẩu' } },

        { path: '/search', name: 'search', component: Search, meta: { title: 'Trang tìm kiếm' } },

        { path: '/profile', name: 'profile', component: Profile, meta: { title: 'Trang thông tin tài khoản' } },
        { path: '/account-verify-:tab', name: 'account_verify', component: AccountVerify, meta: { title: 'Xác thực thông tin tài khoản' } },
        { path: '/daily-challenge', name: 'daily_challenge', component: DailyChallenge, meta: { title: 'Thử thách hàng ngày' } },
        { path: '/daily-bonus', name: 'daily_bonus', component: DailyBonus, meta: { title: 'Thưởng đăng nhập hàng ngày' } },
        { path: '/inbox', name: 'inbox', component: Inbox, meta: { title: 'Trang thông báo' } },
        { path: '/exchange-gift', name: 'exchange_gift', component: ExchangeGift, meta: { title: 'Trang đổi quà tặng' } },
        { path: '/exchange-history', name: 'exchange_history', component: ExchangeHistory, meta: { title: 'Trang lịch sử đổi thưởng' } },
        { path: '/exchange-collection', name: 'exchange_collection', component: ExchangeCollection, meta: { title: 'Trang bộ sưu tập' } },
        { path: '/buy-sticker', name: 'buy_sticker', component: BuySticker, meta: { title: 'Trang mua sticker' } },
        { path: '/leaderboard', name: 'leaderboard', component: LeaderBoard, meta: { title: 'Bảng xếp hạng' } },
        { path: '/unit/:subject_id/:unit_id/:page', name: 'unit', component: UnitList },
        { path: '/prep/:subject_id/:unit_id/:page', name: 'prep', component: PrepList },
        { path: '/mission', name: 'achievement', component: Achievement },
        { path: '/lesson/:subject_id-:unit_id-:lesson_id', name: 'lesson_detail', component: LessonDetail },
        { path: '/extension/:subject_id-:unit_id-:lesson_id', name: 'extension_detail', component: ExtensionDetail },
        { path: '/lesson/vocabulary/:item_id', name: 'lesson_vocabulary', component: LessonVocabulary },
        { path: '/lesson/lecture/:item_id', name: 'lesson_lecture', component: LessonLecture },
        { path: '/lesson/practice/:item_id', name: 'lesson_practice', component: LessonPractice },
        { path: '/parent/report', name: 'parent_report', component: ParentReport, meta: { title: 'Dành cho phụ huynh - Báo cáo học tập' } },
        { path: '/parent/feedback', name: 'parent_feedback', component: ParentFeedback, meta: { title: 'Dành cho phụ huynh - Phản hồi' } },
        { path: '/parent/account', name: 'parent_account', component: ParentAccount, meta: { title: 'Dành cho phụ huynh - Thông tin tài khoản' } },
        { path: '/parent/certificate', name: 'parent_certificate', component: ParentCertificate, meta: { title: 'Dành cho phụ huynh - Chứng chỉ học tập' } },
        { path: '/upgrade-account', name: 'upgrade_account', component: UpgradeAccount, meta: { title: 'Trang nâng cấp tài khoản' } },
        { path: '/tutoring', name: 'tutoring', component: Tutoring, meta: { title: 'Trang học bổ trợ trực tuyến' } },
        { path: '/live-class', name: 'live_class', component: LiveClass, meta: { title: 'Trang học trực tuyến' } },
        { path: '/events', name: 'events', component: Events, meta: { title: 'Trang sự kiện hàng tuần' } },
        { path: '/payment/detail', name: 'payment_detail', component: PaymentDetail, meta: { layout: 'payment-layout', title: 'Trang thanh toán' } },
        { path: '/payment/detail/tmp', name: 'payment_detail_tmp', component: PaymentDetailTmp, meta: { layout: 'auth-layout', title: 'Trang thanh toán' } },

        { path: '/placement-test/:item_id', name: 'placement_test', component: PlacementTest },

        { path: '/final-test/:item_id/:subject_id', name: 'final_test', component: FinalTest },

        { path: '/faqs', name: 'faqs', component: Faq, meta: { layout: 'blank-layout', title: 'Trang hỏi & đáp' } },

        { path: '/ebook/:unit_id', name: 'ebook', component: Ebook, meta: { layout: 'empty-layout' } },
        { path: '/ebook-home', name: 'ebook_home', component: EbookHome, meta: { layout: 'empty-layout', title: 'Trang chủ Ebook' } },

        { path: '/your-space/trophies', name: 'your_space_trophies', component: YourSpaceTrophies, meta: { title: 'Bộ sưu tập cúp' } },
        { path: '/your-space/badges', name: 'your_space_badges', component: YourSpaceBadges, meta: { title: 'Bộ sưu tập danh hiệu' } },
        { path: '/your-space/certificates', name: 'your_space_certificates', component: YourSpaceCertificates, meta: { title: 'Bộ sưu tập chứng chỉ' } },

        { path: '/user/login-as-student/:username', name: 'login_as_student', component: LoginAsStudent },

        { path: '/open-house-learning-path', name: 'open_house', component: OpenHouse,  meta: { layout: 'empty-layout',title: 'Open house learning path' } },



        { path: '*', component: { default: NotFound, helper: NotFound }, meta: { layout: 'error-layout', title: 'Lỗi' } }
    ],
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0,
        };
    }
});

router.beforeResolve((to, from, next) => {

    if (to.name == 'login') {
        console.log(store.getters.isLoggedIn)
        if (store.getters.isLoggedIn) {
            next('/home')
            return;
        }
    }

    if (to.name == 'landing_page') {
        if (store.getters.isLoggedIn) {
            next('/login')
            return;
        } else {
            next('/home')
            return;
        }
    }

    // if (to.name == 'open_house') {
    //     return;
    // }
    // If this isn't an initial page load.
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (store.getters.isLoggedIn) {
            //store.dispatch("SET_USER_INFO");

            store.commit('setLoading', true);

            next();

            return
        }

        console.log('Auth from route')
        // next('/register-anomynous')

        next('/login')
    }

    //store.commit("setLoading", true);

    if (to.name) {
        // Start the route progress bar.
    }

    next()
});

router.afterEach((to, from) => {
    const DEFAULT_TITLE = 'iSMART Touch';
    store.commit('setLoading', false);
    Vue.nextTick(() => {
        document.title = to.meta.title ? DEFAULT_TITLE + ' - ' + to.meta.title : DEFAULT_TITLE;
    });
    /*const public_pages = ['login'];

    if (public_pages.indexOf(to.name) > -1) {
        if (typeof grecaptcha.reset == 'function') {
            grecaptcha.reset();
        }
    }*/
});

export default router;