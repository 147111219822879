<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/parents.css">

    <div class="container">
      <div id="page-header-fixed" class="mb-25">
        <div class="container">
          <div id="page-header">
            <div class="tools">
              <nav class="ito-tabs">
                <router-link :to="{ name: 'parent_report' }" class="active" @click.native="getSoundClick">{{ $t('report') }}</router-link>
                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_certificate' }" @click.native="getSoundClick">{{ $t('certificate') }}</router-link>
                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_feedback' }" @click.native="getSoundClick">{{ $t('feedback') }}</router-link>
                <router-link :to="{ name: 'parent_account' }" @click.native="getSoundClick">{{ $t('account_information') }}</router-link>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-parents">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('program_report') }}</span>
          </h5>

          <div class="card-content">
            <!--<div class="title">{{$t('completed')}}</div>-->
            <div v-if="report_program.length > 0">
              <!--<template v-for="(sbj, index) in report_total_subject">-->
              <!--<div class="info-item">-->
              <!--<div class="info-label">{{$t(sbj.category_code)}}</div>-->
              <!--<div class="info-body">-->
              <!--<div class="info-body-content">-->
              <!--<div class="info-body-1"><span class="number">{{sbj.completed}}</span>-->
              <!--{{$t('lessons')}}-->
              <!--&lt;!&ndash;{{sbj.unit_name}}s&ndash;&gt;-->
              <!--</div>-->
              <!--<div class="info-body-2">-->
              <!--<div class="progress">-->
              <!--<div class="progress-process"-->
              <!--v-bind:style="{ width: calcPercent(sbj.completed,sbj.total) + '%' }"></div>-->
              <!--</div>-->
              <!--</div>-->
              <!--<div class="info-body-3"><span class="number">{{sbj.completed}}</span>-->
              <!--{{$t('of')}}-->
              <!--{{sbj.total}} {{$t('lessons')}}-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <!--</template>-->
              <template v-for="(sbj, i) in report_program">
                <h4 class="title">{{ $t(sbj.code) }}</h4>
                <template v-for="(sbj1, i1) in sbj.subjects">
                  <template v-for="(sbj2, i2) in sbj1.course">
                    <div class="info-item">
                      <div class="info-label">{{ sbj1.code != 'english' ? $t(sbj1.unit_name) : $t(sbj2.unit_name) }}</div>
                      <div class="info-body">
                        <div class="info-body-content">
                          <div class="info-body-2">
                            <div class="progress">
                              <div class="progress-process" v-bind:style="{ width: calcPercent(sbj2.completed, sbj2.total) + '%' }"></div>
                            </div>
                          </div>
                          <div class="info-body-3"><span class="number">{{ sbj2.completed }}</span>
                            {{ $t('of') }}
                            {{ sbj2.total }} {{ $t('lessons') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
            <!--<div v-if="report_total_vocab && !isLoading">-->
            <!--<div class="info-item">-->
            <!--<div class="info-label">{{$t('vocabulary')}}</div>-->
            <!--<div class="info-body">-->
            <!--<div class="info-body-content">-->
            <!--<div class="info-body-1"><span-->
            <!--class="number">{{report_total_vocab.completed}}</span> {{$t('words')}}-->
            <!--</div>-->
            <!--<div class="info-body-2">-->
            <!--<div class="progress">-->
            <!--<div class="progress-process"-->
            <!--v-bind:style="{ width: calcPercent(report_total_vocab.completed,report_total_vocab.total) + '%' }"></div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="info-body-3"><span-->
            <!--class="number">{{report_total_vocab.completed}}</span> {{$t('of')}}-->
            <!--{{report_total_vocab.total}} {{$t('words')}}-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="title title2">{{$t('usage_report')}}</div>-->
            <!--<div class="desc">{{$t('this_month')}} <b>{{reports.total_time_month}}</b></div>-->
          </div>
        </div>
      </div>

      <div class="card card-parents" v-if="user_info.eco_user_id">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('academic_report') }}</span>
          </h5>

          <div class="card-content ar">
            <div v-if="checkAcdemic()">
              <template v-for="(acd, index) in academics">
                <div class="row">
                  <template v-for="(item, index) in acd.class_term">
                    <div class="col-md-3">
                      <div class="item-academic" style="margin-bottom: 30px">
                        <img src="/themes/ito/images/preview.svg" />
                        <div class="item-academic-class">{{ acd.class_name }}</div>
                        <div class="item-academic-name">{{ item.term_name }}</div>
                        <div class="btn-preview" @click="reviewAcademic(acd.class_id, item.term_id)">
                          {{ $t('preview') }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div v-else style="padding: 30px 0;text-align: center;">
              {{ $t('empty_academic_report') }}
            </div>
          </div>
        </div>
      </div>

      <div class="card card-parents">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('ebook_report') }}</span>
          </h5>

          <div class="" style="padding: 30px; display: flex; align-items: baseline;">
            <div class="form-group">
              <label class="label-input">{{ $t('grade') }}</label>
              <select class="ito-input" name="grade" v-model="grade" :title="$t('choose_a_grade')">
                <option value="">
                  {{ $t('choose_a_grade') }}
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="form-group">
              <label class="label-input">{{ $t('subject') }}</label>
              <select class="ito-input" name="subject" v-model="subject" :title="$t('subject')">
                <option value="">
                  {{ $t('subject') }}
                </option>
                <option value="math">{{ $t('math') }}</option>
                <option value="science">{{ $t('science') }}</option>
                <option value="english">{{ $t('english') }}</option>
              </select>
            </div>
            <div>
              <button v-if="view_table" class="ito-btn-small active" style="border: none" @click="getEbookReports">
                {{ $t('preview') }}
              </button>
              <button v-if="!view_table" class="ito-btn-small" style="border: none">
                {{ $t('preview') }}
              </button>
            </div>
          </div>
          <div v-if="show_result">
            <div class="tbl-report" v-if="ebook_reports && ebook_reports.length > 0">
              <table>
                <tr>
                  <th>{{ $t('ebook_report_time') }}</th>
                  <th>{{ $t('ebook_report_quiz_name') }}</th>
                  <th>{{ $t('ebook_report_page') }}</th>
                  <th>{{ $t('ebook_report_result') }}</th>
                  <th>{{ $t('ebook_report_score') }}</th>
                  <th>{{ $t('ebook_report_time_spend') }}</th>
                </tr>
                <template v-for="(report, index) in ebook_reports">
                  <tr>
                    <td>{{ timeConverterEbook(report.time) }}</td>
                    <td>{{ report.quiz_name }}</td>
                    <td>{{ report.page }}</td>
                    <td>{{ report.result }}</td>
                    <td>{{ report.score }}</td>
                    <td>{{ report.time_spend }}</td>
                  </tr>
                </template>
              </table>
              <div class="tbl-pagination">
                <div class="tbl-pagination__first" v-text="`${page_ebook_reports.from} - ${page_ebook_reports.to} of ${page_ebook_reports.total}`"></div>
                <div class="tbl-pagination__content">
                  <div class="tbl-pagination__preview">
                    <img src="/themes/ito/images/icons/icon-calander-prev.svg" alt="" @click="prevPage()">
                  </div>
                  <div class="tbl-pagination__next">
                    <img src="/themes/ito/images/icons/icon-calander-next.svg" alt="" @click="nextPage()">
                  </div>
                </div>
              </div>
            </div>
            <div class="tbl-report" v-else>
              <div class="text-center" style="font-size: 16px; font-weight: 700; margin: 15px auto;">
                {{ $t('empty_ebook_report') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-parents">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('usage_report') }}</span>
          </h5>
          <div class="card-content">
            <div class="report">
              <div class="report-1">
                <div class="title">{{ $t('completed') }}</div>
                <div v-if="report_day.length > 0">
                  <template v-for="(subjects, index) in report_day">
                    <div v-for="(category_code, index) in subjects.program" v-if="formatDay(subjects.day_time) == day_select">
                      <!--v-if="formatDay(subjects.day_time)==day_select"-->
                      <div class="title">{{ $t(category_code.code) }}</div>
                      <div v-for="(course, index) in category_code.subjects" class="info-item">
                        <div class="info-label">
                          {{ $t(course.code) }}
                        </div>
                        <div class="info-body">
                          <div class="info-body-content">
                            <div class="info-body-1"><span class="number">{{ course.completed }}</span>
                              {{ $t('lesson') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="empty_use_report">
                    <div class="empty_study_success">
                      <span>{{ $t('empty_study_success') }}</span>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <template>
                    <div class="info-item">
                      <div class="info-label">
                        {{ $t('math') }}
                      </div>
                      <div class="info-body">
                        <div class="info-body-content">
                          <div class="info-body-1"><span class="number">0</span> {{ $t('lesson') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="info-item">
                      <div class="info-label">
                        {{ $t('science') }}
                      </div>
                      <div class="info-body">
                        <div class="info-body-content">
                          <div class="info-body-1"><span class="number">0</span> {{ $t('lesson') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="info-item">
                      <div class="info-label">
                        {{ $t('english') }}
                      </div>
                      <div class="info-body">
                        <div class="info-body-content">
                          <div class="info-body-1"><span class="number">0</span> {{ $t('lesson') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div>
                  <!--<div v-if="report_day != null" v-for="(vocabularys,index) in report_day">-->
                  <!--<div v-if="formatDay(vocabularys.day_time)==day_select" class="info-item">-->
                  <!--<div class="info-label">{{$t('vocabulary')}}</div>-->
                  <!--<div class="info-body">-->
                  <!--<div class="info-body-content">-->
                  <!--<div class="info-body-1">-->
                  <!--<span class="number info-labe "> {{vocabularys.vocabulary.completed}} </span>-->
                  <!--</div>-->
                  <!--</div>-->
                  <!--</div>-->

                  <!--</div>-->
                  <!--&lt;!&ndash;<div v-else class="info-item">&ndash;&gt;-->
                  <!--&lt;!&ndash;<div class="info-label">{{$t('vocabulary')}}</div>&ndash;&gt;-->
                  <!--&lt;!&ndash;<div class="info-body">&ndash;&gt;-->
                  <!--&lt;!&ndash;<div class="info-body-content">&ndash;&gt;-->
                  <!--&lt;!&ndash;<div class="info-body-1">&ndash;&gt;-->
                  <!--&lt;!&ndash;<span class="number info-labe "> 0 </span>&ndash;&gt;-->
                  <!--&lt;!&ndash;</div>&ndash;&gt;-->
                  <!--&lt;!&ndash;</div>&ndash;&gt;-->
                  <!--&lt;!&ndash;</div>&ndash;&gt;-->

                  <!--&lt;!&ndash;</div>&ndash;&gt;-->
                  <!--</div>-->

                </div>
                <!--                                <div class="title title2">Usage Report:</div>-->
                <!--                                <div class="desc">This month: <b>{{reports.total_time_month}}</b></div>-->
              </div>
              <div class="report-2">
                <div class="calendar">
                  <div class="calendar-body">
                    <div class="calendar-days">
                      <div class="calendar-header">
                        <div class="calendar-header-tool">
                          <a class="calendar-prev" href="#" @click="prevDay">
                            <img src="/themes/ito/images/icons/icon-calander-prev.svg" alt="">

                          </a>
                          <a class="calendar-next" href="#" @click="nextDay">
                            <img src="/themes/ito/images/icons/icon-calander-next.svg" alt="">
                          </a>
                        </div>
                        <div class="calendar-header-title">{{ month_label }} {{ year }}</div>
                        <div class="calendar-header-char">
                          <a href="#">
                            <img src="/themes/ito/images/btn-calander-char.svg" alt="">
                          </a>
                        </div>
                      </div>
                      <div class="calendar-row calendar-row-1">
                        <div class="calendar-col">{{ $t('mo') }}</div>
                        <div class="calendar-col">{{ $t('tu') }}</div>
                        <div class="calendar-col">{{ $t('we') }}</div>
                        <div class="calendar-col">{{ $t('th') }}</div>
                        <div class="calendar-col">{{ $t('fr') }}</div>
                        <div class="calendar-col">{{ $t('sa') }}</div>
                        <div class="calendar-col">{{ $t('su') }}</div>
                      </div>
                      <div class="calendar-row" v-for="calander in calanders">
                        <template v-for="day in calander">
                          <div class="calendar-col" v-if="day.day == ''"></div>
                          <div class="calendar-col day active border" :class="day_select == day.day ? 'border' : ''" @click="Test(day)" v-else-if="day.day == '' && day.current == true">
                            <div class="text">{{ day.day }}</div>
                            <div v-for="day_complete_count  in report_day" v-if="formatDay(day_complete_count.day_time) == day.day" class="alert">
                              {{ day_complete_count.day_complete_count }}
                            </div>
                          </div>

                          <div class="  calendar-col day active  " :class="day_select == day.day ? 'border' : ''" @click="Test(day)" v-else-if="day.day != '' && day.day_complete_count == true">
                            <div class="text">{{ day.day }}</div>
                            <div v-for="day_complete_count  in report_day" v-if="formatDay(day_complete_count.day_time) == day.day" class="alert">
                              <div>{{ (day_complete_count.day_complete_count) }}</div>
                            </div>
                          </div>

                          <div class="calendar-col day disable" v-else-if="day.disabled == true">
                            <div class="text">{{ day.day }}</div>
                          </div>
                          <div class="calendar-col day" :class="day_select == day.day ? 'border' : ''" v-else="day.day != ''" @click="Test(day)">
                            <div class="text">{{ day.day }}</div>
                            <div v-for="day_complete_count  in report_day" v-if="formatDay(day_complete_count.day_time) == day.day" class="alert">
                              {{ day_complete_count.day_complete_count }}
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { pushQueries, soundClick, toPercent } from '../../utils/utils'
import { mapGetters } from 'vuex'
import {
  getParentReport,
  getAcademicReport,
  getEbookReport,
  getParentReportGrade,
  getParentReportMonth
} from "../../models/parent";
import paginate from 'vuejs-paginate'

export default {
  name: "ParentReport",
  components: { paginate },
  title: 'Dành cho phụ huynh - Báo cáo học tập',

  data() {
    let date = new Date();
    let year = date.getFullYear();
    let months = [
      this.$i18n.t('month_1'),
      this.$i18n.t('month_2'),
      this.$i18n.t('month_3'),
      this.$i18n.t('month_4'),
      this.$i18n.t('month_5'),
      this.$i18n.t('month_6'),
      this.$i18n.t('month_7'),
      this.$i18n.t('month_8'),
      this.$i18n.t('month_9'),
      this.$i18n.t('month_10'),
      this.$i18n.t('month_11'),
      this.$i18n.t('month_12')
    ];
    // let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = date.getMonth() + 1;
    let month_label = months[month - 1];
    let calanders = this.getCalanders(month, year);

    return {
      page: 1,
      reports: [],
      report_total_subject: [],
      report_program: [],
      report_total_vocab: [],
      report_day: [],
      isLoading: true,
      year: year,
      months: months,
      month: month,
      month_label: month_label,
      calanders: calanders,
      day_select: '',
      day_active_month: [],
      academics: [],
      grade: '',
      subject: '',
      ebook_reports: [],
      view_table: false,
      show_result: false,
      empty_use_report: false,
      page_ebook_reports: {
        total: 0,
        from: 0,
        to: 0,
        per_page: 0,
        current_page: 0
      }
    };
  },

  created() {
    this.$store.commit('setMenuActive', 'parent');

    this.fetch();
  },
  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },
  watch: {
    'grade': function (newVal, oldVal) {
      this.show_result = false;
      if (newVal != '' && this.subject != '') {
        this.view_table = true;
      } else {
        this.view_table = false;
      }
    },
    'subject': function (newVal, oldVal) {
      this.show_result = false;
      if (newVal != '' && this.grade != '') {
        this.view_table = true;
      } else {
        this.view_table = false;
      }
    },
  },

  methods: {
    prevPage() {
      if (this.page_ebook_reports.current_page === 1) {
        return false;
      }
      this.getEbookReports(this.page_ebook_reports.current_page - 1, true)
    },
    nextPage() {
      this.getEbookReports(this.page_ebook_reports.current_page + 1, true)
    },
    formatDay(timestamp) {
      var date = new Date(timestamp * 1000);
      var day = date.getDate();
      return day;
    },
    Test(day) {
      this.empty_use_report = true;
      this.day_select = day.day;
      for (let [key, subjects] of Object.entries(this.report_day)) {
        if (this.formatDay(subjects.day_time) === this.day_select) {
          this.empty_use_report = false;
        }
      }
    },
    fetch(page = 1) {
      let that = this;
      that.isLoading = true;
      this.page = page;

      let params = {
        // year: that.year,
        // month: that.month
        grade: localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade
      };
      axios.all([
        getParentReportGrade(params)
        // getParentReport(params),
      ]).then(axios.spread((report_response) => {
        that.isLoading = false;

        //that.$store.commit("setLoading", false);

        if (report_response.data.code != 0) {
          return;
        }
        that.reports = report_response.data.results || [];
        // that.report_total_subject = report_response.data.results.report_total.subjects || [];
        // that.report_total_subject = report_response.data.results.program.subjects[0] || [];
        that.report_program = report_response.data.results.program || [];
        // that.report_total_vocab = report_response.data.results.report_total.vocabulary || [];
        // that.report_day = report_response.data.results.report_day || [];

      }));
      that.getAcademic();
    },
    getEbookReports(page = 1, loading = false) {
      let that = this;
      that.isLoading = loading;
      that.$store.commit("setLoading", loading);
      let params = {
        'subject': that.subject,
        'grade': that.grade,
        'page': page
      }
      axios.all([
        getEbookReport(params),
      ]).then(axios.spread((report_response) => {
        that.isLoading = false;
        that.show_result = true;
        that.$store.commit("setLoading", false);
        that.ebook_reports = report_response.data.results.ebook_reports;
        that.page_ebook_reports.total = report_response.data.results.total;
        that.page_ebook_reports.from = report_response.data.results.from;
        that.page_ebook_reports.to = report_response.data.results.to;
        that.page_ebook_reports.per_page = report_response.data.results.per_page;
        that.page_ebook_reports.current_page = report_response.data.results.current_page;
        // if (report_response.data.code != 0) {
        //     return;
        // }
      }));
    },
    checkAcdemic() {
      if (this.academics.length === 0) {
        return false;
      }
      for (let [key, entry] of Object.entries(this.academics)) {
        if (entry.class_term.length > 0) {
          return true;
        }
      }
      return false;
    },
    getAcademic() {
      let that = this;

      that.$store.commit("setLoading", true);
      let formData = new FormData();
      formData.append('eco_user_id', that.user_info.eco_user_id ? that.user_info.eco_user_id : '');
      getAcademicReport(formData).then((data) => {
        that.$store.commit("setLoading", false);
        if (data.status == 200) {
          that.academics = data.data.data;
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        alert(response.data.results);
      });
    },
    reviewAcademic(classId, termId) {
      let eco_user_id = this.user_info.eco_user_id ? this.user_info.eco_user_id : '';
      let url = 'https://eco.ismart.edu.vn/api_eco.php/get_ar?class_id=' + classId + '&eco_id=' + eco_user_id + '&class_term=' + termId;
      this.redirect_blank(url);
    },

    redirect_blank(url) {
      var a = document.createElement('a');
      a.target = "_blank";
      a.href = url;
      a.click();
    },

    prevDay(e) {
      let that = this;
      that.day_select = '';
      if (this.month == 1) {
        this.month = 12;
        this.year = this.year - 1;
      } else {
        this.month = this.month - 1;
      }
      this.month_label = this.months[this.month - 1];

      this.calanders = this.getCalanders(this.month, this.year);


      that.$store.commit("setLoading", true);


      let params = {
        year: that.year,
        month: that.month
      };
      axios.all([
        getParentReportMonth(params),
      ]).then(axios.spread((report_response) => {
        that.isLoading = false;
        that.$store.commit("setLoading", false);

        if (report_response.data.code != 0) {
          return;
        }
        that.reports = report_response.data.results || [];
        // that.report_total_subject = report_response.data.results.report_total.subjects || [];
        // that.report_total_vocab = report_response.data.results.report_total.vocabulary || [];
        that.report_day = report_response.data.results.report_day || [];
      }));

      e.preventDefault();
    },
    nextDay(e) {
      let that = this;
      that.day_select = '';
      if (this.month == 12) {
        this.month = 1;
        this.year = this.year + 1;
      } else {
        this.month = this.month + 1;
      }
      this.month_label = this.months[this.month - 1];

      this.calanders = this.getCalanders(this.month, this.year);

      that.$store.commit("setLoading", true);

      let params = {
        year: that.year,
        month: that.month
      };
      axios.all([
        getParentReportMonth(params),
      ]).then(axios.spread((report_response) => {
        that.isLoading = false;

        that.$store.commit("setLoading", false);

        if (report_response.data.code != 0) {
          return;
        }
        that.reports = report_response.data.results || [];
        // that.report_total_subject = report_response.data.results.report_total.subjects || [];
        // that.report_total_vocab = report_response.data.results.report_total.vocabulary || [];
        that.report_day = report_response.data.results.report_day || [];

      }));

      e.preventDefault();
    },

    calcPercent(process, total) {
      return toPercent(process, total);
    },

    chunks(array, size) {
      var results = [];
      while (array.length) {
        results.push(array.splice(0, size));
      }
      return results;
    },

    getSoundClick() {
      soundClick();
    },

    getCalanders(month, year) {
      let that = this;
      let date = new Date();
      let data = [];
      let firstDay = this.getFirstDayInMonth(month, year);
      let lastDay = this.getDaysInMonth(month, year);
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let current_date = date.getDate();
      let current_month = date.getMonth() + 1;
      let current_year = date.getFullYear();
      let params = {
        year: year,
        month: month
      };
      that.day_active_month = [];
      axios.all([
        getParentReport(params),
        getParentReportMonth(params),
      ]).then(axios.spread((report_response, second_response) => {
        that.isLoading = false;

        that.$store.commit("setLoading", false);

        if (second_response.data.code != 0) {
          return;
        }
        that.reports = second_response.data.results || [];
        // that.report_total_subject = second_response.data.results.report_total.subjects || [];
        // that.report_total_vocab = second_response.data.results.report_total.vocabulary || [];
        that.report_day = second_response.data.results.report_day || [];
        // if(that.report_day && that.report_day.length !=0){
        //
        // }
        if (that.report_day.length != 0) {

          that.calanders.forEach(function (element) {
            if (element.length > 0) {
              element.forEach(function (item) {
                if (typeof item.current != 'undefined' && item.current) {
                  that.day_select = item.day;
                }
              });
            }
          });
          that.report_day.forEach(function (element) {
            that.day_active_month.push({
              'day_active': that.timeConverter(element.day_time),
              'day_number': element.day_complete_count,
              // 'day_subject': element.subjects,
              // 'day_vocabs': element.vocabulary,
            });
            that.empty_use_report = true;
            if (that.formatDay(element.day_time) === that.day_select) {
              that.empty_use_report = false;
            }
          })
        } else {
          that.day_active_month = [];
        }
      }));
      for (var i = 1; i < firstDay; i++) {
        data.push({
          day: ""
        });
      }

      for (var i = 1; i <= lastDay; i++) {
        let disabled = false;
        let current = false;
        let day_complete_count = false;
        if (current_year < year || (current_year == year && current_month < month) || (current_year == year && current_month == month && current_date < i)) {
          disabled = true;
        }

        if ((current_year == year && current_month == month && current_date > i)) {
          day_complete_count = true;
        }

        if (current_year == year && current_month == month && current_date == i) {
          current = true;
        }
        let d = new Date(year, month - 1, i).getDay();


        data.push({
          day: i,
          label: days[d],
          disabled: disabled,
          current: current,
          day_complete_count: day_complete_count,
        });
      }

      let lastDays = Math.ceil(data.length / 7) * 7 - data.length;
      for (var i = 0; i <= lastDays; i++) {
        data.push({
          day: ""
        });
      }

      return this.chunks(data, 7);
    },

    getDaysInMonth(month, year) {
      // Here January is 1 based
      // Day 0 is the last day in the previous month
      return new Date(year, month, 0).getDate();
      // Here January is 0 based
      // return new Date(year, month+1, 0).getDate();
    },

    getFirstDayInMonth(month, year) {
      return new Date(year, month - 1, 1).getDay();
    },

    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return date;
    },

    getChartJs() {
      return {
        type: 'radar',
        data: {
          labels: ["English: Speaking", "Ability", "Confidence", "English: Listening", "English: Reading", "English: Writing"],
          datasets: [{
            label: "My First dataset",
            fill: true,
            data: [0.5, 0.62, 0.9, 0.18, 0.3, 0.12],
            borderColor: '#3164CC',
            backgroundColor: '#7BD8FF',
            pointBorderColor: '#3164CC',
            pointBackgroundColor: '#3164CC',
            pointBorderWidth: 10,
            borderWidth: 2
          }]
        },
        options: {
          responsive: true,
          legend: false
        }
      }
    },
    timeConverterEbook(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate() < 10 ? '0' + a.getDate() : a.getDate();
      var hour = a.getHours() < 10 ? '0' + a.getHours() : a.getHours();
      var min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
      var sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
      var time = hour + ':' + min + ':' + sec + ' - ' + date + '/' + month + '/' + year;
      return time;
    }
  }
}
</script>

<style scoped>
.item-academic {
  text-align: center;
}

.btn-preview {
  background: linear-gradient(180deg, #45BCFF 0%, #00A3FF 100%);
  box-shadow: 0px 2px 0px 0px #FFFFFF33 inset;
  box-shadow: 4px 4px 0px 0px #00000012;
  height: 35px;
  width: 93px;
  left: 424px;
  top: 259px;
  border-radius: 30px;
  padding: 8px 20px 8px 20px;
  color: #fff;
  cursor: pointer;
  margin: 10px auto;
}

.item-academic-class {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.item-academic-name {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.card-parents .card-content.ar {
  padding: 50px 50px;
}

.ito-input {
  width: 200px;
  margin: 0 10px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #FFFFFF;
  text-align: left;
  padding: 8px;
  font-size: 14px;
}

th {
  background: #40C0F6;
  color: #FFFFFF;
}

tr:nth-child(2n + 1) {
  background: #EEEEEE;
}

tr:nth-child(2n) {
  background-color: #E3E3E3;
}

.tbl-report {
  padding: 0 30px 30px;
}

.label-input {
  font-size: 16px;
}

.card-parents .card-content {
  padding: 51px 70px;
}

.info-item {
  margin-bottom: 12px;
  display: flex;
  padding: 0 45px;
}

.progress {
  height: 14px;
}

.progress .progress-process {
  border-radius: 30px;
  height: 14px;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #40C0F6;
  box-shadow: inset 0px 4px 0px rgba(255, 255, 255, 0.1);
  border-radius: 30px;
}

.progress .progress-process:after {
  animation: none;
  background: transparent;
}

.info-item .info-label {
  width: 160px;
}

.empty_study_success {
  width: 100%;
  padding-right: 10px;
}

.tbl-pagination {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}

.tbl-pagination__content {
  display: flex;
  width: 30px;
  justify-content: space-between;
  margin-left: 10px;
}

.tbl-pagination__first {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.tbl-pagination__preview,
.tbl-pagination__next {
  cursor: pointer;
}

.tbl-pagination__preview img,
.tbl-pagination__next img {
  width: 7px;
}
</style>