<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected.name }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div v-for="(option, i) of options" :class="option.value == selected.value ? 'current' : ''" :key="i"
                @click=" selected = option; open = false; $emit('input', option);">
                {{ option.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                    ? this.options[0]
                    : null,
            open: false,
        };
    },
    mounted() {
        this.$emit("input", this.selected);
    },
};
</script>

<style scoped>
.custom-select {
    display: inline-block;
    position: relative;
    min-width: 164px;
    width: 100%;
    text-align: left;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #777777;
}

.custom-select .selected {
    cursor: pointer;
    user-select: none;
    padding: 8px 40px 8px 16px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    box-shadow: 2px 2px 0px #CFCFCF;
    border-radius: 56px;
}

.custom-select .selected.open {
    background: #FFE9DA;
    border: 1px solid #FC7A1B;
    box-shadow: 2px 2px 0px #FC7A1B;

}

.custom-select .selected:after {
    position: absolute;
    content: url('/public/themes/ito/images/icon-select.svg');
    right: 1em;
}

.custom-select .selected.open:after {
    position: absolute;
    content: url('/public/themes/ito/images/icon-select-focus.svg');
    right: 1em;
}

.custom-select .items {
    color: #fff;
    overflow: hidden;
    position: absolute;
    background-color: #FFFFFF;
    left: 0;
    right: 0;
    margin-top: 3px;
    z-index: 1;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.15));
    border-radius: 16px;
}

.custom-select .items div {
    border-radius: 56px;
    color: #4C4C4C;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    padding: 16px;
    white-space: nowrap;
}

.custom-select .items div.current {
    color: #FC7A1B;

}

.custom-select .items div:hover {
    background-color: #FFE9DA;
}

.selectHide {
    display: none;
}</style>
