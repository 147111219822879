import {get, post} from "../APIGateWay";

export async function getParentFeedbacks(data) {
    // return await post(data, '/feedback')
    // return await post(data, '/feedback/all-feedback')
    return await post(data, '/feedback/get-feedback')
}

export async function sendFeedbackData(data) {
    // return await post(data, '/feedback/send-feedback')
    // return await post(data, '/feedback/send-all-feedback')
    return await post(data, '/feedback/send-feedback')
}
