<template>
    <div id="landing-page">
        <nav-bar />
        <router-view></router-view>
        <footer-element />
    </div>
</template>

<script>
import NavBar from "./LandingPageHeader.vue";
import FooterElement from "./LandingPageFooter.vue";
import { getConfigs, getLanguage, soundClick } from "../../../utils/utils";


export default {
    name: "LandingPageLayout",

    data() {
        const configs = getConfigs();
        const localizes = configs.localize || [];

        return {
            locate: getLanguage(),
            locate_obj: null,
            configs: configs,
            localizes: localizes,
        };
    },

    components: {
        FooterElement,
        NavBar,
    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading
        },
    },

    updated() {
    },

    mounted() {
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/landing_page/assets/css/vendor.css';
        document.head.appendChild(style);

        let style1 = document.createElement('link');
        style1.type = "text/css";
        style1.rel = "stylesheet";
        style1.href = '/landing_page/assets/css/main.css';
        document.head.appendChild(style1);

        let style2 = document.createElement('link');
        style2.type = "text/css";
        style2.rel = "stylesheet";
        style2.href = '/landing_page/assets/css/styles-banner.css';
        document.head.appendChild(style2);

        // let style3 = document.createElement('link');
        // style3.type = "text/css";
        // style3.rel = "stylesheet";
        // style3.href = '/landing_page/user/css/bootstrap.css';
        // document.head.appendChild(style3);
    },

    methods: {
        fetch() {

        },
        getSoundClick() {
            soundClick();
        },
        setLocate(e) {
            this.getSoundClick();
            this.locate_obj = e;
            this.locate = e.lang;
        },

        updateLocate() {
            this.getSoundClick();
            localStorage.setItem('lang', this.locate);
            location.reload();
        },
    }
}
</script>
<style>

</style>
