<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/exchange-gift.css">

        <div class="container">
            <div id="page-header">
                <ul class="breadcrumb">
                    <li class="icon">
                        <router-link :to="{ name: 'home' }">
                            <img src="/themes/ito/images/icon-profile.svg" alt="">
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'exchange_gift' }">
                            {{$t('exchange_gift')}}
                        </router-link>
                    </li>
                    <li class="separator"><i class="ito-icon-breadcrumb-1"></i></li>
                    <li class="active">{{$t('buy_sticker')}}</li>
                </ul>
            </div>

            <div class="card card-exchange-gift">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('exchange_today')}} 1/3</span>
                    </h5>

                    <div class="card-content">
                        <div class="row exchange-gifts">
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="exchange-gift-item">
                                    <div class="exchange-gift-item-info">
                                        <div class="exchange-gift-item-1">
                                            <div class="thumb ratio109x105">
                                                <div class="thumb-content">
                                                    <img src="/themes/ito/images/exchange-gift-item.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="exchange-gift-item-2">
                                            <h4 class="title">Gift exchange name</h4>
                                            <div class="desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                Aenean commodo ligula eget Aenean massa. Cum sociis natoque
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-gift-item-bottom">
                                        <div class="exchange-gift-item-bottom-1">
                                            Sent exchange request at 15:23 - 20, August 2021
                                        </div>
                                        <div class="exchange-gift-item-bottom-2">
                                            <i class="ito-icon-checked"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {pushQueries} from '../../utils/utils'
    import {mapGetters} from 'vuex'
    import {getAllHistoryExchange} from "../../models/exchange_gift";
    import {claimDailyBonus} from "../../models/reward";

    export default {
        name: "ExchangeHistory",
        data() {
            return {
                histories: [],
                isLoading: true
            };
        },

        created() {
            //this.$store.commit('setMenuActive', 'inbox');

            this.fetch();
        },

        methods: {
            fetch(page = 1) {
                const that = this;

                this.page = page;

                const params = {
                    page: this.page,
                };

                //pushQueries(this.$router, '/inbox', params);

                axios.all([
                    getAllHistoryExchange(params),
                ]).then(axios.spread((response) => {
                    that.isLoading = false;

                    //that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    that.histories = response.data.results.mails || [];
                }));
            },

        }
    }
</script>

<style scoped>

</style>
