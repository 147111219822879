<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/profile.css?v=6">
    <div class="modal fade" id="ChangePasswordPopup" tabindex="-1" role="dialog" aria-labelledby="ChangePasswordPopup" aria-hidden="true" data-backdrop="static" data-keyboard=false>
      <div class="modal-dialog modal-dialog-centered ito-popup forgot-popup" role="document">
        <div class="modal-content box-shadow-main">
          <div class="close-popup" data-bs-dismiss="modal"></div>
          <div class="forgot-popup-header">
            <h3 class="popup-title">{{ $t('change_password') }}</h3>
          </div>
          <template>
            <div class="forgot-popup-content">
              <p>{{ $t('password_description') }}</p>
              <div class="form-group">
                <label class="label-input" style="display: block">{{ $t('current_password') }}</label>
                <input type="password" class="ito-input" name="current_password" :placeholder="$t('placeholder_current_password')" v-model="current_password">
                <div class="error-message text-start" v-if="errors.current_password != ''">{{
              errors.current_password
            }}
                </div>
              </div>
              <div class="form-group">
                <label class="label-input" style="display: block">{{ $t('new_password') }}</label>
                <div class="d-flex">
                  <input type="password" class="ito-input" name="new_password" :placeholder="$t('placeholder_new_password')" v-model="new_password">
                  <div class="validate-new-pass" :class="classStrength" v-if="new_password">
                    <div class="progres-status-pw d-flex">
                      <div :class="strength > 0 ? 'progres-status-pw-item active' : 'progres-status-pw-item'"></div>
                      <div :class="strength > 1 ? 'progres-status-pw-item active' : 'progres-status-pw-item'"></div>
                      <div :class="strength > 2 ? 'progres-status-pw-item active' : 'progres-status-pw-item'"></div>
                    </div>
                    <div class="progres-text-pw">
                      Password strength: {{ textStrength }}
                    </div>
                  </div>
                </div>
                <div class="error-message text-start" v-if="errors.new_password != ''">{{ errors.new_password }}</div>
              </div>
              <div class="form-group">
                <label class="label-input" style="display: block">{{ $t('confirm_password') }}</label>
                <input type="password" class="ito-input" name="confirm_password" :placeholder="$t('placeholder_confirm_password')" v-model="confirm_password">
                <div class="error-message text-start" v-if="errors.confirm_password != ''">{{
              errors.confirm_password
            }}
                </div>
              </div>

            </div>

            <div class="forgot-popup-footer">
              <div class="form-group d-flex justify-content-center">
                <div :class="(confirm_password != '' && confirm_password != '' && confirm_password != '') ? 'ito-btn-small active' : 'ito-btn-small'"
                  @click="(confirm_password != '' && confirm_password != '' && confirm_password != '') ? changeNewPass() : ''">
                  {{ $t('save') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div id="changePassSuccess" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered ito-popup" role="document" data-backdrop="static" data-keyboard=false>
        <div class="modal-content">

          <div class="close-popup" data-bs-dismiss="modal"></div>
          <div class="modal-success">
            <h4 class="title">Password updated</h4>

            <img src="/themes/ito/images/get-rewarded.svg" alt="">

            <h6>Password has been updated successfully.</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div id="page-header-fixed" class="mb-25">
        <div class="container">
          <div id="page-header">
            <div class="buttons">
              <div class="tools">
                <nav class="ito-tabs">
                  <router-link :to="{ name: 'parent_report' }" @click.native="getSoundClick">{{ $t('report') }}</router-link>
                  <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_certificate' }" @click.native="getSoundClick">{{ $t('certificate') }}</router-link>
                  <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_feedback' }" @click.native="getSoundClick">{{ $t('feedback') }}</router-link>
                  <router-link :to="{ name: 'parent_account' }" class="active" @click.native="getSoundClick">{{ $t('account_information') }}</router-link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-profile card-account">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('account_information') }}</span>
            <button class="ito-btn-small update-account" @click="onSubmit" :class="{ active: is_valid }" :disabled="!is_valid">{{ $t('save') }}
            </button>
            <!--                        <a href="#" @click="onLogout">-->
            <!--                            <span class="logout">{{ $t('log_out') }}</span>-->
            <!--                        </a>-->
          </h5>

          <div class="card-content">
            <div class="profile-show">
              <div class="profile-avatar-upload">
                <div class="profile-avatar-upload-c" :style="{ backgroundImage: `url(${user_info.avatar_url})` }"></div>

                <a href="#" class="upload-btn" data-bs-toggle="modal" data-bs-target="#uploadAvatarModal">
                  <img src="/themes/ito/images/icon-camera-upload.svg" alt="" />
                </a>

                <div class="emoticon" v-if="user_info.emoji_url">
                  <img src="/themes/ito/images/icon-emoticon-2.svg" :src="user_info.emoji_url" alt="" />
                </div>
              </div>

              <div class="profile-avatar-info">
                <div class="profile-info-item">{{ $t('user_id') }}: <span class="value">{{ user_info.user_id }}</span></div>
                <div class="profile-info-item" v-show="user_info.student_type != 2">{{ $t('total') }}:
                  <div class="profile-info-item-gem">
                    <span class="value">{{ user_info.gem | toCommas }}</span><i class="ito-icon-1"></i>
                  </div>
                  <div class="profile-info-item-crown">
                    <span class="value">{{ user_info.crown | toCommas }}</span><i class="ito-icon-2"></i>
                  </div>
                </div>
                <div class="profile-info-item" v-show="user_info.student_type != 2">{{ $t('this_month') }}:
                  <div class="profile-info-item-gem">
                    <span class="value">{{ user_info.gem_month | toCommas }}</span><i class="ito-icon-1"></i>
                  </div>
                  <div class="profile-info-item-crown">
                    <span class="value">{{ user_info.crown_month | toCommas }}</span><i class="ito-icon-2"></i>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-center mt-3" v-if="user.student_type != 2">
                <button class="ito-btn ito-btn-outline-primary" @click="changePassword()">{{ $t('change_password') }} </button>
                <router-link :to="{ name: 'upgrade_account' }" @click.native="getSoundClick" class="btn btn-default btn-update btn-upgrate">
                  {{ $t('upgrade_account') }}
                </router-link>
              </div>
            </div>
            <div class="profile-update">
              <form>
                <div class="row">
                  <div class="col-sm-6">
                    <label class=" col-form-label">{{ $t('username') }}</label>
                    <input type="text" name="username" class="form-control" v-model="user.username" :placeholder="$t('username')" disabled />
                  </div>
                  <div class="col-sm-6">
                    <label class="col-form-label">{{ $t('full_name') }}</label>
                    <input type="text" name="full_name" class="form-control" v-model="user.fullname" @input="eventValid" :placeholder="$t('full_name')" />
                    <div class="error-message has-form" v-if="errors.fullname">{{ errors.fullname }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <label class="col-form-label">{{ $t('birthday') }}</label>
                    <input type="text" name="birthday" class="form-control date datepicker birthday" v-model="user.birthday" autocomplete="off" :placeholder="$t('birthday')" />
                  </div>
                  <div class="col-sm-6">
                    <label class="col-form-label">{{ $t('gender') }}</label>
                    <select class="form-control" v-model="user.gender">
                      <option value="">{{ $t('select_gender') }}</option>
                      <option value="male" selected>{{ $t('male') }}</option>
                      <option value="female">{{ $t('female') }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="d-flex flex-wrap">
                      <label class="col-form-label text-nowrap">{{ $t('account_type') }}:</label>
                      <p class="desc-blue ms-2 text-nowrap">{{ parent_info.account_type_name }}</p>
                    </div>
                    <div class="d-flex flex-wrap">
                      <label class="col-form-label text-nowrap">{{ $t('exp_date') }}:</label>
                      <p class="desc-blue ms-2 text-nowrap">{{ parent_info.expired_date | convertUnitToTime('DD/MM/YYYY') }}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-form-label">{{ $t('grade') }}</label>
                    <input type="text" name="grade" class="form-control" :value="user.grade" disabled autocomplete="off">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-account card-account-form">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('contact_details') }}</span>
            <button v-if="parent_info.student_type != 1" class="ito-btn-small update-account" @click="onSubmitContact" :class="{ active: activeChangeContactUser }" :disabled="!activeChangeContactUser">{{ $t('save') }}
            </button>
          </h5>

          <div class="card-content">
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('phone') }}</label>
                <div>
                  <input type="text" name="phone" class="form-control noString" v-model="contact_user.phone" @input="changeContact()" :placeholder="$t('phone')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('email') }}</label>
                <div>
                  <input type="text" name="email" class="form-control" v-model="parent_info.email" @input="changeContact()" :placeholder="$t('email')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
            </div>
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('address') }}</label>
                <div>
                  <input type="text" name="address" class="form-control" v-model="contact_user.address" @input="changeContact()" :placeholder="$t('address')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('country') }}</label>
                <div>
                  <select class="form-control" v-model="contact_user.country_id" name="country" :disabled="parent_info.student_type == 1" @change="changeCountry()">
                    <option value="" disabled>{{ $t('country') }}</option>
                    <template v-for="(item, key) in countries">
                      <option :value="item.id">{{ item.name }}</option>
                    </template>
                  </select>
                  <!--                  <input type="text" name="country" class="form-control" :value="contact_user.country"-->
                  <!--                         :placeholder="$t('country')" autocomplete="off" :disabled="parent_info.student_type==1"/>-->
                </div>
              </div>

            </div>
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('province') }}</label>
                <div>
                  <select class="form-control" v-model="contact_user.city_id" name="province" :disabled="parent_info.student_type == 1" @change="changeProvince()">
                    <option value="" disabled>{{ $t('province') }}</option>
                    <template v-for="(item, key) in provinces">
                      <option :value="item.id">{{ item.name }}</option>
                    </template>
                  </select>
                  <!--                  <input type="text" name="province" class="form-control" :value="contact_user.province"-->
                  <!--                         :placeholder="$t('province')" autocomplete="off" :disabled="parent_info.student_type==1"/>-->
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('disctrict') }}</label>
                <div>
                  <select class="form-control" v-model="contact_user.district_id" name="district" :disabled="parent_info.student_type == 1">
                    <option value="" disabled>{{ $t('disctrict') }}</option>
                    <template v-for="(item, key) in districts">
                      <option :value="item.id">{{ item.name }}</option>
                    </template>
                  </select>
                  <!--                  <input type="text" name="disctrict" class="form-control" :value="contact_user.district"-->
                  <!--                         :placeholder="$t('disctrict')" autocomplete="off" :disabled="parent_info.student_type==1"/>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-account card-account-form" v-if="parent_info.student_type == 1">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('school_infomation') }}</span>
          </h5>

          <div class="card-content">
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('school') }}</label>
                <div>
                  <input type="text" name="school" class="form-control" :value="contact_school.school" :placeholder="$t('school')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('class') }}</label>
                <div>
                  <input type="text" name="class" class="form-control" :value="contact_school.class" :placeholder="$t('class')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
            </div>
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('address') }}</label>
                <div>
                  <input type="text" name="address" class="form-control" :value="contact_school.address" :placeholder="$t('address')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('disctrict') }}</label>
                <div>
                  <input type="text" name="disctrict" class="form-control" :value="contact_school.district" :placeholder="$t('disctrict')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
            </div>
            <div class="row" style="width: 100%;">
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('province') }}</label>
                <div>
                  <input type="text" name="province" class="form-control" :value="contact_school.province" :placeholder="$t('province')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label">{{ $t('country') }}</label>
                <div>
                  <input type="text" name="country" class="form-control" :value="contact_school.country" :placeholder="$t('country')" autocomplete="off" :disabled="parent_info.student_type == 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <avatar-edit></avatar-edit>
  </div>
</template>

<script>
import { updateContactUser, updateProfile } from '../../models/profile'
import { changeNewPassword } from '../../models/auth'
import { getCountry, getDistrict, getParentInfo, getProvince } from '../../models/parent'
import { showDialog } from '../../utils/dialog'
import { mapGetters } from 'vuex'
import axios from "axios";
import { getInboxs } from "../../models/inbox";
import { soundClick } from '../../utils/utils';
import AvatarEdit from "../AvatarEdit";

export default {
  name: "ParentAcount",
  components: {
    AvatarEdit
  },
  data() {
    return {
      errors: {
        address: '',
        fullname: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
      },
      user: {
        fullname: '',
        birthday: '',
        gender: '',
        grade: 0,
        type: 2,
        avatar_id: -1,
        avatar_img: '',
        emoji_id: 0,
        extension_file: 'png',
        username: ''
      },
      current_password: '',
      new_password: '',
      confirm_password: '',
      strength: 0,
      classStrength: '',
      textStrength: '',
      parent_info: [],
      contact_user: {
        phone: '',
        address: '',
        district: '',
        province: '',
        country: '',
        country_id: "",
        city_id: "",
        district_id: ""
      },
      contact_school: {
        school: '',
        class: '',
        address: '',
        district: '',
        province: '',
        country: '',
      },
      countries: [],
      provinces: [],
      districts: [],
      is_valid: false,
      activeChangeContactUser: false
    };
  },

  computed: {
    user_info() {
      const user = this.$store.state.user_info;

      this.user.fullname = user.fullname;
      this.user.birthday = user.birthday;
      this.user.gender = user.gender;
      this.user.grade = user.grade;
      this.user.avatar_id = user.avatar_id;
      this.user.emoji_id = user.emoji_id;
      this.user.avatar_img = user.avatar_url;
      this.user.username = user.username;

      return this.$store.state.user_info
    }
  },

  created() {
    this.$store.commit('setMenuActive', 'parent');

    this.fetch();
  },

  watch: {
    'new_password': function (newVal, oldVal) {
      this.strengPassword(newVal);
      this.errors.new_password = (newVal != this.user.username) ? 'New password cannot be  the same as the username.' : '';
    },
    'strength': function (newVal, oldVal) {
      if (newVal == 1) {
        this.classStrength = 'pw-weak';
        this.textStrength = 'Weak';
      } else if (newVal == 2) {
        this.classStrength = 'pw-normal';
        this.textStrength = 'Good';
      } else if (newVal == 3) {
        this.classStrength = 'pw-strong';
        this.textStrength = 'Strong';
      } else {
        this.classStrength = '';
        this.textStrength = '';
      }
    },
    'confirm_password': function (newVal, oldVal) {
      this.errors.confirm_password = (newVal != this.new_password) ? 'Confirm password does not match.' : '';
    },

  },
  beforeMount() {
    this.getCountries();
  },
  mounted() {

    $('.noString').keypress(function (e) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    })
  },
  methods: {
    changeContact() {
      this.activeChangeContactUser = true;
    },
    fetch() {
      axios.all([getParentInfo()]).then(axios.spread((info) => {
        if (info.data.code != 0) {
          return
        }
        this.parent_info = info.data.results
        this.contact_user.phone = this.parent_info.contact_user.phone;
        this.contact_user.address = this.parent_info.contact_user.address;
        this.contact_user.district = this.parent_info.contact_user.district;
        this.contact_user.district_id = this.parent_info.contact_user.district_id;
        this.contact_user.province = this.parent_info.contact_user.city;
        this.contact_user.city_id = this.parent_info.contact_user.city_id;
        this.contact_user.country = this.parent_info.contact_user.country;
        this.contact_user.country_id = this.parent_info.contact_user.country_id;
        this.contact_school.school = this.parent_info.contact_school.school;
        this.contact_school.class = this.parent_info.contact_school.class;
        this.contact_school.address = this.parent_info.contact_school.address;
        this.contact_school.district = this.parent_info.contact_school.district;
        this.contact_school.province = this.parent_info.contact_school.city;
        this.contact_school.country = this.parent_info.contact_school.country;
        this.changeCountry(false);
        if (this.contact_user.city_id > 0) {
          this.changeProvince(false);
        }
      }));

      this.$nextTick(function () {
        //initDatepicker();

        $('.birthday').datepicker({
          dateFormat: 'dd/mm/yy',
          changeMonth: true,
          changeYear: true,
          showOtherMonths: true,
          selectOtherMonths: false,
          yearRange: '1900:+0',
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }).on('change', (e) => {
          this.searchEvent(e);
        });

        initNanoScroller();
        $('button[data-bs-toggle="tab"]').on('show.bs.tab', function (e) {
          initNanoScroller();
        });
        $('button[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
          initNanoScroller();
        });
        $('#uploadAvatarModal').on('shown.bs.modal', function () {
          initNanoScroller();
        });
      })
    },
    getCountries() {
      const that = this;
      axios.all([
        getCountry(),
      ]).then(axios.spread((report_response) => {
        that.countries = report_response.data.results;
      }));
    },
    async changeCountry(reset = true) {
      if (this.contact_user.country_id > 0 && reset) {
        this.contact_user.city_id = 0;
        this.contact_user.district_id = 0;
        this.activeChangeContactUser = true;
      }
      const data = await getProvince({
        country_id: this.contact_user.country_id
      })
      this.provinces = data.data.results;
    },
    async changeProvince(reset = true) {
      if (this.contact_user.city_id > 0 && reset) {
        this.contact_user.district_id = 0;
        this.activeChangeContactUser = true;
      }
      const data = await getDistrict({
        province_id: this.contact_user.city_id
      })
      this.districts = data.data.results;
    },
    getAccountTypeText(accountType) {
      switch (accountType) {
        case 1:
          return 'FREE TRIAL';
        case 2:
          return 'STANDARD';
        case 3:
          return 'PLUS';
        case 4:
          return 'PRO';
        default:
          return "";
      }
    },

    getSoundClick() {
      soundClick();
    },

    searchEvent({ target }) {
      this.user[target.name] = target.value;
    },

    onSubmitContact(e) {
      const that = this;
      if (that.activeChangeContactUser === false) {
        return false;
      }
      const formData = new FormData();
      if (this.contact_user.phone !== "") {
        formData.append('phone', this.contact_user.phone);
      }
      if (this.parent_info.email !== "") {
        formData.append('email', this.parent_info.email);
      }
      if (this.contact_user.address !== "") {
        formData.append('address', this.contact_user.address);
      }
      formData.append('country_id', this.contact_user.country_id);
      formData.append('city_id', this.contact_user.city_id);
      formData.append('district_id', this.contact_user.district_id);
      that.$store.commit("setLoading", true);

      updateContactUser(formData).then((response) => {
        that.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          alert(response.data.msg)
          return;
        }
      }).catch((response) => {
        that.$store.commit("setLoading", false);
        console.error(response)
      });

      e.preventDefault();
    },
    onSubmit(e) {
      const that = this;
      if (that.is_valid === false) {
        return false;
      }
      const formData = new FormData();

      formData.append('fullname', this.user.fullname);
      formData.append('birthday', moment(this.user.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
      formData.append('gender', this.user.gender);
      //formData.append('grade', this.user.grade);
      that.$store.commit("setLoading", true);
      updateProfile(formData).then((response) => {
        that.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          alert(response.data.msg)
          return;
        }
      }).catch((response) => {
        that.$store.commit("setLoading", false);
        console.error(response)
      });
      e.preventDefault();
    },
    onLogout(e) {
      this.$store.dispatch('logout').then(() => {
        // window.location.href = "/register-anomynous";
        window.location.href = "/login";
      });
      e.preventDefault();
    },
    changePassword() {
      $('#ChangePasswordPopup').modal('show');
    },
    changeNewPass() {
      const that = this;
      const formData = new FormData();

      if (that.new_password.length < 8) {
        that.errors.new_password = 'The new password must be at least 8 characters.';
        return false;
      }
      formData.append('old_pass', that.current_password)
      formData.append('new_pass', that.new_password);
      formData.append('confirm_pass', that.confirm_password);

      that.$store.commit("setLoading", true);

      changeNewPassword(formData).then((response) => {
        that.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          alert(response.data.msg)
          return;
        }
        $('#ChangePasswordPopup').modal('hide');
        $('#changePassSuccess').modal('show');
        that.resetPasswordState();
      }).catch((response) => {
        that.$store.commit("setLoading", false);
        console.error(response)
      });
    },
    resetPasswordState() {
      this.current_password = '';
      this.new_password = '';
      this.confirm_password = '';
    },
    strengPassword(password) {
      let str1 = 0;
      let str2 = 0;
      let str3 = 0;
      (password.length >= 8) ? str1 = 1 : str1 = 0;
      (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) ? str2 = 1 : str2 = 0;
      // if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)){this.strength += 1}
      (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) ? str3 = 1 : str3 = 0;
      this.strength = str1 + str2 + str3;
    },

    eventValid({ target }) {
      this.is_valid = true;
      this.is_valid = this.isChangeForm();
      switch (target.name) {
        case 'full_name':
          this.errors.fullname = '';
          if (this.user.fullname.length > 50) {
            this.errors.fullname = "Fullname max 50 characters.";
            this.is_valid = false;
          }
          if (this.regexSpecialChar(this.user.fullname)) {
            this.errors.fullname = "Fullname cannot contain special characters";
            this.is_valid = false;
          }

          break;
      }
      return this.is_valid;
    },
    isChangeForm() {
      if (this.user_info.fullname != this.user.fullname
        || this.user_info.gender != this.user.gender
        || this.user_info.birthday != this.user.birthday) {
        return true;
      }

      return false;
    },
    regexSpecialChar(txt) {
      var specialRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
      if (txt.match(specialRegex)) {
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>
.card-account {
  margin-bottom: 15px;
}

.card-account-form .form-control {
  margin-bottom: 5px;
}
/*
.card-account-form .card-content {
  padding: 40px 115px;
} */

.avatar img.avatar-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 90em;
  overflow: hidden;
}

.update-account {
  border: none !important;
  font-size: 16px !important;
}

.error-message.has-form {
  margin-bottom: 10px;
  margin-top: -25px;
}
</style>