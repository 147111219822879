import { render, staticRenderFns } from "./RegisterAnomynous.vue?vue&type=template&id=029b9a64&scoped=true"
import script from "./RegisterAnomynous.vue?vue&type=script&lang=js"
export * from "./RegisterAnomynous.vue?vue&type=script&lang=js"
import style0 from "./RegisterAnomynous.vue?vue&type=style&index=0&id=029b9a64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "029b9a64",
  null
  
)

export default component.exports