<template>
    <header class="l-header l-header--faq h-shadow-1 navbar-expand-lg">
        <div class="container">
            <div class="l-header__wrapper">
                <div class="l-header__logo">
                    <a href="/login"></a>
                </div>
                <!-- <div class="l-header__toggler">
                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#headerNavbar"><i class="fas"></i></button>
                </div>
                <nav id="headerNavbar" class="l-header__nav navbar-collapse collapse">
                    <ul class="list-unstyled nav-list">
                        <li class="active"><a href="/">Giới thiệu</a></li>
                        <li><a href="/general-principle">Chính sách</a></li>
                        <li><a href="/faqs">Trợ giúp</a></li>
                        <li><a href="/login">Truy cập</a></li>
                    </ul>
                </nav> -->
                <div class="l-header__nav-icons">
                    <ul class="list-item-icons list-unstyled">
                        <li>
                            <div class="c-dropdown dropdown js-dropdown-header-flag">
                                <a href="#" class="item-toggle item-toggle--language is-activated" data-bs-toggle="dropdown">
                                    <span class="language-flag"
                                        style="background-image: url('/landing_page/uploads/flag-vi.png');"
                                        v-if="locate == 'vi'"></span>
                                    <span class="language-flag"
                                        style="background-image: url('/landing_page/uploads/flag-uk.png');" v-else></span>
                                </a>
                                <div
                                    class="dropdown-menu dropdown-menu-right c-dropdown-menu c-dropdown-menu--language c-dropdown-menu--sm h-shadow-1d">
                                    <template v-for="localize in localizes">
                                        <div class="dropdown-item c-dropdown-menu__item change-lang"
                                            :class="localize.lang == locate ? 'active' : ''" @click="setLocate(localize)"
                                            v-if="localize.lang == 'vi'">
                                            <span class="language-flag"
                                                style="background-image: url('/landing_page/uploads/flag-vi.png');"></span>
                                            <span class="language-title">{{ $t(localize.lang) }}</span>
                                        </div>
                                        <div class="dropdown-item c-dropdown-menu__item change-lang"
                                            :class="localize.lang == locate ? 'active' : ''" @click="setLocate(localize)"
                                            v-else>
                                            <span class="language-flag"
                                                style="background-image: url('/landing_page/uploads/flag-uk.png');"></span>
                                            <span class="language-title">{{ $t(localize.lang) }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { getConfigs, getLanguage, soundClick } from "../../../utils/utils";

export default {
    name: "NavBar",

    data() {
        const configs = getConfigs();
        const locate = getLanguage();
        const localizes = configs.localize || [];

        let locate_info = {};
        localizes.forEach(item => {
            if (item.lang == locate) {
                locate_info = item;
            }
        });
        return {
            locate: getLanguage(),
            locate_info: locate_info,
            configs: configs,
            localizes: localizes,
        };
    },

    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        fetch() {
        },
        getSoundClick() {
            soundClick();
        },
        setLocate(e) {
            this.getSoundClick();
            this.locate = e.lang;
            localStorage.setItem('lang', e.lang);
            location.reload();
        },

    }
}
</script>
