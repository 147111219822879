<template>
  <div id="landing-page" class="p-4">
    <img class="mobile-logo" src="/themes/ito/images/logo-mobile.svg" alt="" width="111px">

    <h3 class="title mt-5 mt-sm-3">Tải ứng dụng</h3>

    <div class="description">
      Ứng dụng tương thích trên mọi điện thoại thông minh và máy tính bảng, giúp học viên có thể học tập mọi lúc mọi
      nơi.
    </div>
    <div class="d-flex gap-3 flex-column flex-sm-row-reverse justify-content-center w-100">
      <div class="buttons">
        <a class="mobile-button-android" href="https://play.google.com/store/apps/details?id=ismart.edu.vn.ito&pli=1"
          target="_blank">
          <img src="/themes/ito/images/googleplay.svg" alt="">
        </a>
        <a class="mobile-button-ios" href="https://apps.apple.com/vn/app/ismart-tutoring-online/id1587460616"
          target="_blank">
          <img src="/themes/ito/images/appstore.svg" alt="">
        </a>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <img class="img-fluid" src="/themes/ito/images/mobile-phone.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MobileLayout",

  data() {
    return {};
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    },
    user_info() {
      return this.$store.state.user_info;
    }
  },

  methods: {
    fetch() {

    },
  }
}
</script>

<style scoped>
#landing-page {
  width: 100w;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/public/themes/ito/images/mobile-bg-1.png");
  background-repeat: no-repeat;
  background-position-x: 87%;
  background-position-y: bottom;
  background-size: cover;
  overflow: hidden;
}

.title {
  color: #070A28;
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.description {
  color: #070A28;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  white-space: pre-wrap;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;


}
</style>