<template>
  <div id="content">
    <div class="container">
      <div id="page-header-fixed">
        <div class="container">
          <div id="page-header">
            <ul class="breadcrumb">
              <li class="icon">
                <a @click="$router.go(-1)">
                  <img src="/themes/ito/images/back-to.svg" alt="">
                </a>
              </li>
              <li class="active">{{ unit_name }} - {{ lesson_name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">Lecture</span>
          </h5>
          <div class="card-content" style="height: calc(100vh - 333px);min-height: 550px;">
            <!--<iframe width="100%" height="100%" src="https://ito.vn" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>-->
            <iframe width="100%" height="100%" :src="item.content" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {getItemInfo, getUnitLessonDetail, getUnitLessonItem} from "../../models/dashboard";
import LessonDetailItem from "./LessonDetailItem";

export default {
  name: "LessonLecture",
  data() {
    return {
      isLoading: false,
      item: {},
      unit_name: '',
      lesson_name: '',
    };
  },

  created() {
    //window.addEventListener('message', this.handleMessage, false);

    const {item_id} = this.$route.params;
    const f = function(event){
      switch(event.data.type){
        case 'update_item_info':
          getItemInfo({
            id: item_id,
            item_type: 'lecture',
          });
      }
      window.removeEventListener("message", f, false);
    }
    window.addEventListener("message", f);

    this.getUnitLessonItem();
  },

  methods: {
    handleMessage(event) {
      const {item_id} = this.$route.params;

      switch(event.data.type){
        case 'update_item_info':
          getItemInfo({
            id: item_id,
            item_type: 'lecture',
          });
      }
    },

    getUnitLessonItem() {
      const that = this;

      const {item_id} = that.$route.params;

      that.isLoading = true;

      axios.all([
        getUnitLessonItem(item_id),
      ]).then(axios.spread((response) => {
        that.isLoading = false;

        if (response.data.code != 0) {
          return;
        }

        that.item = response.data.results.item;
        that.unit_name = response.data.results.name_unit;
        that.lesson_name = response.data.results.name_lesson;
      }));
    },
  }
}
</script>

<style scoped>
.card-content {
  padding: 30px;
}

.lecture-wrapper {
  position: relative;
}

.lecture-wrapper img {
  width: 100%;
  height: auto;
}

.play-game {
  position: absolute;
  width: 165px;
  height: 40px;
  background: linear-gradient(
      180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(
      0deg, #00A3FF, #00A3FF);
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 4px 0px rgba(231, 231, 231, 0.25);
  color: #FFFFFF;
  text-align: center;
  line-height: 42px;
  border-radius: 50px;
  bottom: 45px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}
</style>