<template>
    <div id="SiteContent" class="site-wrapper p-landing" data-js-module="landing">
        <div class="l-body l-faq">
            <div class="l-faq__header h-shadow-1 h-bg-cover">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 col-sm-9 col-md-8 col-lg-7">
                            <h1 class="header-title">
                                <span class="header-title__logo"><img src="../../../public/landing_page/assets/img/ismart-logo-white.png" alt=""></span>
                                <span class="header-title__text">{{ $t('help') }}</span>
                            </h1>
                            <div class="header-search position-relative">
                                <label class="header-search__label">{{ $t('how_can_we_help_you') }}</label>
                                <div class="header-search__input-wrapper">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="l-faq__section l-faq__fqs h-bgc-white h-shadow-1" style="font-family: 'Open Sans', sans-serif;">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        {{ $t('general_principle') }}
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">

                                    <h3 style="text-align: center; background: white; margin: 15.0pt 0in 7.5pt 0in;" align="center">
                                        <span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">CH&Iacute;NH
                                            S&Aacute;CH V&Agrave; QUY ĐỊNH CHUNG</span>
                                    </h3>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">1.
                                            Giới thiệu</span>
                                    </h3>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Ch&agrave;o mừng qu&yacute; kh&aacute;ch h&agrave;ng
                                            đến với itouch.ismart.edu.vn</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Ch&uacute;ng t&ocirc;i l&agrave;
                                        </span><strong>C&Ocirc;NG
                                            TY CỔ PHẦN GI&Aacute;O DỤC ISMART</strong><span style="color: #272727;">
                                            c&oacute; địa chỉ trụ sở tại </span>Lầu
                                        3, t&ograve;a nh&agrave; Quỳnh Lan, 60 Hai B&agrave; Trưng, Phường Bến
                                        Ngh&eacute;, Quận 1,
                                        Th&agrave;nh phố Hồ Ch&iacute; Minh, Việt Nam<span style="color: #272727;">.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Khi qu&yacute; kh&aacute;ch h&agrave;ng truy cập
                                            v&agrave;o trang website của ch&uacute;ng t&ocirc;i c&oacute; nghĩa
                                            l&agrave; qu&yacute; kh&aacute;ch đồng &yacute; với c&aacute;c điều khoản
                                            n&agrave;y. Trang web c&oacute; quyền thay đổi, chỉnh sửa, th&ecirc;m hoặc
                                            lược bỏ bất kỳ phần n&agrave;o trong Điều khoản mua b&aacute;n h&agrave;ng
                                            h&oacute;a n&agrave;y, v&agrave;o bất cứ l&uacute;c n&agrave;o. C&aacute;c
                                            thay đổi c&oacute; hiệu lực ngay khi được đăng tr&ecirc;n trang web
                                            m&agrave; kh&ocirc;ng cần th&ocirc;ng b&aacute;o trước. V&agrave; khi
                                            qu&yacute; kh&aacute;ch tiếp tục sử dụng trang web, sau khi c&aacute;c thay
                                            đổi về Điều khoản n&agrave;y được đăng tải, c&oacute; nghĩa l&agrave;
                                            qu&yacute; kh&aacute;ch chấp nhận với những thay đổi đ&oacute;.</span>
                                    </p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Qu&yacute; kh&aacute;ch h&agrave;ng vui l&ograve;ng
                                            kiểm tra thường xuy&ecirc;n để cập nhật những thay đổi của ch&uacute;ng
                                            t&ocirc;i.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">2.
                                            Hướng dẫn sử dụng website</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Khi truy cập v&agrave;o website của ch&uacute;ng
                                            t&ocirc;i, kh&aacute;ch h&agrave;ng phải đảm bảo đủ 18 tuổi, hoặc truy cập
                                            dưới sự gi&aacute;m s&aacute;t của cha mẹ hay người gi&aacute;m hộ hợp
                                            ph&aacute;p. Kh&aacute;ch h&agrave;ng đảm bảo c&oacute; đầy đủ h&agrave;nh
                                            vi d&acirc;n sự để thực hiện c&aacute;c giao dịch mua b&aacute;n h&agrave;ng
                                            h&oacute;a theo quy định hiện h&agrave;nh của ph&aacute;p luật Việt
                                            Nam.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Sau khi đăng k&yacute; tạo t&agrave;i khoản thanh
                                            c&ocirc;ng tr&ecirc;n hệ thống, kh&aacute;ch h&agrave;ng c&oacute; thể sử
                                            dụng t&agrave;i khoản n&agrave;y để mua v&agrave; trải nghiệm c&aacute;c
                                            kh&oacute;a học tr&ecirc;n website itouch.ismart.edu.vn trong khu&ocirc;n
                                            khổ Điều khoản v&agrave; Điều kiện sử dụng đ&atilde; đề ra.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Qu&yacute; kh&aacute;ch h&agrave;ng cần đăng
                                            k&yacute; t&agrave;i khoản với th&ocirc;ng tin x&aacute;c thực về bản
                                            th&acirc;n v&agrave; phải cập nhật nếu c&oacute; bất kỳ thay đổi n&agrave;o.
                                            Mỗi người truy cập phải c&oacute; tr&aacute;ch nhiệm với mật khẩu,
                                            t&agrave;i khoản v&agrave; hoạt động của m&igrave;nh tr&ecirc;n website. Hơn
                                            nữa, qu&yacute; kh&aacute;ch h&agrave;ng cần th&ocirc;ng b&aacute;o cho
                                            ch&uacute;ng t&ocirc;i biết khi t&agrave;i khoản bị truy cập tr&aacute;i
                                            ph&eacute;p. Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu bất kỳ tr&aacute;ch
                                            nhiệm n&agrave;o, d&ugrave; trực tiếp hay gi&aacute;n tiếp, đối với những
                                            thiệt hại hoặc mất m&aacute;t g&acirc;y ra do qu&yacute; kh&aacute;ch
                                            kh&ocirc;ng tu&acirc;n thủ quy định.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Nghi&ecirc;m cấm sử dụng bất kỳ phần n&agrave;o của
                                            website n&agrave;y với mục đ&iacute;ch thương mại hoặc nh&acirc;n danh bất
                                            kỳ đối t&aacute;c thứ ba n&agrave;o nếu kh&ocirc;ng được ch&uacute;ng
                                            t&ocirc;i cho ph&eacute;p bằng văn bản. Nếu vi phạm bất cứ điều n&agrave;o
                                            trong đ&acirc;y, ch&uacute;ng t&ocirc;i sẽ hủy t&agrave;i khoản của
                                            kh&aacute;ch m&agrave; kh&ocirc;ng cần b&aacute;o trước.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Trong suốt qu&aacute; tr&igrave;nh đăng k&yacute;,
                                            qu&yacute; kh&aacute;ch đồng &yacute; nhận email quảng c&aacute;o từ
                                            website. Nếu kh&ocirc;ng muốn tiếp tục nhận mail, qu&yacute; kh&aacute;ch
                                            c&oacute; thể từ chối bằng c&aacute;ch nhấp v&agrave;o đường link ở dưới
                                            c&ugrave;ng trong mọi email quảng c&aacute;o.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">3.
                                            &Yacute; kiến của kh&aacute;ch h&agrave;ng</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Tất cả nội dung trang web v&agrave; &yacute; kiến
                                            ph&ecirc; b&igrave;nh của qu&yacute; kh&aacute;ch đều l&agrave; t&agrave;i
                                            sản của ch&uacute;ng t&ocirc;i. Nếu ch&uacute;ng t&ocirc;i ph&aacute;t hiện
                                            bất kỳ th&ocirc;ng tin giả mạo n&agrave;o, ch&uacute;ng t&ocirc;i sẽ
                                            kh&oacute;a t&agrave;i khoản của qu&yacute; kh&aacute;ch ngay lập tức hoặc
                                            &aacute;p dụng c&aacute;c biện ph&aacute;p kh&aacute;c theo quy định của
                                            ph&aacute;p luật Việt Nam.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">4.
                                            Chấp nhận đơn h&agrave;ng v&agrave; gi&aacute; cả</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Ch&uacute;ng t&ocirc;i c&oacute; quyền từ chối hoặc
                                            hủy đơn h&agrave;ng của qu&yacute; kh&aacute;ch v&igrave; bất kỳ l&yacute;
                                            do g&igrave; li&ecirc;n quan đến lỗi kỹ thuật, hệ thống một c&aacute;ch
                                            kh&aacute;ch quan v&agrave;o bất kỳ l&uacute;c n&agrave;o.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Ngo&agrave;i ra, để đảm bảo t&iacute;nh c&ocirc;ng
                                            bằng cho kh&aacute;ch h&agrave;ng l&agrave; người ti&ecirc;u d&ugrave;ng
                                            cuối c&ugrave;ng của itouch.ismart.edu.vn, ch&uacute;ng t&ocirc;i cũng sẽ từ
                                            chối c&aacute;c đơn h&agrave;ng kh&ocirc;ng nhằm mục đ&iacute;ch sử dụng cho
                                            c&aacute; nh&acirc;n, mua h&agrave;ng số lượng nhiều hoặc với mục
                                            đ&iacute;ch mua đi b&aacute;n lại.Ch&uacute;ng t&ocirc;i cam kết sẽ cung cấp
                                            th&ocirc;ng tin học ph&iacute; ch&iacute;nh x&aacute;c nhất cho người
                                            học.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">6.
                                            Giải quyết hậu quả do lỗi nhập sai th&ocirc;ng tin tại
                                            itouch.ismart.edu.vn</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Kh&aacute;ch h&agrave;ng c&oacute; tr&aacute;ch
                                            nhiệm cung cấp th&ocirc;ng tin đầy đủ v&agrave; ch&iacute;nh x&aacute;c khi
                                            tham gia giao dịch tại itouch.ismart.edu.vn. Trong trường hợp kh&aacute;ch
                                            h&agrave;ng nhập sai th&ocirc;ng tin v&agrave; gửi v&agrave;o website
                                            https://itouch.ismart.edu.vn, itouch.ismart.edu.vn c&oacute; quyền từ chối
                                            thực hiện giao dịch. </span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Trong trường hợp sai th&ocirc;ng tin ph&aacute;t
                                            sinh từ ph&iacute;a itouch.ismart.edu.vn m&agrave; itouch.ismart.edu.vn
                                            c&oacute; thể chứng minh đ&oacute; l&agrave; lỗi của hệ thống hoặc từ
                                            b&ecirc;n thứ ba (sai gi&aacute; sản phẩm, sai xuất xứ, &hellip;),
                                            itouch.ismart.edu.vn sẽ đền b&ugrave; cho kh&aacute;ch h&agrave;ng một
                                            m&atilde; giảm gi&aacute; cho c&aacute;c lần mua sắm tiếp theo với mệnh
                                            gi&aacute; t&ugrave;y từng trường hợp cụ thể v&agrave; c&oacute; quyền
                                            kh&ocirc;ng thực hiện giao dịch bị lỗi.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">7.
                                            Thương hiệu v&agrave; bản quyền</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Mọi quyền sở hữu tr&iacute; tuệ (đ&atilde; đăng
                                            k&yacute; hoặc chưa đăng k&yacute;), nội dung th&ocirc;ng tin v&agrave; tất
                                            cả c&aacute;c thiết kế, văn bản, đồ họa, phần mềm, h&igrave;nh ảnh, video,
                                            &acirc;m nhạc, &acirc;m thanh, bi&ecirc;n dịch phần mềm, m&atilde; nguồn
                                            v&agrave; phần mềm cơ bản đều l&agrave; t&agrave;i sản của ch&uacute;ng
                                            t&ocirc;i. To&agrave;n bộ nội dung của trang web được bảo vệ bởi luật bản
                                            quyền của Việt Nam v&agrave; c&aacute;c c&ocirc;ng ước quốc tế. Bản quyền
                                            đ&atilde; được bảo lưu.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">8.
                                            Quyền ph&aacute;p l&yacute;</span>
                                    </h3>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">C&aacute;c điều kiện, điều khoản v&agrave; nội dung
                                            của trang web n&agrave;y được điều chỉnh bởi luật ph&aacute;p Việt Nam
                                            v&agrave; T&ograve;a &aacute;n c&oacute; thẩm quyền tại Việt Nam sẽ giải
                                            quyết bất kỳ tranh chấp n&agrave;o ph&aacute;t sinh từ việc sử dụng
                                            tr&aacute;i ph&eacute;p trang web n&agrave;y.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">9.
                                            Quy định về bảo mật</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Website của ch&uacute;ng t&ocirc;i coi trọng việc
                                            bảo mật th&ocirc;ng tin v&agrave; sử dụng c&aacute;c biện ph&aacute;p tốt
                                            nhất bảo vệ th&ocirc;ng tin v&agrave; việc thanh to&aacute;n của qu&yacute;
                                            kh&aacute;ch. Th&ocirc;ng tin của qu&yacute; kh&aacute;ch trong qu&aacute;
                                            tr&igrave;nh thanh to&aacute;n sẽ được m&atilde; h&oacute;a để đảm bảo an
                                            to&agrave;n. Sau khi qu&yacute; kh&aacute;ch ho&agrave;n th&agrave;nh
                                            qu&aacute; tr&igrave;nh đặt h&agrave;ng, qu&yacute; kh&aacute;ch sẽ
                                            tho&aacute;t khỏi chế độ an to&agrave;n.</span>
                                    </p>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Qu&yacute; kh&aacute;ch kh&ocirc;ng được sử dụng bất
                                            kỳ chương tr&igrave;nh, c&ocirc;ng cụ hay h&igrave;nh thức n&agrave;o
                                            kh&aacute;c để can thiệp v&agrave;o hệ thống hay l&agrave;m thay đổi cấu
                                            tr&uacute;c dữ liệu. Trang web cũng nghi&ecirc;m cấm việc ph&aacute;t
                                            t&aacute;n, truyền b&aacute; hay cổ vũ cho bất kỳ hoạt động n&agrave;o nhằm
                                            can thiệp, ph&aacute; hoại hay x&acirc;m nhập v&agrave;o dữ liệu của hệ
                                            thống. C&aacute; nh&acirc;n hay tổ chức vi phạm sẽ bị tước bỏ mọi quyền lợi
                                            cũng như sẽ bị truy tố trước ph&aacute;p luật nếu cần thiết.</span>
                                    </p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Mọi th&ocirc;ng tin giao dịch sẽ được bảo mật ngoại
                                            trừ trong trường hợp cơ quan ph&aacute;p luật y&ecirc;u cầu.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">10.
                                            Thanh to&aacute;n an to&agrave;n v&agrave; tiện lợi tại
                                            itouch.ismart.edu.vn</span>
                                    </h3>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Người mua c&oacute; thể tham khảo c&aacute;c phương
                                            thức thanh to&aacute;n học ph&iacute; sau đ&acirc;y v&agrave; lựa chọn
                                            &aacute;p dụng phương thức ph&ugrave; hợp:</span>
                                    </p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><strong><u><span style="color: #272727;">C&aacute;ch 1:</span></u></strong><span style="color: #272727;">&nbsp;Thanh to&aacute;n trực tiếp</span></p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><strong><u><span style="color: #272727;">C&aacute;ch 2:</span></u></strong><span style="color: #272727;">&nbsp;Thanh to&aacute;n sau (COD &ndash; giao
                                            h&agrave;ng v&agrave; thu tiền tận nơi)</span>
                                    </p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><strong><u><span style="color: #272727;">C&aacute;ch 3:</span></u></strong><span style="color: #272727;">&nbsp;Thanh to&aacute;n itouch qua thẻ ATM, thẻ ghi
                                            nơ, thẻ t&iacute;n dụng</span>
                                    </p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><strong><u><span style="color: #272727;">C&aacute;ch 4:</span></u></strong><span style="color: #272727;">&nbsp;Chuyển khoản</span></p>
                                    <p style="background: white; margin: 0in 0in 7.5pt 0in;"><strong><u><span style="color: #272727;">C&aacute;ch 5:</span></u></strong><span style="color: #272727;"> Thanh to&aacute;n qua Momo</span></p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">11.
                                            Đảm bảo an to&agrave;n giao dịch tại itouch.ismart.edu.vn</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Ch&uacute;ng t&ocirc;i sử dụng c&aacute;c dịch vụ để
                                            bảo vệ th&ocirc;ng tin về nội dung kh&oacute;a học tr&ecirc;n
                                            itouch.ismart.edu.vn để đảm bảo c&aacute;c giao dịch được tiến h&agrave;nh
                                            th&agrave;nh c&ocirc;ng, hạn chế tối đa rủi ro c&oacute; thể ph&aacute;t
                                            sinh.</span>
                                    </p>
                                    <h3 style="background: white; margin: 15.0pt 0in 7.5pt 0in;"><span style=" line-height: 115%;  color: #272727; mso-bidi-font-weight: normal;">12.
                                            Luật ph&aacute;p v&agrave; thẩm quyền tại L&atilde;nh thổ Việt Nam</span>
                                    </h3>
                                    <p style="text-align: justify; background: white; margin: 0in 0in 7.5pt 0in;"><span style="color: #272727;">Tất cả c&aacute;c Điều Khoản v&agrave; Điều Kiện
                                            n&agrave;y v&agrave; Hợp Đồng (v&agrave; tất cả nghĩa vụ ph&aacute;t sinh
                                            ngo&agrave;i hợp đồng hoặc c&oacute; li&ecirc;n quan) sẽ bị chi phối
                                            v&agrave; được hiểu theo luật ph&aacute;p của Việt Nam. Nếu c&oacute; tranh
                                            chấp ph&aacute;t sinh bởi c&aacute;c Quy định Sử dụng n&agrave;y, qu&yacute;
                                            kh&aacute;ch h&agrave;ng c&oacute; quyền gửi khiếu nại/khiếu kiện l&ecirc;n
                                            T&ograve;a &aacute;n c&oacute; thẩm quyền tại Việt Nam để giải quyết.</span>
                                    </p>
                                    <p class="MsoNormal" style="text-align: justify;"><span style=" line-height: 115%; ">&nbsp;</span>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {{ $t('information_security_principles') }}
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p style="text-align: center; line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;" align="center"><strong><span style="color: black; mso-themecolor: text1;">CH&Iacute;NH S&Aacute;CH
                                                BẢO MẬT</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;">
                                        <strong><span style="color: black; mso-themecolor: text1;">Mục đ&iacute;ch
                                                v&agrave; phạm vi thu thập</span></strong>
                                    </p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Việc thu thập dữ liệu chủ yếu tr&ecirc;n
                                            website&nbsp;</span><span style="color: #263238;">&nbsp;</span><a href="https://itouch.ismart.edu.vn/">https://itouch.ismart.edu.vn/</a> <span style="color: #222222;">bao gồm: email, điện thoại, t&ecirc;n,
                                            địa chỉ
                                            kh&aacute;ch h&agrave;ng. Đ&acirc;y l&agrave; c&aacute;c th&ocirc;ng tin
                                            m&agrave; ISMART cần kh&aacute;ch h&agrave;ng cung cấp bắt buộc khi đăng
                                            k&yacute; sử dụng dịch vụ v&agrave; để ISMART li&ecirc;n hệ x&aacute;c nhận
                                            khi kh&aacute;ch h&agrave;ng đăng k&yacute; sử dụng dịch vụ tr&ecirc;n
                                            website nhằm đảm bảo quyền lợi cho kh&aacute;ch h&agrave;ng.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Ch&uacute;ng t&ocirc;i thu thập cho c&aacute;c mục
                                            đ&iacute;ch sau:</span></p>
                                    <p style="text-indent: -7.1pt; line-height: 130%; mso-list: l0 level1 lfo1; background: white; margin: 6.0pt 0in 6.0pt 7.1pt;">
                                        <!-- [if !supportLists]--><span style="color: #222222;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;
                                                </span></span></span><!--[endif]--><span style="color: #222222;">Để cung
                                            cấp Dịch vụ hoặc t&iacute;nh năng m&agrave; kh&aacute;ch h&agrave;ng
                                            y&ecirc;u cầu;</span>
                                    </p>
                                    <p style="text-indent: -7.1pt; line-height: 130%; mso-list: l0 level1 lfo1; background: white; margin: 6.0pt 0in 6.0pt 7.1pt;">
                                        <!-- [if !supportLists]--><span style="color: #222222;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;
                                                </span></span></span><!--[endif]--><span style="color: #222222;">Để cung
                                            cấp nội dung theo đối tượng kh&aacute;ch h&agrave;ng v&agrave; khuyến nghị
                                            dựa v&agrave;o c&aacute;c hoạt động trong qu&aacute; khứ của kh&aacute;ch
                                            h&agrave;ng tr&ecirc;n dịch vụ của ch&uacute;ng t&ocirc;i;</span>
                                    </p>
                                    <p style="text-indent: -7.1pt; line-height: 130%; mso-list: l0 level1 lfo1; background: white; margin: 6.0pt 0in 6.0pt 7.1pt;">
                                        <!-- [if !supportLists]--><span style="color: #222222;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;
                                                </span></span></span><!--[endif]--><span style="color: #222222;">Để
                                            đ&aacute;nh gi&aacute; v&agrave; ph&acirc;n t&iacute;ch thị trường,
                                            kh&aacute;ch h&agrave;ng, sản phẩm v&agrave; dịch vụ của Ch&uacute;ng
                                            t&ocirc;i (kể cả hỏi &yacute; kiến của Kh&aacute;ch h&agrave;ng về
                                            c&aacute;c sản phẩm v&agrave; dịch vụ của Ch&uacute;ng t&ocirc;i cũng như
                                            thực hiện khảo s&aacute;t về kh&aacute;ch h&agrave;ng);</span>
                                    </p>
                                    <p style="text-indent: -7.1pt; line-height: 130%; mso-list: l0 level1 lfo1; background: white; margin: 6.0pt 0in 6.0pt 7.1pt;">
                                        <!-- [if !supportLists]--><span style="color: #222222;"><span style="mso-list: Ignore;">-<span style="font: 7.0pt 'Times New Roman';">&nbsp;
                                                </span></span></span><!--[endif]--><span style="color: #222222;">Để hiểu
                                            c&aacute;ch mọi người sử dụng Dịch vụ của Ch&uacute;ng t&ocirc;i để
                                            Ch&uacute;ng t&ocirc;i c&oacute; thể cải tiến v&agrave; ph&aacute;t triển
                                            c&aacute;c sản phẩm v&agrave; dịch vụ mới.</span>
                                    </p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">C&aacute;c kh&aacute;ch h&agrave;ng sẽ tự chịu
                                            tr&aacute;ch nhiệm về bảo mật v&agrave; lưu giữ mọi hoạt động sử dụng dịch
                                            vụ dưới t&ecirc;n đăng k&yacute;, mật khẩu, hộp thư điện tử của m&igrave;nh
                                            (nếu c&oacute;). Ngo&agrave;i ra, kh&aacute;ch h&agrave;ng c&oacute;
                                            tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o kịp thời cho ISMART về những
                                            h&agrave;nh vi sử dụng tr&aacute;i ph&eacute;p, lạm dụng, vi phạm bảo mật,
                                            lưu giữ t&ecirc;n đăng k&yacute;, mật khẩu của b&ecirc;n thứ ba để c&oacute;
                                            biện ph&aacute;p giải quyết ph&ugrave; hợp.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><a name="__DdeLink__147_1949806830"></a><strong><span style="color: black; mso-themecolor: text1;">Phạm vi sử dụng th&ocirc;ng
                                                tin</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Cung cấp c&aacute;c dịch vụ đến c&aacute;c
                                            kh&aacute;ch h&agrave;ng.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Gửi c&aacute;c th&ocirc;ng b&aacute;o về hoạt động
                                            trao đổi giữa kh&aacute;ch h&agrave;ng v&agrave; ISMART.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Ngăn ngừa c&aacute;c hoạt động ph&aacute; hủy
                                            t&agrave;i khoản người d&ugrave;ng kh&aacute;ch h&agrave;ng hoặc c&aacute;c
                                            hoạt động giả mạo kh&aacute;ch h&agrave;ng.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Li&ecirc;n lạc v&agrave; giải quyết với kh&aacute;ch
                                            h&agrave;ng trong trường hợp đặc biệt.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Kh&ocirc;ng sử dụng th&ocirc;ng tin c&aacute;
                                            nh&acirc;n của kh&aacute;ch h&agrave;ng ngo&agrave;i mục đ&iacute;ch
                                            x&aacute;c nhận v&agrave; li&ecirc;n hệ c&oacute; li&ecirc;n quan đến giao
                                            dịch ngo&agrave;i ISMART.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Trong trường hợp c&oacute; y&ecirc;u cầu c&oacute;
                                            ph&aacute;p luật: ISMART c&oacute; tr&aacute;ch nhiệm hợp t&aacute;c cung
                                            cấp th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng khi
                                            c&oacute; y&ecirc;u cầu từ cơ quan tư ph&aacute;p bao gồm: Viện Kiểm
                                            s&aacute;t, T&ograve;a &aacute;n, Cơ quan C&ocirc;ng an điều tra li&ecirc;n
                                            quan đến h&agrave;nh vi vi phạm ph&aacute;p luật n&agrave;o đ&oacute; của
                                            kh&aacute;ch h&agrave;ng. Ngo&agrave;i ra, kh&ocirc;ng ai c&oacute; quyền
                                            x&acirc;m phạm v&agrave;o th&ocirc;ng tin c&aacute; nh&acirc;n của
                                            kh&aacute;ch h&agrave;ng.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><a name="__DdeLink__161_1949806830"></a><strong><span style="color: black; mso-themecolor: text1;">Thời gian lưu trữ
                                                th&ocirc;ng tin</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Dữ liệu c&aacute; nh&acirc;n của kh&aacute;ch
                                            h&agrave;ng sẽ được lưu trữ cho đến khi c&oacute; y&ecirc;u cầu hủy bỏ hoặc
                                            tự kh&aacute;ch h&agrave;ng đăng nhập v&agrave; thực hiện hủy bỏ. C&ograve;n
                                            lại trong mọi trường hợp th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch
                                            h&agrave;ng sẽ được bảo mật tr&ecirc;n m&aacute;y chủ của ISMART.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;">
                                        <strong><span style="color: black; mso-themecolor: text1;">Những người hoặc tổ
                                                chức c&oacute; thể được tiếp cận với th&ocirc;ng tin</span></strong>
                                    </p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Ch&uacute;ng t&ocirc;i chỉ cho ph&eacute;p
                                            nh&acirc;n vi&ecirc;n, nh&agrave; thầu v&agrave; người đại diện của
                                            Ch&uacute;ng t&ocirc;i truy cập th&ocirc;ng tin c&aacute; nh&acirc;n
                                            v&igrave; họ l&agrave; những người cần biết th&ocirc;ng tin n&agrave;y để xử
                                            l&yacute; c&aacute;c th&ocirc;ng tin đ&oacute; cho Ch&uacute;ng t&ocirc;i
                                            v&agrave; họ phải chịu sự chi phối của nghĩa vụ bảo mật nghi&ecirc;m ngặt
                                            trong hợp đồng v&agrave; c&oacute; thể bị kỷ luật hoặc chấm dứt hợp đồng nếu
                                            họ kh&ocirc;ng tu&acirc;n theo c&aacute;c nghĩa vụ đ&oacute;.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><a name="__DdeLink__165_1949806830"></a><strong><span style="color: black; mso-themecolor: text1;">Địa chỉ của đơn vị thu thập
                                                v&agrave; quản l&yacute; th&ocirc;ng tin c&aacute;
                                                nh&acirc;n</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">T&ecirc;n thương nh&acirc;n/ tổ chức:
                                        </span><strong>C&Ocirc;NG TY CỔ PHẦN GI&Aacute;O DỤC ISMART</strong></p>
                                    <p class="MsoNormal"><span style=" line-height: 107%; mso-bidi-font-family: 'Times New Roman'; color: #222222;">Địa
                                            chỉ: </span><span style=" line-height: 107%; mso-bidi-font-family: 'Times New Roman';">Lầu 3,
                                            t&ograve;a nh&agrave; Quỳnh Lan, 60 Hai B&agrave; Trưng, Phường Bến
                                            Ngh&eacute;, Quận 1, Th&agrave;nh phố Hồ Ch&iacute; Minh, Việt Nam.</span>
                                    </p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Điện thoại:</span> 1900 088 893<span style="color: #222222;"><span
                                                style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span></span></p>
                                    <p class="MsoNormal"><span style=" line-height: 107%; mso-bidi-font-family: 'Times New Roman'; color: #222222;">M&atilde;
                                            số thuế: </span><span style=" line-height: 107%; mso-bidi-font-family: 'Times New Roman';">0311810462</span>
                                    </p>
                                    <p class="MsoNormal"><span style=" line-height: 107%; mso-bidi-font-family: 'Times New Roman';">Email:&nbsp;<span style="color: #232222; background: #F2F3F5;">report@ismart.edu.vn</span></span>
                                    </p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><a name="__DdeLink__175_1949806830"></a><strong><span style="color: black; mso-themecolor: text1;">Phương tiện v&agrave;
                                                c&ocirc;ng cụ để người d&ugrave;ng tiếp cận v&agrave; chỉnh sửa dữ liệu
                                                c&aacute; nh&acirc;n của m&igrave;nh</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Kh&aacute;ch h&agrave;ng c&oacute; quyền tự kiểm
                                            tra, cập nhật, điều chỉnh hoặc hủy bỏ th&ocirc;ng tin c&aacute; nh&acirc;n
                                            của m&igrave;nh bằng c&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản
                                            v&agrave; chỉnh sửa th&ocirc;ng tin c&aacute; nh&acirc;n hoặc y&ecirc;u cầu
                                            ISMART.vn thực hiện việc n&agrave;y.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Kh&aacute;ch h&agrave;ng c&oacute; quyền gửi khiếu
                                            nại tới ISMART. Khi tiếp nhận những phản hồi n&agrave;y, ISMART sẽ
                                            x&aacute;c nhận lại th&ocirc;ng tin, trường hợp đ&uacute;ng như phản
                                            &aacute;nh của kh&aacute;ch h&agrave;ng t&ugrave;y theo mức độ ISMART sẽ
                                            c&oacute; những biện ph&aacute;p xử l&yacute; kịp thời.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><a name="__DdeLink__181_1949806830"></a><strong><span style="color: black; mso-themecolor: text1;">Cam kết bảo mật th&ocirc;ng
                                                tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng</span></strong></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Th&ocirc;ng tin c&aacute; nh&acirc;n của
                                            kh&aacute;ch h&agrave;ng được ISMART cam kết bảo mật tuyệt đối theo
                                            ch&iacute;nh s&aacute;ch bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của
                                            ISMART. Việc thu thập v&agrave; sử dụng th&ocirc;ng tin của mỗi kh&aacute;ch
                                            h&agrave;ng chỉ được thực hiện khi c&oacute; sự đồng &yacute; cảu
                                            kh&aacute;ch h&agrave;ng đ&oacute; trừ trường hợp ph&aacute;p luật c&oacute;
                                            quy định kh&aacute;c. Kh&ocirc;ng sử dụng, kh&ocirc;ng chuyển giao, cung cấp
                                            hay tiết lộ cho b&ecirc;n thứ 3 n&agrave;o về th&ocirc;ng tin c&aacute;
                                            nh&acirc;n của kh&aacute;ch h&agrave;ng khi kh&ocirc;ng c&oacute; sự cho
                                            ph&eacute;p đồng &yacute; từ kh&aacute;ch h&agrave;ng.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Trong trường hợp m&aacute;y chủ lưu trữ th&ocirc;ng
                                            tin bị hacker tấn c&ocirc;ng dẫn đến mất dữ liệu c&aacute; nh&acirc;n
                                            kh&aacute;ch h&agrave;ng, ISMART sẽ c&oacute; tr&aacute;ch nhiệm th&ocirc;ng
                                            b&aacute;o vụ việc cho cơ quan chức năng điều tra xử l&yacute; kịp thời
                                            v&agrave; th&ocirc;ng b&aacute;o cho kh&aacute;ch h&agrave;ng được
                                            biết.</span></p>
                                    <p style="line-height: 130%; background: white; margin: 6.0pt 0in 6.0pt 0in;"><span style="color: #222222;">Ban quản l&yacute; ISMART y&ecirc;u cầu c&aacute;c
                                            c&aacute; nh&acirc;n khi đăng k&yacute; dịch vụ phải cung cấp đầy đủ
                                            th&ocirc;ng tin c&aacute; nh&acirc;n c&oacute; li&ecirc;n quan như: (họ
                                            v&agrave; t&ecirc;n, địa chỉ li&ecirc;n lạc, email,&hellip;) V&agrave; chịu
                                            tr&aacute;ch nhiệm về t&iacute;nh ph&aacute;p l&yacute; của những
                                            th&ocirc;ng tin tr&ecirc;n. Ban quản l&yacute; ISMART kh&ocirc;ng chịu
                                            tr&aacute;ch nhiệm cũng như kh&ocirc;ng giải quyết mọi khiếu nại c&oacute;
                                            li&ecirc;n quan đến quyền lợi của kh&aacute;ch h&agrave;ng đ&oacute; nếu
                                            x&eacute;t thấy tất cả c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n của
                                            kh&aacute;ch h&agrave;ng đ&oacute; cung cấp khi đăng k&yacute; ban đầu
                                            l&agrave; kh&ocirc;ng ch&iacute;nh x&aacute;c.</span></p>
                                    <p class="MsoNormal" style="line-height: 130%; margin: 6.0pt 0in 6.0pt 0in;"><span style=" line-height: 130%; mso-bidi-font-family: 'Times New Roman';">&nbsp;</span>
                                    </p>
                                    <p class="MsoNormal" style="line-height: 115%; margin: 6.0pt 0in 6.0pt 0in;"><span style=" line-height: 115%; mso-bidi-font-family: 'Times New Roman';">&nbsp;</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        {{ $t('delivery_principles') }}
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">

                                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: 130%; background: white;" align="center"><strong><span style=" line-height: 130%;   color: #272727;">CH&Iacute;NH S&Aacute;CH
                                                GIAO H&Agrave;NG</span></strong></p>
                                    <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: 130%; background: white;">
                                        <strong><span style=" line-height: 130%;   color: #272727;">1. Đối với
                                                h&igrave;nh thức Giao kh&oacute;a học v&agrave; thu tiền tận nơi
                                                (COD):&nbsp;</span></strong>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l1 level1 lfo1; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Sau khi nhận được th&ocirc;ng
                                            tin đặt h&agrave;ng của Kh&aacute;ch h&agrave;ng, ISMART sẽ li&ecirc;n lạc
                                            để x&aacute;c nhận ch&iacute;nh x&aacute;c địa chỉ, số điện thoại v&agrave;
                                            tiến h&agrave;nh l&ecirc;n đơn h&agrave;ng. Sau đ&oacute; ISMART sẽ chuyển
                                            giao th&ocirc;ng tin v&agrave; M&atilde; k&iacute;ch hoạt qua hệ thống
                                            c&aacute;c b&ecirc;n giao nhận. Nh&acirc;n vi&ecirc;n giao h&agrave;ng của
                                            c&aacute;c b&ecirc;n giao nhận sẽ li&ecirc;n lạc để giao M&atilde;
                                            k&iacute;ch hoạt v&agrave; thu tiền.</span>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l1 level1 lfo1; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Giới hạn địa l&yacute;:
                                            &aacute;p dụng với mọi tỉnh th&agrave;nh, quận huyện tại Việt Nam. Chưa
                                            &aacute;p dụng với c&aacute;c quốc gia kh&aacute;c tr&ecirc;n thế
                                            giới.</span>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l1 level1 lfo1; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Thời gian giao h&agrave;ng dự
                                            kiến: TPHCM v&agrave; H&agrave; Nội: 1 - 2 ng&agrave;y. C&aacute;c tỉnh
                                            th&agrave;nh kh&aacute;c: 1 - 4 ng&agrave;y</span>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l1 level1 lfo1; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Kh&aacute;ch h&agrave;ng
                                            c&oacute; thể v&agrave;o học ngay sau khi nhận được m&atilde; k&iacute;ch
                                            hoạt kh&oacute;a học.</span>
                                    </p>
                                    <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: 130%; background: white;">
                                        <strong><span style=" line-height: 130%;   color: #272727;">2. Đối với
                                                h&igrave;nh thức thanh to&aacute;n trực tuyến (thẻ ATM/VISA MASTER hoặc
                                                Chuyển khoản ng&acirc;n h&agrave;ng...):</span></strong>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l0 level1 lfo2; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Giới hạn địa l&yacute;:
                                            kh&ocirc;ng giới hạn</span>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l0 level1 lfo2; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><span style=" line-height: 130%;   color: #272727;">Sau khi thanh to&aacute;n
                                            th&agrave;nh c&ocirc;ng, Kh&aacute;ch h&agrave;ng sẽ nhận được email
                                            th&ocirc;ng b&aacute;o đơn h&agrave;ng, x&aacute;c nhận thanh to&aacute;n
                                            th&agrave;nh c&ocirc;ng v&agrave; hướng dẫn tham gia học tại ISMART.</span>
                                    </p>
                                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 130%; mso-list: l0 level1 lfo2; tab-stops: list .25in; background: white; margin: 0in 0in .0001pt .25in;">
                                        <!-- [if !supportLists]--><span
                                            style="font-size: 10.0pt; mso-bidi- line-height: 130%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: #272727; mso-bidi-font-weight: bold;"><span
                                                style="mso-list: Ignore;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span></span></span><!--[endif]--><strong><span style=" line-height: 130%;   color: #272727;">Kh&aacute;ch h&agrave;ng
                                                c&oacute; thể v&agrave;o học ngay sau khi thanh to&aacute;n
                                                xong.</span></strong>
                                    </p>
                                    <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: 130%;"><strong style="mso-bidi-font-weight: normal;"><span style=" line-height: 130%; ">&nbsp;</span></strong></p>

                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        {{ $t('refund_principles') }}
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="MsoNormal" style="margin-bottom: 6.0pt; text-align: center; text-indent: 21.3pt; line-height: 130%; background: white;" align="center"><strong style="mso-bidi-font-weight: normal;"><span
                                                style="font-size: 13.0pt; line-height: 130%;   color: #272727;">CH&Iacute;NH
                                                S&Aacute;CH HO&Agrave;N HỌC PH&Iacute;</span></strong></p>
                                    <p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: 130%; background: white;">
                                        <strong><span style=" line-height: 130%;   color: #505050;">Ho&agrave;n
                                                tiền&nbsp;</span></strong><span style=" line-height: 130%;   color: #505050;">l&agrave; trường hợp sau khi
                                            thanh to&aacute;n học ph&iacute; tr&ecirc;n </span><a href="https://itouch.ismart.edu.vn/">https://itouch.ismart.edu.vn/</a><span style=" line-height: 130%;   color: #505050;">, bạn kh&ocirc;ng thể tham gia
                                            học tập tr&ecirc;n </span><a href="https://itouch.ismart.edu.vn/">https://itouch.ismart.edu.vn/</a><span style=" line-height: 130%;   color: #505050;"> v&agrave; mong muốn
                                            ho&agrave;n lại tiền. Để được ho&agrave;n tiền, t&agrave;i khoản phải thỏa
                                            m&atilde;n đồng thời c&aacute;c điều kiện dưới đ&acirc;y:</span>
                                    </p>
                                    <ul style="margin-top: 0in;" type="disc">
                                        <li class="MsoNormal" style="color: #505050; margin-bottom: 7.5pt; text-align: justify; line-height: 130%; mso-list: l0 level1 lfo1; tab-stops: list .5in; background: white;">
                                            <span style=" line-height: 130%;  ">Bạn được y&ecirc;u cầu ho&agrave;n trả
                                                học ph&iacute; trong ba (03) ng&agrave;y kể từ ng&agrave;y nạp học
                                                ph&iacute; th&agrave;nh c&ocirc;ng về t&agrave;i khoản ISMART.</span>
                                        </li>
                                        <ul style="margin-top: 0in;" type="circle">
                                            <li class="MsoNormal" style="color: #505050; margin-bottom: 7.5pt; text-align: justify; line-height: 130%; mso-list: l0 level2 lfo1; tab-stops: list 1.0in; background: white;">
                                                <span style=" line-height: 130%;  ">ISMART ho&agrave;n trả 70% số tiền
                                                    trong t&agrave;i khoản ch&iacute;nh m&agrave; Bạn đ&atilde; chuyển
                                                    v&agrave;o t&agrave;i khoản ISMART. Số tiền c&ograve;n lại (30%)
                                                    được t&iacute;nh l&agrave; lệ ph&iacute; ho&agrave;n th&agrave;nh
                                                    thủ tục ho&agrave;n trả học ph&iacute;.</span>
                                            </li>
                                            <li class="MsoNormal" style="color: #505050; margin-bottom: 7.5pt; text-align: justify; line-height: 130%; mso-list: l0 level2 lfo1; tab-stops: list 1.0in; background: white;">
                                                <span style=" line-height: 130%;  ">Để yêu cầu hoàn lại tiền, Bạn vui
                                                    l&ograve;ng điền đầy đủ th&ocirc;ng tin theo </span><a href="spec/DondenghihoantienIto.docx" download="DondenghihoantienIto" style="text-decoration: underline; color: #2a70b8;">mẫu</a>, sau
                                                đ&oacute; gửi e-mail đến&nbsp;<strong><span style=" line-height: 130%;   color: #2970b8;">info@ismart.edu.vn</span></strong><span style=" line-height: 130%;  ">. Xin lưu &yacute; rằng, nếu ISMART
                                                    ph&aacute;t hiện Bạn đang lợi dụng ch&iacute;nh s&aacute;ch
                                                    ho&agrave;n trả của ch&uacute;ng t&ocirc;i, ch&uacute;ng t&ocirc;i
                                                    c&oacute; quyền hạn chế hoặc kh&oacute;a &nbsp;t&agrave;i khoản của
                                                    Bạn; từ chối hoặc hạn chế bất kỳ hay tất cả c&aacute;c quyền sử dụng
                                                    trong hiện tại v&agrave; &nbsp;tương lai của Bạn tại ISMART,
                                                    m&agrave; kh&ocirc;ng cần bất kỳ tr&aacute;ch nhiệm ph&aacute;p
                                                    l&yacute;.</span>
                                            </li>
                                            <li class="MsoNormal" style="color: #505050; margin-bottom: 7.5pt; text-align: justify; line-height: 130%; mso-list: l0 level2 lfo1; tab-stops: list 1.0in; background: white;">
                                                <span style=" line-height: 130%;  ">C&aacute;c trường hợp kh&aacute;c,
                                                    ISMART kh&ocirc;ng chấp nhận việc ho&agrave;n trả học
                                                    ph&iacute;.</span>
                                            </li>
                                        </ul>
                                    </ul>
                                    <p class="MsoNormal" style="margin-bottom: 6.0pt; line-height: 130%; mso-outline-level: 1;">
                                        <strong><span style=" line-height: 130%;   mso-font-kerning: 18.0pt;">&nbsp;</span></strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        const params = this.$route.path;
        return {
            params,
        }
    },
    computed: {

    },
    mounted() {
        $('#collapseFour').on('shown.bs.collapse', function () {
            var element = document.getElementById("headingFour");
            element.scrollIntoView();
        });

        $('#collapseThree').on('shown.bs.collapse', function () {
            var element = document.getElementById("headingThree");
            element.scrollIntoView();
        });

        $('#collapseTwo').on('shown.bs.collapse', function () {
            var element = document.getElementById("headingTwo");
            element.scrollIntoView();
        });
        $('#collapseOne').on('shown.bs.collapse', function () {
            var element = document.getElementById("headingOne");
            element.scrollIntoView();
        });
        if (this.params == '/privacy-policy') {
            $('#collapseTwo').collapse('show');
        }
    },
    created() {

    },
    methods: {

    },
};
</script>
<style scoped>
h3 span {
    font-size: 24px;
    font-weight: bold;
}

.mb-0 button {
    color: white;
    background: #EC6D43;
    width: 50%;

}

.mb-0 {
    text-align: center;
}

@media(max-width: 767px) {
    .mb-0 button {
        width: 100%;
        font-size: 14px;

    }
}

.l-faq__header {
    background-image: url(../../../public/landing_page/assets/img/bg--faq.jpg);
}

.bg-color-light-brown-600 {
    background: #EC6D43;
}

.h-shadow-1 {
    box-shadow: none !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: honeydew;
    border-radius: 50px;
}
</style>