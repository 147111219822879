<template>
    <div>
        <div class="class-item" v-if="tutoring.status == 2 ">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringDetailJoinClassModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}}{{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-join-class" @click = "join_class(tutoring)">{{$t('button_join_class')}}</button>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailJoinClassModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-join-class" @click = "join_class(tutoring)">{{$t('button_join_class')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="class-item blue" v-else-if="tutoring.status == 3">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringRegisterModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}}{{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-register-class disable">{{$t('button_join_class')}}</button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringRegisterModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="tutoringRegisterModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="tutoring-line">
                                <i class="ito-icon-tutoring-teacher"></i>
                                <span class="tutoring-key">{{$t('registration_time')}}</span>
                                <span class="tutoring-value">{{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}</span>
                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-register-class disable " >{{$t('button_join_class')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="class-item blue" v-else-if="tutoring.status == 0 || tutoring.status == 6 ">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringRegisterDisabledModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}}{{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-register-class disable " @click="subscribe(tutoring)">
                        {{$t('register')}}
                    </button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringRegisterDisabledModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="tutoring-line">
                                <i class="ito-icon-tutoring-teacher"></i>
                                <span class="tutoring-key">{{$t('registration_time')}}</span>
                                <span class="tutoring-value">{{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}</span>
                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-register-class disable">{{$t('register')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="class-item blue" v-else-if="tutoring.status == 5">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringDetailFullSlotModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> to <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}} {{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content full" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-full-slot">{{$t('button_full_slot')}}</button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailFullSlotModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key red">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>

                        <div class="d-flex">
                            <div class="tutoring-line">
                                <i class="ito-icon-tutoring-teacher"></i>
                                <span class="tutoring-key">{{$t('registration_time')}}</span>
                                <span class="tutoring-value">{{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}</span>
                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-full-slot btn-class-2">{{$t('button_full_slot')}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="class-item blue" v-else-if="tutoring.status == 1">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringDetailCancelRegistrationModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}} {{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-class-progresing">
                        <countdown :time = "tutoring.time_start" />
                    </button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailCancelRegistrationModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-register-class" @click = "unsubscribeVerify(tutoring)">{{$t('button_cancel_registration')}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailCancelRegistrationVerifyModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="width: 380px; text-align: center;">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title text-center" style="width: 100%; margin-left: 9px;">{{$t('button_cancel_registration')}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div style="margin: 25px auto;">{{$t('cancel_registration_question')}}</div>

                        <div>
                            <button class="btn btn-register-class disable btn-class-2" data-bs-dismiss="modal" style="margin-right: 20px;">{{$t('no')}}</button>
                            <button class="btn btn-register-class btn-class-2" @click="unsubscribe(tutoring)">{{$t('yes')}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="class-item gray" v-else-if="tutoring.status == 7">
            <div class="class-item-content">
                <div class="class-item-col-1">
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringDetailFinishedModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large">
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}} {{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-class-finish">{{$t('Registration timeout')}}</button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailFinishedModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-class-finish-2">{{$t('Registration timeout')}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div   class="class-item gray" v-else-if="tutoring.status == 4 ">
            <div class="class-item-content"  >
                <div class="class-item-col-1" >
                    <h4 data-bs-dismiss="modal" data-bs-toggle="modal" :data-bs-target="'#tutoringDetailFinishedModal'+tutoring.id">
                        [{{tutoring.class_name}}] - {{tutoring.desc}}
                    </h4>

                    <div class="desc large" >
                        <strong>{{tutoring.time_start | convertUnitToTime('HH:mm')}}</strong> {{$t('to')}} <strong>{{tutoring.time_end | convertUnitToTime('HH:mm')}}</strong>
                        <template v-if="tutoring.is_live">
                            - {{$t('live_now')}} <span class="dot"></span>
                        </template>
                    </div>
                    <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    <div class="desc">
                        {{$t('attendees')}} {{tutoring.current_student}}/{{tutoring.max_student}}
                        <div class="progress">
                            <div class="progress-content" v-if="tutoring.current_student < tutoring.max_student" :style="{'width':(100*tutoring.current_student/tutoring.max_student)+'%'}"></div>
                            <div class="progress-content full" v-else style="width: 100%;"></div>
                        </div>
                    </div>
                </div>
                <div class="class-item-col-2">
                    <button class="btn btn-class-finish">{{$t('button_finished')}}</button>

                    <div class="desc">
                        {{$t('from')}} <b>{{tutoring.subscribe_from | convertUnitToTime('HH:mm')}}</b> {{$t('to')}} <b>{{tutoring.subscribe_to | convertUnitToTime('HH:mm')}}</b>, {{tutoring.subscribe_to | convertUnitToTime('DD MMM, YYYY')}}
                    </div>
                </div>
            </div>

            <div class="modal fade modal-tutoring" :id="'tutoringDetailFinishedModal'+tutoring.id" tabindex="-1" role="dialog" aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
                    <div class="modal-content box-shadow-main">
                        <div class="d-flex">
                            <h4 class="title">[{{tutoring.class_name}}] - {{tutoring.desc}}</h4>
                            <div class="close-popup" data-bs-dismiss="modal"></div>
                        </div>

                        <div class="d-flex">
                            <div class="col-1 c-flex">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-teacher"></i>
                                    <span class="tutoring-key">{{$t('teacher')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.teacher}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-time"></i>
                                    <span class="tutoring-key">{{$t('time')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.time_start | convertUnitToTime('HH:mm')}} - {{tutoring.time_end | convertUnitToTime('HH:mm, DD MMM, YYYY')}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-desc"></i>
                                    <span class="tutoring-key">{{$t('description')}}</span>
                                </div>
                            </div>
                            <div class="col-2" style="width: 160px;">
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-grade"></i>
                                    <span class="tutoring-key">{{$t('grade')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.grade}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-subject"></i>
                                    <span class="tutoring-key">{{$t('subject')}}</span>
                                    <span class="tutoring-value"><b>{{$t('class_'+tutoring.subject)}}</b></span>
                                </div>
                                <div class="tutoring-line">
                                    <i class="ito-icon-tutoring-users"></i>
                                    <span class="tutoring-key">{{$t('attendees')}}</span>
                                    <span class="tutoring-value"><b>{{tutoring.current_student}}/{{tutoring.max_student}}</b></span>
                                </div>
                            </div>
                        </div>
                        <div class="tutoring-desc">
                            <div>{{tutoring.desc}}</div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-class-finish-2">{{$t('button_finished')}}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import axios from "axios";
    import {getTutorings, tutoringSubscribe} from "../../models/tutoring";
    import { joinClass } from "../../models/tutoring";
    import { convertSecondToText } from "../../utils/utils";
    import Countdown from '../element/Countdown.vue';
    export default {
        components: { Countdown },
        name: "TutoringItem",
        props: ['tutoring'],
        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }
                return {
                    gem: 0
                }
            })
        },
        data() {
            return {
                register_time_start: "",
            };
        },
        created() {
            this.countdown = this.tutoring.time_end;
        },
        methods: {
            join_class(tutoring) {
                const that = this;
                that.$store.commit("setLoading", true);
                joinClass({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }
                    $('#tutoringDetailJoinClassModal'+tutoring.id).modal("hide");
                    window.open(response.data.results.link, '_blank');
                    that.$emit('updateTutoring', 'join_class', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);
                    console.error(response);
                });
            },

            subscribe(tutoring) {
                const that = this;
                that.$store.commit("setLoading", true);

                tutoringSubscribe({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);
                    if (response.data.results.status == 6){
                        return false;
                    }
                    if (response.data.code != 0) {
                        return;
                    }

                    $('#tutoringRegisterModal' + tutoring.id).modal("hide");

                    that.$emit('updateTutoring', 'subscribe', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            },

            unsubscribeVerify(tutoring) {
                $('#tutoringDetailCancelRegistrationModal'+tutoring.id).modal('hide');
                $('#tutoringDetailCancelRegistrationVerifyModal'+tutoring.id).modal();
            },

            unsubscribe(tutoring) {
                const that = this;

                that.$store.commit("setLoading", true);

                tutoringSubscribe({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    $('#tutoringDetailCancelRegistrationVerifyModal'+tutoring.id).modal('hide');

                    that.$emit('updateTutoring', 'unsubscribe', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            },


        }
    }
</script>

<style scoped>

</style>
