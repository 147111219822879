<template>
    <nav id="nav-bar" class="d-flex justify-content-between align-items-center pe-4">
        <a href="/home" class="logo"></a>
        <div class="d-flex align-items-center">
            <div class="menu-item select-lang me-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#selectLangModal">
                <img class="flag" :src="locate_info.img_flag" alt="" />
            </div>
            <!-- <a href="/faqs" class="ito-btn ito-btn-primary">{{ $t('help') }} <i class="ito-icon ito-help"></i></a> -->
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { getConfigs, getLanguage } from "../utils/utils";

export default {
    name: "NavBarAuth",

    data() {
        const configs = getConfigs();
        const locate = getLanguage();
        const localizes = configs.localize || [];

        let locate_info = {};
        localizes.forEach(item => {
            if (item.lang == locate) {
                locate_info = item;
            }
        });
        return {
            user: { name: 'Guest' },
            locate: getLanguage(),
            locate_info: locate_info,
            configs: configs,
            localizes: localizes,
        };
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },
    mounted() {
        if (this.locate == 'vi') {
            $.datepicker.setDefaults($.datepicker.regional['vi']);
        } else {
            $.datepicker.setDefaults($.datepicker.regional['']);
        }
    },
    created() {
        this.locate = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi';
    },
    methods: {
        setLocate(e) {
            localStorage.setItem('lang', e);
            this.locate = e;
            location.reload();
        }
    }
}
</script>

<style scoped>
</style>
