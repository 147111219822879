import { render, staticRenderFns } from "./ExchangeCollection.vue?vue&type=template&id=3d747ab9&scoped=true"
import script from "./ExchangeCollection.vue?vue&type=script&lang=js"
export * from "./ExchangeCollection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d747ab9",
  null
  
)

export default component.exports