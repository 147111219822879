<template>
    <div id="WelcomeBackModal" class="modal fade " tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content p-0">
                <div class="modal-header border-0">
                    <h4 class="title w-100 text-center">{{ $t('welcome_back') }}</h4>
                    <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                </div>
                <div class="modal-body d-flex flex-column align-items-center gap-3">
                    <div class="image">
                        <img class="img-fluid" src="/themes/ito/images/mascot/isaac-congrats.svg" :alt="$t('welcome_back')">
                    </div>
                    <div class="text">
                        <p>{{ $t('welcome_back_msg') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WelcomeBackModal",
}
</script>

<style scoped>
#WelcomeBackModal .modal-header {
    width: 100%;
    position: relative;
    padding: 30px 30px 0 30px;
}

#WelcomeBackModal .modal-header .modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
}

#WelcomeBackModal .modal-header .title {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

#WelcomeBackModal .modal-body {
    padding: 30px;
}

#WelcomeBackModal .modal-body .gem {
    color: #000;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#WelcomeBackModal .modal-body .text {
    color: #141821;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#WelcomeBackModal .modal-body .gem span,
#WelcomeBackModal .modal-body .text span {
    color: #000;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


#WelcomeBackModal .modal-body .image {
    max-width: 136px;
}

.ito-icon-close:hover {
    filter: brightness(0.9);
}</style>
