<template>
    <div class="ebooks" style="padding-bottom: 0;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12" style="height: 100vh">
                    <iframe :src="ebook_link"
                            frameborder="0" allowfullscreen width="100%" height="100%" referrerpolicy="origin"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {API_LCMS_URL} from "../../constant";
    import axios from "axios";
    import {getEbook, getEbookHome} from "../../models/dashboard";

    export default {
        name: "EbookHome",
      data() {
        return this.initState();
      },
      created() {
        this.fetch();
      },
      methods: {
        fetch(){
          this.getEbooks()
        },
        initState() {
          return {
            ebook_link: '',
          };
        },
        getEbooks() {
          const that = this;
          that.$store.commit("setLoading", true);
          const params = this.$route.query;
          axios.all([
            getEbookHome(params)
          ]).then(axios.spread((ebook) => {
            that.$store.commit("setLoading", false);
            if (ebook.data.code == 0){
              that.ebook_link = ebook.data.results.url || '';
            }
          }));
        },
      }
    }
</script>

<style scoped>
</style>
