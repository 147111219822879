import {get_3} from "../APIGateWay";

export async function LoginAsStudent(params = {}) {
    const res = await get_3(params, '/user/login-as-student')
    const data = res.data.results
    const access_token = data.access_token
    const expires_in = data.expires_in
    document.cookie = `_wid=${access_token}; expires=${expires_in},  path=/`;
    let x = document.cookie;
    return res
}