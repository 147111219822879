<template>
    <nav id="sidebar" class="">
        <ul class="list-unstyled sidebar-menu components mb-5">
            <li :class="menu_active == 'home' ? 'active' : ''">
                <router-link :to="{ name: 'home' }" @click.native="getSoundClick">
                    <i class="ito-icon ito-home"></i>{{ $t('menu_item_home') }}
                </router-link>
            </li>

            <li class="live-class-menu"
                :class="{ active: menu_active == 'live_class', disable: lock_features.includes('live_class') }"
                v-if="user_info.account_live_class == 1">
                <router-link :to="{ name: 'live_class' }" @click.native="getSoundClick">
                    <i class="ito-icon ito-live-class"></i>{{ $t('live_class') }}
                </router-link>
                <div class="live-class-alert" v-if="living_class && is_ism">
                    <div class="live-class-alert-title">{{ $t('class') }}: {{ living_class.class_name }}</div>
                    <div>{{ $t('subject') }}: {{ living_class.subject }}</div>
                    <div><span class="live-class-dot"></span><span class="live-class-text">{{ $t('live_now') }}</span>
                    </div>
                    <div style="margin-top: 8px">
                        <button class="ito-btn ito-btn-block ito-btn-sm ito-btn-success mt-3">{{ $t('join_to_class')
                            }}</button>
                    </div>
                </div>
            </li>

            <li :class="{ active: menu_active == 'achievement', disable: lock_features.includes('mission') }">
                <router-link :to="lock_features.includes('mission') ? '' : { name: 'daily_challenge' }"
                    :class="{ has_noti: (user_info.dailychallenge_noti != 0 || user_info.first_login == 1 || user_info.count_achieve_unread > 0) }"
                    @click.native="getSoundClick">
                    <i class="ito-icon ito-missions"></i>{{ $t('menu_item_achievements') }}
                </router-link>
            </li>

            <li :class="{ active: menu_active == 'events', disable: lock_features.includes('events') }">
                <router-link :to="lock_features.includes('events') ? '' : { name: 'events' }"
                    @click.native="getSoundClick">
                    <i class="ito-icon ito-events"></i>{{ $t('menu_item_events') }}
                </router-link>
            </li>

            <li :class="{ active: menu_active == 'parent', disable: lock_features.includes('parents') }">
                <router-link :to="lock_features.includes('parents') ? '' : { name: 'parent_report' }"
                    @click.native="getSoundClick">
                    <i class="ito-icon ito-parents"></i>{{ $t('menu_item_parents') }}
                </router-link>
            </li>

            <li :class="{ active: menu_active == 'your_space', disable: lock_features.includes('your_space') }">
                <router-link :to="lock_features.includes('your_space') ? '' : { name: 'your_space_trophies' }"
                    :class="{ has_noti: user_info.trophy_unread != 0 }" @click.native="getSoundClick">
                    <i class="ito-icon ito-your-space"></i>{{ $t('menu_item_your_space') }}
                </router-link>
            </li>

        </ul>
    </nav>
</template>

<script>
import axios from 'axios'
import { soundClick } from '../utils/utils'
import { getLiveClass } from '../models/dashboard'

export default {
    name: "SideBar",
    data() {
        const lock_feature = this.$store.state.user_info.lock_feature ? Array.prototype.map.call(this.$store.state.user_info.lock_feature, (x) => x.name) : []
        return {
            menus: [],
            live_classes: [],
            living_class: null,
            is_free_trial: false,
            is_ism: false,
            lock_features: lock_feature
        };
    },

    computed: {
        user_info() {
            return this.$store.state.user_info
        },
        menu_active() {
            return this.$store.state.menu_active
        },
    },

    created() {
        this.fetch();
    },

    updated: function () {
        this.$nextTick(function () {
            //App.init();
        })
    },

    methods: {
        fetch() {

            if (this.user_info.account_type == 1) {
                this.is_free_trial = true
            } else {
                this.is_free_trial = false
            }

            if (this.user_info.student_type == 1) {
                this.is_ism = true
            } else {
                this.is_ism = false
            }

            this.getLiveClasses();
        },

        getLiveClasses(params = {}) {
            const that = this;
            axios.all([getLiveClass(params)]).then(
                axios.spread((lastest_response) => {
                    if (lastest_response.data.code != 0) {
                        return;
                    }

                    that.live_classes = lastest_response.data.results || [];

                    that.$store.commit("setLiveClasses", that.live_classes);

                    if (that.live_classes.length > 0) {
                        that.live_classes.forEach((item) => {
                            if (item.is_live == 1 && that.living_class != null) {
                                that.living_class = item;
                            }
                        });
                    }
                })
            );
        },

        getSoundClick() {
            soundClick();
        },

        onLogout(e) {
            this.$store.dispatch('logout').then(() => {
                // window.location.href = "/register-anomynous";
                window.location.href = "/login";
            });

            e.preventDefault();
        },
    }
}
</script>

<style scoped>
/*sidebar*/

#sidebar {
    position: sticky;
    top: 0;
    width: 100px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background: var(--primary);
}

#sidebar .sidebar-menu {
    position: sticky;
    top: 80px;
    padding-right: 10px;
    width: 110px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

#sidebar ul li {
    width: 110px;
    padding-right: 10px;
    border-radius: 0px 16px 16px 0px;
    min-height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: all ease-out .2s;
}

#sidebar ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.24px;
    color: var(--white);
    transition: all 0.4s;
    overflow: hidden;
}

#sidebar ul li a.has_noti::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #FA450F;
    border: 2px solid #ffffff;
    pointer-events: none;
    bottom: 2px;
    left: 80%;
}

#sidebar ul li.active,
#sidebar ul li:hover {
    background: var(--yellow);
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset;
}

#sidebar ul li.active a,
#sidebar ul li:hover a {
    font-weight: 700;
}

#sidebar ul li.disable::after {
    position: absolute;
    /* top: 0; */
    left: 0;
    content: '';
    z-index: 1;
    width: 100px;
    height: 100px;
    cursor: not-allowed;
    background-image: url('/themes/ito/images/icons/icon-lock-new.svg');
    background-repeat: no-repeat;
    background-position: 34px 25px;
    background-size: 32px 32px;

}

#sidebar ul li.disable,
#sidebar ul li.disable:hover {
    background: none;
    box-shadow: none;
    cursor: not-allowed;
}

#sidebar ul li.disable a,
#sidebar ul li.disable:hover a {
    font-weight: normal;
}

#sidebar ul li.disable a {
    opacity: 0.6;
}


.live-class-menu {
    position: relative;
}

.live-class-alert {
    display: block !important;
    position: absolute;
    top: 0;
    left: 105px;
}

.live-class-alert::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: -12px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
}
</style>