<template>
    <div id="getValidateModal" class="modal fade modal-flex" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="popup-title mb-20">{{$t('error')}}</h3>
                    <p class="txt-popup">{{$t(err_code)}}</p>
                </div>
                <i class="close-popup" data-bs-dismiss="modal"
                   aria-label="Close"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ValidateModal",
        props: ['err_code'],
        methods: {}
    }
</script>

<style scoped>
    #getValidateModal .modal-content {
        padding: 15px;
    }
</style>
