<template>
    <footer class="l-footer l-footer--landing h-bg-cover"
        style="background-image: url('/landing_page/assets/img/bg-footer.jpg');">
        <div class="l-footer--landing__main">
            <div class="container">
                <div class="l-footer--landing__logo text-center"><img src="/landing_page/assets/img/ismart-logo.png"
                        alt="Logo"></div>
                <h4 class="l-footer--landing__title text-center h-title-1">TRƯỜNG TRỰC TUYẾN DẠY TIẾNG ANH THÔNG QUA TOÁN VÀ
                    KHOA HỌC</h4>
                <div class="l-footer--landing__email text-start"
                    style="font-size: 15px; width: 70%; margin: 0 auto; margin-top: 5vh">
                    <div class="single-address__info"><i class="fas fa-map-marker" aria-hidden="true"></i> Địa chỉ
                        : Tòa nhà Center Point, Số 27 Lê Văn Lương, Thanh Xuân, Hà Nội.</div>

                    <div class="single-address__info"><i class="fas fa-phone"></i> Hotline đào tạo:
                        <b>0866 588 482</b>
                    </div>
                    <div class="single-address__info"><i class="fas fa-envelope"></i> E-mail: <b><a
                                href="mailto: studentcare@ismartschool.edu.vn">studentcare@ismartschool.edu.vn</a></b>
                    </div>

                </div>

                <div class="l-footer--landing__social-wrapper text-center">
                    <div class="l-footer--landing__social-title">Kết nối với iSMART Online School</div>
                    <ul class="l-footer__social-icons list-unstyled">

                        <li>
                            <a href="https://twitter.com/iSMARTEducatio1" target="_blank">
                                <img src="/landing_page/assets/img/icons/instagram.png" alt="instagram">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/ismart.ecosystem" target="_blank">
                                <img src="/landing_page/assets/img/icons/facebook.png" alt="facebook">
                            </a>
                        </li>


                        <li>
                            <a href="https://www.youtube.com/user/DigiClassVietnam" target="_blank">
                                <img src="/landing_page/assets/img/icons/youtube.png" alt="">
                            </a>
                        </li>

                        <li>
                            <a href='http://online.gov.vn/Home/WebDetails/83151' style="width: 130px; height: 52px"
                                target="_blank">
                                <img alt='' title='' src="/landing_page/images/logoSaleNoti.png" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="l-footer--landing__copyright text-center">{{$t('copy_right')}}</div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>
@media (min-width: 992px) {
    .l-footer--landing__title {
        font-size: 32px;

    }
}

.l-footer .single-address__info:before {
    background: none;
}
</style>
