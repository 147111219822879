<template>
    <div class="container">
        <div id="resetPassSuccess" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered ito-popup" role="document" data-backdrop="static"
                data-keyboard=false>
                <div class="modal-content">
                    <div class="modal-success">
                        <h4 class="title">{{ $t('pass_reset') }}</h4>

                        <img src="/themes/ito/images/get-rewarded.svg" alt="">

                        <h6>{{ $t('pass_reset_text') }}</h6>

                        <a class="ito-btn ito-btn-primary" href="/login">
                            {{ $t('login') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="sendPassSuccess" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered ito-popup" role="document" data-backdrop="static"
                data-keyboard=false>
                <div class="modal-content">
                    <div class="modal-success">
                        <h4 class="title">{{ $t('title_send_pass') }}</h4>

                        <h6>{{ $t('text_send_pass_1') }} {{ email }}.
                            {{ $t('text_send_pass_2') }}</h6>

                        <a class="ito-btn ito-btn-primary" href="/login">
                            {{ $t('ok') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="ForgotPopup" tabindex="-1" role="dialog" aria-labelledby="ForgotPopup"
            aria-hidden="true" data-backdrop="static" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered ito-popup forgot-popup" role="document">
                <div class="modal-content box-shadow-main">
                    <a class="close-popup" style="z-index: 10" href="/login"></a>
                    <div class="forgot-popup-header">
                        <div class="popup-title ">{{ $t('forgot_password') }}</div>
                        <div v-if="screen == 1"><a href="login"><img width="10px" height="23px" class="img_back"
                                    src="/themes/ito/images/faqs/back.svg" alt=""></a>
                        </div>

                        <div v-if="screen == 2"><a @click="changeScreen(1)"><img width="10px" height="23px" class="img_back"
                                    src="/themes/ito/images/faqs/back.svg" alt=""></a></div>
                        <div v-if="screen == 3"><a @click="changeScreen(2)"><img width="10px" height="23px" class="img_back"
                                    src="/themes/ito/images/faqs/back.svg" alt=""></a></div>
                        <div v-if="screen == 4"><a @click="changeScreen(3)"><img width="10px" height="23px" class="img_back"
                                    src="/themes/ito/images/faqs/back.svg" alt=""></a></div>
                        <div v-if="screen == 11"><a @click="changeScreen(1)"><img width="10px" height="23px"
                                    class="img_back" src="/themes/ito/images/faqs/back.svg" alt=""></a></div>
                        <div v-if="screen == 12"><a @click="changeScreen(11)"><img width="10px" height="23px"
                                    class="img_back" src="/themes/ito/images/faqs/back.svg" alt=""></a></div>

                    </div>


                    <template v-if="screen == 1">
                        <div class="forgot-popup-content">
                            <div class="form-group">
                                <label class="label-input">{{ $t('username') }}</label>
                                <input type="text" class="ito-input input-full-width" name="username"
                                    :placeholder="$t('enter_your_username')" v-model="user_name">
                            </div>
                            <div class="error-message text-start" v-if="errors.user_name">{{ errors.user_name }}</div>
                            <div class="forgot-id-link mt-3">
                                <a @click="forgetID">{{ $t('forgot_username') }}</a>
                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>

                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button class="ito-btn ito-btn-primary" :disabled="user_name == ''"
                                    @click="user_name != '' ? getAccount() : ''">{{ $t('next') }}</button>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen == 2">
                        <div class="forgot-popup-content">
                            <p>{{ $t('account') }}<b>{{ user_name ? user_name : '' }}</b>
                                {{ $t('registered_with_the_information') }}</p>
                            <p v-if="email">{{ $t('email') }}<span class="txt-blue">{{ hideEmail(email) }} </span></p>

                            <p v-if="phone">{{ $t('phone') }}<span class="txt-blue">{{ hidePhone(phone) }}</span></p>
                            <div class="form-group">
                                <p>{{ $t('choose_pass_rs') }}</p>
                                <input type="radio" id="email" name="fav_language" value=0 v-model="type" v-if="email">
                                <label class="ms-3" for="email">{{ $t('email') }}</label><br>
                                <div class="form-group" style="margin-bottom: 10px" v-if="type == 0 && type != ''">
                                    <label class="label-input">{{ $t('email') }}</label>
                                    <input type="text" class="ito-input input-full-width"
                                        :class="errors.email ? 'has-err' : ''" name="re_email"
                                        :placeholder="$t('re_enter_email')" v-model="re_email" autocomplete="off">
                                    <div class="error-message text-start" v-if="errors.email">{{ errors.email }}</div>
                                </div>
                                <input type="radio" id="phone" name="fav_language" value=1 v-model="type" v-if="phone">
                                <label class="ms-3" for="phone" v-if="phone">{{ $t('phone') }}</label><br>
                                <div v-if="type == 1 && type != ''"
                                    v-html="$t('forget_password_sms', { username: user_name })"></div>
                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>
                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button type="button" class="ito-btn ito-btn-primary"
                                    :disabled="type == '' && email == '' && re_email == ''" @click="getOtp()">
                                    {{ $t('next') }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen == 3">
                        <div class="forgot-popup-content">
                            <p>{{ $t('sent_otp') }} <b class="txt-blue">{{ email }}</b>
                                {{ $t('confirm_otp') }}</p>
                            <div class="form-group">
                                <label class="label-input">{{ $t('otp_code') }}</label>
                                <input type="text" class="ito-input input-full-width" name="otp"
                                    :placeholder="$t('input_otp')" v-model="otp" autocomplete="off">
                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>
                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button class="ito-btn ito-btn-primary" :disabled="otp == ''" @click="confirmOTP()">{{
                                    $t('next') }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen == 4">
                        <div class="forgot-popup-content">
                            <p>{{ $t('cf_change_password') }}</p>
                            <div class="form-group">
                                <label class="label-input">{{ $t('new_password') }}</label>
                                <input type="password" class="ito-input input-full-width" name="password"
                                    :placeholder="$t('placeholder_new_password')" v-model="password" autocomplete="off">
                            </div>
                            <div class="form-group">
                                <label class="label-input">{{ $t('confirm_password') }}</label>
                                <input type="password" class="ito-input input-full-width" name="cf_password"
                                    :placeholder="$t('placeholder_confirm_password')" v-model="cf_password"
                                    autocomplete="off">
                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>
                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button type="button" class="ito-btn ito-btn-primary"
                                    :disabled="password == '' && cf_password == ''" @click="resetNewPassword()">{{
                                        $t('next') }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen == 11">
                        <div class="forgot-popup-content">
                            <div class="form-group">
                                <label class="label-input">{{ $t('full_name') }}</label>
                                <input type="text" class="ito-input input-full-width" name="fullname"
                                    :placeholder="$t('enter_your_full_name')" v-model="fullname" autocomplete="off">
                            </div>
                            <div class="form-group">
                                <label class="label-input">{{ $t('date_of_birth') }}</label>
                                <input type="text" class="ito-input input-full-width date datepicker birthday"
                                    name="birthday" autocomplete="off" placeholder="dd/mm/yyyy" v-model="birthday">
                            </div>
                            <div class="form-group">
                                <p class="error-message" v-if="errors.list_accounts">{{ errors.list_accounts }}</p>

                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>

                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button type="button" class="ito-btn ito-btn-primary"
                                    :disabled="fullname == '' && birthday == ''" @click="getListForgetAcc()">{{ $t('next')
                                    }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-if="screen == 12">
                        <div class="forgot-popup-content">
                            <p>{{ $t('system_found') }} <b class="txt-blue">{{ list_accounts ? list_accounts.length : '0'
                            }}</b> {{ $t('student') }}</p>
                            <div class="h-75 overflow-auto">
                                <div class="account-item" v-for="(acc, index) in list_accounts">
                                    <input type="radio" :id="'account_' + index" name="radio" :value="acc"
                                        v-model="account">
                                    <label :for="'account_' + index">
                                        <div>{{ fullname }} ({{ birthday }})</div>
                                        <div>Email: <b class="txt-blue">{{ hideEmail(acc.email) }}</b></div>
                                        <div>Điện thoại: <b class="txt-blue">{{ acc.phone ? hidePhone(acc.phone) : '' }}</b>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <a href="#" class="support-link-bottom">{{ $t('contact_hotline') }} <br /><b>1900 088 893 (Hà
                                    Nội)
                                    - 1900 088 893 (TP. Hồ Chí Minh)</b></a>
                        </div>
                        <div class="forgot-popup-footer">
                            <div class="form-group d-flex justify-content-center gap-3">
                                <a class="ito-btn ito-btn-default" href="/login">{{ $t('cancel') }}</a>
                                <button type="button" class="ito-btn ito-btn-primary" :disabled="account == ''"
                                    @click="listAccoutToForgot()">
                                    {{ $t('next') }}
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getForgetAccount,
    getListForgetAccount,
    confirmForget,
    confirmOtp,
    resetPassword
} from '../../models/auth'
import axios from "axios";
import { showDialog } from '../../utils/dialog'
import { mapGetters } from 'vuex'

export default {
    name: "ForgotPassword",
    data() {
        return this.initState();
    },

    created() {
        // this.fetch()
    },
    mounted() {
        $('#ForgotPopup').modal('show');

    },
    methods: {

        initState() {
            return {
                errors: {
                    user_name: '',
                    email: '',
                    list_accounts: ''
                },
                user_name: '',
                user_id: '',
                phone: '',
                email: '',
                re_email: '',
                fullname: '',
                birthday: '',
                otp: '',
                password: '',
                cf_password: '',
                screen: 1,
                list_accounts: [],
                type: '',
                account: '',
                // errors: [],
            };
        },
        // fetch() {
        // },
        forgetID() {
            this.user_name = '';
            this.user_id = '';
            this.fullname = '';
            this.phone = '';
            this.email = '';
            this.re_email = '';
            this.screen = 11;
            this.$nextTick(function () {
                this.initDatepicker();
            })
        },
        initDatepicker() {
            $('.birthday').datepicker({
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: false,
                yearRange: '1900:+0',
            }).on('change', (e) => {
                this.searchDate(e);
            });
        },

        getAccount() {
            const that = this;
            that.isLoading = true;
            that.errors.user_name = '';
            const params = {
                username: that.user_name,
            };
            axios.all([
                getForgetAccount(params),
            ]).then(axios.spread((user_response) => {
                that.isLoading = false;
                if (user_response.data.code == 0) {
                    if (user_response.data.results) {
                        that.user_id = user_response.data.results.id;
                        that.email = user_response.data.results.email;
                        that.phone = user_response.data.results.phone;
                        that.changeScreen(2);
                    } else {
                        that.errors.user_name = this.$i18n.t('username_not_exist');
                    }
                } else {
                    that.errors.user_name = user_response.data.msg;
                }
            }));
        },
        getListForgetAcc() {
            const that = this;
            const params = {
                fullname: that.fullname,
                birthday: moment(that.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
            };
            that.$store.commit("setLoading", true);

            getListForgetAccount(params).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    alert(response.data.msg)
                    return;
                }
                if (response.data.results) {
                    that.list_accounts = response.data.results.account || [];
                    that.changeScreen(12)
                } else {
                    that.errors.list_accounts = this.$i18n.t('student_not_found_please_check_again')
                }
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        listAccoutToForgot() {
            this.user_name = this.account.username;
            this.email = this.account.email;
            this.phone = this.account.phone;
            this.fullname = this.account.fullname;
            this.re_email = '';
            this.changeScreen(2);
        },
        getOtp() {
            const that = this;
            var emailLower = that.email.toLowerCase();
            var re_emailLower = that.re_email.toLowerCase();
            if (emailLower != re_emailLower) {
                that.errors.email = this.$i18n.t('email_does_not_match');
                return false;
            }
            that.errors.email = '';
            const formData = new FormData();
            formData.append('username', that.user_name);
            formData.append('type', that.type);
            formData.append('email', that.type == 0 ? that.email : '');
            formData.append('phone', that.type == 1 ? that.phone : '');
            that.$store.commit("setLoading", true);
            confirmForget(formData).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    alert(response.data.msg)
                    return;
                }
                $('#ForgotPopup').modal('hide');
                $('#sendPassSuccess').modal('show');
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        confirmOTP() {
            const that = this;
            const formData = new FormData();

            formData.append('username', that.user_name);
            formData.append('code', that.otp);

            that.$store.commit("setLoading", true);

            confirmOtp(formData).then((response) => {
                that.$store.commit("setLoading", false);

                if (response.data.code != 0) {
                    alert(response.data.msg)

                    return;
                }
                that.changeScreen(4)
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        resetNewPassword() {
            const that = this;
            const formData = new FormData();

            formData.append('username', that.user_name)
            formData.append('new_pass', that.password);
            formData.append('confirm_pass', that.cf_password);

            that.$store.commit("setLoading", true);

            resetPassword(formData).then((response) => {
                that.$store.commit("setLoading", false);

                if (response.data.code != 0) {
                    alert(response.data.msg)

                    return;
                }
                $('#ForgotPopup').modal('hide');
                $('#resetPassSuccess').modal('show');
                // that.changeScreen(4)
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response)
            });
        },
        searchDate({ target }) {
            this.birthday = target.value;
        },
        searchEvent({ target }) {
            this.user[target.name] = target.value;
        },
        changeScreen($screen) {
            this.screen = $screen;
            if ($screen == 11) {
                this.$nextTick(function () {
                    this.initDatepicker();
                })
            }
        },
        isNumeric(value) {
            return /^\d+$/.test(value);
        },
        validateEmail(value) {
            let is_valid = false;
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                is_valid = true;
            }
            return is_valid;
        },
        hideEmail(email) {
            if (email == '') {
                return ''
            }
            const partialEmail = email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
            return partialEmail;
        },
        hidePhone(phone) {
            if (phone == '') {
                return ''
            }
            return phone.replace(/(\d{2})(.*)(\d{3})/, '$1*****$3')
        }
    }
}
</script>

<style scoped>
.forgot-popup {
    width: 550px;
    max-width: 550px;
}

.forgot-popup .modal-content {
    position: relative;
    height: 470px;
    padding: 0px;
}

.forgot-popup-header {
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 0;
    flex-direction: row-reverse;
    display: flex;
}

.forgot-popup-content {
    position: relative;
    height: 320px;
    padding: 20px 50px;
}

.forgot-popup-footer {
    border-top: 1px solid #E5E5E5;
    padding: 20px 0;
}


.input-full-width {
    width: 100%;
}

.forgot-popup-footer .ito-btn-small {
    margin: 0 10px;
}

.support-link-bottom {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.support-link-bottom b {
    font-weight: 700;
}

.forgot-id-link {
    color: #1F9FFC;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.forgot-popup-content p {
    font-size: 14px;
    margin-bottom: 5px;
}

label {
    font-size: 14px;
    font-weight: 400;
}

.txt-blue {
    color: #1F9FFC;
}

.account-item {
    margin: 6px 0px;
    display: flex;
    align-items: center;
}

.account-item input {
    margin-right: 15px;
}

.account-item label {
    margin-bottom: 0;
}

.modal-success {
    text-align: center;
}

.modal-success .title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.modal-success h6 {
    margin: 15px 0px;
    font-size: 14px;
    line-height: 19px;
}

.img_back {
    /*float: right;*/
    /*margin-right: 137px;*/
    margin-left: 14px;

}

.popup-title {
    margin-bottom: 0;
    /*float: right;*/
    /*margin-right: 217px;*/
}
</style>
