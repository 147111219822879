import axios from 'axios'

export async function get(params, url) {
    const bodyFormData = getFormData(params)
    const token = getCookie('_wid')
    const res = await axios.get(process.env.VUE_APP_API_URL + url,
        {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return res
}

export async function get_1(params, url) {
    const bodyFormData = getFormData(params)
    const token = getCookie('_wid')
    const res = await axios.get(url,
        {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return res
}
export async function get_2(params, url) {
    const res = await axios.get(url,
        {
            params,
            headers: {
                Authorization: `Plain 1`,
            }
        }
    )
    return res
}
export async function get_3(params, url) {
    const username = params.username
    const target = process.env.VUE_APP_API_URL + url + '/' + username
    const token = getCookie('_wid')
    const res = await axios.get(target,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return res
}

export async function post(params, url) {
    let data
    if (params instanceof FormData) {
        data = params
    } else {
        data = getFormData(params)
    }
    const token = getCookie('_wid')
    const res = await axios.post(process.env.VUE_APP_API_URL + url, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return res
}

export async function post_1(params, url) {
    let data
    if (params instanceof FormData) {
        data = params
    } else {
        data = getFormData(params)
    }
    const token = getCookie('_wid')
    const res = await axios.post(url, data);
    return res
}

// export function authHeader() {
//     const token = getCookie('_wid')
//     return {
//         Authorization: `Bearer ${token}`,
//     };
// }
function getCookie(key) {
    const cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((accumulator, [key, value]) =>
            ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {})
    return cookies[key]
}
function getFormData(params) {
    var bodyFormData = new FormData();
    Object.keys(params).forEach((key) => {
        bodyFormData.append(key, params[key])
    })
    return bodyFormData
}

export async function vietqr(params) {
    var data =
    {
        "accountNo": '2021226666', // Số tài khoản nhận tiền
        "accountName": "CÔNG TY CỔ PHẦN GIÁO DỤC ISMART", //tên hiển thị (không bắt buộc)
        "acqId": '970416', //mã ngân hàng nhận tiền
        "amount": params.amount, //số tiền
        "addInfo": params.addInfo, //Nội dung chuyển khoản
        "template": "Knhawiz" //Loại mã [compact,compact2,qr_only,print]
    }
    const res = await axios.post('https://api.vietqr.io/v2/generate', data, {
        headers: {
            'x-client-id': '0e552207-d0f9-4f4a-bc12-5737ca86b8da', //client id lấy trong tài khoản https://my.vietqr.io/profile
            'x-api-key': '1be98bbe-9c6e-4e7a-ba87-353db23c4273', //api key lấy trong tài khoản https://my.vietqr.io/profile
            'Content-Type': 'application/json'
        },

    });
    return res
}
//     {
//         "code": "ICB",
//         "bin": "970415"
//     },
//     {
//         "code": "VCB",
//         "bin": "970436"
//     },
//     {
//         "code": "BIDV",
//         "bin": "970418"
//     },
//     {
//         "code": "VBA",
//         "bin": "970405"
//     },
//     {
//         "code": "OCB",
//         "bin": "970448"
//     },
//     {
//         "code": "MB",
//         "bin": "970422"
//     },
//     {
//         "code": "TCB",
//         "bin": "970407"
//     },
//     {
//         "code": "ACB",
//         "bin": "970416"
//     },
//     {
//         "code": "VPB",
//         "bin": "970432"
//     },
//     {
//         "code": "TPB",
//         "bin": "970423"
//     },
//     {
//         "code": "STB",
//         "bin": "970403"
//     },
//     {
//         "code": "HDB",
//         "bin": "970437"
//     },
//     {
//         "code": "VCCB",
//         "bin": "970454"
//     },
//     {
//         "code": "SCB",
//         "bin": "970429"
//     },
//     {
//         "code": "VIB",
//         "bin": "970441"
//     },
//     {
//         "code": "SHB",
//         "bin": "970443"
//     },
//     {
//         "code": "EIB",
//         "bin": "970431"
//     },
//     {
//         "code": "MSB",
//         "bin": "970426"
//     },
//     {
//         "code": "CAKE",
//         "bin": "546034"
//     },
//     {
//         "code": "Ubank",
//         "bin": "546035"
//     },
//     {
//         "code": "TIMO",
//         "bin": "963388"
//     },
//     {
//         "code": "VTLMONEY",
//         "bin": "971005"
//     },
//     {
//         "code": "VNPTMONEY",
//         "bin": "971011"
//     },
//     {
//         "code": "SGICB",
//         "bin": "970400"
//     },
//     {
//         "code": "BAB",
//         "bin": "970409"
//     },
//     {
//         "code": "PVCB",
//         "bin": "970412"
//     },
//     {
//         "code": "Oceanbank",
//         "bin": "970414"
//     },
//     {
//         "code": "NCB",
//         "bin": "970419"
//     },
//     {
//         "code": "SHBVN",
//         "bin": "970424"
//     },
//     {
//         "code": "ABB",
//         "bin": "970425"
//     },
//     {
//         "code": "VAB",
//         "bin": "970427"
//     },
//     {
//         "code": "NAB",
//         "bin": "970428"
//     },
//     {
//         "code": "PGB",
//         "bin": "970430"
//     },
//     {
//         "code": "VIETBANK",
//         "bin": "970433"
//     },
//     {
//         "code": "BVB",
//         "bin": "970438"
//     },
//     {
//         "code": "SEAB",
//         "bin": "970440"
//     },
//     {
//         "code": "COOPBANK",
//         "bin": "970446"
//     },
//     {
//         "code": "LPB",
//         "bin": "970449"
//     },
//     {
//         "code": "KLB",
//         "bin": "970452"
//     },
//     {
//         "code": "KBank",
//         "bin": "668888"
//     },
//     {
//         "code": "UOB",
//         "bin": "970458"
//     },
//     {
//         "code": "SCVN",
//         "bin": "970410"
//     },
//     {
//         "code": "PBVN",
//         "bin": "970439"
//     },
//     {
//         "code": "NHB HN",
//         "bin": "801011"
//     },
//     {
//         "code": "IVB",
//         "bin": "970434"
//     },
//     {
//         "code": "IBK - HCM",
//         "bin": "970456"
//     },
//     {
//         "code": "IBK - HN",
//         "bin": "970455"
//     },
//     {
//         "code": "VRB",
//         "bin": "970421"
//     },
//     {
//         "code": "WVN",
//         "bin": "970457"
//     },
//     {
//         "code": "KBHN",
//         "bin": "970462"
//     },
//     {
//         "code": "KBHCM",
//         "bin": "970463"
//     },
//     {
//         "code": "HSBC",
//         "bin": "458761"
//     },
//     {
//         "code": "HLBVN",
//         "bin": "970442"
//     },
//     {
//         "code": "GPB",
//         "bin": "970408"
//     },
//     {
//         "code": "DOB",
//         "bin": "970406"
//     },
//     {
//         "code": "DBS",
//         "bin": "796500"
//     },
//     {
//         "code": "CIMB",
//         "bin": "422589"
//     },
//     {
//         "code": "CBB",
//         "bin": "970444"
//     },
//     {
//         "code": "CITIBANK",
//         "bin": "533948"
//     },
//     {
//         "code": "KEBHANAHCM",
//         "bin": "970466"
//     },
//     {
//         "code": "KEBHANAHN",
//         "bin": "970467"
//     },
//     {
//         "code": "MAFC",
//         "bin": "977777"
//     },
//     {
//         "code": "VBSP",
//         "bin": "999888"
//     }
