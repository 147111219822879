<template>
  <div class="modal fade" id="upgradeAccountModal" tabindex="-1" role="dialog" aria-labelledby="UpgradeAccountPopup"
       aria-hidden="true" data-backdrop="static" data-keyboard=false>
    <div class="modal-dialog modal-dialog-centered ito-popup-large" role="document">
      <div class="modal-content box-shadow-main">
        <div class="close-popup" data-bs-dismiss="modal"></div>
        <h3 class="popup-title mb-20">{{ $t('unclock_full_access') }}</h3>
        <div class="select-upgrade-type d-flex mb-20">
          <div class="package package-1 box-shadow-main">
            <div class="package-title">PLUS ACCOUNT</div>
            <div class="package-price">75,000 {{ $t('vnd_per_month') }}</div>
            <div class="package-content">
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-1.svg">
                <div class="package-items-text">{{ $t('plus_account_1') }}</div>
              </div>
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-1.svg">
                <div class="package-items-text">{{ $t('plus_account_2') }}
                </div>
              </div>
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-1.svg">
                <div class="package-items-text">{{ $t('plus_account_3') }}</div>
              </div>
            </div>
          </div>
          <div class="package package-2 box-shadow-main">
            <div class="package-title">PRO ACCOUNT</div>
            <div class="package-price">199,000 {{ $t('vnd_per_month') }}</div>
            <div class="package-content package-content-2">
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-2.svg">
                <div class="package-items-text">{{ $t('pro_account_1') }}</div>
              </div>
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-2.svg">
                <div class="package-items-text">{{ $t('pro_account_2') }}
                </div>
              </div>
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-2.svg">
                <div class="package-items-text">{{ $t('pro_account_3') }}</div>
              </div>
              <div class="package-items d-flex">
                <img src="/themes/ito/images/tick-2.svg">
                <div class="package-items-text">{{ $t('pro_account_4') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <div class="ito-btn-large">{{ $t('upgrade_account') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpgradeAccountModal"
}
</script>

<style scoped>

</style>
