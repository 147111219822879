<template>
    <modal-dialog ref="popup">
        <div class="reward-modal">
            <h2>{{ title }}</h2>
            <div class="extension-result" v-if="message">
                <div class="text-center score">
                    <span>{{ $t('score') }}: </span>
                    <strong>{{ message.quest_correct }}/{{ message.total_quest }}</strong>
                </div>
                <div class="time">
                    <span>{{ $t('time_spent') }}: </span>
                    <strong>{{ secondsToHms(message.total_time) }}</strong>
                </div>
            </div>
            <div class="btns">
                <button class="ito-btn ito-btn-primary" @click="_cancel" v-if="cancelButton">{{ cancelButton }}</button>
                <button class="ito-btn ito-btn-primary" @click="_confirm" v-if="okButton">{{ okButton }}</button>
            </div>
        </div>
    </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
export default {
    name: "ExtensionResultDialog",
    components: { ModalDialog },
    data: () => ({
        closeImg: '/images/close_btn.png',
        title: undefined,
        message: undefined,
        okButton: undefined,
        cancelButton: 'Go Back',

        resolvePromise: undefined,
        rejectPromise: undefined,
    }),
    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.$refs.popup.open()
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
        },

        secondsToHms(d) {
            d = Number(d);
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let mDisplay = m > 0 ? (m < 10 ? '0' + m : m) : "00";
            let sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : "";
            return mDisplay + ':' + sDisplay;
        }
    },
}
</script>

<style scoped>
.reward-modal {
    padding: 20px 60px;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}

.btns {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 15px;
}

.extension-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #FF7800;
    box-shadow: 0px 4px 0px 0px rgba(231, 231, 231, 0.35) inset;
    filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.07));
    padding: 21px;
    border-radius: 15px;
    color: #fff;
}
</style>