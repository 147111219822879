<template>
    <div id="landing-page">
      <div id="tablet">
        <img class="tablet-logo" src="/themes/ito/images/logo-mobile.svg" alt="">

        <img class="tablet-desktop" src="/themes/ito/images/mobile-desktop.svg" alt="">

        <div class="tablet-desktop-desc-1">Hãy mở rộng trình duyệt để tiếp tục sử dụng!</div>
        <div class="tablet-desktop-desc-2">Hoặc</div>

        <div class="tablet-bottom">
          <h3 class="tablet-title">Tải Ứng dụng</h3>
          <div class="tablet-desc">Ứng dụng tương thích trên mọi điện thoại thông minh và máy tính bảng, giúp học
            viên có thể học tập mọi lúc mọi nơi.
          </div>
          <div class="tablet-buttons">
            <a href="https://apps.apple.com/vn/app/ismart-tutoring-online/id1587460616" target="_blank">
              <img class="tablet-button-android" src="/themes/ito/images/appstore.svg" alt="">
            </a>
            <a href="https://play.google.com/store/apps/details?id=ismart.edu.vn.ito&pli=1" target="_blank">
              <img class="tablet-button-ios" src="/themes/ito/images/googleplay.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>

  export default {
    name: "LandingPageLayout",

    data() {
      return {};
    },

    computed: {
      isLoading() {
        return this.$store.getters.isLoading
      },
      user_info() {
        return this.$store.state.user_info;
      }
    },

    methods: {
      fetch() {

      },
    }
  }
  </script>

  <style scoped>
  #landing-page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 40px 94px 65px 94px;
    background: url(/public/themes/ito/images/mobile-bg-1.png) no-repeat;
  }

  #tablet {
    display: block;
  }

  #mobile {
    display: none !important;
  }

  .tablet-logo {
    width: 175px;
    height: 95px;
    margin: 0 auto;
    display: block;
  }

  .tablet-desktop {
    width: 262px;
    height: 114px;
    margin: 42px auto 18px auto;
    display: block;
  }

  .tablet-desktop-desc-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #070A28;
    text-align: center;
  }

  .tablet-desktop-desc-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #070A28;
    margin-top: 60px;
    text-align: center;
  }

  h3.tablet-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #070A28;
    margin-top: 48px;
  }

  .tablet-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: rgba(7, 10, 40, 0.8);
    margin: 18px auto 12px auto;
  }

  .tablet-buttons {
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tablet-button-android {
    display: inline-block;
  }

  .tablet-button-ios {
    display: inline-block;
  }

  .tablet-bottom {
    text-align: center;
  }
  </style>