<template>
    <div class="faqs" style="padding-bottom: 0;">
        <div class="container">
            <div class="row">
                <div class="col-md-12" style="height: calc(100vh - 170px);">
                    <div class="nano">
                        <div class="nano-content" style="height: calc(100vh - 170px)">
                            <h1 class="text-center">{{$t('how_can_help_you')}}</h1>
                            <div class="d-flex align-items-center search-header">
                                <div class="button-search"></div>
                                <input type="text" class="search-main faq-search" :placeholder="$t('search')" autocomplete="off"/>
                            </div>
                            <br/>
                            <div class="card card-parents card-faq">
                                <div class="card-body">
                                    <h5 class="card-title">
                                        <!--<span class="title"><img src="/themes/ito/images/faqs/all.svg"/>{{$t('all_of_topics')}}</span>-->
                                        <span class="title">{{$t('all_of_topics')}}</span>
                                    </h5>
                                    <div class="card-content faq-content">
                                        <div class="list-faq-control">
                                            <template v-for="(f, i) in faqs">
                                                <div class="faq-control" :class="currentTab === f.type?'active':''" v-on:click="choose(f.type)">
                                                    <img v-if="i == 0" src="/themes/ito/images/faqs/faq_1.svg"/>
                                                    <img v-if="i == 1" src="/themes/ito/images/faqs/faq_2.svg"/>
                                                    <img v-if="i == 2" src="/themes/ito/images/faqs/faq_3.svg"/>
                                                    <img v-if="i == 3" src="/themes/ito/images/faqs/faq_4.svg"/>
                                                    <div class="line-faq"></div>
                                                    <div class="faq-type">{{ f.name }}</div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div class="card card-parents card-faq">
                                <div class="card-body">
                                    <!--<h5 class="card-title">-->
                                        <!--<span class="title"><img src="/themes/ito/images/faqs/faq-list.svg"/>{{$t('frequently_asked_questions')}}</span>-->
                                    <!--</h5>-->
                                    <template v-for="(fitem, i) in faqs">
                                        <template v-if="fitem.questions">
                                            <div class="card-content faq-content" v-show="currentTab === fitem.type || currentTab === ''">
                                                <div class="lb_accordeon" id="lb_accordeon">
                                                    <ul class="lb_accordeon__item">
                                                        <li v-for="(topic, index) in fitem.questions" class="lb_item"
                                                            v-on:click="faqTrigger(topic.id)"
                                                            v-bind:class="{'lb_ext-active': (currentFaq === topic.id)}">
                                                            <!--<li v-for="(item, index) in faq" class="lb_item" v-on:click="faqTrigger(index)"-->
                                                            <!--v-bind:class="{'lb_ext-active': (currentFaq === index)}">-->
                                                            <div class="lb_item__hl">
                                                                <div class="accordeon-title">
                                                                    <div class="left-accord">
                                                                        <img src="/themes/ito/images/faqs/faq_1.svg"/>
                                                                        <span v-html="topic.name"></span>
                                                                    </div>
                                                                    <div class="right-accord">
                                                                        <div class="button-accord"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition mode="out-in" name="faq-fade"
                                                                        v-on:before-enter="beforeEnterFaq"
                                                                        v-on:enter="enterFaq">
                                                                <div class="lb_item__copy" v-show="currentFaq === topic.id">
                                                                    <div class="lb_item-copy-ct" v-html="topic.content">
                                                                    </div>
                                                                </div>
                                                            </transition>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import axios from "axios";
    import {soundClick, getConfigs, getLanguage, soundGetReward, soundClaimTreasure} from '../../utils/utils';
    import {getFaqs} from '../../models/faq';

    export default {
        name: "Faq",

        props: ['user'],
        data: function () {
            const lang = localStorage.getItem('lang');
            return {
                faqs: [],
                currentFaq: -10,
                currentTab: '',
                lang: lang
            }
        },
        created() {
            this.fetch();
        },

        methods: {
            fetch() {
                const that = this;
                const params = {
                    lang: that.lang,
                };
                axios.all([
                    getFaqs(params),
                ]).then(axios.spread((response) => {
                    that.isLoading = false;
                    if (response.data.code != 0) {
                        return;
                    }
                    that.faqs = response.data.results.topics;
                    that.currentTab = that.faqs[0].type;

                    that.$nextTick(function() {
                        initNanoScroller();
                    });
                }));
            },
            choose(type) {
                this.currentTab = type;
            },
            faqTrigger: function (newFaq) {
                if (newFaq === this.currentFaq) {
                    this.currentFaq = -10
                } else {
                    this.currentFaq = newFaq
                }
            },
            beforeEnterFaq: function (_t) {
                _t.style.display = 'block'
                _t.style.maxHeight = null
                _t.myHeight = _t.offsetHeight
                _t.style.maxHeight = 0
                _t.style.display = null
            },
            enterFaq: function (_t) {
                _t.style.maxHeight = _t.myHeight + 'px'
            },
            getSoundClick() {
                soundClick();
            },

            getSoundGetReward() {
                soundGetReward();
            },
            getSoundClaimTreasure() {
                soundClaimTreasure();
            }
        }
    }
</script>

<style scoped>
    .faqs {
        padding: 30px;
    }

    .faq-search {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
        padding-left: 48px;
        font-weight: 400;
        line-height: 38px;
        color: rgba(0, 0, 0, 0.3);
    }

    .button-search {
        left: 15px;
    }

    .card > .card-body > .card-title {
        background: #ffffff;
        border-bottom: 1px solid #EEEEEE;
    }

    .card-title .title {
        display: flex;
        align-items: center;
    }

    .card-title .title img {
        margin-right: 10px;
    }

    .faq-content {
        padding: 20px;
    }

    .list-faq-control {
        display: flex;
        justify-content: center
    }

    .faq-control {
        width: 180px;
        height: 120px;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
        padding: 20px 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 15px;
        cursor: pointer;
    }

    .line-faq {
        width: 100px;
        height: 1px;
        background: #979797;
    }

    .faq-control img {
        height: 50px;
    }

    .faq-type {
        color: #E98437;
    }

    .faq-control:hover {
        border: 1px solid #E98437;
    }

    .faq-control:hover .line-faq {
        background: #E98437;
    }

    .faq-control.active {
        background: #E98437;
    }

    .faq-control.active .faq-type {
        color: #FFFFFF;
    }

    .faq-control.active .line-faq {
        background: #FFFFFF;
    }

    /*accordion*/
    @-webkit-keyframes accordeonAni {
        0% {
            max-height: 0;
        }
    }

    @keyframes accordeonAni {
        0% {
            max-height: 0;
        }
    }

    .lb_accordeon .lb_item {
        display: block;
        margin-bottom: 20px;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
        overflow: hidden;
    }

    .lb_accordeon .lb_item__hl {
        position: relative;
        padding: 12px;
        cursor: pointer;
        transition: color 200ms;
        background: #ffffff;
    }

    .accordeon-title img {
        height: 25px;
        margin-right: 15px;
    }

    .lb_accordeon .lb_item__hl:hover {
        color: #666;
    }

    .lb_accordeon .lb_item__copy {
        background: #ddd;
        margin: 0;
        padding: 0;
    }

    .lb_accordeon .lb_item .lb_item-copy-ct {
        padding: 20px;
    }

    .faq-fade-enter {
        max-height: 0;
        display: block;
    }

    .faq-fade-enter-active {
        -webkit-animation: accordeonAni 400ms ease-out;
        animation: accordeonAni 400ms ease-out;
        overflow: hidden;
    }

    .faq-fade-leave {
        display: block;
    }

    .faq-fade-leave-to {
        display: block;
    }

    .faq-fade-leave-active {
        -webkit-animation: accordeonAni 400ms ease-out;
        animation: accordeonAni 400ms ease-out;
        animation-direction: reverse;
        overflow: hidden;
    }

    .button-accord {
        position: relative;
        width: 14px;
        height: 14px;
    }

    .button-accord:before, .button-accord:after {
        content: "";
        position: absolute;
        background-color: #000000;
        transition: transform 0.25s ease-out;
        /* Vertical line */
    }

    .button-accord:before {
        top: 0;
        left: 7px;
        width: 2px;
        height: 14px;
        margin-left: -1px;
    }

    .button-accord:after {
        top: 7px;
        left: 0;
        width: 14px;
        height: 2px;
        margin-top: -1px;
    }

    .button-accord:hover {
        cursor: pointer;
    }

    .lb_item.lb_ext-active .button-accord:before {
        transform: rotate(90deg);
    }

    .lb_item.lb_ext-active .button-accord:after {
        transform: rotate(180deg);
    }

    .lb_accordeon .lb_item.lb_ext-active {
        border: 1px solid #EC6E43;
    }

    .lb_item.lb_ext-active .left-accord span {
        color: #EC6E43;
    }

    .lb_item.lb_ext-active .button-accord:before, .lb_item.lb_ext-active .button-accord:after {
        background-color: #EC6E43;
    }

    .accordeon-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    ol, ul {
        padding-left: 0rem;
    }
</style>
