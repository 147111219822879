<template>
  <div id="content">
    <div class="container">

      <div id="notifications" class="card">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('inbox') }}</span>
          </h5>
          <div class="card-content">
            <template v-if="isLoading == false">
              <div class="notification-list" v-if="inboxes.length > 0">
                <div :class="inbox.status == 1 ? 'notification-item is-read' : 'notification-item'"
                  v-for="(inbox, index) in inboxes">
                  <div class="title">
                    <h4>{{ inbox.title }}</h4>
                    <span class="time">{{ inbox.time | convertUnitToTime }}</span>
                  </div>
                  <div class="desc">
                    {{ inbox.content }}
                  </div>

                  <div class="tools" v-if="inbox.status != 1">
                    <button class="btn btn-default btn-notification-open" @click="readInbox(inbox)">
                      {{ $t('open') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="notification-list" v-else>
                <div class="notification-list-empty">
                  <div class="message">
                    {{ $t('no_inbox') }}
                  </div>
                  <img src="/themes/ito/images/notification-empty.svg" alt="">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { pushQueries } from '../../utils/utils'
import { mapGetters } from 'vuex'
import { getInboxs, readInbox } from "../../models/inbox";
import { claimDailyBonus } from "../../models/reward";

export default {
  name: "Inbox",
  data() {
    return {
      inboxes: [],
      isLoading: true
    };
  },

  created() {
    this.$store.commit('setMenuActive', 'inbox');

    this.fetch();
  },

  methods: {
    fetch(page = 1) {
      const that = this;

      this.page = page;

      const params = {
        page: this.page,
      };

      pushQueries(this.$router, '/inbox', params);

      axios.all([
        getInboxs(params),
      ]).then(axios.spread((inbox_response) => {
        that.isLoading = false;

        //that.$store.commit("setLoading", false);

        if (inbox_response.data.code != 0) {
          return;
        }

        that.inboxes = inbox_response.data.results ? inbox_response.data.results.mails : [];

        this.$nextTick(function () {
          initNanoScroller();
        })
      }));
    },

    readInbox(inbox) {
      const that = this;

      that.$store.commit("setLoading", true);

      readInbox({
        id: inbox.id,
        //is_raw: true
      }).then((response) => {
        that.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          return;
        }

        inbox.status = 1;
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);

        console.error(response);
      });
    }
  }
}
</script>

<style scoped>
#notifications {}

#notifications .card-content {
    min-height: 310px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    padding: 0 30px
}

.notification-list {
    margin-top: 30px;
    margin-bottom: 30px;
}

.notification-list .notification-item {
    background: #FFFFFF;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 13px;
    padding: 12px 27px;
}

.notification-list .notification-item.is-read {
    background: #EEEEEE;
}

.notification-list .notification-item>.title {
    display: flex;
}

.notification-list .notification-item>.title>h4 {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #070A28;
    margin: 0;
}

.notification-list .notification-item>.title>.time {
    width: 200px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #070A28;
    opacity: 0.6;
    text-align: right;
    padding-right: 13px;
}

.notification-list .notification-item>.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #070A28;
    opacity: 0.6;
    margin-top: 5px;
}

.notification-list .notification-item .tools {
    text-align: right;
}

.notification-list .notification-item .tools>button.btn-notification-open {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #40C0F6;
    box-shadow: inset 0px 2px 0px rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.notification-list>.notification-list-empty {
    margin-top: 30px;
    text-align: center;
}

.notification-list>.notification-list-empty>.message {
    color: #0D0D0C;
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.notification-list>.notification-list-empty>img {
    margin-top: 15px;
    margin-bottom: 28px;
}
</style>
