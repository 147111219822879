<template>
    <div>
        <div class="class-item blue" v-if="tutoring.status == 1">
            <div class="class-item-content">
                <h4>{{tutoring.subscribe_from | convertUnitToTime('ddd')}} - {{tutoring.subscribe_from | convertUnitToTime('DD/MM/YYYY')}} - {{tutoring.time_start | convertUnitToTime('HH:mm')}} {{$t('to')}} {{tutoring.time_end | convertUnitToTime('HH:mm')}}
                    <template v-if="tutoring.is_live == 1">
                        <span class="live-class-dot"></span><span class="live-class-text">{{$t('live_now')}}</span>
                    </template>
                </h4>

                <div class="d-flex">
                    <div class="class-item-col-1">
                        <div class="desc"><b>{{$t('class')}} </b> <span class="underline">{{tutoring.class_name}}</span></div>
                        <div class="desc"><b>{{$t('subject')}} </b> <span class="underline">{{tutoring.subject}}</span></div>
                        <div class="desc"><b>{{$t('teacher')}} </b> <span class="underline">{{tutoring.teacher}}</span></div>
                    </div>
                    <div class="class-item-col-2">
                        <button class="btn btn-register-class"  style="padding: 8px 25px;">{{$t('class_open_in')}}
                            <countdown :time="tutoring.time_start" @finished = "countdownFinished" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="class-item" v-else-if="tutoring.status == 2">
            <div class="class-item-content">
                <h4>{{tutoring.subscribe_from | convertUnitToTime('ddd')}} - {{tutoring.subscribe_from | convertUnitToTime('DD/MM/YYYY')}} - {{tutoring.time_start | convertUnitToTime('HH:mm')}} {{$t('to')}} {{tutoring.time_end | convertUnitToTime('HH:mm')}}
                    <template v-if="tutoring.is_live == 1">
                        <span class="live-class-dot"></span><span class="live-class-text">{{$t('live_now')}}</span>
                    </template>
                </h4>

                <div class="d-flex">
                    <div class="class-item-col-1">
                        <div class="desc"><b>{{$t('class')}}</b> <span class="underline">{{tutoring.class_name}}</span></div>
                        <div class="desc"><b>{{$t('subject')}}</b> <span class="underline">{{tutoring.subject}}</span></div>
                        <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>

                    </div>
                    <div class="class-item-col-2">
                        <button class="btn btn-join-class" style="padding: 8px 25px;  width: 180px" @click="join_class(tutoring)">{{$t('join_to_class')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="class-item gray" v-else-if="tutoring.status == 4">
            <div class="class-item-content">
                <h4>{{tutoring.subscribe_from | convertUnitToTime('ddd')}} - {{tutoring.subscribe_from | convertUnitToTime('DD/MM/YYYY')}} - {{tutoring.time_start | convertUnitToTime('HH:mm')}} {{$t('to')}} {{tutoring.time_end | convertUnitToTime('HH:mm')}}</h4>

                <div class="d-flex">
                    <div class="class-item-col-1">
                        <div class="desc"><b>{{$t('class')}}</b> <span class="underline">{{tutoring.class_name}}</span></div>
                        <div class="desc"><b>{{$t('subject')}}</b> <span class="underline">{{tutoring.subject}}</span></div>
                        <div class="desc"><b>{{$t('teacher')}}</b> <span class="underline">{{tutoring.teacher}}</span></div>
                    </div>
                    <div class="class-item-col-2">
                        <button class="btn btn-class-finish"  style="padding: 8px 25px; width: 180px">{{$t('finished_classes')}}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import axios from "axios";
    import {getTutorings, tutoringSubscribe} from "../../models/tutoring";
    import { joinLiveClass } from "../../models/tutoring";
    import { convertSecondToText } from "../../utils/utils";
    import Countdown from '../element/Countdown.vue';
    export default {
        components: { Countdown },
        name: "TutoringItem",

        props: ['tutoring'],

        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }
                return {
                    gem: 0
                }
            })
        },
        data() {
            return {
                register_time_start: "",
            };
        },
        created() {
            //this.tutoring.status = 1;

            this.countdown = this.tutoring.time_end;
        },
        methods: {
            join_class(tutoring) {
                const that = this;
                that.$store.commit("setLoading", true);
                joinLiveClass({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    $('#tutoringDetailJoinClassModal'+tutoring.id).modal("hide");

                    window.open(response.data.results.link, '_blank');

                    that.$emit('updateTutoring', 'join_class', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);
                    console.error(response);
                });
            },

            subscribe(tutoring) {
                const that = this;
                that.$store.commit("setLoading", true);

                tutoringSubscribe({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);
                    if (response.data.results.status == 6){
                        return false;
                    }
                    if (response.data.code != 0) {
                        return;
                    }

                    $('#tutoringRegisterModal' + tutoring.id).modal("hide");

                    that.$emit('updateTutoring', 'subscribe', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            },

            unsubscribeVerify(tutoring) {
                $('#tutoringDetailCancelRegistrationModal'+tutoring.id).modal('hide');
                $('#tutoringDetailCancelRegistrationVerifyModal'+tutoring.id).modal();
            },

            countdownFinished() {
                console.log('Finished....!');

                this.tutoring.status = 2;
            },

            unsubscribe(tutoring) {
                const that = this;

                that.$store.commit("setLoading", true);

                tutoringSubscribe({
                    id: tutoring.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    $('#tutoringDetailCancelRegistrationVerifyModal'+tutoring.id).modal('hide');

                    that.$emit('updateTutoring', 'unsubscribe', tutoring, response.data.results);
                }).catch(({ response }) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            },


        }
    }
</script>

<style scoped>

</style>
