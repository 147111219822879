<template>
    <div id="content">
        <div class="container" v-if="items_length > 0">
            <div id="page-header-fixed">
                <div class="container">
                    <div id="page-header">
                        <ul class="breadcrumb">
                            <li class="icon">
                                <a @click="$router.go(-1)">
                                    <img src="/themes/ito/images/back-to.svg" alt="">
                                </a>
                            </li>
                            <li class="active">{{ unit_name }} - {{ lesson_name }} {{ lesson_detail }} </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mark-lesson" v-if="screen != 'list'" @click="handleFocusOut"></div>
            <div class="card" :class="screen != 'list' ? 'box-leson' : 'mt-3'">
                <div class="menu-next-item" v-if="show_next_item" @click="handleFocusOut">
                    <li @click="nextItem" v-if="(next != '' && next != undefined)">
                        <img v-if="next.type == 'vocabulary'" src="/themes/ito/images/lession/icon-lession-vocabulary.svg" style="height:22px; margin-right:5px;">
                        <img v-if="next.type == 'lecture'" src="/themes/ito/images/lession/icon-lession-lecture.svg" style="height:22px; margin-right:5px;">
                        <img v-if="next.type == 'practice'" src="/themes/ito/images/lession/icon-lession-practice.svg" style="height:22px; margin-right:5px;">
                        <img v-if="next.type == 'handouts'" src="/themes/ito/images/lession/icon-lession-handouts.svg" style="height:22px; margin-right:5px;">
                        {{ (next != '' && next != undefined) ? $t('go_to') + " " + $t(next.type) : $t('go_to_next_item')
                        }}
                    </li>
                    <li @click="gotoWave" v-else>
                        {{ (next != '' && next != undefined) ? $t('go_to') + " " + $t(next.type) : $t('go_to_next_item')
                        }}
                    </li>
                    <hr />
                    <li @click="gotoWave"><img src="/themes/ito/images/close1.svg" style="height:22px; margin-right:5px; margin-left: 5px">{{ $t('quit') }}</li>
                </div>
                <div class="card-body" v-if="screen == 'list'">
                    <div class="wave-lesson">
                        <div class="waves flex-fill" :class="items_length < 6 ? 'min-100' : ''">
                            <template v-for="(item, index) in items">
                                <div class="item-wave-lesson">
                                    <div @click="detailLessonOpen(item.id, item.type)">
                                        <div class="item-wave-lesson-inner">
                                            <div class="rating-crown" v-if="item.type == 'practice'">
                                                <template v-for="i in 3">
                                                    <img :src="(i <= item.star) ? '/themes/ito/images/icon-crown.svg' : '/themes/ito/images/icon-crown-disable.svg'">
                                                </template>
                                            </div>
                                            <img class="mt-2" :src="'/themes/ito/images/lession/icon-lession-' + item.type + '.svg'">
                                            <div class="item-wave-lesson-text">
                                                {{ $t(item.type) }}
                                                <i :class="item.complete == 1 ? 'ito-icon-checked' : 'ito-icon-checked-disable'" v-if="item.type != 'handouts'"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="wave-lesson-bottom">
                        <div style="margin: 0 25px; font-size: 15px">
                            <i class="ito-icon-checked-disable"></i> {{ $t('incomplete') }}
                        </div>
                        <div style="margin: 0 25px; font-size: 15px">
                            <i class="ito-icon-checked"></i> {{ $t('complete') }}
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'vocabulary'">
                    <div class="lesson-action-button" @click="showMenuItem"><span>+</span></div>
                    <div class="card-content" style="height: 100%; padding: 0;min-height: 550px;">
                        <iframe :src="item.content" frameborder="0" allowfullscreen width="100%" height="100%"></iframe>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'lecture'">
                    <div class="lesson-action-button" @click="showMenuItem"><span>+</span></div>
                    <h5 class="card-title">
                        <span class="title">{{ $t('lecture') }}</span>
                    </h5>
                    <div class="card-content p-0" style="height: calc(100% - 52px);min-height: 550px;">
                        <iframe width="100%" height="100%" :src="item.content" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'practice'">
                    <div class="lesson-action-button" @click="showMenuItem"><span>+</span></div>
                    <div class="card-content p-0">
                        <iframe :src="item.content" frameborder="0" allowfullscreen width="100%" height="100%" scrolling="no"></iframe>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'handouts'">
                    <div class="lesson-action-button" @click="showMenuItem"><span>+</span></div>
                    <div class="card-content" style="padding: 0;min-height: 550px;">
                        <iframe :src="item.content" frameborder="0" allowfullscreen width="100%" height="100%"></iframe>
                    </div>
                </div>
            </div>

        </div>

        <div class="container" v-if="errors">
            <div class="card fade-down">
                <div class="card-body p-4">
                    <div class="card-content">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center">
                            <h5>{{ $t(errors) }}
                            </h5>
                            <img class="mt-3" src="/themes/ito/images/mascot/isaac-extension.svg" alt="" width="125px">
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade reward-popup" id="rewardPopup" tabindex="-1" role="dialog" aria-labelledby="rewardPopup" aria-hidden="true" data-backdrop="static" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                <div class="modal-content box-shadow-main paymment-status">
                    <div class="close-popup" data-bs-dismiss="modal" @click="gotoWave"></div>
                    <h3 class="popup-title success">{{ $t('completed_practice') }}</h3>
                    <div class="list-item-result-popup">
                        <div class="item-result-popup" :class="crown > 0 ? 'active' : ''"></div>
                        <div class="item-result-popup" :class="crown > 1 ? 'active' : ''"></div>
                        <div class="item-result-popup" :class="crown > 2 ? 'active' : ''"></div>
                    </div>
                    <div class="box-result-popup">
                        <div class="result-txt">{{ $t('you_answered_correctly') }}</div>
                        <div class="result-score">
                            <span class="score">{{ correct }}</span>/<span class="total"> {{ total }} </span>
                        </div>
                    </div>
                    <button class="next-item-modal" v-if="(next != '' && next != undefined)" @click="nextItem" data-bs-dismiss="modal" aria-label="Close">
                        {{ (next != '' && next != undefined) ? $t('go_to') + " " + $t(next.type) : $t('close') }}
                    </button>
                </div>
            </div>
        </div>

        <div id="modalLectureSuccess" class="modal fade modal-flex" tabindex="-1" role="dialog" style="text-align: center">
            <div class="modal-dialog pyro modal-sm" role="document">
                <div class="modal-content">
                    <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                    <div class="modal-body">
                        <h4 class="title">{{ $t('congratulations') }}</h4>

                        <img src="/themes/ito/images/lession/icon-lession-lecture-big.svg" alt="">
                        <p class="mt-2">{{ $t('completed_vocabulary_lesson') }}</p>
                        <div class="d-flex justify-content-center mt-3">
                            <button class="ito-btn ito-btn-primary" v-if="(next != '' && next != undefined)" @click="nextItem" data-bs-dismiss="modal" aria-label="Close">
                                {{ (next != '' && next != undefined) ? $t('go_to') + " " + $t(next.type) : $t('close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="modalVocabSuccess" class="modal fade modal-flex" tabindex="-1" role="dialog" style="text-align: center">
            <div class="modal-dialog pyro modal-sm" role="document">
                <div class="modal-content">
                    <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                    <div class="modal-body">
                        <h4 class="title">{{ $t('congratulations') }}</h4>

                        <img src="/themes/ito/images/lession/icon-lesson-vocabulary-big.svg" alt="">
                        <p class="mt-2">{{ $t('completed_vocabulary_lesson') }}</p>
                        <div class="d-flex justify-content-center mt-3">
                            <button class="ito-btn ito-btn-primary" v-if="(next != '' && next != undefined)" @click="nextItem" data-bs-dismiss="modal" aria-label="Close">
                                {{ (next != '' && next != undefined) ? $t('go_to') + " " + $t(next.type) : $t('close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { getUnitLessonDetail, getUnitLessonItem, getItemInfo } from "../../models/dashboard";
import LessonDetailItem from "./LessonDetailItem";

export default {
    name: "LessonDetail",
    components: { LessonDetailItem },
    props: [
        'user_info'
    ],
    computed: {
        user() {
            return this.$store.state.user_info;
        },
    },
    data() {
        return {
            isLoading: false,
            items: [],
            unit_id: this.$route.params.unit_id,
            lesson_id: this.$route.params.lesson_id,
            unit_name: '',
            lesson_name: '',
            lesson_detail: '',
            item: {},
            screen: 'list',
            correct: 0,
            total: 0,
            crown: 0,
            current_item: 0,
            next: '',
            show_next_item: false,
            items_length: 0,
            page_title: "Lesson detail",
            errors: undefined
        };
    },

    created() {
        this.getUnitLessonDetail()
        if (this.item) {
            const f = function (event) {
                switch (event.data.type) {
                    case 'update_item_info':
                        getItemInfo({
                            id: this.item.id,
                            item_type: this.item.type,
                        });
                }
                window.removeEventListener("message", f, false);
            }
            window.addEventListener("message", f);
        }
    },
    mounted() {

        window.addEventListener('message', this.handleMessage)
    },
    updated() {
        document.title = this.page_title;
        const slider = document.querySelector('.wave-lesson');
        let isDown = false;
        let startX;
        let scrollLeft;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage)
    },
    watch: {
        'item': function (newVal, oldVal) {
            if (newVal) {
                const that = this;
                let index = this.items.findIndex(x => x.id == newVal.id);
                that.current_item = index;
                let array_next = [];
                for (let i = 0; i < that.items.length; i++) {
                    if (i < that.current_item && that.items[i].complete == 0) {
                        array_next.push(i);
                    }
                    that.next = that.items[array_next[0]];
                    if (i > that.current_item) {
                        if (that.items[i].complete == 0) {
                            that.next = that.items[i];
                            break;
                        } else {
                            if (array_next[0]) {
                                that.next = that.items[array_next[0]];
                                break;
                            }
                            else {
                                that.next = '';
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        handleFocusOut() {
            this.show_next_item = false;
        },
        nextItem() {
            gtag('event', 'item', {});
            this.show_next_item = false;
            let id = this.next.id;

            const that = this;
            that.isLoading = true;

            axios.all([
                getUnitLessonItem(id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.item = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.lesson_detail = response.data.results.title_lesson;
                // that.lesson_type = response.data.results.item.type;
                that.screen = response.data.results.item.type;
            }));
        },
        handleMessage(event) {
            const that = this;

            if (this.item) {
                switch (event.data.type) {
                    case 'update_item_info':
                        axios.all([
                            getItemInfo({
                                id: this.item.id,
                                item_type: this.item.type,
                            }),
                        ]).then(axios.spread((response) => {
                            if (response.data.code != 0) {
                                return;
                            }
                            that.user.dailychallenge_noti = response.data.results.dailychallenge_noti || 0;
                            that.user.count_achieve_unread = response.data.results.count_achieve_unread || 0;
                            if (this.item.type == 'practice') {
                                this.correct = response.data.results.current_result.correct_quest;
                                this.total = response.data.results.current_result.total_quest;
                                this.crown = response.data.results.current_result.crown;
                                let user = this.$store.state.user_info;
                                user.crown += parseInt(response.data.results.current_result.crown)
                                user.crown_month += parseInt(response.data.results.current_result.crown)
                                $('#rewardPopup').modal('show');
                            }
                            if (this.item.type == 'vocabulary') {
                                $('#modalVocabSuccess').modal('show');
                            }
                            if (this.item.type == 'lecture') {
                                $('#modalLectureSuccess').modal('show');
                            }
                            this.getUnitLessonDetail();
                        }));
                }
            }
        },
        showMenuItem() {
            if (this.show_next_item == true) {
                this.show_next_item = false;
            } else {
                if (this.next == '' || this.next == undefined) {
                    this.screen = 'list';
                    this.show_next_item = false;
                    this.$store.commit("setShowEffect", true);
                    getItemInfo({
                        id: this.item.id,
                        item_type: this.item.type,
                    });
                } else {
                    this.show_next_item = true;
                }
            }
        },
        gotoWave() {
            // soundBackground().play();
            this.screen = 'list';
            this.show_next_item = false;
            this.$store.commit("setShowEffect", true);
            getItemInfo({
                id: this.item.id,
                item_type: this.item.type,
            });
        },
        getUnitLessonDetail() {
            const that = this;
            const { subject_id, unit_id, lesson_id } = that.$route.params;
            that.isLoading = true;
            axios.all([
                getUnitLessonDetail(subject_id, unit_id, lesson_id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    this.errors = response.data.msg;
                    return;
                }
                this.errors = undefined;
                that.items = response.data.results.item;
                that.items_length = that.items.length;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.lesson_detail = response.data.results.title_lesson;
                that.page_title = that.unit_name + " - " + that.lesson_name + " : " + that.lesson_detail;
            }));
        },
        detailLessonOpen(id, type) {
            const that = this;
            that.isLoading = true;
            this.$store.commit("setLoading", true);
            this.$store.commit("setShowEffect", false);
            switch (type) {
                case 'vocabulary':
                    this.handleGACustomEvent('ITC_W_3000', id)
                    break;
                case 'lecture':
                    this.handleGACustomEvent('ITC_W_3001', id)
                    break;
                case 'practice':
                    this.handleGACustomEvent('ITC_W_3002', id)
                    break;
                case 'handouts':
                    this.handleGACustomEvent('ITC_W_3004', id)
                    break;
                default:
                    break;
            }
            axios.all([
                getUnitLessonItem(id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    return;
                }
                that.item = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.lesson_detail = response.data.results.title_lesson;
                that.screen = response.data.results.item.type;
                this.$store.commit("setLoading", false);

            }));
        },
        testHandouts(id, type) {
            const that = this;
            that.isLoading = true;
            that.item = {
                "id": 3169,
                "name": "vocabulary",
                "content": "https://dev-lcms.dreamlab.com.vn/handouts",
                "type": "vocabulary",
                "complete": 1,
                "star": 0,
                "attemp": null
            }
            that.unit_name = "Unit 1";
            that.lesson_name = "Lesson 1";
            that.screen = 'vocabulary';
            // axios.all([
            //     getUnitLessonItem(id),
            // ]).then(axios.spread((response) => {
            //     that.isLoading = false;
            //
            //     if (response.data.code != 0) {
            //         return;
            //     }
            //
            //     that.item = response.data.results.item;
            //     that.unit_name = response.data.results.name_unit;
            //     that.lesson_name = response.data.results.name_lesson;
            //     // that.lesson_type = response.data.results.item.type;
            //     that.screen = 'vocabulary';
            // }));
        },
        handleGACustomEvent(element, item_id) {
            var params = {
                'w_user_id': this.user.user_id,
                'item_id': item_id
            }
            params[element] = this.user.user_id + '|' + item_id
            gtag('event', element, params)
        }
    }
}
</script>

<style scoped>
#page-header .breadcrumb {
    margin-bottom: 0;
}

.wave-lesson {
    position: relative;
    height: 550px;
    display: flex;
    width: 100%;
    align-items: center;
    overflow: auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
}

.waves {
    height: 200px;
    /*background: url(/themes/ito/images/waves.svg) repeat-x;*/
    background: url(/public/images/loop.svg);
    /*width: 100%;*/
    display: flex;
    /*min-width: 100%;*/
}

.item-wave-lesson {
    display: inline-block;
    margin-left: 60px;
    width: 118px;
    text-align: center;
}

.item-wave-lesson:nth-child(2n + 1) .item-wave-lesson-inner {
    margin-top: -40px;
}

.item-wave-lesson:nth-child(2n) .item-wave-lesson-inner {
    margin-top: 140px;
}

.item-wave-lesson-text {
    font-size: 16px;
    font-weight: 700;
    color: #1E263C;
    margin-top: 8px;
}

.wave-lesson-bottom {
    position: absolute;
    width: 356px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 16px 16px 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-wave-lesson:nth-child(2n + 1) .item-wave-lesson-inner {
    margin-top: -40px;
}

.item-wave-lesson:nth-child(2n) .item-wave-lesson-inner {
    margin-top: 140px;
}

.item-wave-lesson a {
    color: #000;
}


.item-wave-lesson-inner {
    position: relative;
}

.rating-crown {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 57px;
    margin: auto;
    height: 18px;
    gap: 4px;
}

.crown-default {
    display: flex;
    position: absolute;
    text-align: center;
    justify-content: center;
    width: 57px;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.crown-default img {
    width: 19px;
    height: 18px;
}

.crown-active-img {
    z-index: 10;
}



.menu-next-item {
    position: absolute;
    width: 200px;
    min-width: fit-content;
    height: 100px;
    right: 5px;
    top: 5px;
    border-radius: 10px;
    box-shadow: 4px 4px 0px 0px #00000012;
    background: #fff;
    z-index: 100;
    padding: 0px 20px;
}

.menu-next-item li {
    cursor: pointer;
    list-style: none;
    display: flex;
    height: 50%;
    width: 100%;
    align-items: center;
    justify-content: start;
    font-size: 15px;
    font-weight: 600;
}

.menu-next-item hr {
    border: none;
    margin: 0;
}

.modal-sm {
    max-width: 400px;
}

.ito-icon-close {
    position: absolute;
    right: 18px;
}

.next-item-modal {
    background: linear-gradient(180deg, #45BCFF 0%, #00A3FF 100%);
    border-radius: 20px;
    box-shadow: 4px 4px 0px 0px #00000012;
    border: none;
    padding: 5px 10px;
    color: #fff;
    margin-top: 10px;
}

.box-result-popup {
    padding: 7px 15px;
}

.min-100 {
    min-width: 100%;
}
</style>