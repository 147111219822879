<template>
    <div class="container">
        <div class="login-box" id="login-box">
            <div class="login-body" style="min-height: 335px;">
                <h3 class="popup-title mb-20">{{ $t('hello') }}</h3>
                <h3 class="popup-title mb-1">{{ $t('login') }}</h3>
                <form id="create_form" method="POST" encType="multipart/form-data" @submit.prevent="onSubmit"
                    style="max-width: 300px;margin: auto;">
                    <div class="form-group">
                        <input type="text" :class="error.field_username ? 'ito-input has-err' : 'ito-input'" name="username"
                            :placeholder="$t('username')" autocomplete="off" v-model="user.username" />
                        <div class="error-message text-start" v-if="error.field_username">{{ error.field_username }}</div>

                    </div>
                    <div class="form-group">
                        <input :type="showPass ? 'text' : 'password'" id="login-password-field" name="password"
                            :class="error.field_password ? 'ito-input has-err' : 'ito-input'" :placeholder="$t('password')"
                            v-model="user.password" />
                        <span :class="[showPass ? 'icon-eye eye-show' : 'icon-eye eye-hide']"
                            @click="showPass = !showPass"></span>
                        <div class="error-message text-start" v-if="error.field_password">{{ error.field_password }}</div>

                    </div>

                    <a href="/forgot-password" class="forgot-link link-underline mb-1 mt-3">{{ $t('you_ve_forgot_password')
                    }}</a>

                    <div class="form-group" style="margin-top: 30px; margin-bottom: 10px">
                        <button type="submit" class="ito-btn ito-btn-primary ito-btn-block">{{ $t('login') }}</button>
                        <div class="error-message text-center" v-if="error.message">{{ error.message }}</div>
                        <login-google />
                        <button type="button" class="ito-btn ito-btn-secondary ito-btn-block mt-3" @click="tryForFree()">{{
                            $t('let_s_go_and_try') }}</button>
                    </div>

                    <div class="links text-center mt-3">
                        <span>{{ $t('dont_have_an_account') }}</span>
                        <a href="/register" class="link-underline register-link ms-1">{{ $t('create_account') }}</a>
                    </div>
                </form>
            </div>
        </div>
        <div id="app-login">
            <div class="app-login-title">Download application</div>
            <div class="app-login-desc">{{ $t('download_app') }}</div>
            <div class="app-login-download">
                <div class="app-login-android">
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=ismart.edu.vn.ito&pli=1" target="_blank">
                            <img src="/themes/ito/images/qr-android.svg" class="app-login-qr" alt="">
                        </a>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=ismart.edu.vn.ito&pli=1" target="_blank">
                            <img src="/themes/ito/images/download-android.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="app-login-ios">
                    <div>
                        <a href="https://apps.apple.com/vn/app/ismart-tutoring-online/id1587460616" target="_blank">
                            <img src="/themes/ito/images/qr-ios.svg" class="app-login-qr" alt="">
                        </a>
                    </div>
                    <div>
                        <a href="https://apps.apple.com/vn/app/ismart-tutoring-online/id1587460616" target="_blank">
                            <img src="/themes/ito/images/download-ios.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authLogin } from '../../models/auth'
import axios from "axios";
import { getAvatars, getConfig } from "../../models/profile";
import LoginGoogle from './LoginGoogle.vue';

export default {
    name: "Login",
    components: { LoginGoogle },

    data() {
        return {
            error: {
                message: '',
                field_username: '',
                field_password: ''
            },
            user: {
                username: '',
                password: ''
            },
            showPass: false,
        };
    },

    created() {
        this.fetch();
    },

    methods: {
        fetch() {
            localStorage['is_login'] = false;
            localStorage.removeItem('access_token');
            localStorage.removeItem('grade');
            localStorage.removeItem('student');
            localStorage.removeItem('program');
            localStorage.removeItem('hideBanner');
        },

        onSubmit(e) {
            this.resetErrorsValid();
            if (!this.user.username) {
                this.error.field_username = this.$i18n.t('value_not_empty');
            }

            if (!this.user.password) {
                this.error.field_password = this.$i18n.t('value_not_empty');
            }

            if (this.error.field_username != '' || this.error.field_password != '') {
                return false;
            }
            else {
                const username = this.user.username;
                const password = this.user.password;
                this.login({ username, password });
                e.preventDefault();
            }
        },

        resetErrorsValid() {
            this.error = {
                message: '',
                field_username: '',
                field_password: ''
            }
        },

        login(user) {
            const that = this;
            that.$store.commit("setLoading", true);
            authLogin(user).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    this.error.message = this.$i18n.t(response.data.error_code);
                    return false;
                }
                that.$store.commit('setAuth', true);
                localStorage.removeItem('checkedSubjects');
                localStorage.setItem('access_token', response.data.results.access_token);
                const leftCorner = document.querySelector("#left-corner");
                const rightCorner = document.querySelector("#right-corner");
                leftCorner?.classList.add('hide')
                rightCorner?.classList.add('hide')
                localStorage.setItem('lang', 'vi');
                setTimeout(() => {
                    location.href = '/home';
                }, 500);
            }).catch(({ response }) => {
                this.$store.commit('setAuth', false);
                that.$store.commit("setLoading", false);
                that.error.message = this.$i18n.t('has_error');
            });
        },

        tryForFree() {
            window.location.href = '/register-anomynous'
        }
    }
}
</script>

<style scoped>
.form-group {
    position: relative;
}

.ito-input {
    width: 100%;
}

#login-box {
    position: relative;
    width: 592px;
    min-height: 362px;
    background: #ffffff;
    margin: auto;
    text-align: center;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 4px 0px rgba(231, 231, 231, 0.25);
    border-radius: 16px;
    overflow: hidden;
}

#login-box .form-group {
    position: relative;
    max-width: 300px;
}

.login-body {
    padding: 20px;
}
</style>
