<template>
  <div class="gg-sigin mt-3">
    <div id="g_id_onload" :data-client_id="google_client_id" data-context="signin" data-ux_mode="popup"
         data-auto_prompt="false" data-callback="callbackSignIn">
    </div>
    <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="outline" data-text="continue_with"
         data-size="large" data-logo_alignment="center" data-width="300">
    </div>
    <div id="error" class="error-message text-center"></div>
  </div>
</template>

<script>
import {store} from '../../store';
import {API_URL, GOOGLE_CLIENT_ID} from "@/constant";

window.callbackSignIn = function (e) {
  var params = {
    token: e.credential,
    type: 'web'
  };
  store.commit('setLoading', true);
  $.post(API_URL + '/user/google-sign', params).then(function (response) {
    store.commit('setLoading', false);
    if (response.code != 0) {
      window.location.href = '/register?token=' + params.token;
      return false;
    }
    store.commit('setAuth', true);
    localStorage.removeItem('checkedSubjects');
    const data = response.results
    const access_token = data.access_token
    const expires_in = data.expires_in
    document.cookie = `_wid=${access_token}; expires=${expires_in},  path=/`;

    localStorage.setItem('access_token', response.results.access_token);
    location.href = '/home';
  })
}

export default {
  name: 'login_signup_social',
  data() {
    return {
      google_client_id: GOOGLE_CLIENT_ID,
      error: {
        message: '',
      },
    }
  },
  methods: {}
}
</script>