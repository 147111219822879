<template>
    <div class="modal fade" id="notification" tabindex="-1" role="dialog" data-backdrop="static"
        aria-labelledby="notification" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="swiper swiper-container" id="scroll-tags">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="banner in banners">
                                <div class=" d-flex justify-content-center flex-column gap-3 position-relative">
                                    <a :href="banner.cta_url" target="_blank" v-if="banner.cta_url">
                                        <div class="w-100" :style="banner.image"></div>
                                    </a>
                                    <div class="w-100" :style="banner.image" v-else></div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-prev" v-if="banners.length > 1"></div>
                        <div class="swiper-button-next" v-if="banners.length > 1"></div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                <div class="close-popup" data-bs-dismiss="modal"></div>
                <div class="event-content ">
                    <button class="event-button" data-bs-dismiss="modal" @click="hideBanner()">
                        {{ $t('popup_btn_close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "EventModal",
    props: ['banners'],


    mounted() {
        const that = this;
        that.$store.commit("setLoading", true);
        setTimeout(() => {
            this.$nextTick(function () {
                that.initSwiper();
                $('#notification').modal('show');
            });
            that.$store.commit("setLoading", false);
        }, 1000)

    },
    updated() {
        this.$nextTick(function () {
            this.initSwiper();
        });
    },

    methods: {
        hideBanner() {
            this.$emit('hideBanner');
        },
        initSwiper() {
            var options = {
                observer: true,
                observeParents: true,
                lazy: true,
                slidesPerView: 1,
                autoHeight: true,
                freeMode: false,
                spaceBetween: 10,
                mousewheel: true,
            }
            if (this.banners.length > 1) {
                options.speed = 400
                options.autoplay = {
                    delay: 3000,
                }
                options.navigation = {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
                options.pagination = {
                    el: ".swiper-pagination",
                    clickable: true,
                    dynamicBullets: true,
                }
                options.loop = true
            }
            return new Swiper(".swiper-container", options);
        }
    }
}
</script>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-size: contain;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 0.125C14.4647 0.125 11.0087 1.17335 8.06919 3.13748C5.12966 5.10161 2.83858 7.8933 1.48566 11.1595C0.132745 14.4258 -0.22124 18.0198 0.468471 21.4872C1.15818 24.9547 2.86061 28.1397 5.36047 30.6395C7.86034 33.1394 11.0454 34.8418 14.5128 35.5315C17.9802 36.2212 21.5742 35.8673 24.8405 34.5143C28.1067 33.1614 30.8984 30.8703 32.8625 27.9308C34.8267 24.9913 35.875 21.5353 35.875 18C35.8695 13.2609 33.9845 8.71754 30.6335 5.36652C27.2825 2.01549 22.7391 0.130477 18 0.125ZM23.7323 19.0219L16.8573 25.2094C16.7231 25.3302 16.5665 25.4235 16.3962 25.4838C16.226 25.5442 16.0455 25.5704 15.8652 25.5609C15.6848 25.5515 15.5081 25.5066 15.3451 25.4289C15.1821 25.3511 15.036 25.242 14.9152 25.1077C14.7943 24.9735 14.7011 24.8168 14.6409 24.6465C14.5807 24.4763 14.5545 24.2958 14.5641 24.1155C14.5736 23.9351 14.6186 23.7584 14.6964 23.5954C14.7742 23.4324 14.8834 23.2864 15.0177 23.1656L20.7567 18L15.0177 12.8344C14.7469 12.5904 14.5841 12.2488 14.565 11.8848C14.546 11.5208 14.6723 11.1641 14.9162 10.8932C15.16 10.6222 15.5015 10.4592 15.8655 10.44C16.2295 10.4208 16.5863 10.5469 16.8573 10.7906L23.7323 16.9781C23.8756 17.107 23.9901 17.2646 24.0686 17.4407C24.147 17.6167 24.1875 17.8073 24.1875 18C24.1875 18.1927 24.147 18.3833 24.0686 18.5593C23.9901 18.7354 23.8756 18.893 23.7323 19.0219Z" fill="%23CCCCCC" fill-opacity="0.5"/></svg>');
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    background-image: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 0.125C21.5353 0.125 24.9913 1.17335 27.9308 3.13748C30.8703 5.10161 33.1614 7.8933 34.5143 11.1595C35.8673 14.4258 36.2212 18.0198 35.5315 21.4872C34.8418 24.9547 33.1394 28.1397 30.6395 30.6395C28.1397 33.1394 24.9546 34.8418 21.4872 35.5315C18.0198 36.2212 14.4258 35.8673 11.1595 34.5143C7.8933 33.1614 5.1016 30.8703 3.13747 27.9308C1.17334 24.9913 0.124992 21.5353 0.124992 18C0.13047 13.2609 2.01548 8.71754 5.36651 5.36652C8.71754 2.01549 13.2609 0.130477 18 0.125ZM12.2677 19.0219L19.1427 25.2094C19.2769 25.3302 19.4335 25.4235 19.6038 25.4838C19.774 25.5442 19.9545 25.5704 20.1348 25.5609C20.3152 25.5515 20.4919 25.5066 20.6549 25.4289C20.8179 25.3511 20.964 25.242 21.0848 25.1077C21.2057 24.9735 21.2989 24.8168 21.3591 24.6465C21.4193 24.4763 21.4455 24.2958 21.4359 24.1155C21.4264 23.9351 21.3814 23.7584 21.3036 23.5954C21.2258 23.4324 21.1166 23.2864 20.9823 23.1656L15.2433 18L20.9823 12.8344C21.2531 12.5904 21.4159 12.2488 21.435 11.8848C21.454 11.5208 21.3277 11.1641 21.0838 10.8932C20.84 10.6222 20.4985 10.4592 20.1345 10.44C19.7705 10.4208 19.4137 10.5469 19.1427 10.7906L12.2677 16.9781C12.1244 17.107 12.0099 17.2646 11.9314 17.4407C11.853 17.6167 11.8125 17.8073 11.8125 18C11.8125 18.1927 11.853 18.3833 11.9314 18.5593C12.0099 18.7354 12.1244 18.893 12.2677 19.0219Z" fill="%23CCCCCC" fill-opacity="0.5"/></svg>');
}

.modal-dialog {
    max-width: 60%;
}

.close-popup {
    width: 25px;
    height: 25px;
    z-index: 1;
}

.modal-content {
    padding: 0;
    background: none;
    border: none !important;
}

.event-content {
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
}

.event-text {
    display: flex;
    width: 360px;
    height: 74px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #F04821;
    font-size: 16px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 0px 0px rgba(231, 231, 231, 0.25) inset;

}

.event-text p {
    font-weight: 400;
}

.event-text p a {
    color: #F04821;
    font-weight: 700;
    text-decoration-line: underline;

}


.event-button {
    color: #1E263C;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    display: inline-flex;
    border: none;
    background: #00000066;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.modal-content {
    border: 5px solid #FFF;
    border-radius: 10px;
    overflow: hidden;
}

.modal-body {
    border: 5px solid #FFF;
    border-radius: 10px;
    overflow: hidden;
    background-color: #C4C4C4;
}
</style>