<template>
  <div id="content">
    <div class="container">
      <div id="page-header-fixed">
        <div class="container">
          <div id="page-header">
            <ul class="breadcrumb">
              <li class="icon">
                <a @click="$router.go(-1)">
                  <img src="/themes/ito/images/back-to.svg" alt="">
                </a>
              </li>
              <li class="active">{{ unit_name }} - {{ lesson_name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <!--          <h5 class="card-title">
                      <span class="title">Vocabulary</span>
                    </h5>
                    <div class="card-content">
                      {{item.content}}
                    </div>-->

          <div class="card-content" style="height: calc(100vh - 285px); padding: 0;min-height: 550px;">
            <iframe :src="item.content"
                    frameborder="0" allowfullscreen width="100%" height="100%"></iframe>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {getItemInfo, getUnitLessonDetail, getUnitLessonItem} from "../../models/dashboard";
import LessonDetailItem from "./LessonDetailItem";

export default {
  name: "LessonVocabulary",
  data() {
    return {
      isLoading: false,
      item: {},
      unit_name: '',
      lesson_name: '',
    };
  },

  created() {
    //window.removeEventListener('message', this.handleMessage, false);
    //window.addEventListener('message', this.handleMessage, false);

    const {item_id} = this.$route.params;
    const f = function(event){
      switch(event.data.type){
        case 'update_item_info':
          getItemInfo({
            id: item_id,
            item_type: 'vocabulary',
          });
      }
      window.removeEventListener("message", f, false);
    }
    window.addEventListener("message", f);

    this.getUnitLessonItem()
  },

  methods: {
    handleMessage(event) {
      const {item_id} = this.$route.params;

      switch(event.data.type){
        case 'update_item_info':
          getItemInfo({
            id: item_id,
            item_type: 'vocabulary',
          });
      }
    },

    getUnitLessonItem() {
      const that = this;

      const {item_id} = that.$route.params;

      that.isLoading = true;

      axios.all([
        getUnitLessonItem(item_id),
      ]).then(axios.spread((response) => {
        that.isLoading = false;

        if (response.data.code != 0) {
          return;
        }

        that.item = response.data.results.item;
        that.unit_name = response.data.results.name_unit;
        that.lesson_name = response.data.results.name_lesson;
      }));
    },
  }
}
</script>

<style scoped>
.card-content {
  padding: 30px;
}

.vocabulary-wrapper {
  padding-left: 10px;
  border-radius: 8px;
  background: #F3F3F3;
}

.list-of-lesson {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-of-lesson li {
  height: 65px;
  line-height: 65px;
  display: flex;
  align-items: center;
}

.list-of-lesson li.active {
  background: #FFFFFF;
}

.vocabulary-left {
  width: 367px;
  background: #F3F3F3;
}

.list-of-lesson .ito-icon-checked {
  width: 41px;
  height: 41px;
  background-size: 100%;
  margin: 0 10px;
}

.list-of-lesson .ito-icon-checked-disable {
  width: 41px;
  height: 41px;
  background-size: 100%;
  margin: 0 10px;
}

.vocabulary-right {
  position: relative;
  width: calc(100% - 367px);
  background: #FFFFFF;
  box-shadow: 0px 4px 0px 0px #E7E7E740 inset;
  box-shadow: 4px 4px 0px 0px #00000012;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 20px;
}

.name-of-lesson {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: #1E263C;
}

.lesson-des-text {
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
}

.img-preview {
  width: 100%;
  margin: 20px auto;
}

.img-preview img {
  width: 100%;
}

.player-button {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 42px;
  height: 42px;
  background: url(/public/themes/ito/images/sound.svg) no-repeat center;
  background-size: 100%;
}
</style>