<template>
  <nav id="nav-bar" class="d-flex">
    <router-link class="logo" :to="{ path: '/home' }"></router-link>
    <div class="navbar-content container">
      <div class="navbar-content-left info-avatar d-flex" id="account_info">
        <div class="dropdown dropdown-hover">
          <div class="avatar" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
            <div class="avatar-c" :style="{ backgroundImage: `url(${user_info.avatar_url})` }"></div>
            <span class="sub-avatar" v-if="user_info.emoji_url">
              <img :src="user_info.emoji_url">
            </span>
          </div>
          <ul class="dropdown-menu profile_actions p-0" aria-labelledby="dropdownMenuButton">
            <li class="dropdown-item user_info_sm">
              <div class="dropright dropdown-hover text-center justify-content-center d-flex">
                <div class="user-name" id="dropdownMenuButton3" data-mdb-toggle="dropdown" aria-expanded="false">
                  <span class="username ">{{ user_info.fullname || user_info.username }}</span>
                  <a href="/profile" class="status warning" v-if="user_info.student_type != 2 && (!user_info.email_verified || !user_info.phone_verified)">
                    <i class="ito-icon-warning"></i>
                    {{ $t('account_not_verified') }}
                  </a>
                </div>
                <div class="dropdown-menu profile_actions_3" aria-labelledby="dropdownMenuButton3" v-if="user_info.student_type != 2 && (!user_info.email_verified || !user_info.phone_verified)">
                  <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="/themes/ito/images/mascot/account_not_verify.png" alt="">
                    <p class="text-bold fs-6" v-html="$t('verify_reward_annoucement')"></p>
                  </div>
                </div>
              </div>
            </li>

            <li class="dropdown-divider user_info_sm"></li>

            <li class="dropdown-item">
              <router-link :to="{ name: 'profile' }" class="d-flex align-items-center">
                <svg class="menu-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {{ $t('profile') }}
              </router-link>
            </li>

            <li class="dropdown-item">
              <a class="d-flex justify-content-between align-items-center" href="#" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#selectLangModal">
                <div class="d-flex">
                  <svg class="menu-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 17H9C10.6569 17 12 18.3431 12 20V10C12 7.17157 12 5.75736 11.1213 4.87868C10.2426 4 8.82843 4 6 4H5C4.05719 4 3.58579 4 3.29289 4.29289C3 4.58579 3 5.05719 3 6V15C3 15.9428 3 16.4142 3.29289 16.7071C3.58579 17 4.05719 17 5 17Z"
                      stroke-width="2" />
                    <path
                      d="M19 17H15C13.3431 17 12 18.3431 12 20V10C12 7.17157 12 5.75736 12.8787 4.87868C13.7574 4 15.1716 4 18 4H19C19.9428 4 20.4142 4 20.7071 4.29289C21 4.58579 21 5.05719 21 6V15C21 15.9428 21 16.4142 20.7071 16.7071C20.4142 17 19.9428 17 19 17Z"
                      stroke-width="2" />
                  </svg>
                  <span class="menu-text">{{ $t('language') }}</span>
                </div>

                <img class="flag" :src="locate_info.img_flag" alt="" />
              </a>
            </li>

            <li class="dropdown-item" v-if="user_info.student_type != 2">
              <router-link :to="{ name: 'upgrade_account' }" class="d-flex align-items-center">
                <svg class="menu-image menu-fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.4374 4.27252C12.7641 3.2425 11.2359 3.24249 10.5626 4.27252L7.16316 9.47274L4.56478 8.00058C3.26302 7.26303 1.69785 8.41888 2.05047 9.85733L3.81575 17.0584C3.89523 17.3827 4.18964 17.6111 4.52808 17.6111H19.4719C19.8104 17.6111 20.1048 17.3827 20.1843 17.0584L21.9495 9.85733C22.3022 8.41887 20.737 7.26304 19.4352 8.00058L16.8368 9.47274L13.4374 4.27252ZM12.2053 5.05483C12.1092 4.90768 11.8908 4.90768 11.7947 5.05482L8.01794 10.8322C7.80654 11.1556 7.37505 11.2589 7.03666 11.0672L3.83432 9.25286C3.64835 9.1475 3.42475 9.31262 3.47513 9.51811L5.10494 16.1666H18.8951L20.5249 9.51811C20.5752 9.31262 20.3517 9.1475 20.1657 9.25286L16.9633 11.0672C16.6249 11.2589 16.1935 11.1556 15.9821 10.8322L12.2053 5.05483Z"
                    stroke-width="0.5" />
                  <path d="M5.16032 19.5C4.75559 19.5 4.42749 19.8234 4.42749 20.2223C4.42749 20.6211 4.75559 20.9445 5.16032 20.9445H18.8397C19.2444 20.9445 19.5725 20.6211 19.5725 20.2223C19.5725 19.8234 19.2444 19.5 18.8397 19.5H5.16032Z"
                    stroke-width="0.5" />
                </svg>

                {{ $t('upgrade_account') }}
              </router-link>
            </li>

            <li class="dropdown-item">
              <a class="d-flex justify-content-between align-items-center" href="#">
                <label class="menu-text d-flex m-0" for="flexSwitchCheckDefault">
                  <svg class="menu-image menu-fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 2C12.29 2 9.95 3.54 8.78 5.78C7.49615 6.44888 6.44888 7.49615 5.78 8.78C4.64119 9.37174 3.68655 10.2646 3.02005 11.3613C2.35354 12.458 2.00072 13.7166 2 15C2 18.87 5.13 22 9 22C11.71 22 14.05 20.46 15.22 18.22C16.5038 17.5511 17.5511 16.5038 18.22 15.22C19.3588 14.6283 20.3134 13.7354 20.98 12.6387C21.6465 11.542 21.9993 10.2834 22 9C22 5.13 18.87 2 15 2ZM9 20C8.07175 19.9993 7.16201 19.7402 6.37259 19.2519C5.58317 18.7636 4.9452 18.0652 4.53007 17.235C4.11495 16.4047 3.93904 15.4753 4.02203 14.5508C4.10502 13.6262 4.44364 12.743 5 12C5 15.87 8.13 19 12 19C11.16 19.63 10.12 20 9 20ZM12 17C11.0717 16.9993 10.162 16.7402 9.37259 16.2519C8.58317 15.7636 7.9452 15.0652 7.53007 14.235C7.11495 13.4047 6.93904 12.4753 7.02203 11.5508C7.10502 10.6262 7.44364 9.74305 8 9C8 12.86 11.13 15.99 15 16C14.16 16.63 13.12 17 12 17ZM16.7 13.7C16.17 13.89 15.6 14 15 14C12.24 14 10 11.76 10 9C10 8.4 10.11 7.83 10.3 7.3C10.83 7.11 11.4 7 12 7C14.76 7 17 9.24 17 12C17 12.6 16.89 13.17 16.7 13.7ZM19 12C19 8.14 15.87 5.01 12 5C12.9633 4.28166 14.1531 3.93414 15.3516 4.02098C16.5501 4.10783 17.6773 4.62325 18.527 5.47296C19.3768 6.32267 19.8922 7.44987 19.979 8.6484C20.0659 9.84693 19.7183 11.0367 19 12Z" />
                  </svg>
                  {{ $t('animation') }}
                </label>
                <div class="form-check form-switch">
                  <input class="ito-form-check form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="animating" v-model="animating" @change="showEffect()">
                </div>
              </a>
            </li>

            <li class="dropdown-item">
              <a class="d-flex align-items-center" href="#" @click.prevent="cfLogout()">
                <svg class="menu-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16 17L21 12L16 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M21 12H9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                {{ $t('log_out') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="dropdown dropdown-hover user_info_lg">
          <div class="user-name" id="dropdownMenuButton2" data-mdb-toggle="dropdown" aria-expanded="false">
            <span class="username ">{{ user_info.fullname || user_info.username }}</span>
            <a href="/profile" class="status warning" v-if="user_info.student_type != 2 && (!user_info.email_verified || !user_info.phone_verified)">
              <i class="ito-icon-warning"></i>
              {{ $t('account_not_verified') }}
            </a>
          </div>
          <div class="dropdown-menu profile_actions_2" aria-labelledby="dropdownMenuButton2" v-if="user_info.student_type != 2 && (!user_info.email_verified || !user_info.phone_verified)">
            <div class="d-flex flex-column justify-content-center align-items-center text-center">
              <img src="/themes/ito/images/mascot/account_not_verify.png" alt="">
              <p class="text-bold fs-6" v-html="$t('verify_reward_annoucement')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-content-right d-flex flex-fill justify-content-end align-items-center gap-2">
        <div class="dropdown save_account" v-if="user_info.student_type == 2">
          <router-link :to="{ name: 'register' }" @click.native="getSoundClick" id="dropdownMenuButton3" data-mdb-toggle="dropdown" aria-expanded="false">
            <div class="ito-btn ito-btn-secondary d-flex  align-items-center">
              <span>{{ $t('save_account') }}</span>
              <img src="/themes/ito/images/icons/User_fill.svg">
            </div>
          </router-link>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
            <div class="d-flex gap-2 justify-content-center align-items-center text-center text-nowrap">
              <img src="/themes/ito/images/icons/emoticon-love.svg" alt="">
              <p class="text-bold fs-6">{{ $t('get_gems_save_account') }}</p>
              <img src="/themes/ito/images/icon-gem.svg">
            </div>
          </div>
        </div>
        <!-- grade -->
        <div class="dropdown gradeSelector">
          <button class="nav-item" @click="clickHeaderHandler('ITC_W_1002')" type="button" id="gradeSelector" :data-bs-toggle="user_info.lock_grade == 0 ? 'dropdown' : ''" aria-haspopup="true" aria-expanded="false">
            <img src="/themes/ito/images/icon-league.svg">
            <span>{{ user.grade }}</span>
            <span class="nav-item-tool-tip fade-in-top">{{ $t('grade') }}</span> <!--tooltip-->
          </button>
          <div class="dropdown-menu" aria-labelledby="gradeSelector">
            <template v-for="(grade, index) in grades">
              <a class="dropdown-item" :class="{ active: grade == grade_selected }" href="#" @click="(grade == grade_selected || (sl_grade_err && sl_grade_err.grade == grade)) ? '' : changeGrade(grade)">
                <p>{{ $t('grade') + ' ' + grade }}</p>
                <span class="error-message" v-if="sl_grade_err && sl_grade_err.grade == grade">{{ sl_grade_err.message
                  }}</span>
              </a>
              <hr class="dropdown-divider m-0" style="height: 0px;" v-if="index + 1 < grades.length">
            </template>
          </div>
        </div>

        <!-- gem  -->
        <div class="nav-item" @click="getSoundClick">
          <img src="/themes/ito/images/icon-gem.svg">
          <span>{{ user_info.gem | toCommas }}</span>
          <span class="nav-item-tool-tip fade-in-top">{{ $t('gems_collected') }}</span> <!--tooltip-->
        </div>

        <!-- leaderboard -->
        <router-link class="nav-item" :to="{ name: 'leaderboard' }" @click.native="clickHeaderHandler('ITC_W_1003')" v-if="user_info.account_type != 1 && user_info.student_type != 2">
          <img src="/themes/ito/images/icon-chart.svg">
          <span>{{ user_info.rank != 0 ? user_info.rank : $t('rank') }}</span>
          <span class="nav-item-tool-tip fade-in-top">{{ $t('leaderboard') }}</span> <!--tooltip-->
        </router-link>

        <!-- inbox  -->
        <router-link class="nav-item" :to="{ name: 'inbox' }" @click.native="getSoundClick" v-if="user_info.account_type != 1 && user_info.student_type != 2">
          <img src="/themes/ito/images/icon-mail.svg">
          <span>{{ user_info.count_mail_unread }}</span>
          <span class="nav-item-tool-tip fade-in-top">{{ $t('inbox') }}</span> <!--tooltip-->
        </router-link>

        <!-- search box -->
        <div class="d-flex align-items-center search-header" v-if="user_info.account_type != 1 && user_info.student_type != 2">
          <div class="button-search" @click="handleSubmit"></div>
          <input type="text" @keyup.enter="handleSubmit" class="box-search w-100" :placeholder="$t('search')" autocomplete="off" v-model="search" />
        </div>
      </div>
    </div>

  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { getConfigs, getLanguage, soundClick } from "../utils/utils";
import { changeGrade } from '../models/profile';

export default {
  name: "NavBar",
  data() {
    const configs = getConfigs();
    const locate = getLanguage();
    const localizes = configs.localize || [];
    const grades = configs.grades || [];
    const grade_default = localStorage.getItem('grade');
    let locate_info = {};
    localizes.forEach(item => {
      if (item.lang == locate) {
        locate_info = item;
      }
    });

    return {
      user: {
        username: '',
        fullname: '',
        avatar_url: '',
        emoji_url: '',
        grade: 0,
        rank: 0,
        gem: 0,
        phone_verified: 0,
        email_verified: 0,
      },
      grades: grades.sort(function (a, b) { return a - b }),
      grade_selected: grade_default || 1,
      locate: getLanguage(),
      locate_info: locate_info,
      configs: configs,
      localizes: localizes,
      search: '',
      sl_grade_err: {},
      animating: this.$store.getters.showEffect,
    };
  },

  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },
  created() {
    this.fetch();
  },
  mounted() {
    if (this.$route.query.keyword) {
      this.search = this.$route.query.keyword;
    }
    $('#show_menu').click(function () {
      $('.dropdown__menu').toggleClass('show');
    })
    setTimeout(() => {
      this.$nextTick(function () {
        $('.save_account .dropdown-menu').dropdown('show')
      });
    }, 1500)
    if (this.locate == 'vi') {
      $.datepicker.setDefaults($.datepicker.regional['vi']);
    } else {
      $.datepicker.setDefaults($.datepicker.regional['']);
    }
    this.grade_selected = localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade;
  },
  methods: {
    fetch() {
      this.user.grade = localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade;
      if (!localStorage.getItem('grade')) {
        localStorage['grade'] = this.user_info.grade
      }
    },
    cfLogout() {
      $('#confirmLogout').modal('show');
    },
    onLogout(e) {
      this.$store.dispatch('logout').then(() => {
        localStorageNaNpxoveItem("grade");
        localStorageNaNpxoveItem('is_login')
        // window.location.href = "/register-anomynous";
        window.location.href = "/login";
      });
      // localStorageNaNpxoveItem('grade');
      e.preventDefault();
    },

    gotoDailyChallenge() {
      //window.location.href = '/daily-challenge';

      this.$router.push({ name: 'daily_challenge' })
    },
    clickHeaderHandler(element) {
      this.handleGACustomEvent(element)
      soundClick();
    },

    getSoundClick() {
      soundClick();
    },

    handleSubmit() {
      // const path = `/search/${this.search}`
      if (this.search == '') {
        return false;
      }
      this.$router.push({
        path: "/search",
        query: {
          keyword: this.search,
          t: new Date().getTime(),
        }
      });
      this.handleGACustomEvent('ITC_W_1004', this.search);
    },
    handleGACustomEvent(element, keyword) {
      const user_id = this.user_info.user_id || '';
      var params = {
        'w_user_id': user_id
      }

      if (keyword) {
        params['keyword'] = keyword
        params[element] = user_id + '|' + keyword
      } else {
        params[element] = user_id
      }
      gtag('event', element, params)
    },
    changeGrade(grade) {
      const that = this;
      that.$store.commit("setLoading", true);
      const params = {
        'grade': grade,
      }
      changeGrade(
        params
      ).then((response) => {
        that.$store.commit("setLoading", false);
        if (response.data.code != 0) {
          return;
        }
        switch (parseInt(response.data.results.status)) {
          case 0:
            this.grade_selected = grade;
            localStorage.setItem('grade', that.grade_selected);
            $('#updateGradeModal').modal("hide");
            window.location.href = '/home';
            break;
          case 1:
            this.sl_grade_err = {
              grade: grade,
              message: this.$i18n.t('change_grade_error_1', { grade: that.user_info.grade }) + '.'
            }
            this.$emit('show_error', this.sl_grade_err);
            break;
          case 2:
            that.$store.commit("setLoading", true);
            const path = '/placement-test/' + that.grade_selected;
            if (that.$route.path !== path) {
              that.$router.push(path)
            } else {
              that.$store.commit("setLoading", false);
            }
            break;
          case 3:
            this.sl_grade_err = {
              grade: grade,
              message: this.$i18n.t('change_grade_error_3', { grade: that.user_info.grade }) + '.'
            }
            this.$emit('show_error', this.sl_grade_err);
            break;
          case 4:
            this.sl_grade_err = {
              grade: grade,
              message: this.$i18n.t('change_grade_error_4') + '.'
            }
            this.$emit('show_error', this.sl_grade_err);
            break;
          default:
            $('.gradeSelector').dropdown('dispose')
            break;
        }
      });
    },
    showEffect() {
      this.$store.commit("setShowEffect", this.animating);
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  /* display: none; */
  min-width: 17.5rem;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #14233F;
  text-align: left;
  list-style: none;
  background-clip: padding-box;

  border-radius: .75rem;
  border: .0625rem solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: .25rem .25rem .25rem 0rem rgba(0, 0, 0, 0.07);
}

.dropdown-menu .dropdown-item {
  /* border: none; */
  width: 100%;
  padding: .75rem 1.25rem;
  background-color: transparent;
}

.dropdown-menu .dropdown-item a {
  text-decoration: none;
  white-space: nowrap;
  font-weight: 400;
  color: #14233F;
  text-align: inherit;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #00A3FF ;
  /* background-color: #FF7800; */
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--primary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
}

.dropdown-menu .dropdown-item:first-child {
  border-radius: .75rem .75rem 0 0;
}

.dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 .75rem .75rem;
  border-bottom: none;
}

.user-name {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.user-name .username {
  font-size: 16px;
  font-weight: 700;
}

.user-name .status {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  gap: 3px;
}

.user-name .status.warning {
  color: var(--warning);
}

.dropdown.dropdown-hover:hover>.dropdown-menu,
.dropright.dropdown-hover:hover>.dropdown-menu {
  display: block;

}

.dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.profile_actions,
.profile_actions_2 {
  width: 276px;
  border-radius: 8px;
  /* overflow: hidden; */
}

.profile_actions_2 {
  padding: 15px;
}

.profile_actions .dropdown-item {
  line-height: 26px;
  padding: 12px 30px;
}

.profile_actions .dropdown-item img {
  width: 30px;
  height: 30px;
}

.profile_actions .dropdown-item:focus,
.dropdown-item:hover {
  background: #F5F8FD;
}

.profile_actions .dropdown-item:focus a,
.dropdown-item:hover a {
  background: #F5F8FD;
  color: #00A3FF;
  cursor: pointer;
}

.profile_actions .dropdown-item .menu-image {
  stroke: #333333;
}

.profile_actions .dropdown-item .menu-image.menu-fill {
  fill: #333333;
}

.profile_actions .dropdown-item:focus .menu-image,
.dropdown-item:hover .menu-image {
  stroke: #00A3FF;
}

.profile_actions .dropdown-item:focus .menu-image.menu-fill,
.dropdown-item:hover .menu-image.menu-fill {
  fill: #00A3FF;
}


.save_account .dropdown-menu {
  padding: 8px 14px;
  color: #EA521C;
  border: 1px solid #FFE277;
  background: #FFF0BB;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  box-shadow: 4px 4px 0px 0px #00000012;
}

.save_account.show .dropdown-menu {
  top: calc(100% + 4px);
  max-height: 200px;
  opacity: 1;
}

.nav-item {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 80px;
  color: var(--primary);
  font-weight: 700;
  font-size: 18px;
  position: relative;
  background: #fff;
}

.nav-item:hover>.nav-item-tool-tip {
  display: block;
}

.nav-item:focus>.nav-item-tool-tip {
  display: none;
}

.nav-item-tool-tip {
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  padding: 8px 14px;
  background-color: #FFF0BB;
  border: 1px solid #FFE277;
  box-shadow: 4px 4px 0px 0px #00000012;
  border-radius: 8px;
}

.search-header {
  background: #F8FAFC;
  border: 1px solid var(--primary);
  border-radius: 56px;
  padding: 0 10px;
  transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.box-search {
  background: none;
  border: none;
  font-weight: 700;
  line-height: 38px;
  color: var(--primary) !important;
  text-overflow: ellipsis;
  transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.box-search::placeholder {
  opacity: 0.5;
  color: var(--primary) !important;
}

.button-search {
  width: 24px;
  height: 24px;
  content: url('data:image/svg+xml,<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="8.99991" cy="8.25" rx="4.90909" ry="4.5" stroke="%23FF7800" stroke-width="2"/><path d="M8.99996 6C8.67762 6 8.35844 6.0582 8.06064 6.17127C7.76284 6.28434 7.49226 6.45008 7.26433 6.65901C7.0364 6.86794 6.8556 7.11598 6.73225 7.38896C6.6089 7.66195 6.54541 7.95453 6.54541 8.25" stroke="%23FF7800" stroke-width="2" stroke-linecap="round"/><path d="M16.3637 15L13.9092 12.75" stroke="%23FF7800" stroke-width="2" stroke-linecap="round"/></svg>');
  left: 12px;
}


.fade-in-top {
  -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-out-top {
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
}

.profile_actions_3 {
  left: calc(100% + 5px);
  top: 0
}

.profile_actions_3::after {
  content: '';
  position: absolute;
  left: -5px;
  top: 20px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: #fff;
}

.user_info_sm {
  display: none;
}

@media only screen and (max-width: 1140px) {
  .user_info_sm {
    display: block !important;
  }

  .user_info_lg {
    display: none !important;
  }
}
</style>