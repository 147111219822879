<template>
  <footer id="footer" class="d-flex align-items-center">
    <div class="ft-transparent"></div>
    <div class="container">
      <div class="footer-container">
        <div class="copy-right-txt">{{ $t('copy_right') }}</div>
        <ul class="btn__social">
          <li>
            <a @click="handleGACustomEvent('ITC_W_1005')" href="https://www.facebook.com/ismart.ecosystem"
              target="_blank">
              <img src="/themes/ito/images/social-fb.svg" alt="">
            </a>
          </li>
          <li>
            <a @click="handleGACustomEvent('ITC_W_1006')" href="https://www.youtube.com/user/DigiClassVietnam"
              target="_blank">
              <img src="/themes/ito/images/social-ytb.svg" alt="">
            </a>
          </li>
          <li>
            <a @click="handleGACustomEvent('ITC_W_1007')" href="https://twitter.com/iSMARTEducatio1" target="_blank">
              <img src="/themes/ito/images/social-instagram.svg" alt="">
            </a>
          </li>
          <li><a href="http://online.gov.vn/Home/WebDetails/83151" target="_blank"><img
                src="/themes/ito/images/icons/bo-cong-thuong.png" alt=""></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { getMenus } from '../models/menu'
import $ from 'jquery'

export default {
  name: "FooterElement",

  data() {
    return {
      // user: this.$store.getters.user_info,
      menus: [],
      user: {
        name: '',
        fullname: 'Guest',
      }
    };
  },

  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },

  created() {
    this.fetch();
  },

  updated: function () {
    this.$nextTick(function () {
      App.init();
    })
  },

  methods: {
    fetch() {

    },
    handleGACustomEvent(element) {
      const user_id = this.user_info.user_id || 0;
      var params = {
        'w_user_id': user_id
      }
      params[element] = user_id
      gtag('event', element, params)
    }

  }
}
</script>

<style scoped>
.btn__social {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
</style>
