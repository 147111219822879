import { render, staticRenderFns } from "./YourSpaceBadges.vue?vue&type=template&id=9fb77ab6&scoped=true"
import script from "./YourSpaceBadges.vue?vue&type=script&lang=js"
export * from "./YourSpaceBadges.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb77ab6",
  null
  
)

export default component.exports