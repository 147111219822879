<template>
  <div id="getRewardedLockModal" class="modal fade modal-flex" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content pyro reward-modal reward-modal-small">
        <div class="modal-body">
          <h4 class="title">{{ $t('claim_success') }}</h4>
          <img src="/themes/ito/images/mascot/isaac-key.svg" alt="" height="150px">
          <div class="gem">
            <span id="congratulation_gem">+{{ reward }}</span>
          </div>
          <div class="d-flex w-100 justify-content-center">
            <button class="ito-btn ito-btn-primary px-5" data-bs-dismiss="modal" aria-label="Close">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetRewardLockModal",
  props: ['reward'],
}
</script>

<style scoped></style>
