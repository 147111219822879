<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/percentage-circle.css">
    <link rel="stylesheet" href="/themes/ito/css/leaderboard.css?v=18">

    <div class="container">

      <div id="leaderboard" class="card" style="height: calc(100vh - 190px);">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('leader_board') }}</span>
            <div class="tools">
              <button class="tool-item" :class="'all_users' == filter_type ? ' active' : ''"
                      @click="filter('all_users')">
                <img src="/themes/ito/images/earth.svg" alt=""/>
                {{ $t('all_users') }}
              </button>
              <button class="tool-item" :class="'school' == filter_type ? ' active' : ''"
                      v-if="user_info.student_type == 1" @click="filter('school')">
                <img src="/themes/ito/images/school.svg" alt=""/>
                {{ $t('school') }}
              </button>
              <div class="month-select">
                <i class="ito-icon-next next" @click="prev"></i>
                <span class="text">{{ month_txt }} {{ year }}</span>
                <i :class="isNextDisable() ? 'ito-icon-prev prev prev-disable' : 'ito-icon-prev prev'"
                   @click="next"></i>
              </div>
            </div>
          </h5>
          <div class="card-content">
            <div class="board">
              <div class="board-left" style="margin-right: 10px">
                <div class="row1">
                  <div class="d-flex align-items-center">
                    <div class="flex">{{ $t('top_gems_leaders') }}</div>
                    <div>
                      <img src="/themes/ito/images/icon-gem-3.svg" alt=""/>
                    </div>
                  </div>
                </div>
                <div class="row23" style="height: calc(100vh - 365px)">
                  <template v-if="leaderboards.length > 0">
                    <div class="row2">

                      <div class="nano">
                        <div class="nano-content">
                          <template v-for="(user, index) in leaderboards">
                            <div :class="['level-item', 'level' + index]">
                              <img class="level" :src="getLevelUrl(index)" alt=""
                                   v-if="index <= 2"/>
                              <span class="level-txt" v-else>{{ index + 1 }}</span>

                              <div class="avatar">
                                <img :src="user.avatar_url" alt="" v-if="user.avatar_url"/>
                                <img src="/themes/ito/images/avatar-default.svg" alt=""
                                     v-else/>

                                <div class="emotion" v-if="user.emoji_url">
                                  <img class="emotion" :src="user.emoji_url" alt="">
                                </div>
                              </div>
                              <div class="name">
                                {{ user.fullname || user.username }}

                                <small>{{ user.username }}</small>
                              </div>
                              <div class="gem">
                                <div>{{ user.gem || user.username }} <i
                                    class="ito-icon-gem"></i></div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>

                    </div>
                    <div class="row3">
                      <div class="level-items top10" v-if="my_rank && my_rank.rank != 0">
                        <div class="level-item">
                          <div class="no">{{ my_rank.rank }}</div>
                          <div class="avatar">
                            <img :src="my_rank.avatar_url" alt="" v-if="my_rank.avatar_url"/>
                            <img src="/themes/ito/images/avatar-default.svg" alt="" v-else/>

                            <img class="emotion" :src="my_rank.emoji_url" alt=""
                                 v-if="my_rank.emoji_url">
                          </div>
                          <div class="name">{{ my_rank.fullname || my_rank.username }}</div>
                          <div class="gem">
                            <div class="gem-info">
                              <div class="total">{{ my_rank.gem | toCommas }}</div>
                              <i class="ito-icon-gem"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="board-right-message" v-else>
                        <p><b>{{ $t('you_not_rated') }}</b></p>
                        {{ $t('leader_board_not_rated_gem_decs') }}
                      </div>
                    </div>
                  </template>
                  <div class="leaderboard-empty gems-board" v-else>
                    <div class="leaderboard-empty-txt">
                      {{ $t('no_leaderboard_record') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="board-left" style="margin-left: 10px">
                <div class="row1" style="background: #B073FC;">
                  <div class="d-flex align-items-center">
                    <div class="flex">{{ $t('top_crowns_leaders') }}</div>
                    <div>
                      <img src="/themes/ito/images/icon-crown-3.svg" alt=""/>
                    </div>
                  </div>
                </div>
                <div class="row23" style="height: calc(100vh - 365px)">
                  <template v-if="crown_leaderboards.length > 0">
                    <div class="row2">

                      <div class="nano">
                        <div class="nano-content">
                          <template v-for="(user, index) in crown_leaderboards">
                            <div :class="['level-item', 'level' + index]">
                              <img class="level" :src="getLevelUrl(index)" alt=""
                                   v-if="index <= 2"/>
                              <span class="level-txt" v-else>{{ index + 1 }}</span>

                              <div class="avatar">
                                <img :src="user.avatar_url" alt="" v-if="user.avatar_url"/>
                                <img src="/themes/ito/images/avatar-default.svg" alt=""
                                     v-else/>

                                <div class="emotion" v-if="user.emoji_url">
                                  <img class="emotion" :src="user.emoji_url" alt="">
                                </div>
                              </div>
                              <div class="name">
                                {{ user.fullname || user.username }}

                                <small>{{ user.username }}</small>
                              </div>
                              <div class="gem">
                                <div>{{ user.gem || user.username }}
                                  <img src="/themes/ito/images/icon-crown-3.svg" alt=""/>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>

                    </div>
                    <div class="row3">
                      <div class="level-items top10" v-if="crown_my_rank && crown_my_rank.rank != 0">
                        <div class="level-item">
                          <div class="no">{{ crown_my_rank.rank }}</div>
                          <div class="avatar">
                            <img :src="crown_my_rank.avatar_url" alt=""
                                 v-if="crown_my_rank.avatar_url"/>
                            <img src="/themes/ito/images/avatar-default.svg" alt="" v-else/>

                            <img class="emotion" :src="crown_my_rank.emoji_url" alt=""
                                 v-if="crown_my_rank.emoji_url">
                          </div>
                          <div class="name">{{ crown_my_rank.fullname || crown_my_rank.username }}
                          </div>
                          <div class="gem">
                            <div class="gem-info">
                              <div class="total">{{ crown_my_rank.gem | toCommas }}</div>
                              <img src="/themes/ito/images/icon-crown-3.svg" alt=""/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="board-right-message" v-else>
                        <p><b>{{ $t('you_not_rated') }}</b></p>
                        {{ $t('leader_board_not_rated_crown_decs') }}
                      </div>
                    </div>
                  </template>
                  <div class="leaderboard-empty crowns-board" v-else>
                    <div class="leaderboard-empty-txt">
                      {{ $t('no_leaderboard_record') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {updateProfile} from '../../models/profile'
import {showDialog} from '../../utils/dialog'
import {mapGetters} from 'vuex'
import axios from "axios";
import {getDailyChallenge} from "../../models/reward";
import {getLeaderboard} from "../../models/leadboard";

export default {
  name: "LeaderBoard",

  data() {
    const d = new Date();
    const months = [
      this.$i18n.t('jan'),
      this.$i18n.t('feb'),
      this.$i18n.t('mar'),
      this.$i18n.t('apr'),
      this.$i18n.t('may'),
      this.$i18n.t('jun'),
      this.$i18n.t('jul'),
      this.$i18n.t('aug'),
      this.$i18n.t('sep'),
      this.$i18n.t('oct'),
      this.$i18n.t('nov'),
      this.$i18n.t('dec')
    ];
    const month = this.$route.query.month || d.getMonth() + 1;
    const year = this.$route.query.year || d.getFullYear();

    return {
      leaderboards: [],
      my_rank: null,
      crown_leaderboards: [],
      crown_my_rank: null,
      year: year,
      months: months,
      month: month,
      month_txt: months[month - 1],
      filter_type: 'all_users'
    };
  },

  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    })
  },

  watch: {
    $route() {
      this.fetch()
    }
  },

  created() {
    this.$store.commit("setMenuActive", "home");
    this.fetch();
  },

  methods: {
    fetch() {
      this.getData();
    },

    getLevelUrl(index) {
      const file_name_index = index + 1;
      return '/themes/ito/images/lb-level-' + file_name_index + '.svg'
    },

    isNextDisable() {
      const that = this;
      const date = new Date();
      const current_month = date.getMonth() + 1;
      const current_year = date.getFullYear();
      const year_next = that.month == 12 ? parseInt(that.year) + 1 : that.year;
      const month_next = that.month == 12 ? 1 : parseInt(that.month) + 1;

      if (current_year == year_next && current_month < month_next || current_year < year_next) {
        return true;
      }

      return false;
    },
    getData() {
      let that = this;
      that.$store.commit("setLoading", true);

      axios.all([
        getLeaderboard({
          grade: that.user_info.grade,
          school: that.filter_type == 'all_users' ? 0 : 1,
          year: that.year,
          month: that.month
        }),
      ]).then(axios.spread((response) => {
        that.$store.commit("setLoading", false);

        if (response.data.code != 0) {
          return;
        }

        that.leaderboards = response.data.results.gem.leaderboard || [];
        that.my_rank = response.data.results.gem.my_rank || null;

        that.crown_leaderboards = response.data.results.crown.leaderboard || [];
        that.crown_my_rank = response.data.results.crown.my_rank || null;

        that.month_txt = that.months[that.month - 1];

        that.$nextTick(function () {
          initNanoScroller();
        })
      }));
    },

    next() {
      const that = this;
      const date = new Date();
      const current_month = date.getMonth() + 1;
      const current_year = date.getFullYear();
      const year_next = that.month == 12 ? parseInt(that.year) + 1 : that.year;
      const month_next = that.month == 12 ? 1 : parseInt(that.month) + 1;

      if (current_year == year_next && current_month < month_next) {
        return;
      }
      that.year = year_next;
      that.month = month_next;
      this.getData();


    },

    prev() {
      const that = this;
      const year_prev = that.month == 1 ? parseInt(that.year) - 1 : that.year;
      const month_prev = that.month == 1 ? 12 : parseInt(that.month) - 1;

      that.year = year_prev;
      that.month = month_prev;
      this.getData();

    },

    filter(type) {
      this.filter_type = type;
      this.getData();
    }
  }
}
</script>

<style scoped>
.board-list-empty {
  margin: auto;
  text-align: center;
}

/*.level-items.top10 {
        height: 430px;
        overflow: auto;
    }*/

.level-items.top10::-webkit-scrollbar,
.level-items.top10::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

.level-items.top10::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.level-items.top10:hover {
  color: rgba(0, 0, 0, 0.3);
}

#leaderboard .board > .board-left .row3 .board-right-message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  padding: 15px 5px;
}

#leaderboard .board > .board-left .row3 .board-right-message b {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.ito-tab-btn-primary {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
  background: #FFFFFF;
  border-radius: 80px;
  margin: 4px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  font-weight: 700;
}

.tool-item {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background: #FFFFFF;
  border-radius: 90em;
  border: none;
  padding: 6px 12px;
  color: #000;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tool-item.active {
  background: #FF7800;
  color: #FFFFFF;
}
</style>
