<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/exchange-gift.css">

        <div class="container">
            <div id="page-header">
                <ul class="breadcrumb">
                    <li class="icon">
                        <router-link :to="{ name: 'home' }">
                            <img src="/themes/ito/images/icon-profile.svg" alt="">
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'exchange_gift' }">
                            {{$t('exchange_gift')}}
                        </router-link>
                    </li>
                    <li class="separator"><i class="ito-icon-breadcrumb-1"></i></li>
                    <li>
                        <router-link :to="{ name: 'buy_sticker' }">
                            {{$t('buy_sticker')}}
                        </router-link>
                    </li>
                    <li class="separator"><i class="ito-icon-breadcrumb-1"></i></li>
                    <li class="active">{{$t('collection')}}</li>
                </ul>
            </div>

            <div class="card card-exchange-gift">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('collection')}}</span>
                    </h5>

                    <div class="card-content">
                        <div>
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation">
                                    <a href="#home" class="active" aria-controls="home" role="tab" data-bs-toggle="tab" @click="changeTag('astronault')">Astronault</a>
                                </li>
                                <li role="presentation">
                                    <a href="#profile" aria-controls="profile" role="tab" data-bs-toggle="tab" @click="changeTag('scientist')">Scientist</a>
                                </li>
                                <li role="presentation">
                                    <a href="#messages" aria-controls="messages" role="tab"
                                       data-bs-toggle="tab" @click="changeTag('doctor')">Doctor</a>
                                </li>
                                <li role="presentation">
                                    <a href="#settings" aria-controls="settings" role="tab"
                                       data-bs-toggle="tab" @click="changeTag('professor')">Professor</a>
                                </li>
                            </ul>

                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane active">
                                    <div class="sticker-list row">
                                        <template v-for="(item, index) in stickers">
                                            <div v-if="item.tag_name == tag || item.tag_name == ''" class="col-md-2 sticker-item">
                                                <div class="thumb">
                                                    <div class="thumb-content">
                                                        <img :src="item.image" alt="">

                                                        <div class="total">{{item.collected}}</div>
                                                    </div>
                                                </div>
                                                <div class="title">{{item.name}}</div>
                                                <button class="btn btn-exchange-gift">{{$t('buy_more')}}</button>
                                                <!--<div class="gem">-->
                                                    <!--{{item.price}} <i class="ito-icon-gem"></i>-->
                                                <!--</div>-->
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {pushQueries} from '../../utils/utils'
    import {mapGetters} from 'vuex'
    import {getAllStikers, getStikerCollection} from "../../models/exchange_gift";
    import {claimDailyBonus} from "../../models/reward";

    export default {
        name: "ExchangeCollection",
        data() {
            return {
                stickers: [],
                isLoading: true,
                tag: ''
            };
        },

        created() {
            //this.$store.commit('setMenuActive', 'inbox');

            this.fetch();
        },

        methods: {
            changeTag(tag){
                this.tag = tag;
            },
            fetch(page = 1) {
                const that = this;

                this.page = page;

                const params = {
                    page: this.page,
                };

                axios.all([
                    getStikerCollection(params),
                ]).then(axios.spread((response) => {
                    that.isLoading = false;

                    //that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    that.stickers = response.data.results.item || [];
                }));
            },

        }
    }
</script>

<style scoped>

</style>
