<template>
    <iframe id="chatBox" class="chatBox" :src="chat_client_url+'/?embed=1&lang='+lang" frameborder="0"
        allowfullscreen width="100%" height="100%" @load="loaded()"></iframe>
</template>
<script>
import { getLanguage } from "../../utils/utils";
import {VUE_APP_CHAT_CLIENT_URL} from "@/constant";


export default {
    name: "ChatBox",
    data() {
        var lang = getLanguage();
        if (lang == 'en') {
            lang = 'en-US';
        } else if (lang == 'vi') {
            lang = 'vi-VN';
        }
        return {
            lang: lang,
            chat_client_url: VUE_APP_CHAT_CLIENT_URL,
        }
    },

    methods: {
        loaded() {
            var iframe = document.getElementById("chatBox");
            this.$emit("chat_loaded", iframe);
        }
    },
};
</script>
