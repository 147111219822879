import {get, post} from "../APIGateWay";

export async function getPlacementTest(params = {}) {
    return await get(params, '/placement-test')
}

export async function startPlacementTest(data) {
    return await post(data, '/placement-test/start')
}

export async function resultPlacementTest(data) {
    return await post(data, '/placement-test/result')
}