'use strict';

import Vue from 'vue'
import Vuex from 'vuex'
import VueContentPlaceholders from 'vue-content-placeholders'
import { authLogout } from './models/auth'
import { getProfile, getConfigs } from './models/profile'
import axios from "axios"
import app from './main'
import { convertSecondToText, toCommas } from "./utils/utils";

moment.locale('vi', {
    months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_'),
    monthsShort: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_'),
    weekdaysShort: 'Chủ nhật_Thứ 2_Thứ 3_Thứ 4_Thứ 5_Thứ 6_Thứ 7'.split('_'),
    relativeTime: {
        future: 'Trong %s',
        past: 'Cách đây %s',
        s: 'vài giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm'
    },
});

Vue.use(Vuex);
Vue.use(VueContentPlaceholders);

Vue.filter('toCurrency', function(value, currency) {
    const num = new Intl.NumberFormat('us-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(parseFloat(value));

    return num.replace('US$', '').replace('$', '') + '$';
});

Vue.filter('formatDate', function(value, format = 'HH:mm DD/MM/YYYY') {
    if (value) {
        return moment(String(value)).format(format)
    }
});

Vue.filter('convertUnitToTime', function(time, format = 'DD MMM, YYYY HH:mm:ss') {
    if (time) {
        const lang = localStorage.getItem('lang') || 'vi';

        return moment.unix(time).locale(lang).format(format)
    }

    return '';
});

Vue.filter('convertSecondToCountdown', function(time) {
    if (time) {
        const current_time = Math.floor(Date.now() / 1000);
        const countdown = time - current_time;

        console.log(countdown, time, current_time)

        if (countdown <= 0) {
            return "00:00:00";
        }

        return convertSecondToText(countdown)
    }

    return '';
});

Vue.filter('toCommas', function(value) {
    if (value) {
        return toCommas(value)
    }

    return '0';
});

//axios.defaults.withCredentials = true;

axios.interceptors.response.use(function(response) {
    // Do something with response data
    // if (response.data.code == 401 && window.location.pathname != '/register-anomynous') {
    if (response.data.code == 401 && window.location.pathname != '/login') {
        // window.location.href = "/register-anomynous"
        window.location.href = "/login"
    }

    return response;
}, function(error) {
    // Do something with response error
    console.log(error);

    return Promise.reject(error);
});

export const store = new Vuex.Store({
    state: {
        //layout: 'app-layout',
        layout: 'loading-layout',
        locale: 'en',
        is_login: localStorage.getItem('is_login') || false,
        user_info: {
            name: 'Guest',
            profile: {
                fullname: ''
            },
            menus: [],
            balances: [],
        },
        configs: JSON.parse(localStorage.getItem('configs')),
        alert: {
            is_show: false,
            type: 'error',
            message: ''
        },
        is_loading: false,
        menu_active: 'home',
        live_classes: [],
        showEffect: true,
    },

    mutations: {
        SET_LAYOUT(state, payload) {
            state.layout = payload
        },

        SET_USER_INFO(state, payload) {
            state.user_info = payload;
        },

        SET_LANG(state, locale) {
            app.$i18n.locale = locale
        },

        setAlert(state, alert) {
            state.alert = alert;
        },

        setLoading(state, isLoading) {
            state.is_loading = isLoading;
        },

        setConfigs(state, configs) {
            state.configs = configs;
        },

        setLiveClasses(state, live_classes) {
            state.live_classes = live_classes;
        },

        setShowEffect(state, showEffect) {
            state.showEffect = showEffect;
        },

        setMenuActive(state, menu) {
            state.menu_active = menu;
        },

        setAuth(state, isLogin) {
            state.is_login = isLogin;

            localStorage.setItem('is_login', isLogin);
        },

        auth_success(state, access_token, user_info) {
            state.user_info = user_info;
        },

        auth_error(state) {

        },

        logout(state) {
            state.user_info = {
                name: 'Guest'
            };
            state.is_login = false;

            localStorage.removeItem('is_login');
            localStorage.removeItem('configs');
            localStorage.removeItem('program');
            localStorage.removeItem("grade");
            localStorage.removeItem("access_token");
        }
    },

    actions: {
        SET_USER_INFO: async({ commit }) => {
            // if (window.location.pathname != '/register-anomynous') {
            if (window.location.pathname != '/login') {
                await axios.all([
                    getProfile({
                        notification_id: window.localStorage.getItem('firebaseToken'),
                        type_code: 'web'
                    })
                ]).then(axios.spread(({ data }) => {
                    commit("setLoading", false);

                    commit("SET_LAYOUT", 'app-layout');

                    if (data.code != 0) {
                        localStorage.removeItem('is_login');
                        localStorage.removeItem('grade');
                        console.log('Auth require from store 147')

                        /////window.location.href = "/register-anomynous";

                        return;
                    }

                    const user_info = data.results;

                    user_info.avatar_url = user_info.avatar_url || '/themes/ito/images/avatar-default-orange.svg';
                    //user_info.first_login = 1;

                    commit("SET_USER_INFO", user_info);
                })).catch(error => {
                    console.log(error)

                    //commit("SET_LAYOUT", 'app-layout');
                    commit('logout');

                    console.log('Auth require from store 162')

                    //window.location.href = "/login"
                });
            }
        },

        setLang({ commit }, locale) {
            commit('SET_LANG', locale)
        },

        setAlert({ commit }, alert) {
            commit('setAlert', alert);
        },

        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading);
        },

        setConfigs({ commit }, configs) {
            commit('setConfigs', configs);
        },

        setLiveClasses({ commit }, live_classes) {
            commit('setLiveClasses', live_classes);
        },

        setShowEffect({ commit }, showEffect) {
            commit('setShowEffect', showEffect);
        },

        setMenuActive({ commit }, menu) {
            commit('setMenuActive', menu);
        },

        setAuth({ commit }, isAuth) {
            commit('setAuth', isAuth);
        },

        logout({ commit }) {
            authLogout().then(({ data }) => {
                commit('logout');

                localStorage.removeItem('is_login');
                localStorage.removeItem('grade');
            });
        }
    },

    getters: {
        isLoggedIn(state) {
            return state.is_login;
        },

        layout(state) {
            return state.layout
        },

        isLoading(state) {
            return state.is_loading
        },

        menuActive(state) {
            return state.menu_active
        },

        alert(state) {
            return state.alert
        },

        user_info(state) {
            return state.user_info
        },

        configs(state) {
            return state.configs
        },

        live_classes(state) {
            return state.live_classes
        },

        showEffect(state) {
            return state.showEffect
        }
    }
});