<template>
    <div>
        <nav-bar-auth />
        <div id="wrapper" class="wrapper d-flex align-items-stretch">
            <div id="left-corner" class="left-corner"> </div>
            <div id="right-corner" class="right-corner"> </div>
            <div id="content">
                <router-view></router-view>
            </div>
            <footer-element />
        </div>
        <div class="modal fade" id="selectLangModal" tabindex="-1" role="dialog" aria-labelledby="selectLangModal"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('choose_language') }}</h3>
                    <div class="select-lang">
                        <div :class="locate == localize.lang ? 'select-lang-item active' : 'select-lang-item'"
                            @click="setLocate(localize)" v-for="(localize, index) in configs.localize">
                            <div class="lang-icon">
                                <img :src="localize.img_flag" alt="">
                            </div>
                            <div class="lang-text">
                                {{ $t(localize.lang) }}
                            </div>
                            <div class="lang-checked" v-if="locate == localize.lang">
                                <i class="ito-icon-checked-white"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="ito-btn ito-btn-primary mt-20" @click="updateLocate()">
                            {{ $t('confirm') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="loading" id="loading" v-if="isLoading">
            <img src="/themes/ito/images/loadding.gif" alt="" />
        </div>

    </div>
</template>

<script>
import SideBar from "../SideBar";
import FooterElement from "../FooterElement";
import NavBarAuth from "../NavBarAuth";
import { getConfigs, getLanguage, soundClick } from "../../utils/utils";
import { updateLanguage } from '../../models/profile';

export default {
    name: "AuthLayout",
    data() {
        const grade_default = localStorage.getItem('grade');
        //const configs = getConfigs();
        //const localizes = configs.localize || [];

        const configs = this.$store.getters.configs;

        return {
            locate: getLanguage(),
            locate_obj: null,
            user: {
                grade: 1
            },
            grade_selected: grade_default || 1,
            placement_test: {
                id: 0,
                condition_pass: 0,
                time: 0,
                total_question: 0,
            },
            //configs: configs,
            //localizes: localizes,
            localizes: configs ? configs.localize : [],
        };
    },

    components: {
        NavBarAuth,
        FooterElement,
        SideBar
    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading
        },
        configs() {
            const configs = this.$store.getters.configs;
            if (configs) {
                return configs;
            }

            return {
                localize: []
            }
        },
    },

    methods: {
        getSoundClick() {
            soundClick();
        },

        setLocate(e) {
            this.getSoundClick();
            this.locate_obj = e;
            this.locate = e.lang;
        },

        updateLocate() {
            const that = this;
            this.getSoundClick();
            localStorage.setItem('lang', this.locate);
            if (that.locate_obj != null) {
                window.location.reload();
            }
        }
    }
}
</script>
<style scoped>
.left-corner {
    position: fixed;
    width: 13vw;
    max-width: 250px;
    z-index: 2;
    top: 165px;
    left: 50px;
    animation: 1s ease-out 0s 1 slideInFromLeft, 3s ease-in-out 1s infinite floating;
    content: var(--left-corner-image);
}


.right-corner {
    position: fixed;
    width: 13vw;
    max-width: 200px;
    top: 115px;
    right: 50px;
    animation: 1s ease-out 0s 1 slideInFromRight;
    content: var(--right-corner-image);
}

.left-corner.hide {
    transition: all 1s ease-out;
    left: -100%;
}

.right-corner.hide {
    transition: all 1s ease-out;
    right: -100%;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes floating {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0px, 0px);
    }
}
</style>