<template>
    <iframe id="game" class="game" :src="game_url" frameborder="0" allowfullscreen width="100%" height="100%"
        scrolling="no" @load="loaded()"></iframe>
</template>
<script>

export default {
    name: "Game",
    props: {
        game_url: String,
    },

    methods: {
        loaded() {
            var iframe = document.getElementById("game");
            this.$emit("game_loaded", iframe);
        }
    },
};
</script>
