<template>
    <div class="bonus-item">
        <div class="bonus-item-content w-100">
            <div class="title">{{ today_id == bonus.id || bonus.status == 1 ? $t('to_day_uppercase') : '' }}</div>
            <div class="thumb">
                <div class="thumb-content">
                    <div class="bonus-item-body" :class="{
                        claimed: bonus.status == 2,
                        current: today_id == bonus.id,
                        special: bonus.desc == 'day_7'
                    }">
                        <div class="row1">{{ $t(bonus.desc) }}</div>
                        <div class="row2"><i class="ito-icon-bonus"></i></div>
                        <div class="row3">+{{ bonus.gem }}</div>
                        <div class="row4" v-if="bonus.status == 2 || (bonus.status == 1 && user_info.first_login == 0)">
                            <i class="ito-icon-checked-1"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="ito-btn ito-btn-sm ito-btn-primary" v-if="bonus.status == 1 && user_info.first_login == 1"
                    @click="claim()">
                    {{ $t('claim') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import { claimDailyBonus } from "../../models/reward";

export default {
    name: "DailyBonusItem",
    props: ['bonus', 'today_id'],
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        })
    },

    data() {
        return {};
    },

    created() {

    },

    methods: {
        claim() {
            const that = this;
            claimDailyBonus().then((response) => {
                if (response.data.code != 0) {
                    return;
                }
                that.bonus.status = response.data.results.status || '';
                that.user_info.dailychallenge_noti = response.data.results.dailychallenge_noti || 0;
                const new_gem = response.data.results.update_gem || 0;
                that.user_info.gem = new_gem;
                this.$emit('updateBonus', response.data.results);
                that.$store.dispatch("SET_USER_INFO");
                $('#rewardModal').modal('show');
            }).catch(({ response }) => {
                console.error(response);
            });
        },

    }
}
</script>

<style scoped></style>
