<template>
    <div id="content">
        <div class="container">
            <div id="page-header-fixed">
                <div class="container">
                    <div id="page-header">
                        <ul class="breadcrumb">
                            <li class="icon">
                                <a @click="$router.go(-1)">
                                    <img src="/themes/ito/images/back-to.svg" alt="">
                                </a>
                            </li>
                            <li class="active">{{ unit_name }} - {{ lesson_name }} {{ title_lesson }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mark-lesson" v-if="screen != 'list'"></div>
            <div class="card" :class="screen != 'list' ? 'box-leson' : 'border-0 bg-transparent mt-3'">
                <div class="" v-if="screen == 'list'">
                    <div class="extensin-list">
                        <template v-for="(item, index) in items">
                            <div class="extension-item" style="margin-top: 20px;">
                                <div class="extension-image">
                                    <img src="/themes/ito/images/extensions/extension1.svg" />
                                </div>
                                <div class="extension-content">
                                    <div class="extension-content-title">{{ item.name }}</div>
                                    <div class="extension-content-des"></div>
                                </div>
                                <div class="extension-status" style="padding: 0 20px;">
                                    <i v-if="item.complete == 1" class="ito-icon-checked"></i>
                                    <i v-else class="ito-icon-checked-disable"></i>
                                </div>
                                <div class="extension-action">
                                    <button class="ito-btn ito-btn-primary" @click="detailLessonOpen(item.id, item.type)">{{
                                        $t('discover') }}</button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'vocabulary'">
                    <div class="lesson-action-button" @click="quitPracticeNotCf"><span>+</span></div>
                    <div class="card-content" style="height: 100%; padding: 0;">
                        <iframe ref="extensionFrame" :src="item.content" frameborder="0" allowfullscreen width="100%"
                            height="100%"></iframe>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'lecture'" style="background: #fff;">
                    <div class="lesson-action-button" @click="quitPracticeNotCf"><span>+</span></div>
                    <h5 class="card-title">
                        <span class="title">Lecture</span>
                    </h5>
                    <div class="card-content" style="height: calc(100% - 52px);">
                        <iframe ref="extensionFrame" width="100%" height="100%" :src="item.content" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="card-body" v-if="screen == 'practice'">
                    <div class="lesson-action-button" @click="quitPractice"><span>+</span></div>
                    <div class="card-content" style="height: 100%; padding: 0;">
                        <iframe ref="extensionFrame" :src="item.content" frameborder="0" allowfullscreen width="100%"
                            height="100%" scrolling="no"></iframe>

                    </div>
                </div>
                <div class="card-body" v-if="screen == 'handouts'">
                    <div class="lesson-action-button" @click="quitPracticeNotCf"><span>+</span></div>
                    <div class="card-content" style="height: 100%; padding: 0;">
                        <iframe ref="extensionFrame" :src="item.content" frameborder="0" allowfullscreen width="100%"
                            height="100%" scrolling="no"></iframe>

                    </div>
                </div>
                <div class="card-body" v-if="screen == 'story'">
                    <div class="lesson-action-button" @click="quitPracticeNotCf"><span>+</span></div>
                    <div class="card-content" style="height: 100%; padding: 0;">
                        <iframe ref="extensionFrame" :src="item.content" frameborder="0" allowfullscreen width="100%"
                            height="100%"></iframe>
                    </div>
                </div>
            </div>

            <div class="modal fade reward-popup" id="rewardPopup" tabindex="-1" role="dialog" aria-labelledby="rewardPopup"
                aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                    <div class="modal-content box-shadow-main paymment-status">
                        <div class="close-popup" data-bs-dismiss="modal" @click="gotoWave"></div>
                        <h3 class="popup-title success">Completed practice</h3>
                        <div class="list-item-result-popup">
                            <div class="item-result-popup" :class="crown > 0 ? 'active' : ''"></div>
                            <div class="item-result-popup" :class="crown > 1 ? 'active' : ''"></div>
                            <div class="item-result-popup" :class="crown > 2 ? 'active' : ''"></div>
                        </div>
                        <div class="box-result-popup">
                            <div class="result-txt">You answered correctly</div>
                            <div class="result-score"><span class="score">{{ correct }}</span>/<span class="total">{{ total
                            }}</span> questions</div>
                        </div>
                        <button class="ito-btn ito-btn-primary" v-if="(next != '' && next != undefined)" @click="nextItem"
                            data-bs-dismiss="modal" aria-label="Close">{{ (next != '' && next != undefined) ? 'Go to ' +
                                next.name : 'Close' }}</button>
                    </div>
                </div>
            </div>
            <div class="modal fade reward-popup" id="rewardQuiz" tabindex="-1" role="dialog" aria-labelledby="rewardQuiz"
                aria-hidden="true" data-backdrop="static" data-keyboard=false>
                <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 330px;">
                    <div class="modal-content box-shadow-main paymment-status">
                        <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                        <h3 class="popup-title success">Completed fact reading</h3>

                        <div class="box-result-popup">
                            <div class="result-txt">You answered correctly</div>
                            <div class="result-score"><span class="score">{{ correct }}</span>/<span class="total">{{ total
                            }}</span> </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalVocabSuccess" class="modal fade modal-flex" tabindex="-1" role="dialog"
                style="text-align: center">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                        <div class="modal-body">
                            <h4 class="title">{{ $t('congratulations') }}</h4>

                            <img src="/themes/ito/images/get-rewarded.svg" alt="">
                            <p class="mt-2">{{ $t('you_have_completed_your_vocabulary') }}</p>
                            <button class="ito-btn ito-btn-primary" data-bs-dismiss="modal" aria-label="Close">Go to</button>
                            <!-- <button class="next-item-modal" v-if="(next != '' && next != undefined)" @click="nextItem" data-bs-dismiss="modal" aria-label="Close">{{(next != '' && next != undefined)? 'Go to ' + next.name : 'Close'}}</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        <extension-result-dialog ref="extensionResultDialog"></extension-result-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { getUnitLessonDetail, getUnitLessonItem, getItemInfo } from "../../models/dashboard";
import { getConfigs } from "../../utils/utils";
import { ConfirmDialogue, ExtensionResultDialog } from "../modal";

export default {
    name: "ExtensionDetail",
    components: { ConfirmDialogue, ExtensionResultDialog },
    title: 'Extension detail',
    props: [
        'user_info'
    ],
    data() {
        const programData = localStorage.getItem('program');
        const configs = getConfigs();
        const programs = configs.programs;
        var program = {}
        programs.forEach(p => {
            if (p.code == programData) {
                program = p;
            }
        });

        return {
            isLoading: false,
            items: [],
            unit_id: this.$route.params.unit_id,
            lesson_id: this.$route.params.lesson_id,
            unit_name: '',
            lesson_name: '',
            title_lesson: '',
            item: {},
            screen: 'list',
            correct: 0,
            total: 0,
            crown: 0,
            current_item: 0,
            next: '',
            program: program,
        };
    },

    created() {
        this.getUnitLessonDetail()
        if (this.item) {
            const f = function (event) {
                switch (event.data.type) {
                    case 'update_item_info':
                        getItemInfo({
                            id: this.item.id,
                            item_type: this.item.type,
                            program: this.program.code
                        });
                }
                window.removeEventListener("message", f, false);
            }
            window.addEventListener("message", f);
        }
    },
    mounted() {
        window.addEventListener('message', this.handleMessage)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage)
    },
    watch: {
        'item': function (newVal, oldVal) {
            if (newVal) {
                const that = this;
                let index = this.items.findIndex(x => x.id == newVal.id);
                that.current_item = index;
                let array_next = [];
                for (let i = 0; i < that.items.length; i++) {
                    if (i < that.current_item && that.items[i].complete == 0) {
                        array_next.push(i);
                    }
                    that.next = that.items[array_next[0]];
                    if (i > that.current_item) {
                        if (that.items[i].complete == 0) {
                            that.next = that.items[i];
                            break;
                        } else {
                            if (array_next[0]) {
                                that.next = that.items[array_next[0]];
                                break;
                            }
                            else {
                                that.next = '';
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        getUnitLessonDetail() {
            const that = this;
            const { subject_id, unit_id, lesson_id } = that.$route.params;
            that.isLoading = true;

            axios.all([
                getUnitLessonDetail(subject_id, unit_id, lesson_id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    return;
                }
                that.items = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.title_lesson = response.data.results.title_lesson;
            }));
        },

        nextItem() {
            let id = this.next.id;
            const that = this;
            that.isLoading = true;

            axios.all([
                getUnitLessonItem(id),
            ]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    return;
                }

                that.item = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.title_lesson = response.data.results.title_lesson;
                that.screen = response.data.results.item.type;
            }));
        },

        handleMessage(event) {
            if (this.item) {
                switch (event.data.type) {
                    case 'fact_reading_quiz_done':
                        this.correct = event.data.correct;
                        this.total = event.data.total;
                        $('#rewardQuiz').modal('show');
                        break;
                    case 'update_item_info':
                        axios.all([
                            getItemInfo({
                                id: this.item.id,
                                item_type: this.item.type,
                                program: this.program.code
                            }),
                        ]).then(axios.spread((response) => {
                            if (response.data.code != 0) {
                                return;
                            }
                            if (this.item.type == 'practice') {
                                this.correct = response.data.results.current_result.correct_quest;
                                this.total = response.data.results.current_result.total_quest;
                                this.crown = response.data.results.current_result.crown;
                                let user = this.$store.state.user_info;
                                user.crown += parseInt(response.data.results.current_result.crown)
                                user.crown_month += parseInt(response.data.results.current_result.crown)
                                $('#rewardPopup').modal('show');
                            }
                            if (this.item.type == 'vocabulary') {
                                $('#modalVocabSuccess').modal('show');
                            }
                            this.getUnitLessonDetail();
                        }));
                        break;
                    case 'extension_done':
                        this.finishPractice(event.data.result)
                        break;

                }
            }
        },

        sendMessage(action) {
            this.$refs.extensionFrame.contentWindow.postMessage({
                "action": action,
            }, '*');
        },

        async showDialog(dialog, params) {
            const cfm = await dialog.show(params);
            return cfm;
        },

        quitPractice() {
            const dialog = this.$refs.confirmDialogue
            const params = {
                title: this.$t('confirm_close'),
                message: this.$t('confirm_close_content'),
                okButton: this.$t('quit'),
            }
            this.showDialog(dialog, params).then((confirm) => {
                if (confirm) {
                    this.screen = 'list';
                    this.$store.commit("setShowEffect", true);

                    getItemInfo({
                        id: this.item.id,
                        item_type: this.item.type,
                        program: this.program.code
                    });
                    this.getUnitLessonDetail()
                }
            })

        },
        quitPracticeNotCf() {
            this.screen = 'list';
            getItemInfo({
                id: this.item.id,
                item_type: this.item.type,
                program: this.program.code
            });
            this.getUnitLessonDetail()
        },

        finishPractice(result) {
            if (result && result.completed == 1) {
                const dialog = this.$refs.extensionResultDialog
                var nextPractice = undefined
                if (this.next != '' && this.next != undefined) {
                    nextPractice = this.$t('next_practice')
                }
                const params = {
                    title: this.$t('practice_result'),
                    message: result,
                    cancelButton: this.$t('redo_practice'),
                    okButton: nextPractice,
                }
                getItemInfo({
                    id: this.item.id,
                    item_type: this.item.type,
                    program: this.program.code
                });
                this.showDialog(dialog, params).then((confirm) => {
                    if (confirm) {
                        this.nextItem()
                    }
                    else {
                        this.detailLessonOpen(this.item.id, this.item.type)
                    }
                })
            }
        },


        gotoWave() {
            this.screen = 'list';
            this.show_next_item = false;
            this.$store.commit("setShowEffect", true);
            getItemInfo({
                id: this.item.id,
                item_type: this.item.type,
                program: this.program.code
            });
            this.getUnitLessonDetail()
            this.$forceUpdate()
        },

        detailLessonOpen(id, type) {
            const that = this;
            that.$store.commit("setShowEffect", false);
            that.isLoading = true;
            axios.all([
                getUnitLessonItem(id, this.program.code),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.item = response.data.results.item;
                that.unit_name = response.data.results.name_unit;
                that.lesson_name = response.data.results.name_lesson;
                that.screen = response.data.results.item.type;
            }));
        },
    }
}
</script>

<style scoped>
#page-header .breadcrumb {
    margin-bottom: 0;
}

.modal-sm {
    max-width: 400px;
}

.ito-icon-close {
    position: absolute;
    right: 18px;
}

.box-result-popup {
    padding: 7px 15px;
}

.extension-item {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 0px 0px #E7E7E740 inset;
    box-shadow: 4px 4px 0px 0px #00000012;
    display: flex;
    align-items: center;
}

.extension-content-title {
    color: #000;
    font-size: 14px;
    font-weight: 700;
}

.extension-content-des {
    color: #000;
    font-size: 12px;
    font-weight: 400;
}

.extension-image {
    margin-right: 12px;
}

.extension-content {
    width: 100%;
}
</style>