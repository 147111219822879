<template>
    <div class="achievement-item" :class="{ active: achievement.status == 1 }">
        <div class="col0" :class="achievement.status == 2 ? 'chest-open' : 'chest-closed'">
            <i class="image"></i>
            <div class="text">+500 <i class="ito-icon-gem"></i></div>
        </div>
        <div class="col1">
            <div class="title">{{ $t(achievement.name) }} <span>({{ $t('level') }} {{ achievement.level }})</span></div>
            <div class="desc">{{ $t(achievement.desc) }}</div>
            <div class="timeline">
                <div class="timeline-1">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                            :style="{ 'width': (100 * achievement.process / achievement.max_process) + '%' }"></div>
                    </div>
                </div>
                <div class="timeline-2">{{ achievement.process }}/{{ achievement.max_process }}</div>
            </div>
        </div>
        <div class="col2">
            <div class="complete-info" v-if="achievement.status == 2">
                {{ $t('complete') }}
                <i class="ito-icon-checked-2"></i>
            </div>
            <button class="ito-btn ito-btn-sm ito-btn-success" @click="achievement.status == 1 ? claim(achievement) : ''"
                :disabled="achievement.status != 1" v-else>
                {{ $t('claim') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { claimAchievement } from "../../models/achievement";
import { soundClick, soundGetReward, soundClaimKey, soundClaimGem } from '../../utils/utils';

export default {
    name: "AchievementItem",

    props: ['achievement'],

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        })
    },

    data() {
        return {};
    },

    created() {

    },

    methods: {
        claim(achievement) {
            const that = this;
            that.getSoundClaimGem();
            claimAchievement({
                id: achievement.id
            }).then((response) => {
                if (response.data.code != 0) {
                    return;
                }
                that.achievement.status = response.data.results.status || '';

                const new_gem = response.data.results.update_gem || 0;
                const gem = response.data.results.gem_reward || 0;

                that.user_info.gem = new_gem;

                const gem_txt = gem > 0 ? '+' + gem : gem;

                this.$emit('updateMission', {
                    updateMission: 1
                })

                $('#congratulation_gem').text(gem_txt);
                $('#getRewardedModal').modal('show');
            }).catch(({ response }) => {
                console.error(response);
            });
        },
        getSoundClick() {
            soundClick();
        },

        getSoundGetReward() {
            soundGetReward();
        },

        getSoundClaimKey() {
            soundClaimKey();
        },

        getSoundClaimGem() {
            soundClaimGem();
        }
    }
}
</script>

<style scoped></style>
