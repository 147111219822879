import {PAGE_LIMIT} from '../constant'
import {get, post} from "../APIGateWay";

export async function getAllExchangeGift(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/exchange-gif/get-all-exchange-gift')

}
export async function getAllHistoryExchange(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/exchange-gif/get-all-history-exchange')
}

export async function sendExchangeGift(data) {
    return await post(data, '/exchange-gif/send-exchange-gift')
}

export async function getAllStikers(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/exchange-gif/get-all-sticker')
}

export async function getStikerCollection(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/exchange-gif/get-sticker-collection')
}

export async function buyStiker(data) {
    return await post(data, '/exchange-gif/buy-sticker')
}