<template>
    <span>{{time_countdown_txt}}</span>
</template>

<script>
import { convertSecondToText } from '../../utils/utils'

export default {
    name: "Countdown",
    props: [
        'time'
    ],
    data() {
        const time_current = Math.floor(Date.now() / 1000);
        const time_countdown = this.time - time_current;

        return {
            time_countdown: time_countdown,
            time_countdown_txt: convertSecondToText(time_countdown)
        };
    },

    watch: {
        time_countdown: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.time_countdown--;

                        this.time_countdown_txt = convertSecondToText(this.time_countdown) || '';
                    }, 1000);
                }else{
                    this.$emit('finished');
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },

    created() {},

    methods: {

    }
}
</script>

<style scoped>

</style>
