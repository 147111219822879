import {get, post} from "../APIGateWay";

export async function claimDailyBonus(data) {
    return await post({}, '/daily-bonus/claim')
}

export async function getDailyChallenge(params = {}) {
    return await get(params, '/daily-challenge')
}

export async function claimChestDailyChallenge(data) {
    return await post(data, '/daily-challenge/claim-chest')
}

export async function claimKeyDailyChallenge(data) {
    return await post(data, '/daily-challenge/claim-key')
}

export async function getDailyBonus(params = {}) {
    return await get(params, '/daily-bonus')
}

export async function claimDailyBonusAdd(data) {
    return await get(data, '/daily-bonus/claim-add')
}