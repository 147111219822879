<template>
    <div>
        <nav-bar-auth/>

        <div id="wrapper" class="wrapper d-flex align-items-stretch">
            <side-bar/>

            <router-view></router-view>

            <footer-element/>

        </div>

        <div class="modal fade" id="selectLangModal" tabindex="-1" role="dialog" aria-labelledby="selectLangModal"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{ $t('choose_language') }}</h3>
                    <div class="select-lang">

                        <div :class="locate == localize.lang?'select-lang-item active':'select-lang-item'"
                             @click="setLocate(localize)" v-for="(localize, index) in localizes">
                            <div class="lang-icon">
                                <img :src="localize.img_flag" alt="">
                            </div>
                            <div class="lang-text">
                                {{ $t(localize.lang) }}
                            </div>
                            <div class="lang-checked" v-if="locate == localize.lang">
                                <i class="ito-icon-checked-white"></i>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="ito-btn ito-btn-primary mt-20" @click="updateLocate()">
                            {{ $t('confirm') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="loading" id="loading" v-if="isLoading">
            <img src="/themes/ito/images/loadding.gif" alt=""/>
        </div>

        <upgrade-account-modal/>

        <div class="modal fade" id="updateGradeModal" tabindex="-1" role="dialog" aria-labelledby="updateGradeModal"
             aria-hidden="true" data-backdrop="static" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 657px;">
                <div class="modal-content box-shadow-main" style="padding-top: 65px;">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <div class="img-title">
                        <img src="/themes/ito/images/image01/grade.png" alt="">
                    </div>
                    <h3 class="popup-title">{{$t('choose_grade_1')}}</h3>
                    <!--<h3 class="popup-description mb-20">{{$t('choose_grade_2')}}</h3>-->

                    <div class="grade-select-body">
                        <div class="grade-select-list">
                            <div @click="selectGrade(1)"
                                 :class="checkGradeSelected(1)?['grade-select-item', 'active']:['grade-select-item']">1
                            </div>
                            <div @click="selectGrade(2)"
                                 :class="checkGradeSelected(2)?['grade-select-item', 'active']:['grade-select-item']">2
                            </div>
                            <div @click="selectGrade(3)"
                                 :class="checkGradeSelected(3)?['grade-select-item', 'active']:['grade-select-item']">3
                            </div>
                            <div @click="selectGrade(4)"
                                 :class="checkGradeSelected(4)?['grade-select-item', 'active']:['grade-select-item']">4
                            </div>
                            <div @click="selectGrade(5)"
                                 :class="checkGradeSelected(5)?['grade-select-item', 'active']:['grade-select-item']">5
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <button class="ito-btn-large btn-test mt-20" style="width: 115px;" @click="confirmSelectGrade">
                            {{$t('confirm')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="testPlacementModal" tabindex="-1" role="dialog" aria-labelledby="testPlacementModal"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 650px;">
                <div class="modal-content box-shadow-main">
                    <div class="close-popup" data-bs-dismiss="modal"></div>
                    <h3 class="popup-title mb-20">{{$t('placement_test')}}</h3>
                    <div class="d-flex quick-test-box text-center">
                        <img src="/themes/ito/images/reading.svg" style="width: 150px; margin: 0 auto;">
                    </div>
                    <div class="quick-test-bottom box-shadow-main d-flex mt-20">
                        <div class="quick-test-bottom-text">
                            <div class="txt-2-rows">
                                <div class="text-end d-inline-block left-title">{{$t('total')}}</div>
                                <div class="d-inline-block right-text">{{placement_test.total_question}}
                                    {{$t('scores')}}
                                </div>
                            </div>
                            <div class="txt-2-rows">
                                <div class="text-end d-inline-block left-title">{{$t('time_allowance')}}</div>
                                <div class="d-inline-block right-text">{{placement_test.time}} {{$t('minutes')}}</div>
                            </div>
                        </div>
                        <div class="ito-btn-large btn-test mt-20" @click="placementTest">
                            {{$t('start')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<audio ref="audio" src="/themes/ito/audios/musicBG-app.mp3" ></audio>-->
    </div>
</template>

<script>
    import SideBar from "../SideBar";
    import NavBarAuth from "../NavBarAuth";
    import FooterElement from "../FooterElement";
    import {getConfigs, getLanguage, soundClick} from "../../utils/utils";
    import {getPlacementTest, startPlacementTest} from "../../models/placement_test";
    import UpgradeAccountModal from "../modal/UpgradeAccountModal";
    import {updateLanguage} from '../../models/profile';

    export default {
        name: "AuthLayout",

        data() {
            const grade_default = localStorage.getItem('grade');
            const configs = getConfigs();
            const localizes = configs.localize || [];

            return {
                locate: getLanguage(),
                locate_obj: null,
                user: {
                    grade: 1
                },
                grade_selected: grade_default || 1,
                placement_test: {
                    id: 0,
                    condition_pass: 0,
                    time: 0,
                    total_question: 0,
                },
                configs: configs,
                localizes: localizes,
            };
        },

        components: {
            UpgradeAccountModal,
            FooterElement,
            NavBarAuth,
            SideBar
        },

        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            user_info() {
                return this.$store.state.user_info;
            }
        },

        mounted() {
            //setInterval(this.refreshData, 2000);
            // this.grade_selected = this.user_info.grade;
            this.grade_selected = localStorage.getItem('grade') ? localStorage.getItem('grade') : this.user_info.grade;
        },

        methods: {
            fetch() {

            },

            refreshData() {
                if (this.$refs.audio) {
                    this.$refs.audio.play();

                    document.getElementById('footer').click();
                }
            },

            getSoundClick() {
                soundClick();
            },

            confirmSelectGrade() {
                const that = this;

                that.getSoundClick();

                if (this.user_info.grade >= this.grade_selected) {
                    localStorage.setItem('grade', this.grade_selected);
                    // that.$store.commit("setLoading", this.grade_selected);
                    $('#updateGradeModal').modal("hide");
                    location.reload();
                } else {
                    const grade_default = localStorage.getItem('grade');

                    this.grade_selected = grade_default || 1;

                    that.$store.commit("setLoading", true);

                    getPlacementTest({
                        grade_id: this.grade_selected
                    }).then((response) => {
                        that.$store.commit("setLoading", false);

                        if (response.data.code != 0) {
                            return;
                        }

                        this.placement_test = response.data.results[0] || {};

                        $('#updateGradeModal').modal("hide");
                        $('#testPlacementModal').modal();
                    }).catch(({response}) => {
                        that.$store.commit("setLoading", false);

                        console.error(response);
                    });
                }
            },

            placementTest() {
                const that = this;

                that.getSoundClick();

                that.$store.commit("setLoading", true);

                startPlacementTest({
                    id: this.placement_test.id
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    $('#testPlacementModal').modal("hide");
                }).catch(({response}) => {
                    that.$store.commit("setLoading", false);

                    console.error(response);
                });
            },

            selectGrade(grade) {
                this.grade_selected = grade;
            },

            checkGradeSelected(grade) {
                return this.grade_selected == grade;
            },

            onLogout(e) {
                this.$store.dispatch('logout').then(() => {
                    // window.location.href = "/register-anomynous";
                    window.location.href = "/login";
                });

                e.preventDefault();
            },

            setLocate(e) {
                this.getSoundClick();

                this.locate_obj = e;
                this.locate = e.lang;

                //localStorage.setItem('lang', e);
            },

            updateLocate() {
                const that = this;

                this.getSoundClick();

                localStorage.setItem('lang', this.locate);

                if (that.locate_obj != null) {
                    that.$store.commit("setLoading", true);

                    updateLanguage({
                        id: that.locate_obj.id,
                        is_raw: true
                    }).then((response) => {
                        that.$store.commit("setLoading", false);

                        if (response.data.code != 0) {
                            return;
                        }
                    }).catch(({response}) => {
                        that.$store.commit("setLoading", false);

                        console.error(response);
                    });
                }

                location.reload();
            }
        }
    }
</script>
<style scoped>
    @media(max-width: 1024px){
        #nav-bar{
            display: flex!important;
        }
        #wrapper{
            display: block!important;
        }
        #sidebar, #footer{
            display: none!important;
        }
        #content{
            background: transparent;
            padding: 0;
        }
        .card-payment .card-content{
            text-align: center;
            padding: 30px;
        }
        .help-button{
            display: none!important;
        }
    }
    @media (max-width: 480px){
        body {
            padding: 0!important;
        }
    }
</style>