<template>
    <div id="rewardModal" class="modal fade " tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content pyro p-0">
                <div class="modal-header border-0">
                    <h4 class="title w-100 text-center">{{ title }}</h4>
                    <i class="ito-icon-close modal-close" data-bs-dismiss="modal" aria-label="Close"></i>
                </div>
                <div class="modal-body d-flex flex-column align-items-center gap-3">
                    <div class="image">
                        <img class="img-fluid" :src="image" :alt="title">
                    </div>
                    <div class="gem" v-if="type == 'gem'">
                        <span id="congratulation_gem">+{{ reward }}</span>
                    </div>
                    <div class="text" v-if="type == 'text'">
                        <p>{{ reward }}</p>
                    </div>
                    <div class="text" v-if="type == 'leaderboard'">
                        <span id="congratulation_gem">+{{ reward }}</span>
                        <p>{{ content }}</p>
                    </div>
                </div>
                <div class="modal-footer justify-content-center border-0">
                    <button class="ito-btn ito-btn-primary px-5" data-bs-dismiss="modal" aria-label="Close">
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RewardModal",
    props: ['title', 'image', 'reward', 'type', 'content'],
}
</script>

<style scoped>
#rewardModal .modal-header .title {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

#rewardModal .modal-body .gem {
    color: #000;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#rewardModal .modal-body .text {
    color: #141821;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#rewardModal .modal-body .gem span,
#rewardModal .modal-body .text span {
    color: #000;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


#rewardModal .modal-body .image {
    max-width: 136px;
}

.ito-icon-close:hover {
    filter: brightness(0.9);
}</style>
