var parseTimer = function (d) {
//    console.log(d)
    /*var date = Math.floor(d / (24 *3600)),
        h = Math.floor(d % (24 *3600) / 3600),
        m = Math.floor((d % (24 *3600)) % 3600 / 60),
        s = Math.floor(((d % (24 *3600)) % 3600) % 60);*/

    var date = Math.floor(d / (24 * 3600 *1000)),
        h = Math.floor(d % (24 * 3600 *1000) / 3600000),
        m = Math.floor((d % (24 * 3600 *1000)) % 3600000 / 60000),
        s = Math.floor((((d % (24 * 3600 *1000)) % 3600000) % 60000)/1000),
        ms = Math.floor((((d % (24 * 3600 *1000)) % 3600000) % 60000)%1000);
        sec = Math.floor(d/1000)


    if (date < 10) {
        date = '0' + date.toString();
    }

    if (h < 10) {
        h = '0' + h.toString();
    }

    if (m < 10) {
        m = '0' + m.toString();
    }
    if (s < 10) {
        s = '0' + s.toString();
    }
    if (sec < 10) {
        sec = '0' + sec.toString();
    }

    return {
        date: date.toString(),
        hour: h.toString(),
        minute: m.toString(),
        second: s.toString(),
        seconds: sec.toString(),
        millisecond: ms.toString(),
    };
}

function Counter(remain, interval) {
    var self = this;
    this.remain = remain * 1000;
    this.paused = 0;
    this.onTickCallback = function () {

        return;

    }

    this.onEndCallback = function () {
        return;
    }
    this.endTime = 0;
    this.startTime = 0;
    this.tid = 0;


    this.onTick = function (cb) {
        this.onTickCallback = cb;
    }

    this.onEnd = function (cb) {
        this.onEndCallback = cb;
    }

    this.start = function () {
        this.startTime = Date.now();
        this.endTime = this.startTime + this.remain;


        if (this.onTickCallback) {
            this.onTickCallback(parseTimer(this.remain - 1));
        }


        this.tid = setInterval(function () {
            if (self.paused) {
                clearInterval(this.tid);
                return;
            }
            self.remain = self.endTime - Date.now();

            if (self.remain <= 0) {
                clearInterval(self.tid);
                self.onEndCallback();
            } else {
                self.onTickCallback(parseTimer(self.remain));
            }
        }, 100);
    }

    this.drop = function () {
        clearInterval(this.tid);
    }

    this.isPaused = function () {
        return this.paused;
    }

    this.pause = function () {
        this.paused = true;
    }

    this.resume = function () {
        this.paused = false;
        return this.start();
    };


}

window.Counter = Counter;
