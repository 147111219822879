import Vue from 'vue'
import VueI18n from 'vue-i18n';
import viMessage from './lang/vi.json'
import enMessage from './lang/en.json'
import { getLanguage } from "./utils/utils";

Vue.use(VueI18n);
const lang = getLanguage();

const messages = {
    vi: localStorage['vi'] ? JSON.parse(localStorage['vi']) : viMessage,
    en: localStorage['en'] ? JSON.parse(localStorage['en']) : enMessage,
};
const i18n = new VueI18n({
    locale: lang,
    messages,
    fallbackLocale: lang,
    silentTranslationWarn: true
});

export default i18n
