<template>
    <div class="item-wave-lesson" v-if="item.type == 'vocabulary'">
        <router-link :to="{path: '/lesson/vocabulary/'+item.id}">
            <div class="item-wave-lesson-inner">
                <div class="rating-crown">
                    <img src="/themes/ito/images/icon-crown.svg" v-for="i in item.star">
                </div>
                <img src="/themes/ito/images/lession-item1.png">
                <div class="item-wave-lesson-text">
                    Vocabulary <i class="ito-icon-checked" v-if="item.complete == 1"></i><i
                        class="ito-icon-checked-disable"
                        v-else></i>
                </div>
            </div>
        </router-link>
    </div>
    <div class="item-wave-lesson" v-else-if="item.type == 'lecture'">
        <router-link :to="{path: '/lesson/lecture/'+item.id}">
            <div class="item-wave-lesson-inner">
                <div class="rating-crown">
                    <img src="/themes/ito/images/icon-crown.svg" v-for="i in item.star">
                </div>
                <img src="/themes/ito/images/lession-item2.png">
                <div class="item-wave-lesson-text">
                    Lecture <i class="ito-icon-checked" v-if="item.complete == 1"></i><i
                        class="ito-icon-checked-disable"
                        v-else></i>
                </div>
            </div>
        </router-link>
    </div>
    <div class="item-wave-lesson" v-else-if="item.type == 'practice'">
        <router-link :to="{path: '/lesson/practice/'+item.id}">
            <div class="item-wave-lesson-inner">
                <div class="crown-default">
                    <img src="/themes/ito/images/icon-crown-disable.svg">
                    <img src="/themes/ito/images/icon-crown-disable.svg">
                    <img src="/themes/ito/images/icon-crown-disable.svg">
                </div>
                <div class="rating-crown">
                    <img src="/themes/ito/images/icon-crown.svg" v-for="i in item.star" class="crown-active-img">
                </div>
                <img src="/themes/ito/images/lession-item3.png">
                <div class="item-wave-lesson-text">
                    Practice <i class="ito-icon-checked" v-if="item.complete == 1"></i><i
                        class="ito-icon-checked-disable"
                        v-else></i>
                    Multiple-choice
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import axios from "axios";

    export default {
        name: "LessonDetailItem",
        props: ['item'],
        data() {
            return {};
        },

        created() {

        },

        methods: {}
    }
</script>

<style scoped>
    .item-wave-lesson:nth-child(2n + 1) .item-wave-lesson-inner {
        margin-top: -40px;
    }

    .item-wave-lesson:nth-child(2n) .item-wave-lesson-inner {
        margin-top: 140px;
    }

    .item-wave-lesson a {
        color: #000;
    }

    .rating-crown, .item-wave-lesson-inner{
        position: relative;
    }
    .rating-crown{
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 57px;
        margin: auto;
        height: 18px;
    }

    .crown-default {
        display: flex;
        position: absolute;
        text-align: center;
        justify-content: center;
        width: 57px;
        top: 0px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .crown-default img {
        width: 19px;
        height: 18px;
    }
    .crown-active-img{
        z-index: 10;
    }
</style>
