<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/parents.css?v=4" />
    <div class="container">
      <div class="card card-parents card-tutoring">
        <div class="card-body">
          <h5 class="card-title flex">
            <span class="title">{{ $t('live_class') }}</span>
            <div class="select-wrapper" style="display: inline-block;">
              <select name="tutoring_filter" id="tutoring_filter" v-model="tutoring_filter" @change="filterTutoring"
                      class="form-control" placeholder="Filter">
                <option value="">{{ $t('all') }}</option>
                <option value="1">{{ $t('class_in_progress') }}</option>
                <option value="2">{{ $t('class_coming_up') }}</option>
                <option value="3">{{ $t('class_is_finished') }}</option>
              </select>
            </div>
          </h5>
          <div class="card-content">
            <div class="d-flex">
              <div class="description flex">{{ $t('live_class_desc_1') }}</div>
              <div>
                <img src="/themes/ito/images/icon-tutoring-help.svg" data-bs-toggle="modal" data-bs-target="#tutoringHelpModal"
                  alt="">
              </div>
            </div>

            <div class="report live-class-report">
              <div :class="live_classes.length > 0 ? 'report-1' : 'report-1 empty'">
                <div class="recent-class nano">
                  <div class="nano-content">
                    <div class="class-list" v-if="live_classes.length > 0">

                      <template v-for="(live_class, index) in live_classes">
                        <live-class-item :tutoring="live_class" @updateTutoring="updateTutoring" />
                      </template>


                    </div>
                    <div v-else>
                      <div class="class-empty">{{ $t('live_class_empty') }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="report-2">
                <div class="calendar">
                  <div class="calendar-body">
                    <div class="calendar-days">
                      <div class="calendar-header">
                        <div class="calendar-header-title">{{ month_label }} {{ year }}</div>
                        <div class="calendar-header-tool">
                          <a class="calendar-prev" href="#" @click="prevDay">
                            <img src="/themes/ito/images/icons/icon-calander-prev.svg" alt="" />
                          </a>
                          <a class="calendar-next" href="#" @click="nextDay">
                            <img src="/themes/ito/images/icons/icon-calander-next.svg" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="calendar-row calendar-row-1">
                        <div class="calendar-col">{{ $t('mo') }}</div>
                        <div class="calendar-col">{{ $t('tu') }}</div>
                        <div class="calendar-col">{{ $t('we') }}</div>
                        <div class="calendar-col">{{ $t('th') }}</div>
                        <div class="calendar-col">{{ $t('fr') }}</div>
                        <div class="calendar-col">{{ $t('sa') }}</div>
                        <div class="calendar-col">{{ $t('su') }}</div>
                      </div>
                      <div class="calendar-row" v-for="(calander, index) in calanders">
                        <template v-for="(day, index) in calander">
                          <div class="calendar-col" v-if="day.day == ''">
                          </div>
                          <div class="calendar-col day day-active" :class="day_select == day.day ? 'border1' : ''"
                            v-else-if="day.current == true" @click="Test(day)">
                            <div class="text">{{ day.day }}</div>
                            <div v-for="(statuss, index) in org_tutorings"
                              v-if="formatDay(statuss.subscribe_from) == day.day">
                              <!--                              <div v-if="statuss.status==0" class="not-registed"></div>-->
                              <div v-if="statuss.status == 1" class="registed-class"></div>
                              <div v-if="statuss.status == 2" class="live-now"></div>
                              <div v-if="statuss.status == 3" class="live-now"></div>
                              <div v-if="statuss.status == 4" class="finished-class"></div>
                              <div v-if="statuss.status == 5" class="full-slots"></div>
                              <!--                              <div v-if="statuss.status==6" class="not-registed"></div>
                              <div v-if="statuss.status==7" class="not-registed"></div>-->
                            </div>
                          </div>
                          <div class="calendar-col day disable" v-else-if="day.disabled == true">
                            <div class="text">{{ day.day }}</div>
                          </div>
                          <div class="calendar-col day" :class="day_select == day.day ? 'border1' : ''"
                            v-else="day.day != ''" @click="Test(day)">
                            <div class="text">{{ day.day }}</div>
                            <div class="status" v-for="(statuss, index) in org_tutorings"
                              v-if="formatDay(statuss.subscribe_from) == day.day">
                              <!--                              <div v-if="statuss.status==0" class="not-registed"></div>-->
                              <div v-if="statuss.status == 1" class="registed-class"></div>
                              <div v-if="statuss.status == 2" class="live-now"></div>
                              <div v-if="statuss.status == 3" class="live-now"></div>
                              <div v-if="statuss.status == 4" class="finished-class"></div>
                              <div v-if="statuss.status == 5" class="full-slots"></div>
                              <!--                              <div v-if="statuss.status==6" class="not-registed"></div>
                              <div v-if="statuss.status==7" class="not-registed"></div>-->
                            </div>
                            <span class="calendar-ico yellow" v-if="day.subject == 'math'"></span>
                            <span class="calendar-ico ping" v-else-if="day.subject == 'science'"></span>
                            <span class="calendar-ico" v-else-if="day.subject == 'ge'"></span>
                            <span class="calendar-ico" v-else-if="day.subject == 'igs'"></span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-flex" id="tutoringHelpModal" data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="formErrorModal" aria-hidden="true">
      <div class="modal-dialog" style="width: 207px; max-width: 207px;">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="title">{{ $t('colour_explanation') }}</h4>
            <div class="tutoring-help-modal">
              <p><span class="c1"></span> {{ $t('class_in_progress') }}</p>
              <p><span class="c2"></span> {{ $t('class_coming_up') }}</p>
              <p><span class="c5"></span> {{ $t('class_is_finished') }}</p>
            </div>
          </div>
          <i data-bs-dismiss="modal" aria-label="Close" class="ito-icon-close modal-close"></i>
        </div>
      </div>
    </div>

    <div class="modal fade modal-tutoring" id="tutoringDetailRegisterSuccessModal" tabindex="-1" role="dialog"
      aria-labelledby="registerModal" aria-hidden="true" data-backdrop="static" data-keyboard=false>
      <div class="modal-dialog modal-dialog-centered popup-main-1" role="document"
        style="width: 380px; text-align: center;">
        <div class="modal-content box-shadow-main">
          <div class="d-flex">
            <h4 class="title text-center" style="width: 100%; margin-left: 9px;">
              {{ $t('registered_successfully') }}</h4>
            <div class="close-popup" data-bs-dismiss="modal"></div>
          </div>

          <div style="margin: 15px auto;"><img src="/themes/ito/images/get-rewarded.svg" alt=""></div>

          <div v-if="subscribe_from">
            {{ $t('dont_forget_the_class_at') }}
            {{ tutoring_result.time_start | convertUnitToTime('HH:mm, DD MMM, YYYY') }}.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import { getClassess, getTutorings } from "../../models/tutoring";
import { convertSecondToText } from "../../utils/utils";
import LiveClassItem from './LiveClassItem.vue';
import itoSelect from '../form/selector.vue';

export default {
  name: "Tutoring",
  components: { LiveClassItem, itoSelect },
  data() {
    let date = new Date();
    let year = date.getFullYear();
    let months = [
      this.$i18n.t('month_1'),
      this.$i18n.t('month_2'),
      this.$i18n.t('month_3'),
      this.$i18n.t('month_4'),
      this.$i18n.t('month_5'),
      this.$i18n.t('month_6'),
      this.$i18n.t('month_7'),
      this.$i18n.t('month_8'),
      this.$i18n.t('month_9'),
      this.$i18n.t('month_10'),
      this.$i18n.t('month_11'),
      this.$i18n.t('month_12')
    ];
    let month = date.getMonth() + 1;
    let month_label = months[month - 1];
    let calanders = this.getCalanders(month, year);
    return {
      tutorings: [],
      org_tutorings: [],
      live_classes: [],
      org_live_classes: [],
      year: year,
      months: months,
      month: month,
      month_label: month_label,
      calanders: calanders,
      subscribe_days: [],
      day_select_object: {},
      subscribe_from: '',
      day_select: "",
      tutoring_filter: '',
      tutoring_result: null,
    };
  },
  computed: {
    user_info() {
      return this.$store.state.user_info;
    },
  },
  created() {
    if (this.user_info.account_live_class == 1) {
      this.$store.commit("setMenuActive", "live_class");
      for (let [key, entry] of Object.entries(this.calanders)) {
        if (entry.length > 0) {
          for (let [ke, item] of Object.entries(entry)) {

            if (typeof item.current != 'undefined' && item.current === true && typeof item.day != 'undefined') {
              this.day_select_object = item;
              this.day_select = item.day;
            }
          }
        }
      }
      this.fetch();
    } else {
      window.location.href='/home'
    }
  },
  methods: {
    handleData(e) {
      this.tutoring_filter = e;
    },
    Test(day, selectAll = false) {
      let that = this;
      let live_classes = [];
      that.day_select = day.day;
      that.org_tutorings.forEach(function (tutoring) {
        if (that.formatDay(tutoring.subscribe_from) == day.day) {
          live_classes.push(tutoring);
        }
      })
      that.live_classes = live_classes;

    },
    formatDay(timestamp) {
      var date = new Date(timestamp * 1000);
      var day = date.getDate();
      return day;
    },
    formatTime(time) {
      var date = new Date(time * 1000);
      var time = date.getTime();
      return time;
    },
    getListTutoring(month, year) {
      let that = this;

      axios.all([getClassess({
        month: month,
        year: year,
        only_student: 1
      })]).then(
        axios.spread((response) => {
          that.$store.commit("setLoading", false);

          if (response.data.code != 0) {
            that.org_tutorings = [];

            return;
          }


          try {
            that.org_tutorings = response.data.results ? response.data.results.class : [];
            that.org_live_classes = response.data.results ? response.data.results.class : [];

          } catch (error) {
            console.error(error);
          }

          that.calanders.forEach(function (calander) {
            calander.forEach(function (day) {
              if (day.disabled == false) {
                day.subject = that.getSubject(day.day)
              }
            })
          })
          that.$nextTick(function () {
            initNanoScroller();
          });
        })
      );
    },

    fetch() {
      this.getListTutoring(this.month, this.year);
      setTimeout(() => {
        this.Test(this.day_select_object, false)
      }, 1000)
    },
    showTutoringItem(tutoring) {
      if (this.tutoring_filter == '') {
        return true;
      }

      if (this.tutoring_filter == '1' && (tutoring.status == 3 || tutoring.status == 5)) {
        return true;
      }

      if (this.tutoring_filter == '2' && (tutoring.status == 2 || tutoring.status == 5)) {
        return true;
      }

      if (this.tutoring_filter == '3' && tutoring.status == 4) {
        return true;
      }

      return false;
    },

    filterTutoring(e) {

      let that = this;

      let live_classes = [];

      that.tutorings.forEach(function (tutoring) {
        if (that.showTutoringItem(tutoring)) {
          live_classes.push(tutoring);
        }
      })

      that.live_classes = live_classes;

    },
    updateTutoring(action, tutoring, response) {
      //tutoring.status = response.status;

      this.subscribe_from = tutoring.subscribe_from;

      this.tutoring_result = tutoring;

      if (action == 'subscribe') {
        $('#tutoringDetailRegisterSuccessModal').modal();
      }
    },
    convertUnitToTime(time, format = 'DD MMM, YYYY HH:mm:ss') {
      if (time) {
        return moment.unix(time).format(format)
      }
      return '';
    },
    prevDay(e) {
      let that = this;
      //this.$store.commit("setLoading", true);
      if (this.month == 1) {
        this.month = 12;
        this.year = this.year - 1;
      } else {
        this.month = this.month - 1;
      }
      this.month_label = this.months[this.month - 1];

      this.calanders = this.getCalanders(this.month, this.year);

      this.getListTutoring(this.month, this.year);

      e.preventDefault();
    },
    nextDay(e) {
      let that = this;
      // this.$store.commit("setLoading", true);
      if (this.month == 12) {
        this.month = 1;
        this.year = this.year + 1;
      } else {
        this.month = this.month + 1;
      }
      this.month_label = this.months[this.month - 1];

      this.calanders = this.getCalanders(this.month, this.year);

      this.getListTutoring(this.month, this.year);

      e.preventDefault();
    },
    chunks(array, size) {
      var results = [];
      while (array.length) {
        results.push(array.splice(0, size));
      }
      return results;
    },
    getCalanders(month, year) {
      let that = this;
      let date = new Date();
      let data = [];
      let firstDay = this.getFirstDayInMonth(month, year);
      let lastDay = this.getDaysInMonth(month, year);
      let days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
      let current_date = date.getDate();
      let current_month = date.getMonth() + 1;
      let current_year = date.getFullYear();

      let lastDayMonthBefore = this.getDaysInMonth(month - 1, year);
      for (var i = firstDay - 1; i >= 1; i--) {
        data.push({
          day: lastDayMonthBefore - i,
          disabled: true
        });
      }
      for (var i = 1; i <= lastDay; i++) {
        let disabled = false;
        let current = false;
        if (current_year < year || (current_year == year && current_month < month) || (current_year == year && current_month == month && current_date < i)) {
          //disabled = true;
        }
        if (current_year == year && current_month == month && current_date == i) {
          current = true;
        }
        let d = new Date(year, month - 1, i).getDay();
        data.push({
          day: i,
          label: days[d - 1],
          disabled: disabled,
          current: current,
        });
      }
      let lastDays = Math.ceil(data.length / 7) * 7 - data.length;
      for (var i = 1; i <= lastDays; i++) {
        data.push({
          day: i,
          disabled: true,
          current: false
        });
      }
      return this.chunks(data, 7);
    },
    getSubject(day) {
      let that = this;
      let subject = "";

      that.tutorings.forEach(function (tutoring) {
        let s_day = that.convertUnitToTime(tutoring.subscribe_from, 'DD');
        if (s_day == day) {
          subject = tutoring.subject;
        }
      });

      return subject;
    },

    getDaysInMonth(month, year) {
      // Here January is 1 based
      // Day 0 is the last day in the previous month
      return new Date(year, month, 0).getDate();
      // Here January is 0 based
      // return new Date(year, month+1, 0).getDate();
    },

    getFirstDayInMonth(month, year) {
      let day = new Date(year, month - 1, 1).getDay();

      switch (day) {
        case 0:
          return 7;
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 4;
        case 5:
          return 5;
        case 6:
          return 6;
        default:
          return 0;
      }
    },
  },
};
</script>
<style scoped>
.finished-class {
  background: #979797;
  margin-bottom: 1rem;
  margin-top: 40px;
  width: 4px;
  height: 4px;
  right: 16px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  padding: 0;
  z-index: 1;
}

.not-registed {
  background: #F0C020;
  margin-bottom: 1rem;
  margin-top: 40px;
  width: 4px;
  height: 4px;
  right: 16px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  padding: 0;
  z-index: 2;
}

.full-slots {
  background: #FA450F;
  margin-bottom: 1rem;
  margin-top: 40px;
  width: 4px;
  height: 4px;
  right: 16px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  padding: 0;
}

.registed-class {
  background: #00A3FF;
  margin-bottom: 1rem;
  margin-top: 40px;
  width: 4px;
  height: 4px;
  right: 16px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  padding: 0;
  z-index: 3;
}

.live-now {
  background: #98C93C;
  margin-bottom: 1rem;
  margin-top: 40px;
  width: 4px;
  height: 4px;
  right: 16px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  padding: 0;
  z-index: 4;
}

.card-tutoring .calendar-row>.calendar-col.day-active {
  color: #00A3FF;
}

.card-tutoring .calendar-row>.calendar-col.border1 {
  border: 1px solid #00A3FF;
  border-radius: 10px;
  font-weight: 700;
}
</style>
