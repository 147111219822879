import {get, post} from "../APIGateWay";

export async function authLogin(params) {
    const res = await post(params, '/connect/token')
    const data = res.data.results
    const access_token = data.access_token
    const expires_in = data.expires_in
    document.cookie = `_wid=${access_token}; expires=${expires_in},  path=/`;
    return res
}

export async function authLogout() {
    return await get({}, '/user/logout')
}

export async function authCheckUser(params) {
    const res = await post(params, '/user/validate-phone-email')
    return res
}
export async function authRegister(params) {
    const res = await post(params, '/user/register_tmp')
    const data = res.data.results
    const access_token = data.access_token
    const expires_in = data.expires_in
    document.cookie = `_wid=${access_token}; expires=${expires_in}, path=/`;
    return res
}


export async function authAnonymous(params) {
    return await post(params, '/user/change-info-password')
}

export async function authRegisterAnomynous(params) {
    const res = await post(params, '/user/register/anonymous')
    const data = res.data.results
    const access_token = data.access_token
    const expires_in = data.expires_in
    document.cookie = `_wid=${access_token}; expires=${expires_in},  path=/`;
    return res
}

export async function getForgetAccount(params) {
    return await get(params, '/forget-account/show')
}

export async function getListForgetAccount(params) {
    return await get(params, '/forget-account/list')
}

export async function confirmForget(params) {
    return await post(params, '/forget-account/confirm-forget')
}

export async function confirmOtp(params) {
    return await post(params, '/forget-account/confirm-otp')
}

export async function resetPassword(params) {
    return await post(params, '/forget-account/reset-new-pass')
}

export  async function changeNewPassword(params) {
    return await post(params, '/user/change-new-pass')
}