<template>
    <div id="content">
        <div class="container">
            <div id="page-header-fixed" class="mb-25">
                <div id="page-header">
                    <div class="tools">
                        <nav class="ito-tabs">
                            <router-link :to="{ name: 'your_space_trophies' }"
                                @click.native="getSoundClick">Trophies</router-link>
                            <router-link :to="{ name: 'your_space_badges' }"
                                @click.native="getSoundClick">Badges</router-link>
                            <router-link :to="{ name: 'your_space_certificates' }" class="active"
                                @click.native="getSoundClick">Certificates</router-link>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="card card-parents">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">Certificates</span>
                    </h5>
                    <div class="your_space_content d-flex flex-wrap justify-content-center mt-5">
                        <div class="your_space_row certificate" v-if="certs.length == 0">
                            <div class="your_space_item certificate_item disabled"></div>
                        </div>
                        <template v-else>
                            <div class="your_space_row certificate" v-for="item in certs">
                                <template v-for="x in item">
                                    <div class="your_space_item certificate_item"
                                        @click="reviewCert(x.class_id, x.school_year)"></div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCertificateReport } from "../../models/parent";


export default {
    name: "YourSpaceCertificates",
    data() {

        return {
            certs: [],
        };
    },

    created() {
        this.$store.commit('setMenuActive', 'your_space');
        this.fetch();
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },

    methods: {
        fetch(page = 1) {
            this.getCerts();
        },
        getCerts() {
            const that = this;
            that.$store.commit("setLoading", true);
            const formData = new FormData();
            formData.append('eco_user_id', that.user_info.eco_user_id ? that.user_info.eco_user_id : '');
            getCertificateReport(formData).then((data) => {
                that.$store.commit("setLoading", false);
                if (data.status == 200) {
                    that.certs = this.sortItems(data.data.data) ||[];
                }
                else {
                }
            }).catch(({ response }) => {
                that.$store.commit("setLoading", false);
                alert(response.data.results);
            });
        },
        reviewCert(classId, year) {
            let eco_user_id = this.user_info.eco_user_id ? this.user_info.eco_user_id : '';
            let url = 'https://eco.ismart.edu.vn/api_eco.php/get_cc?class_id=' + classId + '&eco_id=' + eco_user_id + '&school_year=' + year;
            this.redirect_blank(url);
        },

        redirect_blank(url) {
            var a = document.createElement('a');
            a.target = "_blank";
            a.href = url;
            a.click();
        },
        sortItems(arr) {
            var result = arr.map(function (x, i) {
                return i % 5 === 0 ? arr.slice(i, i + 5) : NaN;
            }).filter(n => n);
            return result;
        }

    }
}
</script>

<style scoped></style>
