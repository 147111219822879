<template>
  <div id="content">
    <div class="container" v-if="hasEvents == true">
      <div class="mark-lesson" v-if="screen != 'list'"></div>
      <div class="card fade-down" :class="screen != 'list' ? 'box-leson' : 'box-list'">
        <div class="card-body" v-if="screen == 'list'">
          <div class="ito-events-tabs">
            <ul class="ito-nav-tabs">
              <li class="ito-nav-item">
                <button class="ito-nav-link" :class="{ active: tab == 'battlegame' }" @click="changeTab('battlegame')">
                  {{ $t('tab_battle_game') }}
                </button>
              </li>
              <li class="ito-nav-item">
                <button class="ito-nav-link" :class="{ active: tab == 'chat' }" @click="changeTab('chat')">
                  {{ $t('tab_chat_room') }}
                </button>
              </li>
              <li class="ito-nav-item">
                <button class="ito-nav-link" :class="{ active: tab == 'ranking' }" @click="changeTab('ranking')">
                  {{ $t('tab_ranking') }}
                </button>
              </li>
            </ul>
            <div class="ito-tab-content">
              <div class="event-info">
                <div class="event-text m-0">
                  <div class="event-title">
                    <div class="title">{{ events.name }}</div>
                    <p class="desc">{{ events.start_time + ' ' + $t('to') + ' ' + events.end_time }}</p>
                  </div>
                </div>
                <div class="event-header d-flex align-items-center gap-3">
                  <div class="title">{{ events.game_name }}</div>
                  <div class="event-image" v-if="events.thumbnail_url">
                    <img class="w-100 h-100" :src="events.thumbnail_url" :alt="events.name">
                  </div>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div class="ito-tab-pane" v-if="tab == 'battlegame'" :key="tab">
                  <div class="d-flex flex-column gap-4 mt-3">
                    <div class="event-item" v-for="entry in events.sub_events">
                      <div class="event-item-content">
                        <div class="d-flex w-100">
                          <div class="event-image w-25" v-if="entry.thumbnail_url">
                            <img class="w-100 h-100" :src="entry.thumbnail_url" alt="">
                          </div>
                          <div class="event-text w-75">
                            <div class="event-title">
                              <div class="title">{{ entry.name }} <span>({{ entry.subject }})</span></div>
                              <p class="desc">{{ entry.description }}</p>
                            </div>
                            <div class="d-flex gap-3 justify-content-end align-items-center">
                              <div class="even-playing" v-if="entry.time_remaining == 0">
                                <div class="title"><i class="ito-icon ito-user"></i>{{ entry.number_playing }}</div>
                                <p class="desc">{{ $t('student_playing') }}</p>
                              </div>
                              <div class="event-countdown" v-else>
                                <div class="title">{{ $t('countdown_time') }}</div>
                                <p class="desc" v-html="timeLeft"></p>
                              </div>
                              <button class="ito-btn ito-btn-primary" :disabled="events.is_start == 0"
                                @click="playGame(entry)">
                                {{ $t('join_battle_event') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ito-tab-pane" v-if="tab == 'chat'" :key="tab">
                  <div class="d-flex flex-column gap-4 mt-3">
                    <div class="chat-room">
                      <ChatBox @chat_loaded="chatLoaded" />
                    </div>
                  </div>
                </div>

                <div class="ito-tab-pane" v-if="tab == 'ranking'" :key="tab">
                  <Ranking :eventId="events.id"></Ranking>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="screen == 'play'">
          <div class="lesson-action-button" @click="quitEvent"><span>+</span></div>
          <div class="card-content p-0">
            <Game :game_url="item.game_url" @game_loaded="gameLoaded" />
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else-if="hasEvents == false">
      <div class="card fade-down">
        <div class="card-body p-4">
          <div class="card-content">
            <div class="d-flex flex-column align-items-center justify-content-center text-center">
              <h5>{{ $t('no_event') }}
              </h5>
              <img class="mt-3" src="/themes/ito/images/mascot/isaac-extension.svg" alt="" width="125px">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="quitEvent" tabindex="-1" role="dialog" aria-labelledby="quitEvent" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered popup-main-1" role="document">
        <div class="modal-content box-shadow-main">
          <div class="close-popup" data-bs-dismiss="modal"></div>
          <div class="modal-body">
            <h3 class="popup-title mb-20">{{ $t('quit_game') }}</h3>
            <p class="txt-popup">{{ $t('quit_game_confirm') }}</p>
          </div>
          <div class="d-flex justify-content-center gap-3">
            <div data-bs-dismiss="modal" class="ito-btn ito-btn-default"> {{ $t('no') }} </div>
            <div class="ito-btn ito-btn-primary" @click="showMenuItem"> {{ $t('yes') }} </div>
          </div>
        </div>
      </div>
    </div>

    <require-upgrade-account-modal ref="requireUpgradeAccountModal"></require-upgrade-account-modal>
    <claim-reward-dialogue ref="claimRewardDialogue"></claim-reward-dialogue>
    <error-modal :err_msg="errorMsg" className="homepageErr" />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from 'vuex'
import { getEvents, getRankings } from "../../models/events";
import { LCMS_URL } from "@/constant";
import { getLanguage } from "../../utils/utils";
import Game from "./Game";
import ChatBox from "./ChatBox";
import Ranking from "./Ranking";
import RewardModal from "../modal/RewardModal";
import { ErrorModal, RequireUpgradeAccountModal, ClaimRewardDialogue } from "../modal";


let counter;


export default {
  name: "Events",
  components: {
    Game,
    ChatBox,
    Ranking,
    RewardModal,
    RequireUpgradeAccountModal,
    ErrorModal,
    ClaimRewardDialogue
  },
  data() {
    const lang = getLanguage();

    return {
      tab: 'battlegame',
      timeLeft: 0,
      lang: lang ? lang : 'en',
      events: {},
      hasEvents: null,
      item: [],
      screen: 'list',
      rankings: [],
      page: 1,
      myRankPage: null,
      errorMsg: '',
    };
  },
  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }

      return this.user
    }),
  },
  async mounted() {


  },
  created() {
    this.$store.commit("setMenuActive", "events");
    this.fetch();
  },
  methods: {
    fetch() {
      this.getEvents()
    },
    timerCountdown(time_remaining) {
      const self = this;
      counter = new Counter(time_remaining + 1, 1000);
      counter.onTick(function (time) {
        self.timeLeft = time.hour + ':' + time.minute + ':' + time.second
      });
      counter.onEnd(function () {
        window.location.reload();

      })
      counter.start();
    },

    getEvents() {
      const that = this;
      const grade = localStorage.getItem('grade');
      if (grade == that.user_info.grade) {
        const params = {
          grade: grade,
        };
        that.$store.commit("setLoading", true);
        axios.all([getEvents(params)]).then(
          axios.spread((response) => {
            that.events = [];
            if (response.data.code != 0) {
              that.$store.commit("setLoading", false);
              that.hasEvents = false;
            } else {
              that.hasEvents = true;
              var events = response.data.results || [];
              events.can_claim = {
                claimable: events.can_claim,
                reward: 1000,
                rank: 1
              }
              that.events = events;
              if (events.time_remaining > 0) {
                that.timerCountdown(events.time_remaining);
              }
            }
            that.$store.commit("setLoading", false);

          })
        );
      } else {
        that.hasEvents = false;
      }
    },

    gameLoaded(e) {
      e.contentWindow.postMessage({
        "action": "ito_info",
        "event_id": this.events.id,
        "sub_event_id": this.item.id,
        "user_id": this.user_info.user_id,
        "access_token": localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '',
        "url_lcms": LCMS_URL,
        "language": this.lang
      }, '*');
    },
    chatLoaded(e) {
      e.contentWindow.postMessage({
        "token": localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '',
        "eventId": this.events.id,
      }, '*');
    },
    checkExpiredAccount() {
      let date1 = new Date(this.user_info.expired_date).getTime();
      let date2 = new Date().getTime();
      return date1 < date2;
    },

    playGame(entry) {
      if (this.checkExpiredAccount()) {
        this.$refs.requireUpgradeAccountModal.show({
          title: this.$t('upgrade_account_require'),
          message: this.$t('please_upgrade_your_account_to_access'),
          okButton: this.$t('upgrade_account'),
        })
        return
      } else
        if (this.events.is_start == 0 && this.events.time_remaining < 0) {
          this.errorMsg = 'Event ' + this.events.name + ' has ended.';
          $('.homepageErr').modal('show');
          return
        } else {
          this.item = entry
          this.screen = 'play'
        }
    },

    quitEvent() {
      $('#quitEvent').modal('show');
    },

    showMenuItem() {
      $('#quitEvent').modal('hide');
      this.screen = 'list';
    },

    changeTab(tab) {
      if (tab == 'chat') {
        if (this.checkExpiredAccount()) {
          this.$refs.requireUpgradeAccountModal.show({
            title: this.$t('upgrade_account_require'),
            message: this.$t('please_upgrade_your_account_to_access'),
            okButton: this.$t('upgrade_account'),
          })
          return
        }
      }
      this.tab = tab;
    },


    async showDialog(dialog, params) {
      const cfm = await dialog.show(params);
      return cfm;
    },
  },
};
</script>
<style scoped>
.ito-events-tabs {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.ito-events-tabs .ito-nav-tabs {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  gap: 12px;
  width: 20%;
  padding: 30px 20px 30px 0px;
}

.ito-events-tabs .ito-nav-tabs .ito-nav-link {
  width: 100%;
  border: none;
  color: #ffffff;
  display: inline-flex;
  padding: 20px 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  opacity: 0.4;
  border-radius: 0px 50px 50px 0px;
  background: #FF7800;
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.20) inset;
}

.ito-events-tabs .ito-nav-tabs .ito-nav-link.active,
.ito-events-tabs .ito-nav-tabs .ito-nav-link:hover {
  opacity: 1;
}

.ito-events-tabs .ito-tab-content {
  width: 80%;
  border-radius: 16px;
  background: #F8F8F8;
  padding: 20px 30px 30px 30px;
  box-shadow: 0px 4px 0px 0px rgba(231, 231, 231, 0.25) inset, 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
}



.ito-events-tabs .ito-tab-content .chat-room {
  min-height: 300px;
  height: calc(100vh - 325px);
  border-radius: 16px 16px 28px 28px;
  border: 8px solid #FF7800;
  box-shadow: 0px 4px 0px 0px rgba(231, 231, 231, 0.25) inset, 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.ito-events-tabs .ito-tab-content .ranking-board {
  border-radius: 8px;
  width: 100%;
  gap: 2px;
  overflow-y: auto;
  margin-top: 1rem;
  border-collapse: collapse;
  text-align: left;
}

.ito-events-tabs .ito-tab-content .ranking-board tr {
  min-height: 60px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  color: #14233F;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 12px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr.active {
  background: #98C923;
  cursor: pointer;
}

.ranking-board tr:first-child td:first-child {
  border-radius: 5px 0 0 5px;
}

.ranking-board tr:first-child td:last-child {
  border-radius: 0 5px 5px 0;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .number {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .avatar {
  position: relative;
  width: 44px;
  height: 44px;
  box-shadow: none;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .avatar .emotion {
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 20px;
  height: 20px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .avatar .emotion img {
  border-radius: 50%;
  border: solid 2px #FFF;
  width: 100%;
  height: 100%;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .name {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .point {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .point span {
  font-size: 20px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .point span small {
  font-weight: 700 !important;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .point small {
  font-size: 14px;
}


.ito-events-tabs .ito-tab-content .ranking-board tr .tropphies {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .tropphies img {
  width: 25px;
  height: 28px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .gem {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #14233F;
  text-align: right;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.ito-events-tabs .ito-tab-content .ranking-board tr td:last-child {
  width: 20% !important;
}

.ito-events-tabs .ito-tab-content .ranking-board tr .gem img {
  width: 52px;
  height: 52px;
}

.icon-gem-bag {
  /* content: url('../../../public/themes/ito/images/battlegame/gem-bag-big.svg'); */
  width: 52px;
  height: 52px;
}

.icon-next-circle {
  content: url('../../../public/themes/ito/images/battlegame/next-circle.svg');
  width: 24px;
  height: 24px;
}

.icon-prev-circle {
  content: url('../../../public/themes/ito/images/battlegame/next-circle.svg');
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>