<template>
    <div class="container">
        <div class="free-trial-box">
            <!--<h3 class="popup-title">{{$t('hello')}}</h3>-->
            <h3 class="popup-title">Learning <span class="text-primary">English</span> through</h3>
            <h6 class="popup-description"><span class="text-info">Mathematics</span> and <span
                    class="text-info">Science</span></h6>
            <div class="trial-img-title">
                <img src="/images/anomy-img.svg" alt="">
            </div>
            <h3 class="popup-title">{{ $t('choose_grade_1') }}</h3>
            <h3 class="popup-description mb-20">{{ $t('choose_grade_2') }}</h3>

            <div class="grade-select-body">
                <div class="grade-select-list flex-wrap">
                    <template v-for="grade in grades">
                        <div @click="selectGrade(grade)" class="grade-select-item"
                            :class="{ active: grade == grade_selected }">{{ grade }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-20">
                <button type="button" class="ito-btn ito-btn-primary" @click="onSubmit" :disabled="!grade_selected">
                    {{ $t('let_s_go_and_try') }}
                </button>
            </div>
        </div>


    </div>
</template>

<script>
import { authRegisterAnomynous } from '../../models/auth'
import axios from "axios";
import { mapGetters } from 'vuex'
import { getAvatars, getConfig } from "../../models/profile";
import { getConfigs, getLanguage, soundClick } from "../../utils/utils";

export default {
    name: "RegisterAnomynous",
    data() {
        const configs = getConfigs();
        const locate = getLanguage();
        const localizes = configs.localize || [];
        const grades = configs.grades || [];

        let locate_info = {};
        localizes.forEach(item => {
            if (item.lang == locate) {
                locate_info = item;
            }
        });
        return {
            grades: grades.sort(function (a, b) { return a - b }),
            error: {
                message: '',
            },
            grade_selected: '',
            locate: getLanguage(),
            locate_info: locate_info,
            configs: configs,
            localizes: localizes,
        };
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },
    created() {
        //this.$store.commit("setLoading", true);

        this.fetch();
    },
    mounted() {
        $('#updateGradeModal').modal('show');
    },
    methods: {
        fetch() {
            localStorage.clear();
            const that = this;
            this.$store.commit("setLoading", true);
            axios.all([getConfig()]).then(axios.spread((response) => {
                this.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    return;
                }
                const configs = response.data.results || {};
                that.$store.commit("setConfigs", configs);
                localStorage.setItem('configs', JSON.stringify(configs));
                const localize = configs.localize
                localize.forEach(element => {
                    if (element.lang === 'vi') {
                        $.getJSON(element.link, function (json) {
                            localStorage['vi'] = JSON.stringify(json);
                        });
                    } else if (element.lang === 'en') {
                        $.getJSON(element.link, function (json) {
                            localStorage['en'] = JSON.stringify(json);
                        });
                    }
                });
            }));
        },
        selectGrade(grade) {
            this.grade_selected = grade;
        },

        checkGradeSelected(grade) {
            return this.grade_selected == grade;
        },
        onSubmit(e) {
            this.resetErrorsValid();

            if (this.error.message != '') {
                return false;
            }
            const grade = this.grade_selected;

            this.login({ grade });

            e.preventDefault();
        },

        resetErrorsValid() {
            this.error = {
                message: '',
            }
        },

        login(user) {
            const that = this;

            that.$store.commit("setLoading", true);

            authRegisterAnomynous(user).then((response) => {
                that.$store.commit("setLoading", false);

                if (response.data.code != 0) {
                    this.error.message = response.data.msg;

                    return false;
                }

                that.$store.commit('setAuth', true);

                localStorage.removeItem('checkedSubjects');

                localStorage.setItem('access_token', response.data.results.access_token);

                location.href = '/home';
            }).catch(({ response }) => {
                this.$store.commit('setAuth', false);
                that.$store.commit("setLoading", false);

                that.error.message = 'An error occurred, please try again later.';
            });
        }
    }
}
</script>

<style scoped>
.form-group {
    position: relative;
}

.ito-input {
    width: 100%;
}

.free-trial-box {
    position: relative;
    width: 658px;
    /* height: 456px; */
    background: #ffffff;
    margin: auto;
    padding: 20px;
    text-align: center;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 4px 0px rgba(231, 231, 231, 0.25);
    border-radius: 8px;
    overflow: hidden;
}

.trial-img-title img {
    width: 110px;
    margin: 15px auto;
}

.free-trial-bottom {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    bottom: 0;
    padding: 15px;
    background: #3164CC;
}

.free-trial-box a {
    display: block;
    margin: 15px auto;
    cursor: pointer;
    color: #ffffff;
}

.free-trial-box a:hover {
    text-decoration: none;
    color: #ffffff;
}

.free-trial-bottom h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #ffffff;
}

.select-lang {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 8px;
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
}
</style>
