<template>
    <div id="errorModal" class="modal fade" :class="className" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="popup-title mb-20">{{$t('error')}}</h3>
                    <p class="txt-popup text-center">{{$t(err_msg)}}</p>
                </div>
                <i class="close-popup" data-bs-dismiss="modal"
                   aria-label="Close"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorModal",
        props: ['err_msg','className'],
        methods: {}
    }
</script>

<style scoped>
    #errorModal .modal-content {
        padding: 15px;
    }
</style>
