<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/unit-detail.css">
        <link rel="stylesheet" href="/themes/ito/css/parents.css">

        <div class="container">
            <div id="page-header-fixed" class="mb-25">
                <div class="container">
                    <div id="page-header">
                        <div class="tools">
                            <nav class="ito-tabs">
                                <router-link :to="{ name: 'parent_report' }" @click.native="getSoundClick">{{ $t('report') }}</router-link>
                                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_certificate' }" @click.native="getSoundClick">{{ $t('certificate') }}</router-link>
                                <router-link v-if="user_info.student_type == 1" class="active" :to="{ name: 'parent_feedback' }" @click.native="getSoundClick">{{ $t('feedback') }}</router-link>
                                <router-link :to="{ name: 'parent_account' }" @click.native="getSoundClick">{{ $t('account_information') }}</router-link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div id="unit-detail" class="card card-comments">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('teacher_rate')}}</span>
                    </h5>

                    <div class="card-content">
                        <div class="" style="justify-content: center; width: 100%; display: flex; flex-wrap: wrap;">
                            <div class="rates">
                                <span class="rate" :class="reading.value >= 1 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 2 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 3 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 4 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 5 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 6 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 7 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 8 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 9 ? 'red' : ''"></span>
                                <span class="rate" :class="reading.value >= 10 ? 'red' : ''"></span>
                                <span class="rate-label">{{$t('read')}}</span>
                            </div>
                            <div class="rates">
                                <span class="rate" :class="speaking.value >= 1 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 2 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 3 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 4 ? 'yellow' : ''" ></span>
                                <span class="rate" :class="speaking.value >= 5 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 6 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 7 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 8 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 9 ? 'yellow' : ''"></span>
                                <span class="rate" :class="speaking.value >= 10 ? 'yellow' : ''"></span>
                                <span class="rate-label">{{$t('speak')}}</span>
                            </div>
                            <div class="rates">
                                <span class="rate" :class="listening.value >= 1 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 2 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 3 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 4 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 5 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 6 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 7 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 8 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 9 ? 'green' : ''"></span>
                                <span class="rate" :class="listening.value >= 10 ? 'green' : ''"></span>
                                <span class="rate-label">{{$t('listen')}}</span>
                            </div>
                            <div class="rates">
                                <span class="rate" :class="writing.value >= 1 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 2 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 3 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 4 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 5 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 6 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 7 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 8 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 9 ? 'oreange' : ''"></span>
                                <span class="rate" :class="writing.value >= 10 ? 'oreange' : ''"></span>
                                <span class="rate-label">{{$t('write')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-comments" style="margin-top: 30px;">

                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('teacher_feedback')}}</span>
                        <i class="double_cheveron_right" v-if="!showAll"></i>
                      <a style="color: #00A3FF;font-size: 16px;font-weight: 600;text-decoration: underline;cursor: pointer" @click="History" v-if="!showAll">{{ $t('history')}}</a>
                      <i  class="double_cheveron_bottom" v-if="showAll"></i>
                      <a style="color: #FA542E;font-size: 16px;font-weight: 600;text-decoration: underline;cursor: pointer" @click="History" v-if="showAll">{{ $t('history')}}</a>
                    </h5>

                    <div class="card-content">
                        <div class="feedback-list">
                            <!--<div class="comment-item feedback-item" v-for="(feedback, index) in feedbacks" v-if="show_all_feedbacks || (show_all_feedbacks == false && index == feedbacks.length - 1)">-->
                            <div class="feedback-item" v-for="(feedback, index) in feedbacks" style="margin-top: 0;">
                                <div v-if="feedback.show == 1 || showAll" class="comment-item feedback-item">
                                    <div class="feedback-item-avatar comment-item-avatar">
                                        <div class="thumb">
                                            <div class="thumb-content">
                                                <img class="avatar" :src="feedback.avatar" alt="" v-if="feedback.avatar"/>
                                                <img class="avatar" src="/themes/ito/images/avatar-default.svg" alt="" v-else/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feedback-item-info comment-item-info">
                                        <div class="feedback-item-header header">
                                            <div class="feedback-item-title title">{{feedback.fullname || feedback.username}}</div>
                                            <div class="feedback-item-time time">{{feedback.time | convertUnitToTime('DD MMM, YYYY - HH:mm:ss')}}</div>
                                        </div>
                                        <div>{{feedback.content}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div class="comment-item feedback-item" style="margin-top: 10px">-->
                            <!--<div class="comment-item-avatar">-->
                                <!--<div class="thumb">-->
                                    <!--<div class="thumb-content">-->
                                        <!--<img src="/themes/ito/images/avatar-1.svg" alt="">-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="comment-item-info">-->
                                <!--<div class="header">-->
                                    <!--<div class="title">Teacher name</div>-->
                                    <!--<div class="time">10 Jun, 2021 - 15:00:32</div>-->
                                <!--</div>-->
                                <!--<div class="desc">-->
                                    <!--Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo-->
                                    <!--ligula eget Aenean massa. Cum sociis natoque penatib...-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    </div>
                    <div class="card-bottom" style="margin-top: 40px" v-if="show_send_feedback_button">
                        <div class="feedback-send">{{$t('parent_feedback')}}</div>
                        <div class="form" style="border-radius: 10px;">
                            <input type="text" class="input-feedback" :placeholder="$t('input_feedback')" v-model="comment"/>

                            <button class="btn btn-default" @click="sendFeedback()">{{$t('send')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";
    import {pushQueries, soundClick} from '../../utils/utils'
    import {mapGetters} from 'vuex'
    import {getInboxs, readInbox} from "../../models/inbox";
    import {getParentFeedbacks, sendFeedbackData} from "../../models/feedback";
    import BuySticker from "../exchange_gift/BuySticker";

    export default {
        name: "ParentFeedback",
        components: {BuySticker},

        data() {
            return {
                feedbacks: [],
                skills: [],
                reading: [],
                speaking: [],
                listening: [],
                writing: [],
                comment: '',
                show_all_feedbacks: false,
                show_send_feedback_button: true,
                showAll: false
            };
        },

        created() {
            this.$store.commit('setMenuActive', 'parent');

            this.fetch();
        },
        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }

                return this.user
            })
        },
        methods: {
            History(){
                this.showAll = !this.showAll;
            },
            fetch(page = 1) {
                const that = this;

                this.page = page;

                const params = {
                    page: this.page,
                };

                //pushQueries(this.$router, '/inbox', params);

                axios.all([
                    getParentFeedbacks({
                        user_id: this.user_info.user_id
                    }),
                ]).then(axios.spread((response) => {
                    that.isLoading = false;

                    //that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    that.feedbacks = response.data.results.feedback || [];
                    that.skills = response.data.results.skills || [];
                    that.reading = response.data.results.skills[0] || [];
                    that.speaking = response.data.results.skills[1] || [];
                    that.listening = response.data.results.skills[2] || [];
                    that.writing = response.data.results.skills[3] || [];

                    if(that.feedbacks.length > 0){
                        const last_feedback = that.feedbacks[that.feedbacks.length - 1];

                        if(last_feedback.is_teacher == 0){
                            that.show_send_feedback_button = false;
                        }
                    }
                    that.feedbacks.forEach(function (e, k){
                      e.show = 0;

                      if(k == (that.feedbacks.length - 1)){
                        e.show = 1
                      }

                      if(that.show_send_feedback_button == false){
                        if(k == (that.feedbacks.length - 2)){
                          e.show = 1
                        }
                      }

                    })
                    this.$nextTick(function() {
                        var initNanoScroller = function() {
                            if ($('.nano').length > 0) {
                                $(".nano").nanoScroller();
                            }
                        }

                        initNanoScroller();
                    })

                }));
            },

            confirmSendFeedback(){
                $('#confirmModal').modal('show');
            },

            updateShowAllFeedback(is_show){
                this.show_all_feedbacks = is_show;
            },

            sendFeedback(){
                const that = this;

                sendFeedbackData({
                    user_id: this.user_info.user_id,
                    content: this.comment,
                }).then((response) => {
                    that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    that.feedbacks = response.data.results.feedback;

                    that.comment = "";

                    // $('#confirmModal').modal('hide');
                }).catch((response) => {
                    that.$store.commit("setLoading", false);

                    console.error(response)
                });
            },

            getSoundClick() {
                soundClick();
            },

        }
    }
</script>

<style scoped>
    .rates{
        width: 50%;
        text-align: center;
    }
    .feedback-send{
        text-align: left;
        color: #000000;
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 14px;
    }
    .input-feedback::-webkit-input-placeholder { /* Edge */
        color: #E4E4E4;
    }

    .input-feedback:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #E4E4E4;
    }

    .input-feedback::placeholder {
        color: #E4E4E4;
    }
</style>
