<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/parents.css">

        <div class="container">
            <div id="page-header-fixed" class="mb-25">
                <div class="container">
                    <div id="page-header">
                        <div class="tools">
                            <nav class="ito-tabs">
                                <router-link :to="{ name: 'parent_report' }" @click.native="getSoundClick">{{ $t('report') }}</router-link>
                                <router-link v-if="user_info.student_type == 1" class="active" :to="{ name: 'parent_certificate' }" @click.native="getSoundClick">{{ $t('certificate') }}</router-link>
                                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_feedback' }" @click.native="getSoundClick">{{ $t('feedback') }}</router-link>
                                <router-link :to="{ name: 'parent_account' }" @click.native="getSoundClick">{{ $t('account_information') }}</router-link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-parents">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('completion_certificates')}}</span>
                    </h5>

                    <div class="card-content cert" v-if="certs.length > 0">
                        <div>
                            <div class="row">
                                <template v-for="(acd, index) in certs">
                                    <div class="col-md-3">
                                        <div class="item-academic" style="margin-bottom: 30px">
                                            <img src="/themes/ito/images/preview1.svg"/>
                                            <div class="item-academic-class">School year</div>
                                            <div class="item-academic-name">{{acd.school_year}}</div>
                                            <div class="btn-preview" @click="reviewCert(acd.class_id,acd.school_year)">
                                                {{$t('preview')}}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div v-else style="padding: 30px 0;text-align: center;">
                        {{$t('empty_completion_certificate')}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {pushQueries, soundClick, toPercent} from '../../utils/utils'
    import {mapGetters} from 'vuex'
    import {getParentReport, getCertificateReport} from "../../models/parent";

    export default {
        name: "ParentCertificate",
        data() {

            return {
                certs: [],
            };
        },

        created() {
            this.$store.commit('setMenuActive', 'parent');

            this.fetch();
        },
        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }

                return this.user
            })
        },

        methods: {
            fetch(page = 1) {
                this.getCerts();
            },
            getCerts() {
                const that = this;

                that.$store.commit("setLoading", true);
                const formData = new FormData();
                formData.append('eco_user_id', that.user_info.eco_user_id ? that.user_info.eco_user_id : '');
                getCertificateReport(formData).then((data) => {
                    that.$store.commit("setLoading", false);
                    if (data.status == 200) {
                        that.certs = data.data.data;
                    }
                    else {
                    }
                }).catch(({response}) => {
                    that.$store.commit("setLoading", false);
                    alert(response.data.results);
                });
            },
            reviewCert(classId, year) {
                let eco_user_id = this.user_info.eco_user_id ? this.user_info.eco_user_id : '';
                let url = 'https://eco.ismart.edu.vn/api_eco.php/get_cc?class_id=' + classId + '&eco_id=' + eco_user_id + '&school_year=' + year;
                this.redirect_blank(url);
            },

            redirect_blank(url) {
                var a = document.createElement('a');
                a.target = "_blank";
                a.href = url;
                a.click();
            },
        }
    }
</script>

<style scoped>
    .item-academic {
        text-align: center;
    }

    .btn-preview {
        background: linear-gradient(180deg, #45BCFF 0%, #00A3FF 100%);
        box-shadow: 0px 2px 0px 0px #FFFFFF33 inset;
        box-shadow: 4px 4px 0px 0px #00000012;
        height: 35px;
        width: 93px;
        left: 424px;
        top: 259px;
        border-radius: 30px;
        padding: 8px 20px 8px 20px;
        color: #fff;
        cursor: pointer;
        margin: 10px auto;
    }

    .item-academic-class {
        font-family: Nunito;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 10px;
    }

    .item-academic-name {
        font-family: Nunito;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
    }

    .item-academic {
        margin-bottom: 30px;
    }

    .card-content.cert {
        padding: 50px 50px;
    }
</style>
