<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/exchange-gift.css">

        <div class="container">
            <div id="page-header">
                <ul class="breadcrumb">
                    <li class="icon">
                        <router-link :to="{ name: 'home' }">
                            <img src="/themes/ito/images/icon-profile.svg" alt="">
                        </router-link>
                    </li>
                    <li class="active">{{$t('exchange_gift')}}</li>
                </ul>
                <div class="buttons">
                    <router-link :to="{ name: 'buy_sticker' }">
                        <button class="btn btn-buy-sticker">
                            <i class="ito-icon-buy-sticker"></i>
                            {{$t('buy_sticker')}}
                        </button>
                    </router-link>
                    <router-link :to="{ name: 'exchange_history' }">
                        <button class="btn btn-exchanger-history">
                            <i class="ito-icon-exchanger-history"></i>
                            {{$t('exchange_history')}}
                        </button>
                    </router-link>
                </div>
            </div>

            <div class="card card-exchange-gift">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{$t('exchange_today')}}1/3</span>
                    </h5>

                    <div class="card-content">
                        <div class="row exchange-gifts">
                            <template v-for="(item, index) in exchange_gifts">
                                <div class="col-md-6">
                                    <div class="exchange-gift-item">
                                        <div class="exchange-gift-item-info">
                                            <div class="exchange-gift-item-1">
                                                <div class="thumb ratio109x105">
                                                    <div class="thumb-content">
                                                        <img :src="item.image" alt="">
                                                    </div>
                                                </div>
                                                <div class="remaining">{{$t('remaining')}}{{item.remaining}}/{{item.total}}</div>
                                                <div class="progress">
                                                    <div class="progress-process"
                                                         v-bind:style="{ width: calcPercent(item.remaining,item.total) + '%' }"></div>
                                                </div>
                                            </div>
                                            <div class="exchange-gift-item-2">
                                                <h4 class="title">{{item.name}}</h4>
                                                <div class="desc">{{item.desc}}
                                                </div>
                                                <div class="buttons">
                                                    <a href="#">
                                                        <button class="btn btn-exchange-gift" @click="sendExchangeGift(item.id)">{{$t('exchange_gift')}}</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import {pushQueries, toPercent} from '../../utils/utils'
    import {mapGetters} from 'vuex'
    import {getAllExchangeGift, sendExchangeGift} from "../../models/exchange_gift";
    import {claimDailyBonus} from "../../models/reward";

    export default {
        name: "ExchangeGift",
        data() {
            return {
                exchange_gifts: [],
                isLoading: true
            };
        },

        created() {
            //this.$store.commit('setMenuActive', 'inbox');

            this.fetch();
        },

        methods: {
            fetch(page = 1) {
                const that = this;

                this.page = page;

                const params = {
                    page: this.page,
                };

                //pushQueries(this.$router, '/inbox', params);

                axios.all([
                    getAllExchangeGift(params),
                ]).then(axios.spread((response) => {
                    that.isLoading = false;

                    //that.$store.commit("setLoading", false);

                    if (response.data.code != 0) {
                        return;
                    }

                    that.exchange_gifts = response.data.results.gifts || [];
                }));
            },
            sendExchangeGift(id){
                const that = this;
                const formData = new FormData();
                formData.append('id', id)
                sendExchangeGift(formData).then((response) => {
                    that.$store.commit("setLoading", false);
                    if (response.data.code != 0) {
                        return;
                    }
                    if(response.data.results.gifts){
                        that.exchange_gifts = response.data.results.gifts || [];
                    }
                    // window.location.href = '/exchange-gift';
                }).catch((response) => {
                    that.$store.commit("setLoading", false);
                    console.error(response)
                });
            },

            calcPercent(process, total) {
                return toPercent(process, total);
            },

        }
    }
</script>

<style scoped>

</style>
