<template>
    <div id="content">
        <div class="container">
            <!-- <div id="page-header-fixed" class="mb-25">
                <div class="container">
                    <div id="page-header">
                        <div class="tools">
                            <nav class="ito-tabs">
                                <router-link class="align-items-center gap-1 active"
                                    :to="{ name: 'your_space_trophies' }">Trophies
                                    <div class="noti-alert" v-if="user_info.trophy_unread != 0"></div>
                                </router-link>
                                <router-link class="align-items-center gap-1" :to="{ name: 'your_space_badges' }"
                                    @click.native="getSoundClick">Badges</router-link>
                                <router-link class="align-items-center gap-1" :to="{ name: 'your_space_certificates' }"
                                    @click.native="getSoundClick">Certificates</router-link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card card-parents">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">Trophies</span>
                    </h5>
                    <div class="your_space_content d-flex flex-column align-items-center pb-5">
                        <div class="your_space_row trophy" v-for="items in entries">
                            <template v-for="item in items">
                                <div class="your_space_item trophy_item" :class="{ new: item.status == 1 }"
                                    v-bind:style="item.trophy_image_url != '' ? { backgroundImage: 'url(' + item.trophy_image_url + ')' } : ''"
                                    @click="showItem(item)">
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade trophy_detail" id="showItemPopup" tabindex="-1" role="dialog" aria-labelledby="showItemPopup"
            aria-hidden="true" data-keyboard=false>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content box-shadow-main">
                    <i class="close-popup" data-bs-dismiss="modal" aria-label="Close"></i>
                    <div class="align-items-center d-flex flex-column justify-content-center">
                        <h3 class="title">{{ entry.name }}</h3>
                        <p class="desc">{{ $t('from') }} {{ entry.start_time }} {{ $t('to') }} {{ entry.end_time }}</p>
                        <div class="d-flex my-4 w-100">
                            <div class="col-4 d-flex justify-content-center">
                                <img class="img-fluid" :src="entry.trophy_image_url" alt="">
                            </div>
                            <div class="col-8 ms-3">
                                <div class="row trophy-subject mt-3" v-for="sbj in entry.sub_events">
                                    <h5 class="title">{{ sbj.name }}</h5>
                                    <p class="desc"> Trophies: {{ sbj.student_trophy }}/{{ sbj.total_trophy }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { soundClick, soundGetReward } from '../../utils/utils'
import { getTrophies, showTrophies } from '@/models/your_space';

export default {
    name: "YourSpaceTrophies",
    data() {
        return {
            entries: [],
            entry: {}
        };
    },

    created() {
        this.$store.commit('setMenuActive', 'your_space');
        this.fetch();
    },
    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }
            return this.user
        })
    },
    mounted() {
    },
    methods: {
        fetch() {
            const that = this;
            that.$store.commit("setLoading", true);
            getTrophies().then((data) => {
                that.$store.commit("setLoading", false);
                var newEnries = []
                const emptyEntries = {
                    end_time: '',
                    id: '',
                    name: '',
                    start_time: '',
                    status: 0,
                    sub_events: '',
                    trophy_image_url: '',
                }
                if (data.data.code == 0) {
                    var entries = data.data.results.events
                    if (entries) {
                        var numbs = entries.length
                        if (numbs < 10) {
                            for (var i = 0; i < numbs; i++) {
                                newEnries.push(entries[i])
                            }
                            for (var i = 0; i < (10 - numbs); i++) {
                                newEnries.push(emptyEntries)
                            }
                        }
                    } else {
                        for (var i = 0; i <= 9; i++) {
                            newEnries.push(emptyEntries)
                        }
                    }
                }
                that.entries = this.sortItems(newEnries) || [];
            }).catch(({ response }) => {
                that.$store.commit("setLoading", false);
                alert(response.data.results);
            });
        },

        showItem(item) {
            soundClick()
            if (item.id) {
                const that = this;
                that.entry = item;
                if (item.status == 1) {
                    const formData = new FormData();
                    formData.append('event_id', item.id ? item.id : '');
                    that.$store.commit("setLoading", true);
                    showTrophies(formData).then(() => {
                        that.$store.commit("setLoading", false);
                        that.$store.dispatch("SET_USER_INFO");
                        that.fetch();
                    }).catch(({ response }) => {
                        that.$store.commit("setLoading", false);
                        alert(response.data.results);
                    });
                    soundGetReward()
                }
                $('#showItemPopup').modal('show');
            }
        },

        sortItems(arr) {
            var result = arr.map(function (x, i) {
                return i % 5 === 0 ? arr.slice(i, i + 5) : NaN;
            }).filter(n => n);
            return result;
        },

        getSoundClick() {
            soundClick();
        },
    }
}
</script>

<style scoped>
.trophy_detail .title {
    color: #070A28;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.4px;
}

.trophy_detail .desc {
    color: #10213F;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
}

.trophy_detail .trophy-subject .title {
    color: #10213F;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
}

.trophy_detail .trophy-subject .desc {
    color: #10213F;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
</style>
