<template>
  <div id="content">
    <div class="card card-tooltip" :class="showtooltip ? 'd-block' : 'd-none'" v-if="typeof live_class_data.class != 'undefined' && live_class_data.class.length > 0">
      <div class="card-body">
        <i class="close-popup" @click="hideTooltip"></i>
        <div class="card-content" v-for="(live_class_item, index) in live_class_data.class" v-if="index === 0">
          <div class="title">{{ live_class_item.subscribe_from | convertUnitToTime('ddd') }} -
            {{ live_class_item.subscribe_from | convertUnitToTime('DD/MM/YYYY') }} -
            {{ live_class_item.time_start | convertUnitToTime('HH:mm') }} {{ $t('to') }}
            {{ live_class_item.time_end | convertUnitToTime('HH:mm') }}</div>
          <p class="desc"><b>{{ $t('subject') }} </b> <span class="underline">{{ live_class_item.subject }}</span></p>
          <template v-if="live_class_item.is_live == 1">
            <span class="live-class-dot"></span><span class="live-class-text">{{ $t('live_now') }}</span>
          </template>
          <div style="margin-top: 16px" v-if="live_class_item.is_live == 1">
            <button class="btn btn-join-class" style="padding: 4px 25px;  width: 100%" @click="join_class(live_class_item)">{{
              $t('join_to_class') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="card fade-down card-live-alert mb-4" v-if="user_info.student_type == 1 && (typeof live_class_data.class != 'undefined' || examinations.length > 0)">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('learning_notifications') }}</span>
            <div class="tools">
              <nav class="ito-tabs">
                <a :class="notice == 'live_class' ? 'active' : ''" @click="changeNotice('live_class')" v-if="user_info.account_live_class == 1">{{ $t('live_class') }}</a>
                <a :class="notice == 'online_testing' ? 'active' : ''" @click="changeNotice('online_testing')">Online
                  testing</a>
              </nav>
            </div>
          </h5>
          <div class="card-content" style="background: #F8F8F8;">
            <div id="swiper-live-class" class="swiper mySwiper" v-if="user_info.account_live_class == 1 && notice == 'live_class'">
              <div class="swiper-wrapper" v-if="!live_class_data.class || live_class_data.class.length <= 0">
                <div style="padding: 42px 0; text-align: center; width: 100%;">{{ $t('empty_live_class_data') }}</div>
              </div>
              <template v-else>
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(live_class_item, index) in live_class_data.class">
                    <div class="card-live-alert-item">
                      <div class="title">{{ live_class_item.subscribe_from | convertUnitToTime('ddd') }} -
                        {{ live_class_item.subscribe_from | convertUnitToTime('DD/MM/YYYY') }} -
                        {{ live_class_item.time_start | convertUnitToTime('HH:mm') }} {{ $t('to') }}
                        {{ live_class_item.time_end | convertUnitToTime('HH:mm') }}
                        <template v-if="live_class_item.is_live == 1">
                          <span class="live-class-dot"></span><span class="live-class-text">{{ $t('live_now') }}</span>
                        </template>
                      </div>
                      <p class="desc"><b>{{ $t('class') }} </b> <span class="underline">{{ live_class_item.class_name
                      }}</span></p>
                      <p class="desc"><b>{{ $t('subject') }} </b> <span class="underline">{{ live_class_item.subject
                      }}</span></p>
                      <p class="desc"><b>{{ $t('teacher') }} </b> <span class="underline">{{ live_class_item.teacher
                      }}</span></p>
                      <div style="margin-top: 16px" v-if="live_class_item.is_live == 1">
                        <button class="btn btn-join-class" style="padding: 4px 25px;  width: 100%" @click="join_class(live_class_item)">{{ $t('join_to_class') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-button-next">
                  <img src="/themes/ito/images/live-class-next.svg" alt="">
                </div>
                <div class="swiper-button-prev">
                  <img src="/themes/ito/images/live-class-prev.svg" alt="">
                </div>
              </template>
            </div>
            <template v-if="notice == 'online_testing'">

              <div class="swiper mySwiper" v-if="examinations.length">
                <div class="swiper-wrapper">
                  <template v-for="examination in examinations">
                    <div class="swiper-slide">
                      <div class="card-live-alert-item">
                        <div class="title">
                          {{ $t('from') }} {{ examination.start }} {{ $t('to') }} {{ examination.end }}
                        </div>
                        <p class="desc"><b>{{ examination.name }}</b></p>
                        <p class="desc"><b>{{ $t('subject') }} </b> <span class="underline">{{ examination.subject
                        }}</span></p>

                        <a :href="examination.url" target="_blank" class="ito-btn ito-btn-sm ito-btn-primary ito-btn-block mt-4">
                          {{ $t('take_a_test') }}
                        </a>
                      </div>
                    </div>
                  </template>

                </div>
                <!-- If we need navigation buttons -->
                <div class="swiper-button-prev">
                  <img src="/themes/ito/images/live-class-prev.svg" alt="">
                </div>
                <div class="swiper-button-next">
                  <img src="/themes/ito/images/live-class-next.svg" alt="">
                </div>
              </div>

              <div class="swiper-wrapper" v-else>
                <div style="padding: 42px 0; text-align: center; width: 100%;">{{ $t('no_test_available') }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="card fade-down card-event-alert mb-4" v-if="events != '' && grade == user_info.grade">
        <div class="card-body">
          <div class="card-content d-flex justify-content-between">
            <div class="event-info">
              <div class="event-image overflow-hidden">
                <img class="h-100 w-100" :src="events.thumbnail_url" :alt="events.name">
              </div>
              <div class="event-text">
                <div class="event-title">
                  <div class="title">{{ events.name }}</div>
                  <p class="desc mt-2">{{ $t('from') }} {{ events.start_time }} {{ $t('to') }} {{ events.end_time }}</p>
                </div>
                <div class="event-note">
                  <p>{{ $t('game') }} <strong>{{ events.game_name }}</strong></p>
                </div>
              </div>
            </div>

            <div class="event-action">
              <div class="even-playing" v-if="events.is_start || events.time_remaining <= 0">
                <template v-if="events.is_start">
                  <div class="title"><i class="ito-icon ito-user"></i>{{ events.number_playing }}</div>
                  <p class="desc">{{ $t('student_playing') }}</p>
                </template>
                <template v-else-if="events.time_remaining <= 0">
                </template>

              </div>
              <div class="event-countdown" v-else>
                <div class="title">{{ $t('countdown_time') }}</div>
                <p class="desc" v-html="timeLeft"></p>
              </div>
              <router-link class="ito-btn ito-btn-primary" :to="{ path: '/events' }">
                {{ $t('view_event') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="filter-main">
        <ul class="box-filter">
          <li v-for="subject in subjectData" @click="selectSubject(subject)" :class="{ active: subject.id == selectedSubject.id }">
            {{ (subject.program == 'ismart' ? 'iSMART: ' : '') + subject.name }}
          </li>
        </ul>
      </div>

      <div class="card fade-down mt-20" v-if="selectedSubject.require_subject == 0">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ selectedSubject.name }}</span>
            <div class="d-flex justify-content-between gap-3">
              <router-link :to="{ path: '/final-test/' + user_info.grade + '/' + selectedSubject.id }" class="ito-btn ito-btn-primary" v-if="selectedSubject.show_final_test == 1 && selectedSubject.is_completed == 1 && user_info.student_type == 1">
                {{ $t('do_final_test') }}
              </router-link>

              <button class="ito-btn ito-btn-outline-primary" v-if="selectedSubject.show_final_test == 0">
                {{ $t('final_test_passed') }}
              </button>

              <button class="ito-btn ito-btn-primary" data-bs-toggle="modal" data-bs-target="#openHouse" v-if="user_info.campaign_info.id != 0">
                {{ $t('open_house') }}
                <i class="ito-icon ito-connect"></i>
              </button>

              <router-link @click.native="handleGACustomEvent('ITC_W_1000', selectedSubject.id)" class="ito-btn ito-btn-primary" :to="{ path: '/ebook/' + selectedSubject.units[0].id }" target="_blank"
                v-if="user_info.account_type > 1 && selectedSubject.program == 'ismart' && selectedSubject.show_final_test == 1 && selectedSubject.ebook != ''">
                {{ $t('ebook_digital') }}
                <i class="ito-icon ito-book"></i>
              </router-link>

              <button class="ito-btn ito-btn-primary" @click="studyGuide(selectedSubject)">
                {{ $t('study_guide') }}
                <i class="ito-icon ito-circle-question"></i>
              </button>

              <!-- <div class="levels" style="margin-left: 15px; font-size: 16px" v-if="selectedSubject.program == 'Eng_Test_Prep'">
                {{ $t('level') }}: <b>{{ user_info.ge_level }}</b>
              </div> -->
            </div>
          </h5>
          <div class="card-content">
            <div class="last-study d-flex justify-content-between mb-5" v-if="selectedSubject.has_latest_lesson && selectedSubject.program != 'Eng_Test_Prep'">
              <div class=" last-study-image d-flex w-25">
                <img :src="selectedSubject.latest_lesson.image" alt="" class="img-fluid" />
              </div>
              <div class="last-study-content ms-3 w-75">
                <div class="last-study-name">
                  {{ selectedSubject.latest_lesson.name_unit }}: {{ selectedSubject.latest_lesson.title_unit }}
                </div>
                <div class="last-study-des">
                  {{ selectedSubject.latest_lesson.name_lesson }}: {{ selectedSubject.latest_lesson.title_lesson }}
                </div>
                <div class="d-flex">
                  <router-link :to="{ path: '/lesson/' + selectedSubject.latest_lesson.subject_id + '-' + selectedSubject.latest_lesson.unit_id + '-' + selectedSubject.latest_lesson.lesson_id, }" class="ito-btn ito-btn-success mt-2">
                    {{ $t("continue_txt") }}
                  </router-link>
                </div>
              </div>
              <span class="desc-time">
                <b>{{ selectedSubject.latest_lesson.time_ago_txt }}</b>
                <template v-if="selectedSubject.latest_lesson.time_txt">
                  {{ $t("at") }} {{ selectedSubject.latest_lesson.time_txt }}
                </template>
              </span>
            </div>
            <div class="row" v-if="selectedSubject.units">
              <template v-if="existsUnitItem(selectedSubject)">
                <template v-for="(unit) in selectedSubject.units">
                  <div class="col-sm-6 col-md-4 col-lg-3 mb-4 card-hover" v-if="selectedSubject.filter_type == 'all' || (selectedSubject.filter_type == 'incomplete' && unit.incomplete == 1)">
                    <div class="card card-hover">
                      <div :class="(unit.status == 1 || unit.status == 2) ? 'lock-unit' : ''" @click="gotoUnit(unit)">
                        <img :src="unit.image" alt="" class="card-img-top rounded-top-md" />
                        <div class="card-body lession-content">
                          <h3 class="lession-name">
                            <span>{{ unit.title }}</span>
                            <div class="tooltiptext">{{ unit.title }}</div>
                          </h3>
                          <h4 class="lession-status">
                            {{ $t("complete") }} {{ unit.process }}/{{ unit.total }}
                          </h4>
                        </div>
                        <img class="lock-image" src="themes/ito/images/icons/icon-lock-new.svg" v-if="unit.status == 1 || unit.status == 2" />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <h3 class="text-center text-no-record">
                  {{ $t("incomplete_empty") }}
                </h3>
              </template>
            </div>
            <h3 class="text-center text-no-record" v-else>
              {{ $t("incomplete_empty") }}
            </h3>
          </div>
        </div>
      </div>

      <div class="modal fade" id="popupUpgradeAccount" tabindex="-1" role="dialog" aria-labelledby="popupUpgradeAccount">
        <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
          <div class="modal-content box-shadow-main" style="padding: 20px">
            <div class="modal-body">
              <h3 class="popup-title mb-20">{{ $t('upgrade_account_require') }}</h3>
              <p class="txt-popup" v-html="$t('upgrade_to_package', { package_name: requirePackage })">
              </p>
            </div>
            <i data-bs-dismiss="modal" aria-label="Close" class="close-popup"></i>
            <div class="d-flex justify-content-end gap-3 mt-3">
              <button type="button" class="ito-btn ito-btn-primary" @click="doTest()" v-if="user_info.placement_test == 0 && user_info.student_type != 2">
                {{ $t('upgrade_account') }}
              </button>
              <router-link :to="{ name: user_info.student_type == 2 ? 'register' : 'upgrade_account' }" data-bs-dismiss="modal" v-else>
                <button class="ito-btn ito-btn-primary">
                  {{ $t('upgrade_account') }}
                </button>
              </router-link>
              <button type="button" class="ito-btn ito-btn-default" data-bs-dismiss="modal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="popupUnitIncomplete" tabindex="-1" role="dialog" aria-labelledby="popupUnitIncomplete">
        <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
          <div class="modal-content box-shadow-main" style="padding: 20px 10px 30px;">
            <h3 class="popup-title mb-20">Bài học đang bị khóa</h3>
            <p class="txt-popup">
              Bạn cần hoàn thành bài học <strong>{{ prevUnit }}</strong> để mở khóa bài học này.
            </p>
            <div class="d-flex justify-content-center mt-3">
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>

      <error-modal :err_msg="errorMsg" className="homepageErr" />

      <div class="modal fade" id="studyGuide" tabindex="-1" role="dialog" aria-labelledby="popupStudyGuide">
        <div class="modal-dialog modal-dialog-centered image-modal-xl" role="document">
          <div class="image-modal modal-content box-shadow-main p-0">
            <span class="image-modal-dissmiss" data-bs-dismiss="modal" title="Close"></span>
            <img :src="subjectStudyGuidePic" :alt="subjectName">
          </div>
        </div>
      </div>

      <div class="modal fade" id="openHouse" tabindex="-1" role="dialog" aria-labelledby="popupOpenHouse">
        <div class="modal-dialog modal-dialog-centered modal-95" role="document">
          <div class="image-modal modal-content box-shadow-main p-0">
            <span class="image-modal-dissmiss" data-bs-dismiss="modal" title="Close"></span>
            <iframe :src="getrouterLink('open_house', { grade: user_info.grade_code })" frameborder="0" width="100%" height="100%" onload="frameLoaded = true"></iframe>
          </div>
        </div>
      </div>
      <claim-reward-dialogue ref="claimRewardDialogue"></claim-reward-dialogue>
      <welcome-back-modal></welcome-back-modal>
      <div class="open_house_shortcut shake-top" data-bs-toggle="modal" data-bs-target="#openHouse" v-if="user_info.campaign_info.id != 0">
        <img class="img-fluid" src="/themes/ito/images/open-house/floating_icon.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { soundClick, soundGetReward } from "../utils/utils";
import { getAllUnit, getLastest, getListUnit, getOnlineTestData, getEvents } from "../models/dashboard";
import { joinLiveClass } from "../models/tutoring";
import { ErrorModal, WelcomeBackModal, ClaimRewardDialogue, OpenHouse } from './modal';
import { claimReward } from "../models/events";


var counter;

export default {
  name: "Welcome",
  components: {
    ErrorModal,
    WelcomeBackModal,
    ClaimRewardDialogue,
    OpenHouse,
  },

  data() {
    return this.initState();
  },

  computed: {

    user_info() {
      return this.$store.state.user_info;
    },
    live_class_data() {
      return this.$store.state.live_classes;
    },
  },

  watch: {
    $route() {
      this.fetch();
    },
  },

  created() {
    window.addEventListener('message', this.handleMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage, false);
  },
  mounted() {
    this.$store.commit("setMenuActive", "home");
    this.fetch();
  },

  methods: {
    initState() {

      return {
        timeLeft: 0,
        timeRemain: 0,
        timeRemainShow: 0,

        errorMsg: '',
        events: [],
        subjectStudyGuidePic: '',
        subjectName: '',
        requirePackage: '',

        isLoading: false,
        subjectData: [],
        examinations: [],
        live_classes: [],
        notice: "online_testing",
        grade: '',
        prevUnit: '',
        selectedSubject: [],
        checkedSubjects: [],
        showtooltip: true,
        frameLoaded: false,
      };
    },

    fetch() {
      this.getAllUnits();
      this.getEvents();
      this.getOnlineTestData();
    },

    getrouterLink(name, params) {
      let props = this.$router.resolve({
        name: name,
        params: params,
      });
      var href = this.serialize(props.href, params)
      return href;
    },

    serialize(url, obj) {
      var str = [];
      for (var p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return url + '?' + str.join("&");
    },

    getAllUnits() {
      const that = this;
      const checkedSubjects = localStorage.getItem('checkedSubjects');
      let user_has_latest_lesson = false;
      const grade = localStorage.getItem('grade') ? localStorage.getItem('grade') : that.user_info.grade;
      const params = {
        grade: grade
      };
      that.grade = grade
      axios.all([getAllUnit(params)]).then(
        axios.spread((response) => {
          if (response.data.code != 0) {
            return;
          }
          that.subjectData = [];
          const subjects = response.data.results.subjects || [];
          subjects.forEach((sbj) => {
            sbj.show_final_test = 1;
            if (that.user_info) {
              if (that.user_info.final_test_grade) {
                that.user_info.final_test_grade.forEach(function (e) {
                  if (e.subject_id == sbj.subject_id && localStorage.getItem('grade') == e.grade) {
                    sbj.show_final_test = 0;
                  }
                })
              }

            }
            sbj.filter_type = 'all';
            sbj.units = sbj.units.sort((a, b) => {
              return a.order - b.order;
            })
            const units = sbj.units;
            sbj.has_latest_lesson = false

            if (sbj.latest_lesson.lesson_id) {
              units.forEach((unit) => {
                if (unit.id == sbj.latest_lesson.unit_id) {
                  sbj.has_latest_lesson = true
                  user_has_latest_lesson = true
                  // $('#WelcomeBackModal').modal('show');
                }
              })
            }
            that.subjectData.push(sbj);
          })

          if (checkedSubjects) {
            var subject = this.subjectData.filter((sbj) => sbj.category_code == checkedSubjects);
            this.selectSubject(subject[0])
          } else {
            this.selectSubject(this.subjectData[0])
          }

          if (user_has_latest_lesson && (sessionStorage.getItem('showed_incomplete_lesson_popup') === null || sessionStorage.getItem('showed_incomplete_lesson_popup') === "false")) {
            $('#WelcomeBackModal').modal('show');
            sessionStorage.setItem('showed_incomplete_lesson_popup', "true");
          } else {
            $('#WelcomeBackModal').modal('hide');
          }
        })
      );
    },

    selectSubject(subject) {
      if (!subject) return;

      this.getSoundClick()
      this.selectedSubject = subject;
      this.checkedSubjects = subject.category_code;
      localStorage.setItem('checkedSubjects', subject.category_code);
      localStorage.setItem('program', subject.program);
    },


    join_class(tutoring) {
      const that = this;
      that.$store.commit("setLoading", true);
      joinLiveClass({
        id: tutoring.id
      }).then((response) => {
        that.$store.commit("setLoading", false);

        if (response.data.code != 0) {
          return;
        }

        $('#tutoringDetailJoinClassModal' + tutoring.id).modal("hide");

        window.open(response.data.results.link, '_blank');

        that.$emit('updateTutoring', 'join_class', tutoring, response.data.results);
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        console.error(response);
      });
    },

    existsUnitItem(subject) {
      let exists = false;
      subject.units.forEach((unit) => {
        if (subject.filter_type == 'all' || (subject.filter_type == 'incomplete' && unit.incomplete == 1)) {
          exists = true;
        }
      });

      return exists;
    },

    changeNotice(notice) {
      this.notice = notice;
      this.$nextTick(function () {
        this.initSwiper()
      });
    },

    getEvents() {
      const that = this;
      const params = {
        grade: localStorage.getItem('grade') ? localStorage.getItem('grade') : that.user_info.grade,
      };
      this.grade = localStorage.getItem('grade') ? localStorage.getItem('grade') : that.user_info.grade;
      this.$store.commit("setLoading", true);
      axios.all([getEvents(params)]).then(
        axios.spread((response) => {
          const events = response.data.results || [];
          if (events.can_claim) {
            const dialog = this.$refs.claimRewardDialogue
            const params = {
              title: this.$t('congratulation'),
              image: events.can_claim.reward_img_gem,
              reward: events.can_claim.reward_gem,
              message: this.$t('battle_ranking_reward_1') + ' #' + events.can_claim.rank + ' ' + this.$t('battle_ranking_reward_2') + ' <strong>' + events.can_claim.event_name + '</strong>',
              okButton: this.$t('claim'),
            }
            this.showDialog(dialog, params).then((confirm) => {
              if (confirm) {
                const payload = {}
                that.$store.commit("setLoading", true);
                claimReward(payload).then((response) => {
                  that.$store.commit("setLoading", false);
                  if (response.data.code == 0) {
                    let user = this.$store.state.user_info;
                    user.gem += parseInt(events.can_claim.reward_gem)
                    user.gem_month += parseInt(events.can_claim.reward_gem)
                    this.getSoundGetReward();
                    const params = {
                      title: this.$t('claim_success'),
                      reward: events.can_claim.reward_gem,
                      okButton: this.$t('ok'),
                    }
                    this.showDialog(dialog, params)
                  }
                });
                this.$store.commit("setLoading", false);
              }
            })
          }
          if (response.data.code != 0) {
            this.$store.commit("setLoading", false);
            return;
          }
          that.events = [];
          that.events = events
          this.$store.commit("setLoading", false);
          if (events.time_remaining > 0) {
            that.timerCountdown(events.time_remaining);
          }

        })
      );
    },

    getOnlineTestData() {
      const that = this;
      const params = {
        user_id: that.user_info.user_id,
        grade: that.user_info.grade,
      };
      axios.all([getOnlineTestData(params)]).then(
        axios.spread((response) => {
          if (response.data.code != 200) {
            return;
          }
          that.examinations = response.data.results || [];
          if (that.live_class_data.length <= 0) {
            this.changeNotice('live_class')
          }
          this.$nextTick(function () {
            this.initSwiper()
          });
        })
      );
    },

    getSoundClick() {
      soundClick();
    },
    getSoundGetReward() {
      soundGetReward();
    },

    upgradeAccount(package_name) {
      this.requirePackage = package_name;
      $('#popupUpgradeAccount').modal('show');
    },

    unitIncomplete(units) {
      if (units) {
        const names = units.map(x => x.name);
        this.prevUnit = names.join(', ');
        $('#popupUnitIncomplete').modal('show');
      } else {
        this.errorMsg = 'Requirement units is empty, please contact the administrator.';
        $('.homepageErr').modal('show');
      }
    },

    updateFirstLogin(key) {
      this.user_info.first_login = key;
    },

    studyGuide(subject) {
      this.handleGACustomEvent('ITC_W_1001', subject.id)
      this.$store.commit("setLoading", true);
      this.subjectStudyGuidePic = subject.study_guide_picture;
      this.subjectName = subject.name;
      this.$store.commit("setLoading", false);
      $('#studyGuide').modal('show');
    },

    doTest() {
      $('#popupUpgradeAccount').modal('hide');
      $('#placementModal').modal('show');
    },

    gotoUnit(unit) {
      if (unit.status == 0) {
        var path = '';
        if (this.selectedSubject.program == 'Eng_Test_Prep') {
          path = '/prep/' + this.selectedSubject.id + '/' + unit.id + '/1';
          this.handleGACustomEvent('ITC_W_4000')
          this.$router.push({ path: path })

        } else {
          if (this.selectedSubject.category_code == 'math') {
            this.handleGACustomEvent('ITC_W_1009', this.selectedSubject.id)
          }
          else if (this.selectedSubject.category_code == 'science') {
            this.handleGACustomEvent('ITC_W_1010', this.selectedSubject.id)
          }
          path = '/unit/' + this.selectedSubject.id + '/' + unit.id + '/1';
          this.$router.push({ path: path })

        }
        this.handleGACustomEvent('ITC_W_1008', this.selectedSubject.id)
      } else if (unit.status == 2) {
        this.unitIncomplete(unit.component_requirement)
      } else {
        if (this.user_info.account_type != 2) {
          this.upgradeAccount(unit.package_name)
        }
      }
    },

    timerCountdown(time_remaining) {
      const self = this;
      counter = new Counter(time_remaining + 1, 1000);
      counter.onTick(function (time) {
        self.timeLeft = time.hour + ':' + time.minute + ':' + time.second
      });
      counter.onEnd(function () {
        window.location.reload();

      })
      counter.start();
    },

    handleLockedUnit(unit) {
      if (unit.status == 1) {
        this.requirePackage = unit.package_name
        $('#popupUpgradeAccount').modal('show');
      } else {
        var requiedComponent = unit.component_requirements
        if (requiedComponent) {
          const names = requiedComponent.map(x => x.name);
          this.prevUnit = names.join(', ');
          $('#popupUnitIncomplete').modal('show');
        } else {
          this.errorMsg = 'Data component requirements does not exist.'
          $('#errorModal').modal('show');
        }
      }
    },
    hideTooltip() {
      this.showtooltip = false;
    },
    initSwiper() {
      return new Swiper(".mySwiper", {
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    handleGACustomEvent(element, id) {
      var user_id = this.user_info.user_id || 0;
      var school_id_ref = parseInt(this.user_info.school_id_ref) || 0;
      var params = {
        'w_user_id': user_id
      };

      if (element == 'ITC_W_1008') {
        params['subject_id'] = id || 0;
        params[element] = user_id + '|' + id;
      }

      else if (element == 'ITC_W_4000' || element == 'ITC_W_1009' || element == 'ITC_W_1010') {
        params['school_id'] = school_id_ref
        params[element] = user_id + '|' + school_id_ref;
      }

      else {
        params['course_id'] = id || 0;
        params[element] = user_id + '|' + id;
      }
      gtag('event', element, params);
    },
    async showDialog(dialog, params) {
      const cfm = await dialog.show(params);
      return cfm;
    },
    handleMessage(event) {
      const item = event.data.data;
      switch (event.data.event) {
        case 'go-to-lesson':
          if (item) {
            $('#openHouse').modal('hide');
            var route = {
              name: 'unit',
              params: {
                subject_id: item.subject_id,
                unit_id: item.unit_id,
                page: 1
              }
            }
            var url = this.$router.resolve(route)
            if (this.$route.path != url.href) {
              this.$router.push(route);
            }
          }
          break;
        case 'go-to-battlegame':
          $('#openHouse').modal('hide');
          this.$router.push({
            name: 'events'
          })
          break;
        case 'go-to-liveclass':
          $('#openHouse').modal('hide');
          if (item) {
            window.open(item.url, '_blank');
          }
          break;
        case 'go-to-arena':
          $('#openHouse').modal('hide');
          if (item) {
            window.open(item.url, '_blank');
          }
          break;
      }
    },

  },
};
</script>

<style scoped>
.ebook-link {
  display: flex;
  align-items: center;
  padding: 11px 19px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF !important;
  height: 40px;
  background: #434343;
  border: 1px solid #FFFFFF;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07), inset 0px 2px 0px rgba(255, 255, 255, 0.2);
  border-radius: 40px;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 65px;
  margin: auto;
  left: 0;
  right: 0;
  transform: translateX(45px);
  z-index: 99;
}

.btn-class-final-passed {
  cursor: auto;
  border: 1px solid #00a3ff;
  box-sizing: border-box;
  border-radius: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #00a3ff;
}



.filter-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
  overflow: hidden;
}

.sticky .filter-main {
  padding: 15px 30px;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.07);
  height: 65px;
  background: #f8f8f8;
  border-radius: 0 0 8px 8px;
}

.card-tooltip {
  position: fixed;
  left: 105px;
  top: 171px;
  overflow: inherit;
  width: 200px;
  z-index: 1;
}

.card-tooltip::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 0;
  margin-left: -12px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}

/* .card-live-alert-item {
  width: 459px;
} */

.card-tooltip .live-class-dot {
  margin-left: 0;
}

/* .card-tooltip .title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #00A3FF;
  margin-bottom: 4px;
} */

.image-modal-dissmiss {
  position: absolute;
  right: 10px;
  top: 10px;
  content: url('/public/themes/ito/images/icons/image-modal-dissmiss.svg');
  user-select: none !important;
  -webkit-user-select: none;
  cursor: pointer;
}

.image-modal {
  border-radius: 8px;
  overflow: hidden;
}

.image-modal-xl {
  max-width: 1920px;
  width: 80%;
}

.modal-95 {
  width: 95vw;
  max-width: none;
  height: 100%;
  margin: 0 auto;
}

.modal-95 .modal-content {
  height: 95%;
  border: 0;
  border-radius: 8px;
}

.card-hover {
  cursor: pointer;
}


.box-filter {
  border-radius: 32px;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
  background: var(--white);
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  list-style-type: none;
  display: flex;
  margin: 0;
  justify-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
}



.box-filter li {
  border-radius: 32px;
  font-family: 'Nunito';
  font-style: normal;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-gray);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 3px solid #FFFFFF;
  transition: all .2s;
  height: 40px;

}

.box-filter li.active,
.box-filter li:hover {
  color: var(--text-white);
  background: var(--primary);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset;
}

.box-filter li.disabled:hover {
  background: #c4c4c4;
}

.open_house_shortcut {
  content: '';
  width: 80px;
  height: 80px;
  position: fixed;
  top: 75px;
  right: 10px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background-color: var(--primary);
  box-shadow: var(--primary) 0px 0px 10px 7px;
}

.open_house_shortcut:hover {
  animation: none;
}


.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.shake-top {
  -webkit-animation: shake-top 1.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation: shake-top 1.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
  animation-delay: 0.8s;
}

@-webkit-keyframes shake-top {

  0%,
  25%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }

  5% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  10% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  15% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  20% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}

@keyframes shake-top {

  0%,
  50%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }

  5%,
  45% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  10%,
  20%,
  30% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  15%,
  25%,
  35% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  40% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }


}
</style>