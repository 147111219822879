import {get, post} from "../APIGateWay";

export function getEvents(params) {
    return get(params, '/battle-game/event', params);
}

export function getRankings(params) {
    return get(params, '/battle-game/event-ranking', params);
}
export function claimReward(params) {
    return post(params, '/battle-game/claim', params);
}
