<template>
    <div class="modal fade" id="dailyBonusModal" tabindex="-1" role="dialog" aria-labelledby="dailyBonusModal"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
            <div class="modal-content pyro box-shadow-main reward-modal">
                <h3 class="popup-title mb-10">{{ $t('daily_bonus') }} {{ $t(user.dailybonus_dayname) }}</h3>
                <div class="align-items-center d-flex flex-column justify-content-center mt-20">
                    <img class="m-0 w-auto h-auto" src="/themes/ito/images/isaac-rewards.svg" alt="">
                    <h2 class="popup-value mt-2">+{{ user.dailybonus_gemreward || 0 }}</h2>
                    <p class="txt-popup mt-2">{{ $t('daily_bonus_1') }}</p>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button type="button" class="ito-btn ito-btn-primary" @click="claim">
                        {{ $t('claim') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { claimDailyBonus } from "../../models/reward";
import { soundClick, soundGetReward, soundClaimTreasure } from '../../utils/utils';

export default {
    name: "DailyBonusModal",

    props: ['user'],

    watch: {
        $route() {
            this.$nextTick(function () {
                if (this.user.first_login == 1) {
                    $("#dailyBonusModal").modal();
                }
            })
        },
    },

    created() {
        this.$nextTick(function () {
            if (this.user.first_login == 1) {
                $("#dailyBonusModal").modal();
            }
        })

    },

    methods: {
        claim() {
            const that = this;
            that.getSoundClick();
            that.$store.commit("setLoading", true);
            claimDailyBonus().then((response) => {
                that.$store.commit("setLoading", false);
                $('#dailyBonusModal').modal("hide");
                if (response.data.code != 0) {
                    this.$emit('updateFirstLogin', 1, response.data.msg)
                 return;
                }

                const gem_reward = response.data.results.gem_reward || 0;
                const new_gem = response.data.results.update_gem || 0;
                that.user.gem = new_gem;
                const gem_txt = gem_reward >= 0 ? '+' + gem_reward : '-' + gem_reward;
                that.getSoundClaimTreasure();
                this.$emit('updateFirstLogin', 0, gem_txt)
            }).catch((response) => {
                that.$store.commit("setLoading", false);
                console.error(response);
            });
        },

        getSoundClick() {
            soundClick();
        },

        getSoundGetReward() {
            soundGetReward();
        },
        getSoundClaimTreasure() {
            soundClaimTreasure();
        }
    }
}
</script>

<style scoped></style>
