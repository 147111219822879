<template>
  <div id="SiteContent" class="site-wrapper p-landing" data-js-module="landing">
    <div class="l-body l-landing h-shadow-1">
      <div class="l-landing__introduce1 h-bg-cover d-flex align-items-end">
        <div class="container">
          <div class="align-items-end">
            <div class="col-12 col-md-auto">
              <div class="intro-wrapper">
                <h2 class="intro-title">Nơi <span class="intro-title__text-lg color-yellow-a200">Công nghệ Giáo
                    dục</span><br>mang đến cho bạn trải nghiệm tốt nhất</h2>
                <div class="intro-actions d-flex flex-wrap gap-4">
                  <a href="/register-anomynous" class="btn btn-lg btn--primary">Trải nghiệm thử<i
                      class="fas fa-angle-double-right ms-2"></i></a>
                  <a href="/register" class="btn btn-lg btn--primary">Đăng ký ngay<i
                      class="fas fa-angle-double-right ms-2"></i></a>
                </div>
                <div class="intro-image">
                  <img src="/landing_page/assets/img/landing__intro.png" alt="#" style="opacity: 0;">
                  <a href="#" class="intro-image__next-section"><i class="fas fa-angle-down"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Section__Landing--Features" class="l-landing__features">
        <div class="features-list">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-4">
                <div class="single-feature">
                  <div class="single-feature__icon">
                    <div class="icon-wrapper bg-color-light-blue-500 animated"><img
                        src="/landing_page/assets/img/icons/learning.png" alt="Icon"></div>
                  </div>
                  <h3 class="single-feature__title animated">Công nghệ bài giảng số</h3>
                  <div class="single-feature__descr animated">Công nghệ và Giáo dục song hành với nhau mang đến một
                    trải nghiệm mới trong học tập.</div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="single-feature">
                  <div class="single-feature__icon">
                    <div class="icon-wrapper bg-color-light-green-500 animated delay-02s"><img
                        src="/landing_page/assets/img/icons/student.png" alt="Icon"></div>
                  </div>
                  <h3 class="single-feature__title animated delay-02s">Lấy học viên là trung tâm</h3>
                  <div class="single-feature__descr animated delay-02s">Học liệu được nghiên cứu và thiết kế nhằm thay
                    đổi tích cực cách tiếp cận của học viên trong học tập.</div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="single-feature">
                  <div class="single-feature__icon">
                    <div class="icon-wrapper bg-color-cyan-600 animated delay-04s"><img
                        src="/landing_page/assets/img/icons/category.png" alt="Icon"></div>
                  </div>
                  <h3 class="single-feature__title animated delay-04s">Kiến thức theo chủ đề</h3>
                  <div class="single-feature__descr animated delay-04s">Kiến thức phân loại theo các chủ đề giúp học
                    viên thu nạp kiến thức toàn diện hơn bao giờ hết.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-actions">
          <div class="container">
            <div class="features-actions__wrapper d-flex align-items-center justify-content-center">
              <h2 class="features-actions__title animated">Học theo <span class="h-c-primary">phong cách mới</span>
              </h2>
              <div class="features-actions__link animated d-flex flex-wrap gap-4">
                <a href="/register-anomynous" class="c-button c-button--primary c-button--rounded btn">Trải nghiệm thử<i
                    class="fas fa-angle-double-right ms-2"></i></a>
                <a href="/register" class="c-button c-button--primary c-button--rounded btn">Đăng ký ngay<i
                    class="fas fa-angle-double-right ms-2"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-landing__learn-by-english h-bg-cover">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-xs-10 col-md-6">
              <div class="lbe-content">
                <h2 class="lbe-content__title">Học <span class="h-c-primary">Tiếng Anh</span><br>thông qua<br><span
                    class="color-blue-500">Toán</span> và <br><span class="color-blue-500">Khoa học</span></h2>
                <div class="lbe-content__descr">Học viên có cơ hội khám phá những con số, phép toán, tìm hiểu về các
                  hiện tượng trong đời sống và thế giới xung quanh bằng ngôn ngữ thứ hai.</div>
                <div class="lbe-content__actions d-flex flex-wrap gap-4">
                  <a href="/register-anomynous" class="btn btn-lg c-button c-button--primary c-button--rounded">Trải
                    nghiệm thử<i class="fas fa-angle-double-right ms-2"></i></a>
                  <a href="/register" class="btn btn-lg c-button c-button--primary c-button--rounded">Đăng ký ngay<i
                      class="fas fa-angle-double-right ms-2"></i></a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5">
              <div class="lbe-icons">
                <div class="row">
                  <div class="col-6">
                    <div class="lbe-icons__icon lbe-icons__icon--1st"><img
                        src="/landing_page/assets/img/icons/chemistry.png" alt="#">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="lbe-icons__icon lbe-icons__icon--2nd"><img src="/landing_page/assets/img/icons/math.png"
                        alt="#">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-landing__learn-everywhere h-bg-cover">
        <div class="lew-content animated fadeInUp">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-10 col-md-3">
                <h2 class="lew-content__title">Học <br><span class="color-orange-a200">mọi lúc</span>, <br><span
                    class="color-orange-a200">mọi nơi</span></h2>
              </div>
              <div class="col-10 col-md-9">
                <div class="lew-content__descr">Chúng tôi tin rằng, xu thế áp dụng Công nghệ trong giảng dạy sẽ mang
                  đến một nền Giáo dục hiện đại và hiệu quả. Ở iSMART, học viên có thể trau dồi kiến thức mọi lúc, mọi
                  nơi.</div>
                <div class="lew-content__actions d-flex flex-wrap gap-4">
                  <a href="/register-anomynous" class="btn btn-lg c-button c-button--secondary">Trải
                    nghiệm thử<i class="fas fa-angle-double-right ms-3"></i></a>
                  <a href="/register" class="btn btn-lg c-button c-button--secondary">Đăng ký ngay<i
                      class="fas fa-angle-double-right ms-3"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-landing__benefits">
        <div class="container">
          <h2 class="benefits-title">Những gì chúng tôi đem lại</h2>
          <div class="benefits-content">
            <div class="list-benefits">
              <div id="Landing-benefit-1" class="single-benefit single-benefit--1st animated">
                <div class="single-benefit__image">
                  <div class="image-wrapper"><img src="/landing_page/assets/img/landing__Hoclieusangtao.png" alt="#">
                  </div>
                </div>
                <div class="single-benefit__info">
                  <h3 class="single-benefit__title">Học liệu sáng tạo</h3>
                  <div class="single-benefit__descr">Kiến thức được truyền tải <br>qua trò chơi, phim hoạt hình,
                    <br>sơ đồ tư duy…
                  </div>
                </div>
              </div>
              <div id="Landing-benefit-2" class="single-benefit single-benefit--2nd animated">
                <div class="single-benefit__image">
                  <div class="image-wrapper"><img src="/landing_page/assets/img/landing__Botrotoandien.jpg" alt="#"></div>
                </div>
                <div class="single-benefit__info">
                  <h3 class="single-benefit__title">Bổ trợ toàn diện</h3>
                  <div class="single-benefit__descr">Giúp học viên củng cố và mở rộng <br>thêm những kiến thức đã được
                    học khi ở trường.</div>
                </div>
              </div>
              <div id="Landing-benefit-3" class="single-benefit single-benefit--3rd animated">
                <div class="single-benefit__image">
                  <div class="image-wrapper"><img src="/landing_page/assets/img/landing__Tuybientheodotuoi.png" alt="#">
                  </div>
                </div>
                <div class="single-benefit__info">
                  <h3 class="single-benefit__title">Tuỳ biến <br>theo độ tuổi</h3>
                  <div class="single-benefit__descr">Các khoá học được thiết kế riêng cho<br> từng độ tuổi để đảm bảo
                    sự phát triển toàn diện nhất.</div>
                </div>
              </div>
              <div id="Landing-benefit-4" class="single-benefit single-benefit--4th animated">
                <div class="single-benefit__image">
                  <div class="image-wrapper"><img src="/landing_page/assets/img/landing__Thuthachhangngay.png" alt="#">
                  </div>
                </div>
                <div class="single-benefit__info">
                  <h3 class="single-benefit__title">Mỗi ngày <br>một thử thách</h3>
                  <div class="single-benefit__descr">Tham gia thử thách hàng ngày <br>để hình thành thói quen học tập
                    và trau dồi kiến thức không ngừng.</div>
                </div>
              </div>
              <div id="Landing-benefit-5" class="single-benefit single-benefit--5th animated">
                <div class="single-benefit__image">
                  <div class="image-wrapper"><img src="/landing_page/assets/img/landing__TheodoiKetquahoctap.png" alt="#">
                  </div>
                </div>
                <div class="single-benefit__info">
                  <h3 class="single-benefit__title">Theo dõi kết quả học tập </h3>
                  <div class="single-benefit__descr">Các loại báo cáo thông minh,<br> bảng xếp hạng được cập nhật liên
                    tục.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="benefits-action text-center d-flex flex-wrap gap-4">
            <a href="/register-anomynous" class="btn btn-lg c-button c-button--primary c-button--rounded">Trải
              nghiệm thử<i class="fas fa-angle-double-right ms-2"></i></a>
            <a href="/register" class="btn btn-lg c-button c-button--primary c-button--rounded">Đăng ký ngay<i
                class="fas fa-angle-double-right ms-2"></i></a>
          </div>
        </div>
      </div>
      <div class="l-landing__start-future">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xs-10 col-sm-8">
              <h2 class="stf-title">Nơi tương lai <br>bắt đầu</h2>
            </div>
            <div class="col-xs-10 col-sm-4">
              <div class="stf-image text-center"><img src="/landing_page/assets/img/landing__start-future.png" alt="#">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LandingPage",
  components: {

  },
  data() {
    return{}
  },
  computed: {

  },
  created() {

  },
  methods: {

  },
};
</script>
<style scoped></style>
