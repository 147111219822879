<template>
  <div id="content">
    <!-- <link rel="stylesheet" href="/themes/ito/css/parents.css"> -->

    <div class="container">
      <div id="upgrade-detail" class="card card-comments">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">
              {{ $t('select_package') }}
              {{ selected_package && step > 1 ? ' >> ' + selected_package.name : '' }}
              {{ step == 3 ? ' >> ' + $t('payment') : '' }}
            </span>
          </h5>
          <div class="card-content " :class="step == 1 ? 'list_package py-5 px-0' : step == 3 ? 'px-5' : ''">
            <div v-if="step == 1">
              <div class="swiper packageSwiper position-relative" v-if="packages.length">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="pkgs in packages">
                    <div class="select-upgrade-type d-flex justify-content-center mb-20">
                      <template v-for="pkg in pkgs">
                        <template v-if="pkg">
                          <div class="package" :class="{ 'has_sale': pkg.id == 30 }">
                            <div class="sale-off" v-if="pkg.id == 30"><span>-50%</span></div>
                            <h5 class="title">{{ pkg.name }}</h5>
                            <div class="price"><span v-if="pkg.id == 30">{{ (pkg.price * 2) | toCommas }}
                              </span> {{ pkg.price | toCommas }}&#8363;</div>
                            <button type="button" @click="changePackage(pkg)" class="ito-btn ito-btn-lg ito-btn-block ito-btn-primary mt-20">{{ $t('buy_now') }}</button>
                            <div class="content mt-3">
                              <template v-for="desc in pkg.description">
                                <div class="items d-flex align-items-start mt-3">
                                  <img :src="desc.bullet_icon ? desc.bullet_icon : '/themes/ito/images/payment-check-icon.svg'">
                                  <div class="items-text ms-2">{{ $t(desc.content) }}</div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="swiper-prev"></div>
                <div class="swiper-next"></div>
              </div>
              <div class="no_packages" v-else>
                <h3 class="text-muted">{{ $t('no_packages') }}</h3>
              </div>
            </div>
            <div v-if="step == 2">
              <p class="label-upgrade">1. {{ $t("please_select_how_you_would_like_to_pay") }}</p>
              <div class="row justify-content-center">
                <table class="package-table mt-3 w-75">
                  <tr v-for="product in products">
                    <td class="w-100">
                      <input :id="'product_' + product.product_id" class="ito-pkg" name="product" type="radio" v-model="product_id" :value="product.product_id" @change="changeProduct(product)" />
                      <label class="selection d-flex slect-package justify-content-between" :for="'product_' + product.product_id">
                        <div class="slect-name">
                          <p class="pk-name">{{ product.product_name }}</p>
                          <p class="pk-price">{{ product.price | toCommas }} &#8363;</p>
                        </div>
                        <div class="slect-cost">{{ Math.round(product.price / product.duration * 30) | toCommas }}
                          &#8363;/{{ $t('month') }}
                          <span v-if="product.desc.price_save > 0">{{ $t('discount') }} {{ product.desc.price_save |
                toCommas
                            }}%</span>
                        </div>
                      </label>
                    </td>
                  </tr>
                </table>
                <span class="w-75 package-note" v-html="$t('payment_warning')"></span>
              </div>
              <p class="label-upgrade mt-3" v-if="use_liveclass && live_classes != ''">2. {{ $t("please_select_schedule")
                }}</p>
              <div class="ito-table-wrapper">
                <table class="ito-table ito-table-fixed" v-if="use_liveclass && live_classes != ''">
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("schedule_no") }}</th>
                      <th>{{ $t("schedule_subject") }}</th>
                      <th>{{ $t("schedule_session") }}</th>
                      <th>{{ $t("schedule_date") }}</th>
                      <th>{{ $t("schedule_duration") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody class="ito-tbody" v-for="(live_class, index) in live_classes" v-if="live_classes != []">
                    <template v-for="(live_class_item, i) in live_class.live_class_detail">
                      <tr v-if="i == 0">
                        <td class="text-center" :rowspan="live_class.live_class_detail.length">{{ index + 1 }}</td>
                        <td>{{ live_class_item.subject }}</td>
                        <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                        <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                        <td :rowspan="live_class.live_class_detail.length">{{ live_class.start_date }} - {{
                live_class.end_date
              }}</td>
                        <td :rowspan="live_class.live_class_detail.length">
                          <button class="ito-btn ito-btn-outline-primary" v-if="live_class.id == live_class_id">{{
                $t('selected') }}</button>
                          <button class="ito-btn ito-btn-success" @click="selectLiveClass(live_class.id)" v-else>{{
                $t('select') }}</button>
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ live_class_item.subject }}</td>
                        <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                        <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="d-flex w-100 justify-content-center gap-3 mt-3">
                <button type="button" class="ito-btn ito-btn-lg ito-btn-outline-primary" @click="gotoStep(1)">{{
                $t('back') }}</button>
                <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(3)" :disabled="!package_id || !package_id || !product_id || !live_class_id" v-if="use_liveclass == 1">{{
                $t('continue_txt') }}</button>
                <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(3)" :disabled="!package_id || !product_id" v-else>{{ $t('continue_txt') }}</button>
              </div>
            </div>
            <div v-if="step == 3" class="px-5">
              <div class="d-flex justify-content-between gap-4">
                <div class="label text-start text-break text-wrap">
                  <span>{{ $t("selected_package") }} : </span>
                  <strong>
                    {{ selected_product.product_name }}
                  </strong>
                </div>
                <div class="text-bold text-end" v-if="promotion_code_is_valid">
                  <span class="text-decoration-line-through opacity-25">{{ selected_product.price | toCommas }}đ</span>
                  {{ promotion | toCommas }}đ
                  ({{ Math.round(promotion / selected_product.duration * 30) | toCommas }}đ/{{ $t('month')
                  }})
                </div>
                <p class="text-bold text-end" v-else>
                  {{ selected_product.price | toCommas }}đ
                  ({{ Math.round(selected_product.price / selected_product.duration * 30) | toCommas }}đ/{{ $t('month')
                  }})
                </p>
              </div>
              <div class="d-flex gap-3 mt-3">
                <p class="mt-2">{{ $t('promotion_code') }}:</p>
                <div class="position-relative">
                  <input type="text" class="form-control m-0" v-model="promotion_code" @input="promotion_code_error = null, promotion_code_is_valid = false" :placeholder="$t('enter_the_promotion_code')" />
                  <small class="text-danger" v-if="promotion_code_error">{{ promotion_code_error }}</small>
                  <a href="#" class="clear-text" v-if="promotion_code" @click="promotion_code = promotion_code_error = null, promotion_code_is_valid = false">Clear</a>
                </div>
                <button type="button" class="ito-btn ito-btn-primary" :disabled="!promotion_code" @click="checkPromotionCode()">{{ $t('apply_code') }}</button>
              </div>
              <div class="d-flex slect-method gap-3 justify-content-center mt-3">
                <template v-for="(gate, index) in gates">
                  <input :id="'method_' + index" class="ito-pkg" name="radio-select" type="radio" v-model="method" :value="gate.method" />
                  <label :for="'method_' + index" class="payment-method-item">
                    <div class="img-gate-box">
                      <img :src="gate.icon">
                    </div>
                    <div class="payment-method-text">{{ gate.name }}</div>
                  </label>
                </template>
              </div>
              <div class="d-flex w-100 justify-content-center gap-3 mt-3">
                <button type="button" class="ito-btn ito-btn-outline-primary" @click="gotoStep(2)">{{ $t('back')
                  }}</button>
                <button type="button" class="ito-btn ito-btn-primary" :disabled="!product_id || !method" data-bs-toggle="modal" data-bs-target="#PopupPayment" @click="confirmPayment()">{{
                $t('pay_now') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="package_guide" class="card box-shadow-main mt-20" v-if="step == 1">
        <div class="card-body">
          <div class="card-content">
            <h5 class="title">{{ $t('purchase_title') }}:</h5>
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="col-12 justify-content-center d-flex">
                    <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-1.png" alt="">
                  </div>
                </div>
                <div class="row desc">
                  <h5>{{ $t('purchase_1_title') }}</h5>
                  <p>{{ $t('purchase_1_content') }}</p>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-12 justify-content-center d-flex">
                    <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-2.png" alt="">
                  </div>
                </div>
                <div class="row desc">
                  <h5>{{ $t('purchase_2_title') }}</h5>
                  <p>{{ $t('purchase_2_content') }}</p>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-12 justify-content-center d-flex">
                    <img width="64px" height="64px" src="/themes/ito/images/icons/purchase-3.png" alt="">
                  </div>
                </div>
                <div class="row desc">
                  <h5>{{ $t('purchase_3_title') }}</h5>
                  <p>{{ $t('purchase_3_content') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade payment-popup" id="PopupPayment" tabindex="-1" role="dialog" aria-labelledby="PopupPayment" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
          <div class="modal-content box-shadow-main">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title payment-title">{{ $t('payment_confirmation') }}</h3>
            <div class="modal-body text-start">
              <p><b>{{ $t('your_current_account_information') }}:</b></p>
              <p class="mt-1" v-html="$t('student_code', { user_name: user_info.fullname })"></p>

              <p class="mt-1">{{ $t('current_package') }}: <b> {{ user_info.account_type_name }} </b> </p>
              <p class="mt-1" v-if="user_info.expired_date">{{ $t('expired_date') }}: <b>{{ user_info.expired_date |
                formatDate('DD/MM/YYYY') }}</b></p>

              <template v-if="selected_package.package_order == user_info.package_order">
                <p class="mt-3" v-html="$t('upgrade_package_1', { package: selected_package.name })"></p>
              </template>

              <template v-if="selected_package.package_order > user_info.package_order">
                <template>
                  {{ dateDiff(user_info.expired_date, expire_date) }}
                </template>
                <p class="mt-3" v-html="$t('upgrade_package_3', { package: selected_package.name, expire: expire_date, package2: user_info.account_type_name })">
                </p>
              </template>

              <template v-if="selected_package.package_order < user_info.package_order">
                <p class="mt-3" v-html="$t('upgrade_package_2', { package: selected_package.name, package2: user_info.account_type_name })">
                </p>
              </template>

              <p class="mt-3">{{ $t('are_you_sure_you_want_to_make_the_payment') }}</p>
            </div>
            <div class="d-flex justify-content-center mt-3 gap-3">
              <button type="button" class="ito-btn ito-btn-default" data-bs-dismiss="modal" data-bs-toggle="modal">Cancel</button>
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" @click="paymentMethod()">Yes</button>
            </div>
          </div>
        </div>
      </div>
      <!-- payment method = bank_transfer  -->
      <div class="modal fade payment-popup" id="PopupPaymentTransfer" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentTransfer" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
          <div class="modal-content box-shadow-main">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title payment-title">{{ $t('how_to_use_bank_transfer_method') }}</h3>
            <div class="modal-body text-start" style="font-size: 14px;">
              <p>{{ $t('bank_transfer_instruction') }}</p>
              <p class="title my-2" style="text-decoration: underline;">{{ $t('bank_transfer_account_info') }}:</p>
              <p>{{ $t('bank_transfer_username') }}: <b>{{ user_info.fullname }}</b></p>
              <p>{{ $t('bank_transfer_new_package') }}:
                <b>
                  {{ selected_product ? selected_product.product_name : '' }}
                </b>
              </p>
              <p>{{ $t('bank_transfer_expire_date') }}: <b>{{ expire_date | formatDate('DD/MM/YYYY') }}</b></p>

              <p>{{ $t('bank_transfer_instruction2') }}</p>

              <div class="d-flex mt-3 justify-content-center gap-3">
                <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" @click="Payment()">{{ $t('continue_txt') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade payment-popup" id="PopupPaymentTransfer2" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentTransfer" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
          <div class="modal-content box-shadow-main">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title payment-title">{{ $t('bank_transfer_method') }}</h3>
            <div class="modal-body text-start">
              <div class="d-flex mt-3 mb-3 justify-content-start gap-3">
                <button type="button" :class="banking_method == 'manual' ? 'ito-btn ito-btn-outline-primary' : 'ito-btn ito-btn-primary'" @click="banking_method = 'manual'">{{ $t('btn_bank_transfer_info') }}</button>
                <button type="button" :class="banking_method == 'qrcode' ? 'ito-btn ito-btn-outline-primary' : 'ito-btn ito-btn-primary'" @click="qrGenerate()">{{ $t('btn_scan_qrcode') }}</button>
              </div>
              <template v-if="banking_method == 'qrcode'">
                <img :src="qrcode" alt="">
              </template>
              <template v-else>
                <p class="title my-2" style="text-decoration: underline;">{{ $t('bank_transfer_detail_title') }}:</p>
                <div v-html="$t('bank_transfer_detail_content', { total: toComma(selected_product.price), transfer_content: transfer_content, })">
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade payment-popup" id="PopupPaymentFail" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentFail" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 475px;">
          <div class="modal-content box-shadow-main paymment-status">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title mb-20 fail">Payment unsuccessfully</h3>
            <img src="/themes/ito/images/payment-fail.png">
            <p class="mt-3">Your payment has failed.<br />
              Please make sure that your payment details are correct.</p>
            <div class="d-flex justify-content-center mt-3 gap-3">
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal">Try
                again</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade payment-popup" id="PopupPaymentSuccess" tabindex="-1" role="dialog" aria-labelledby="PopupPaymentSuccess" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 475px;">
          <div class="modal-content box-shadow-main paymment-status">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title mb-20 success">{{ $t('payment_success') }}</h3>
            <img src="/themes/ito/images/payment-check-icon.svg">
            <p class="mt-3" v-html="$t('payment_success_noti')"></p>
          </div>
        </div>
      </div>

      <div class="modal fade payment-popup" id="RequestSent" tabindex="-1" role="dialog" aria-labelledby="RequestSent" data-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 707px;">
          <div class="modal-content box-shadow-main paymment-status">
            <h3 class="popup-title">{{ $t('request_sent') }}</h3>
            <p class="mt-3">{{ $t('bank_transfer_request_sent_content') }}</p>
            <div class="d-flex justify-content-center">
              <router-link class="ito-btn ito-btn-primary px-4 mt-3" data-bs-dismiss="modal" :to="{ name: 'login' }">{{
                $t('back_to_home') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getLanguage, soundClick, toCommas } from '../../utils/utils'
import { mapGetters } from 'vuex'
import { getPackages, postPayment, applyVoucher, qrGenerator } from "../../models/payment";

export default {
  name: "UpgradeAccount",
  data() {
    const weekDays = $.datepicker.regional[getLanguage()].dayNames
    return {
      packages: [],
      test: [],
      package_id: '',
      selected_package: '',

      products: [],
      product_id: '',
      selected_product: '',

      use_liveclass: '',
      live_classes: [],
      live_class_id: '',


      gates: [],

      isLoading: true,
      promotion: null,
      promotion_code: null,
      promotion_code_is_valid: false,
      promotion_code_error: null,

      method: '',
      step: 1,
      weekDays: weekDays,
      expire_date: '',
      qrcode: '',
      banking_method: 'manual',
      transfer_content: ''
    };
  },

  created() {
    this.$store.commit('setMenuActive', 'home');
    this.fetch();
  },
  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }
      return this.user
    }),
  },
  methods: {
    fetch() {
      const that = this;
      that.$store.commit("setLoading", true);
      axios.all([getPackages(),]).then(axios.spread((package_response) => {
        that.isLoading = false;

        if (package_response.data.code != 0) {
          return;
        }
        var response = package_response.data.results.packages || [];
        var packages = [];
        for (var i = 0; i < response.length; i += 2) {
          packages.push([response[i] ? response[i] : '', response[i + 1]])
        }
        that.packages = packages
        that.gates = package_response.data.results.gate || [];
        this.$nextTick(function () {
          that.initSwiper();
        });
        that.$store.commit("setLoading", false);
      }));
    },
    changePackage(selected_package) {
      this.selected_package = selected_package;
      this.package_id = selected_package.id;
      this.use_liveclass = selected_package.use_liveclass == 1 ? true : false;
      this.products = this.selected_package.products
      this.changeProduct(this.products[0])
      this.step = 2
    },
    changeProduct(product) {
      this.selected_product = product
      this.product_id = this.selected_product.product_id
      this.live_classes = product.live_class ? product.live_class : [];
      this.live_class_id = '';
    },
    goUndo(e) {
      this.$nextTick(function () {
        this.initSwiper();
      });
      this.step = this.step - 1;
    },
    gotoStep(step) {
      this.step = step;
      if (step == 1) {
        this.$nextTick(function () {
          this.initSwiper();
        })
      }
    },
    selectLiveClass(id) {
      this.live_class_id = id;
    },
    confirmPayment() {
      const that = this;
      that.addDays(that.user_info.expired_date, that.selected_product.duration)
      $('#PopupPayment').modal('show');
    },
    addDays(date, days) {
      var result = date != '' ? new Date(date) : new Date();
      result.setDate(result.getDate() + days);
      this.expire_date = moment(result, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    dateDiff(date1, date2) {
      // date1 = new Date(date1);
      // date2 = new Date(date2);
      // var Difference_In_Time = date2.getTime() - date1.getTime();
      // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      // return Difference_In_Days;
    },
    checkPromotionCode() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('product_id', this.product_id);
      formData.append('voucher', this.promotion_code);
      applyVoucher(formData).then((data) => {
        that.$store.commit("setLoading", false);
        let results = data.data.results;
        if (results.status === 0 || results.status === 5) {
          this.promotion_code_error = null;
          this.promotion_code_is_valid = true;
          if (results.type === 'percent') {
            this.promotion = this.selected_product.price - (this.selected_product.price / 100 * results.value);
          }

        } else {
          this.promotion_code_error = this.$i18n.t('invalid_promotion_code')
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        that.promotion_code_error = this.$i18n.t('invalid_promotion_code');
      });
    },
    paymentMethod() {
      const that = this;
      if (that.method == 'bank_transfer') {
        $('#PopupPaymentTransfer').modal('show');
      } else {
        if (that.packages.find(item => item.id === that.user_info.package_level_id)) {
          $('#PopupPayment').modal('show');
        } else {
          that.Payment()
        }
      }

    },
    Payment() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('product_id', this.product_id);
      formData.append('live_class_id', this.live_class_id);
      formData.append('voucher', this.promotion_code_is_valid ? this.promotion_code : null);
      formData.append('method', this.method);
      postPayment(formData).then((data) => {
        that.$store.commit("setLoading", false);
        if (data.data.code == 0) {
          if (this.method == 'bank_transfer') {
            this.transfer_content = 'iTouch ' + this.user_info.fullname + ' ' + this.user_info.phone + ' ' + this.selected_product.product_name
            this.switchModal('PopupPaymentTransfer', 'PopupPaymentTransfer2')
          } else {
            window.location.href = data.data.results.url;
            // window.open(data.data.results.url, '_blank');
          }
        } else {
          $('#PopupPaymentFail').modal('show');
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        $('#PopupPaymentFail').modal('show');
      });
    },


    getSoundClick() {
      soundClick();
    },
    initSwiper() {
      new Swiper(".packageSwiper", {
        slidesPerView: 1,
        mousewheel: true,
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
      });
    },
    toComma(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return false;
    },
    switchModal(current, target) {
      if (current != '') {
        $('#' + current).modal('hide');
      }
      if (target != '') {
        $('#' + target).modal('show');
      }
    },
    // viet qr
    qrGenerate() {
      var params = {
        amount: this.selected_product.price,
        addInfo: this.transfer_content,
      }
      this.$store.commit("setLoading", true);
      qrGenerator(params).then((data) => {
        if (data.data.code == '00') {
          this.$store.commit("setLoading", false);
          var result = data.data.data;
          this.qrcode = result.qrDataURL;
          this.banking_method = 'qrcode'
        } else {
          console.error(data.data.desc)
        }
      })

    }
  }
}
</script>

<style scoped>
.popup-title {
  color: #000;
  font-size: 24px;
}

.ito-pkg[type="radio"]:not(:checked),
.ito-pkg[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.slect-method [type="radio"]+label:before,
.slect-method [type="radio"]+label:after {
  display: none;
}

.slect-method [type=radio]:checked+label.payment-method-item {
  opacity: 1;
  border: 2px solid #40C0F6;
}

.radio[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio[type="radio"]:checked+label,
.radio[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
}

.radio-title,
.radio[type="radio"]:checked+label,
.radio[type="radio"]:not(:checked)+label {
  color: #000;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.radio[type="radio"]:checked+label:before,
.radio[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #FF7800;
  border-radius: 100%;
  background: #fff;
}

.radio[type="radio"]:checked+label:after,
.radio[type="radio"]:not(:checked)+label:after {
  content: url('../../../public/themes/ito/images/icons/icon-checked-orange.svg');
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.package-content-1 img {
  filter: grayscale(1);
}

.select-upgrade-type [type=radio]:checked+label.package .package-content-1 img {
  filter: grayscale(0);
}

.slect-method label {
  display: block;
  /*width: 100%;*/
  /*height: 100%;*/
}

.payment-popup .title {
  font-size: 16px;
  font-weight: 700;
}


fieldset {
  overflow: hidden
}

.no_packages {
  margin-bottom: 125px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.clear-text {
  cursor: pointer;
  position: absolute;
  color: #555;
  font-size: 28px;
  top: 11px;
  right: 10px;
  content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 11.4142L7.37377 14.0404L5.95956 12.6262L8.58579 10L5.95956 7.37377L7.37377 5.95956L10 8.58579L12.6262 5.95956L14.0404 7.37377L11.4142 10L14.0404 12.6262L12.6262 14.0404L10 11.4142Z" fill="url(%23paint0_linear_6727_34925)"/><defs><linearGradient id="paint0_linear_6727_34925" x1="10.4643" y1="2.662" x2="10.4643" y2="17.119" gradientUnits="userSpaceOnUse"><stop stop-color="%23FF3E12"/><stop offset="1" stop-color="%23FF7D16" stop-opacity="0.9"/></linearGradient></defs></svg>');
}
</style>