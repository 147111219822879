import axios from 'axios'

export function getLanguage() {
    const lang = localStorage.getItem('lang');

    return lang ? lang : 'vi';
}

export function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toPercent(partialValue, totalValue) {
    if (totalValue == 0) {
        return 0;
    }
    if (partialValue == totalValue) {
        return 100;
    }
    return roundToTwo((100 * partialValue) / totalValue);
}

export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function httpGet(url, params = {}, headers = {}) {
    const promise = axios.get(url, {
        headers: headers,
        params: params
    });

    promise.then(function(response) {
        const data = response.data;

        if (data.code == 0) {
            return;
        }

        /*if (data.error.code == 1004) {
            window.location.href = "/login?frm=1004";
        }*/
    });

    return promise;
}

export function resetErrorsValid(errors) {
    Object.keys(errors).forEach((e) => {
        errors[e] = '';
    });
}

export function isHasErrors(errors) {
    let isError = false;

    Object.keys(errors).forEach((e) => {
        if (errors[e] != '') {
            isError = true;
        }
    });

    return isError;
}

export function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}

export function setCookie(cname, val) {
    document.cookie = cname + "=" + val + "; path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function copyToClipboard(el) {
    alert(el);
    // resolve the element
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    // handle iOS as a special case
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {

        // save current contentEditable/readOnly status
        var editable = el.contentEditable;
        var readOnly = el.readOnly;

        // convert to editable with readonly to stop iOS keyboard opening
        el.contentEditable = true;
        el.readOnly = true;

        // create a selectable range
        var range = document.createRange();
        range.selectNodeContents(el);

        // select the range
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);

        // restore contentEditable/readOnly to original state
        el.contentEditable = editable;
        el.readOnly = readOnly;
    } else {
        el.select();
    }

    // execute copy command
    document.execCommand('copy');
}

export function deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function isNumber(str) {
    return /^-?\d+\.?\d*$/.test(str)
}

export function isNumberAmount(str) {
    return /^\d+$/.test(str)
}

export function pushQueries(router, path, params) {
    const queries = {};

    Object.keys(params).forEach((e) => {
        if (e == 'page' && params[e] == 1) {
            return;
        }

        if (e == 'is_active' && params[e] == -1) {
            return;
        }

        if (params[e] != '') {
            queries[e] = params[e];
        }
    });

    router.push({
        path: path,
        query: queries
    }).catch(() => {});
}

export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

export function convertSecondToText(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    hours = hours >= 0 ? hours : 0;
    minutes = minutes >= 0 ? minutes : 0;
    seconds = seconds >= 0 ? seconds : 0;

    hours = hours >= 10 ? hours : '0' + hours;
    minutes = minutes >= 10 ? minutes : '0' + minutes;
    seconds = seconds >= 10 ? seconds : '0' + seconds;

    return hours + ':' + minutes + ':' + seconds;
}

export function soundBackground() {
    //(new Audio('/themes/ito/audios/btn-click.mp3')).play();
    var bgAudio = document.getElementById('audio-background');
    bgAudio.volume = 0.2;
    bgAudio.play();
    return bgAudio;
}

export function soundClick() {
    //(new Audio('/themes/ito/audios/btn-click.mp3')).play();
    document.getElementById('audio-click').play().catch(console.log);
}

export function soundGetReward() {
    //(new Audio('/themes/ito/audios/get-reward.mp3')).play();
    document.getElementById('audio-get-reward').play();
}

export function soundClaimKey() {
    //(new Audio('/themes/ito/audios/get-reward.mp3')).play();
    document.getElementById('audio-claim-key').play();
}

export function soundClaimTreasure() {
    //(new Audio('/themes/ito/audios/get-reward.mp3')).play();
    document.getElementById('audio-claim-treasure').play();
}

export function soundClaimGem() {
    //(new Audio('/themes/ito/audios/get-reward.mp3')).play();
    document.getElementById('audio-claim-gem').play();
}

export function getConfigs() {
    const configs = localStorage.getItem('configs');

    return configs ? JSON.parse(configs) : {};
}

export function getCheckedSubjects() {
    const checkedSubjects = localStorage.getItem('checkedSubjects');

    return checkedSubjects ? JSON.parse(checkedSubjects) : [];
}

export function updateCheckedSubjects(checkedSubjects) {
    localStorage.setItem('checkedSubjects', JSON.stringify(checkedSubjects));
}

export function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + '/' + month + '/' + year + ' - ' + hour + ':' + min + ':' + sec;
    return time;
}