<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/dashboard.css">
        <link rel="stylesheet" href="/themes/ito/css/unit-detail.css?v=1">
        <div class="container">
            <template v-if="isLoading == true">
                <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                </content-placeholders>
            </template>
            <template v-else>
                <h2 v-if="units.length == 0"></h2>
                <div v-if="units.length != 0">
                    <div id="page-header-fixed">
                        <div class="container">
                            <div id="page-header">
                                <ul class="breadcrumb">
                                    <li class="icon">
                                        <a @click="$router.go(-1)">
                                            <img src="/themes/ito/images/back-to.svg" alt="">
                                        </a>
                                    </li>
                                    <li class="active">{{ units.name }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab == 'overview'">
                        <div id="unit-detail" class="card card-daily-challenge">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <span class="title">{{ $t('curriculum') }} ({{ units.total }} {{ units.total > 1 ?
                                        $t('lessons') : $t('lesson') }})</span>

                                    <!--<div class="tools">-->
                                    <!--<nav class="ito-tabs">-->
                                    <!--<a :class="type == 1?'active':''" @click="changeType(1)" style="cursor: pointer">{{$t('lessons')}}</a>-->
                                    <!--<a :class="type == 2?'active':''" @click="changeType(2)" style="cursor: pointer">{{$t('extension')}}</a>-->
                                    <!--</nav>-->
                                    <!--</div>-->
                                </h5>
                                <!--<div class="card-content" v-if="type==1">-->
                                <!--<div class="row">-->
                                <!--<template v-for="(lesson, index) in units.lessons">-->
                                <!--<div class="col-md-4" v-if="lesson.status == 0">-->
                                <!--<router-link-->
                                <!--:to="{path: '/lesson/'+subject_id+'-'+units.id+'-'+lesson.id}">-->
                                <!--<div class="lesson-item" >-->
                                <!--<div class="lesson-item-body">-->
                                <!--<h4 class="title" style="color: #AAABB7; font-size: 14px; line-height: 19px">{{$t('lesson')}} {{index + 1}}</h4>-->
                                <!--<div class="desc">{{ lesson.title }}</div>-->
                                <!--</div>-->
                                <!--<div class="lesson-item-bottom" v-bind:class="lesson.process == lesson.total ? 'complete': ''">-->
                                <!--<div class="flex">-->
                                <!--<template v-for="(item, i) in lesson.items">-->
                                <!--<i class="ito-icon-checked" :title = "item.name" v-if="item.complete == 1"></i>-->
                                <!--<i class="ito-icon-checked-disable" :title = "item.name" v-else></i>-->
                                <!--</template>-->
                                <!--</div>-->
                                <!--<div>-->
                                <!--{{$t('completeof', {process: lesson.process, total: lesson.total})}}-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</router-link>-->
                                <!--</div>-->
                                <!--<div class="col-md-4" v-if="lesson.status == 1">-->
                                <!--<div class="lesson-item lock-unit" style="overflow: hidden;">-->
                                <!--<img class="lock-image" src="/themes/ito/images/icons/icon-lock.svg"/>-->
                                <!--<div class="lesson-item-body">-->
                                <!--<h4 class="title" style="color: #AAABB7; font-size: 14px; line-height: 19px">{{$t('lesson')}} {{index + 1}}</h4>-->
                                <!--<div class="desc">{{ lesson.title }}</div>-->
                                <!--</div>-->
                                <!--<div class="lesson-item-bottom" v-bind:class="lesson.process == lesson.total ? 'complete': ''">-->
                                <!--<div class="flex">-->
                                <!--<template v-for="(item, i) in lesson.items">-->
                                <!--<i class="ito-icon-checked" :title = "item.name" v-if="item.complete == 1"></i>-->
                                <!--<i class="ito-icon-checked-disable" :title = "item.name" v-else></i>-->
                                <!--</template>-->
                                <!--</div>-->
                                <!--<div>-->
                                <!--{{$t('completeof', {process: lesson.process, total: lesson.total})}}-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</template>-->
                                <!--</div>-->
                                <!--</div>-->
                                <div class="card-content" v-if="type == 2">
                                    <div class="row">
                                        <template v-for="(extension, index) in units.extentions">
                                            <div class="col-md-4" v-if="extension.status == 0">
                                                <router-link
                                                    :to="{ path: '/extension/' + subject_id + '-' + units.id + '-' + extension.id }">
                                                    <div class="lesson-item">
                                                        <div class="lesson-item-body">
                                                            <h4 class="title"
                                                                style="color: rgb(170, 171, 183);font-size: 16px;line-height: 19px;">
                                                                {{ extension.name }}</h4>
                                                            <div class="desc">{{ extension.title }}</div>
                                                        </div>
                                                        <div class="lesson-item-bottom"
                                                            v-bind:class="extension.process == extension.total && extension.total != 0 ? 'complete' : ''">
                                                            <div class="flex">
                                                                <template v-for="(item, i) in extension.items">
                                                                    <i class="ito-icon-checked" :title="item.name"
                                                                        v-if="item.complete == 1"></i>
                                                                    <i class="ito-icon-checked-disable" :title="item.name"
                                                                        v-else></i>
                                                                </template>
                                                            </div>
                                                            <div>
                                                                {{ $t('complete_of', {
                                                                    process: extension.process, total:
                                                                        extension.total
                                                                }) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                            <div class="col-md-4" v-if="extension.status == 1">
                                                <div class="lesson-item lock-unit" style="overflow: hidden;"
                                                    @click="(user_info.student_type != 2) ? upgradeAccountUnitTest(extension.package_name) : ''">
                                                    <img class="lock-image" src="/themes/ito/images/icons/icon-lock.svg" />
                                                    <div class="lesson-item-body">
                                                        <h4 class="title"
                                                            style="color: rgb(170, 171, 183);font-size: 16px;line-height: 19px;">
                                                            {{ extension.name }}</h4>
                                                        <div class="desc">{{ extension.title }}</div>
                                                    </div>
                                                    <div class="lesson-item-bottom"
                                                        v-bind:class="extension.process == extension.total && extension.total != 0 ? 'complete' : ''">
                                                        <div class="flex">
                                                            <template v-for="(item, i) in extension.items">
                                                                <i class="ito-icon-checked" :title="item.name"
                                                                    v-if="item.complete == 1"></i>
                                                                <i class="ito-icon-checked-disable" :title="item.name"
                                                                    v-else></i>
                                                            </template>
                                                        </div>
                                                        <div>
                                                            {{ $t('complete_of', {
                                                                process: extension.process, total:
                                                                    extension.total
                                                            }) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="modal" id="popupLessonUpgradeAccount" tabindex="-1" role="dialog"
                aria-labelledby="popupUpgradeAccount">
                <div class="modal-dialog modal-dialog-centered popup-main-2" role="document">
                    <div class="modal-content box-shadow-main p-3">
                        <div class="modal-header border-0 justify-content-center">
                            <h5 class="modal-title">Yêu cầu nâng cấp tài khoản</h5>
                        </div>
                        <div class="modal-body">
                            <p>Bạn cần nâng cấp lên tài khoản <strong>{{ required_package_name }}</strong> để truy cập bài học
                            này.</p>
                        </div>
                        <div class="modal-footer border-0 justify-content-center">
                            <router-link :to="{ path: '/upgrade-account' }" data-bs-dismiss="modal">
                                <div class="ito-btn ito-btn-primary">
                                    {{ $t('upgrade_account') }}
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from 'vuex';
import { pushQueries, toPercent, timeConverter } from '../../utils/utils'
import {
    getListUnit,
    getListLesson,
    getReportUnit,
    getDiscussions,
    sendDiscuss,
    deleteComment,
    editComment,
    getEbook
} from "../../models/dashboard";
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        this.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', this.event)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', this.event)
    },
});
export default {
    name: "PrepList",
    // props: [
    //     'user_info'
    // ],
    data() {
        return this.initState();
    },
    created() {
        this.fetch();
    },
    mounted() {

    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },
    watch: {
        'tab'(item) {
            if (item == 'report') {
                this.getListReports();
            } else if (item == 'discuss') {
                this.getListDiscuss();

            }
        }
    },
    methods: {
        upgradeAccountUnitTest(package_name) {
            this.required_package_name = package_name;
            $('#popupLessonUpgradeAccount').modal('show');
        },
        resetDiscuss() {
            this.commentType = 'NORMAL';
            this.content = '';
            this.currId = '';
            this.currContent = '';
        },
        showReply(dc) {
            if (dc.show_reply == 1) {
                Vue.set(dc, 'show_reply', 0);

            } else {
                Vue.set(dc, 'show_reply', 1);
            }
        },
        initState() {
            return {
                showReplies: '',
                units: [],
                subject: {},
                isLoading: false,
                tab: 'overview',
                type: 2,
                subject_id: this.$route.params.subject_id,
                reports: [],
                currentItem: -10,
                editItem: -10,
                currentAttemp: -10,
                lesson_complete: 0,
                lesson_incomplete: 0,
                content: '',
                discuss: [],
                total_comment: 0,
                comment_select: 0,
                curDc: '',
                curUserReply: '',
                currContent: '',
                inputType: '',
                curCommenter: [],
                commentType: 'NORMAL',
                ebook_link: '',
                required_package_name: ''
            };
        },
        resetState() {
            Object.assign(this.$data, this.initState());
        },
        resetSearching() {
            this.resetState();

            this.fetch();
        },
        fetch(page = 1) {
            this.getListUnits()
        },
        showEdit: function (newItem) {
            if (newItem === this.editItem) {
                this.editItem = -10
            } else {
                this.editItem = newItem
            }
        },
        showEditCommenter: function (newItem) {
            if (newItem === this.editItem) {
                this.editItem = -10
            } else {
                this.editItem = newItem
            }
        },
        resetEdit() {
            this.editItem = -10;
        },
        resetEditCommenter() {
            this.editItem = -10;
        },

        getListUnits() {
            const that = this;
            that.$store.commit("setLoading", true);
            const params = this.$route.params;
            axios.all([
                getListLesson(params),
            ]).then(axios.spread((list_unit_response) => {
                that.$store.commit("setLoading", false);
                if (list_unit_response.data.code != 0) {
                    return;
                }
                that.units = list_unit_response.data.results.units || [];
                that.subject = list_unit_response.data.results.subject || {};
            }));
        },
        calcPercent(process, total) {
            return toPercent(process, total);
        },
        mathRound(number) {
            return Math.round(number)
        },
        changeType(layout) {
            this.type = layout;
        },
        changeTab(tab) {
            this.tab = tab;
        },
        timeConverter(UNIX_timestamp) {
            var a = new Date(UNIX_timestamp * 1000);
            var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + '/' + month + '/' + year + ' - ' + hour + ':' + min + ':' + sec;
            return time;
        }
    }
}
</script>

<style scoped>
.commenter {
    margin-bottom: 15px;
}

.thumb-comment img {
    filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.07));
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.thumb-comment {
    margin-right: 15px;
}

.list-reply {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15px;
}

@-webkit-keyframes cssProgressActive {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 35px 35px;
    }
}

@keyframes cssProgressActive {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 35px 35px;
    }
}

@-webkit-keyframes cssProgressActiveRight {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -35px -35px;
    }
}

@keyframes cssProgressActiveRight {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -35px -35px;
    }
}

.progress {
    opacity: 1;
}

.progress-load {
    background-color: #FFC700 !important;
    -webkit-animation: cssProgressActive 3s linear infinite;
    animation: cssProgressActive 3s linear infinite;
    transition: none 0s ease 0s;
}

#unit-detail .lesson-item-bottom>.progress>.progress-load {
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
    background-size: 15px 15px;
}

#unit-detail .lesson-item-bottom>.progress>span {
    padding-left: 20px;
    padding-top: 0px;
}

#unit-detail .lesson-item-bottom {
    align-items: center;
}

.ito-tabs a.active {
    color: #fff;
    background: #5AC9F7;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.lesson-item-body .title {}

.lesson-item-lv.report-items {
    padding: 4px 20px;
    margin-bottom: 0;
}

.report-items .c100>span {
    font-size: 11px;
}

.lesson-report-container {
    background: #F6F6F6;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 8px;
}

.report-child-items {
    padding: 6px 20px;
}

.report-child-items:first-child {
    padding-top: 12px;
}

.report-attemps {
    background: #FFFFFF;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px 20px;
}

.chart-wrapper {
    background: #FFFFFF;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 8px;
}

.icon-expand-right {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.active .icon-expand-right img {
    transform: rotate(90deg);
}

.report-item-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

.report-item-head-right {
    display: flex;
    align-items: center;
    text-align: right;
}

.report-item-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    color: #1E263C;
}

.report-item-temp-number {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: rgba(30, 38, 60, 0.5);
}

.report-item-childs,
.report-item-body {
    display: none;
    -webkit-animation: fadeout 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s;
    /* Firefox < 16 */
    -ms-animation: fadeout 1s;
    /* Internet Explorer */
    -o-animation: fadeout 1s;
    /* Opera < 12.1 */
    animation: fadeout 1s;
}

.active+.report-item-childs,
.active+.report-item-body {
    display: block;
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
}

.report-item-body {
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-top: 8px;
    padding: 0;
    overflow: hidden;
}

.attemp-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px;
    font-size: 13px;
    line-height: 16px;
}

.attemp-list:not(last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.attemp-list .crown-active-img {
    width: 14px;
    margin-top: -5px;
}

.report-item-head-right .crown-active-img {
    width: 15px;
}

.ebook-link {
    margin-top: 12px;
    /*display: block;*/
    font-size: 13px;
}

.form {
    background: #FFFFFF;
    border: 2px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 4px 6px;
    display: flex;
}

.form>input[type=text] {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    flex: 1;
    border: none;

}

.form>button {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #40C0F6;
    box-shadow: inset 0px 2px 0px rgb(255 255 255 / 20%);
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 7px 15px;
    width: 70px;
}

#inputComment .modal-content {
    padding: 0;
    border-radius: 30px
}

.button-replies {
    color: #bfa6a6;
    bottom: 0%;
    width: 96%;
    text-align: left;
    background: #FFFFFF;
    border: 1.5px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 8px;

}

.reply-to {
    margin-block: 5px;
    font-weight: bold;
}

.modal-content1 {
    padding: 0;
    border-radius: 30px;
}


.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0.99);
    }

    100% {
        transform: scale(1);
    }
}

.modal-content-edit {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

.box-shadow-main-edit {
    box-shadow: 4px 4px rgb(0 0 0 / 7%);
    border-radius: 20px;
    box-shadow: 4px 4px 0px rgb(0 0 0 / 7%), inset 0px 4px 0px rgb(231 231 231 / 25%);
}

.card-bottom-commenter,
.card-content-commenter,
.comment-item {
    /*position: relative;*/
}

.comment-item:first-child {
    margin-top: 0;
}

.card-content-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 100%;
    background: rgba(51, 122, 183, 0.75);
    top: 0;
}

.comment-item-reply::after {
    content: '';
    display: block;
    position: absolute;
    left: -23px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin-top: -6px;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.commenter:first-child::before {
    content: '';
    display: block;
    position: absolute;
    left: -17px;
    width: 3px;
    height: 50%;
    background: #fff;
    top: 0px;
}

.card-bottom-commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    margin-top: -6px;
    border-radius: 100%;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.card-bottom-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 50%;
    background: #fff;
    bottom: 0;
}

.card-content-commenter {
    margin-left: 52px;
}

.title-rep {
    color: #070A28;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: left;
}

.card-bottom a.reset-commenter {
    font-size: 12px;
    color: #E84343;
    font-weight: 700;
    text-decoration: underline;
    margin-left: 15px;
}

.edit-li {
    color: #fd9301;
}

.delete-li img {
    margin-top: -5px;
    margin-left: 5px;
}

.card-bottom-commenter,
.card-content-commenter,
.commenter {
    position: relative;
}

.card-content-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 100%;
    background: rgba(51, 122, 183, 0.75);
    top: 0;
}

.commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin-top: -6px;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.commenter:first-child .comment-item-reply::before {
    content: '';
    display: block;
    position: absolute;
    left: -17px;
    width: 1px;
    height: 50%;
    background: #fff;
    top: 0px;
}

.card-bottom-commenter::after {
    content: '';
    display: block;
    position: absolute;
    left: -21px;
    width: 13px;
    height: 13px;
    margin-top: -6px;
    border-radius: 100%;
    top: 50%;
    background: rgba(51, 122, 183, 1);
}

.card-bottom-commenter::before {
    content: '';
    display: block;
    position: absolute;
    left: -15px;
    width: 1px;
    height: 50%;
    background: #fff;
    bottom: 0;
}

.lock-unit .lock-image {
    right: 5px;
    left: auto;
    top: 5px;
    width: 28px;
}
</style>
