<template>
    <div id="content">
        <link rel="stylesheet" href="/themes/ito/css/daily-challenge.css?v=4">
        <div class="container">
            <div id="page-header-fixed">
                <div class="container">
                    <div id="page-header">
                        <div class="tools">
                            <nav class="ito-tabs">
                                <router-link class="align-items-center gap-1" :to="{ path: '/daily-challenge' }"
                                    @click.native="getSoundClick">
                                    {{ $t('daily_challenge') }}
                                    <div class="noti-alert" v-if="user_info.dailychallenge_noti != 0"></div>
                                </router-link>
                                <router-link class="active align-items-center gap-1" :to="{ path: '/daily-bonus' }"
                                    @click.native="getSoundClick">
                                    {{ $t('daily_bonus') }}
                                    <div class="noti-alert" v-if="user_info.dailybonus_gemreward != 0"></div>
                                </router-link>
                                <router-link class="align-items-center gap-1" :to="{ path: '/mission' }"
                                    @click.native="getSoundClick">
                                    {{ $t('menu_item_achievements') }}
                                    <div class="noti-alert" v-if="user_info.count_achieve_unread != 0"></div>
                                </router-link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div id="daily-bonus" class="card card-daily-challenge mt-3">
                <div class="card-body">
                    <h5 class="card-title">
                        <span class="title">{{ $t('daily_bonus') }}</span>
                    </h5>

                    <div class="card-content">
                        <div id="daily-challenge" class="daily-bonus">
                            <div class="description mt-3">
                                <div class="col-1">
                                    {{ $t('daily_bonus_1') }}
                                </div>
                                <div class="col-2">
                                    {{ $t('daily_bonus_2') }}: <b>{{ time_next_bonus_txt }}</b>
                                </div>
                            </div>
                            <div class="challenges mt-3">
                                <div class="bonus-list">
                                    <template v-for="(bonus, index) in bonuses">
                                        <daily-bonus-item :bonus="bonus" :today_id="today_id" @updateBonus="updateBonus" />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <reward-modal :title="$t('claim_success')" image="/themes/ito/images/mascot/isaac-key.svg" :reward="rewarded"
            type="gem" />
    </div>
</template>

<script>
import { updateProfile } from '../../models/profile'
import { showDialog } from '../../utils/dialog'
import { mapGetters } from 'vuex'
import axios from "axios";
import { getDailyBonus, getDailyChallenge } from "../../models/reward";
import RewardModal from "../modal/RewardModal";
import DailyBonusItem from "./DailyBonusItem";
import { convertSecondToText, soundClick } from "../../utils/utils";

export default {
    name: "DailyBonus",
    components: { RewardModal, DailyBonusItem },

    data() {
        return {
            bonuses: '',
            today_id: '',
            time_next_bonus: '',
            time_next_bonus_txt: '',
            rewarded: '',
        };
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        })
    },

    created() {
        this.$store.commit('setMenuActive', 'achievement');
        this.fetch();
    },

    watch: {
        time_next_bonus: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.time_next_bonus--;

                        this.time_next_bonus_txt = convertSecondToText(this.time_next_bonus) || '';
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },

    methods: {
        fetch() {
            const that = this;
            axios.all([getDailyBonus(),]).then(axios.spread((response) => {
                that.isLoading = false;
                if (response.data.code != 0) {
                    return;
                }
                that.bonuses = response.data.results.daily_bonus || [];
                that.today_id = response.data.results.today_id || '';
                that.time_next_bonus = response.data.results.time_next_bonus || 0;
                that.time_next_bonus_txt = convertSecondToText(that.time_next_bonus) || '';
            }));
        },

        updateFirstLogin(key) {
            //console.log('xxx' + key + "zzz");
        },

        getSoundClick() {
            soundClick();
        },

        updateBonus(data) {
            this.user_info.first_login = 0;
            this.rewarded = data.gem_reward
            this.fetch();
        },
    }
}
</script>

<style scoped></style>
