require('./counter');

import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './components/App'
import paginate from 'vuejs-paginate'
import router from './router'
import { store } from './store'
import mixinTitle from './utils/mixins/mixinTitle'
import Axios from 'axios'
import i18n from './i18n'
import { API_URL } from "./constant";
import * as Sentry from "@sentry/vue";

Vue.use(VueRouter);

Vue.mixin(mixinTitle);

Vue.prototype.$http = Axios;


Vue.prototype.$http.defaults.baseURL = API_URL;

Vue.component('paginate', paginate);
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});
// Sentry.init({
//     Vue,
//     dsn: "https://57e7cbd522161e14577991a8fcf5a7a6@sentry.blueocean.net.vn/16",
//     environment: process.env.VUE_APP_ENV,
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const app = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    components: { App },
    template: '<App/>',
    mounted() {
        console.log('App mounteds!');
    }
});

export default app