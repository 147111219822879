<template>
  <div id="content">
    <link rel="stylesheet" href="/themes/ito/css/parents.css">

    <div class="container">
      <div id="page-header-fixed" class="mb-25">
        <div class="container">
          <div id="page-header">
            <div class="tools">
              <nav class="ito-tabs">
                <router-link :to="{ name: 'parent_report' }" @click.native="getSoundClick">{{ $t('report')
                }}</router-link>
                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_certificate' }"
                  @click.native="getSoundClick">{{ $t('certificate') }}</router-link>
                <router-link v-if="user_info.student_type == 1" :to="{ name: 'parent_feedback' }"
                  @click.native="getSoundClick">{{ $t('feedback') }}</router-link>
                <router-link :to="{ name: 'parent_account' }" @click.native="getSoundClick">{{ $t('account_information')
                }}</router-link>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div id="upgrade-detail" class="card card-comments">
        <div class="card-body">
          <h5 class="card-title">
            <span class="title">{{ $t('unclock_full_access') }}</span>
          </h5>

          <div class="card-content">
            <div :class="{ 'd-none': step != 1 }">
              <div class="swiper packageSwiper position-relative">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(pkg, index) in packages">
                    <div class="select-upgrade-type mb-20">
                      <input :id="'packages_' + index" class="ito-pkg" name="package_id" type="radio" v-model="package_id"
                        :value="pkg.id" />
                      <label :for="'packages_' + index" class="package package-1 box-shadow-main"
                        @click="changePackage(pkg)">
                        <div class="package-title">{{ pkg.name }}</div>
                        <div class="package-price">{{ pkg.price | toCommas }} &#8363;/<span>{{ $t('month') }}</span>
                        </div>
                        <div class="package-content package-content-1">
                          <template v-if="pkg.desc" v-for="(desc, i) in pkg.desc">
                            <div class="package-items d-flex">
                              <img src="/themes/ito/images/payment-check-icon.svg">
                              <div class="package-items-text">{{ $t(desc) }}
                              </div>
                            </div>
                          </template>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
              <div class="d-flex w-100 justify-content-center gap-3 mt-3">
                <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(2)"
                  :disabled="!package_id">{{
                    $t('continue_txt') }}</button>
              </div>
            </div>
            <div v-if="step == 2">
              <p class="label-upgrade">1. {{ $t("please_select_how_you_would_like_to_pay") }}</p>
              <div class="row justify-content-center">
                <table class="package-table mt-3 w-75">
                  <tr v-for="product in products">
                    <td class="w-100">
                      <input :id="'product_' + product.product_id" class="ito-pkg" name="product" type="radio"
                        v-model="product_id" :value="product.product_id" @change="changeProduct(product)" />
                      <label class="selection d-flex slect-package justify-content-between"
                        :for="'product_' + product.product_id">
                        <div class="slect-name">
                          <p class="pk-name">{{ product.product_name }}</p>
                          <p class="pk-price">{{ product.price | toCommas }} &#8363;</p>
                        </div>
                        <div class="slect-cost">{{ product.desc.price_month | toCommas }} &#8363; {{ $t('month') }}
                          <span v-if="product.desc.price_save > 0">Save {{ product.desc.price_save | toCommas
                          }}%</span>
                        </div>
                      </label>
                    </td>
                  </tr>
                </table>
                <span class="w-75 package-note"><strong>(*) Lưu ý:</strong> Giá các gói nâng cấp có thể có sự khác nhau
                  khi thực hiện thanh toán trên web và trên ứng dụng di động</span>
              </div>
              <p class="label-upgrade mt-3" v-if="use_liveclass && live_classes != ''">2. {{ $t("please_select_schedule")
              }}</p>
              <div class="ito-table-wrapper">
                <table class="ito-table ito-table-fixed" v-if="use_liveclass && live_classes != ''">
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("schedule_no") }}</th>
                      <th>{{ $t("schedule_subject") }}</th>
                      <th>{{ $t("schedule_session") }}</th>
                      <th>{{ $t("schedule_date") }}</th>
                      <th>{{ $t("schedule_duration") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody class="ito-tbody" v-for="(live_class, index) in live_classes" v-if="live_classes != []">
                    <template v-for="(live_class_item, i) in live_class.live_class_detail">
                      <tr v-if="i == 0">
                        <td class="text-center" :rowspan="live_class.live_class_detail.length">{{ index + 1 }}</td>
                        <td>{{ live_class_item.subject }}</td>
                        <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                        <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                        <td :rowspan="live_class.live_class_detail.length">{{ live_class.start_date }} - {{
                          live_class.end_date
                        }}</td>
                        <td :rowspan="live_class.live_class_detail.length">
                          <button class="ito-btn ito-btn-outline-primary" v-if="live_class.id == live_class_id">{{
                            $t('selected') }}</button>
                          <button class="ito-btn ito-btn-success" @click="selectLiveClass(live_class.id)" v-else>{{
                            $t('select') }}</button>
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ live_class_item.subject }}</td>
                        <td>{{ live_class_item.time_start }} - {{ live_class_item.time_end }}</td>
                        <td class="text-capitalize">{{ weekDays[live_class_item.day_of_week] }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="d-flex w-100 justify-content-center gap-3 mt-3">
                <button type="button" class="ito-btn ito-btn-lg ito-btn-outline-primary" @click="gotoStep(1)">{{
                  $t('back') }}</button>
                <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(3)"
                  :disabled="!package_id || !package_id || !product_id || !live_class_id" v-if="use_liveclass == 1">{{
                    $t('continue_txt') }}</button>
                <button type="button" class="ito-btn ito-btn-lg ito-btn-primary" @click="gotoStep(3)"
                  :disabled="!package_id || !product_id" v-else>{{ $t('continue_txt') }}</button>
              </div>
            </div>
            <div v-if="step == 3" class="px-5">
              <div class="d-flex justify-content-between">
                <div class="label">
                  <span>{{ $t("selected_package") }} : </span>
                  <strong>
                    {{ selected_product.product_name }}
                  </strong>
                </div>
                <div class="text-bold">
                  {{ selected_product.price | toCommas }}
                  ({{ selected_product.price | toCommas }}đ/month)
                </div>
              </div>
              <div class="d-flex slect-method gap-3 justify-content-center mt-3">
                <template v-for="(gate, index) in gates">
                  <input :id="'method_' + index" class="ito-pkg" name="radio-select" type="radio" v-model="method"
                    :value="gate.method" />
                  <label :for="'method_' + index" class="payment-method-item">
                    <div class="img-gate-box">
                      <img :src="gate.icon">
                    </div>
                    <div class="payment-method-text">{{ gate.name }}</div>
                  </label>
                </template>
              </div>
              <div class="d-flex w-100 justify-content-center gap-3 mt-3">
                <button type="button" class="ito-btn ito-btn-outline-primary" @click="goUndo()">{{ $t('back') }}</button>
                <button type="button" class="ito-btn ito-btn-primary" :disabled="!product_id || !method"
                  @click="paymentMethod()">{{
                    $t('pay_now') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- payment method = bank_transfer  -->
      <div class="modal fade payment-popup" id="PopupPaymentTransfer" tabindex="-1" role="dialog"
        aria-labelledby="PopupPaymentTransfer" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
          <div class="modal-content box-shadow-main">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title payment-title">How to use bank transfer method?</h3>
            <div class="modal-body text-start">
              <p>Please process payment as per below instruction to complete the upgrade.</p>
              <p class="title underline my-2">Account information after complete money transfer:</p>
              <p>Student code (username): <b>{{ user_info.fullname }}</b></p>
              <p>Renew package:
                <b>
                  {{ selected_product ? selected_product.product_name : '' }}
                </b>
              </p>
              <p>Expected expired time: <b>{{ expire_date | formatDate('DD/MM/YYYY') }}</b></p>
              <p class="title underline my-2">Instruction to make money transfer:</p>
              <p>To upgrade, please make money transfer to iTO bank account according to the following information:</p>
              <p>(1) Beneficiary name: <b>CÔNG TY CỔ PHẦN GIÁO DỤC ISMART</b></p>
              <p>(2) Bank: <b>Vietcombank</b></p>
              <p>(3) Account number: <b>87285789759872</b></p>
              <p>(4) Branch: <b>Sai Thanh</b></p>
              <p>(5) Total money: <b>150,000đ</b></p>
              <p>(6) Content: <b>iTO [Student ID] [Phone number]</b></p>
              <p>For example:</p>
              <p>Customer has <b>Student ID</b> demo_001, <b>Phone number</b> 09xxxxxx00 demanding to
                register iTO1 package. Customer needs to enter the content of money transfer as follows:
                <b>iTO1 demo_001 09xxxxxxx00</b>
              </p>
              <p class="title underline my-2">Note:</p>
              <p>In the content of transfer, the information “Student ID” and “Phone number” are required for
                the account upgrade process completed as soon as possible.<br />
                This request is auto cancelled by iTO after 72 hours (02 working days, except Saturday and
                Sunday) if you don't make money transfer to iTO bank account.</p>
              <div class="d-flex mt-3 justify-content-center">
                <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal"
                  @click="Payment()">Request Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade payment-popup" id="PopupPayment" tabindex="-1" role="dialog" aria-labelledby="PopupPayment"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered popup-main-1" role="document" style="max-width: 700px;">
          <div class="modal-content box-shadow-main">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title payment-title">Payment confirmation</h3>
            <div class="modal-body text-start">
              <p><b>Your current account information:</b></p>
              <p class="mt-1">Student ID (username): <b>{{ user_info.fullname }}</b></p>
              <p class="mt-1">Current package:
                <b>
                  {{ selected_product ? selected_product.product_name : '' }}
                </b>
              </p>
              <p class="mt-1">Expire date: <b>{{ expire_date | formatDate('DD/MM/YYYY') }}</b></p>
              <p class="mt-3">Bạn đang thực hiện mua gói cước
                <b>
                  {{ selected_product ? selected_product.product_name : '' }}
                </b>
              </p>
              <p v-if="package_id == user_info.package_level_id">Nếu đồng ý thanh toán, gói cước này sẽ được cộng dồn
                thời
                gian sử dụng vào gói hiện tại.</p>
              <p v-else>Nếu đồng ý thanh toán, gói cước mới sẽ được sử dụng đồng thời với gói <b>{{
                user_info.account_type_name }}</b> vẫn đang còn hiệu lực.</p>
              <p class="mt-3">Bạn có chắc chắn muốn thực hiện thanh toán?</p>
            </div>
            <div class="d-flex justify-content-center mt-3 gap-3">
              <button type="button" class="ito-btn ito-btn-default" data-bs-dismiss="modal"
                data-bs-toggle="modal">Cancel</button>
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" @click="Payment()">Yes</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade payment-popup" id="PopupPaymentFail" tabindex="-1" role="dialog"
        aria-labelledby="PopupPaymentFail" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 475px;">
          <div class="modal-content box-shadow-main paymment-status">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title mb-20 fail">Payment unsuccessfully</h3>
            <img src="/themes/ito/images/payment-fail.png">
            <p class="mt-3">Your payment has failed.<br />
              Please make sure that your payment details are correct.</p>
            <div class="d-flex justify-content-center mt-3 gap-3">
              <button type="button" class="ito-btn ito-btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal">Try
                again</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade payment-popup" id="PopupPaymentSuccess" tabindex="-1" role="dialog"
        aria-labelledby="PopupPaymentSuccess" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 475px;">
          <div class="modal-content box-shadow-main paymment-status">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title mb-20 success">Payment successful</h3>
            <img src="/themes/ito/images/payment-check-icon.svg">
            <p class="mt-3">Thank you!<br />
              Your payment has been processed successfully.</p>
          </div>
        </div>
      </div>
      <div class="modal fade payment-popup" id="RequestSent" tabindex="-1" role="dialog"
        aria-labelledby="PopupPaymentSuccess" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 707px;">
          <div class="modal-content box-shadow-main paymment-status">
            <div class="close-popup" data-bs-dismiss="modal"></div>
            <h3 class="popup-title">Request sent</h3>
            <p class="mt-3">This request is auto cancelled after 72 hours (02 working days, except Saturday and Sunday) if
              you don't make money transfer to iSMART bank account.</p>
            <div class="d-flex justify-content-center">
              <button type="button" class="ito-btn ito-btn-primary px-4 mt-3" data-bs-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import { getLanguage, soundClick, toCommas } from '../../utils/utils'
import { mapGetters } from 'vuex'
import { getPackages, postPayment } from "../../models/payment";

export default {
  name: "ParrentUpgradeAccount",
  data() {
    const weekDays = $.datepicker.regional[getLanguage()].dayNames
    return {
      packages: [],
      package_id: '',
      selected_package: '',

      subjects: [],
      subject_id: '',
      selected_subject: '',

      products: [],
      product_id: '',
      selected_product: '',

      use_liveclass: '',
      live_classes: [],
      live_class_id: '',

      expire_date: '',

      gates: [],

      isLoading: true,
      promotion_code: '',
      promotion_code_error: false,
      method: '',
      step: 1,
      weekDays: weekDays,
    };
  },

  created() {
    this.$store.commit('setMenuActive', 'parent');
    this.fetch();
  },
  computed: {
    ...mapGetters([
      'user_info'
    ], () => {
      if (this.user_info) {
        return this.user_info
      }
      return this.user
    })
  },

  methods: {
    fetch() {
      const that = this;
      that.$store.commit("setLoading", true);
      axios.all([getPackages(),]).then(axios.spread((package_response) => {
        that.isLoading = false;

        if (package_response.data.code != 0) {
          return;
        }
        that.packages = package_response.data.results.packages || [];
        that.changePackage(that.packages[0]);

        that.gates = package_response.data.results.gate || [];
        this.$nextTick(function () {
          that.initSwiper();
        });
        that.$store.commit("setLoading", false);
      }));
    },
    changePackage(selected_package) {
      this.selected_package = selected_package;
      this.package_id = selected_package.id;
      this.use_liveclass = selected_package.use_liveclass == 1 ? true : false;
      this.products = this.selected_package.products
      this.changeProduct(this.products[0])
    },
    changeSubject(subject) {
      this.selected_subject = subject
      this.subject_id = this.selected_subject.ids
      this.products = this.selected_subject.products;
      this.changeProduct(this.products[0])
    },
    changeProduct(product) {
      this.selected_product = product
      this.product_id = this.selected_product.product_id
      this.live_classes = product.live_class ? product.live_class : [];
      this.live_class_id = '';
    },
    goUndo() {
      this.step = this.step - 1;
    },
    gotoStep(step) {
      if (this.selected_package == '') {
        this.selected_package = this.packages[0].name;
      }
      this.step = step;
    },
    selectLiveClass(id) {
      this.live_class_id = id;
    },
    paymentMethod() {
      const that = this;
      that.expire_date = that.addDays(that.user_info.expired_date, that.selected_product.duration)
      if (that.method == 'bank_transfer') {
        $('#PopupPaymentTransfer').modal('show');
      } else {
        if (that.packages.find(item => item.id === that.user_info.package_level_id)) {
          $('#PopupPayment').modal('show');
        } else {
          that.Payment()
        }
      }

    },
    Payment() {
      const that = this;
      that.$store.commit("setLoading", true);
      const formData = new FormData();
      formData.append('product_id', this.product_id);
      formData.append('live_class_id', this.live_class_id);
      // formData.append('voucher', 'xxx');
      formData.append('method', this.method);
      postPayment(formData).then((data) => {
        that.$store.commit("setLoading", false);
        if (data.data.code == 0) {
          if (this.method == 'bank_transfer') {
            $('#PopupPaymentTransfer').modal('hide');
            $('#RequestSent').modal('show');
          } else {
            // $('#PopupPaymentSuccess').modal('show');
            window.location.href = data.data.results.url;
          }
        } else {
          $('#PopupPaymentFail').modal('show');
        }
      }).catch(({ response }) => {
        that.$store.commit("setLoading", false);
        $('#PopupPaymentFail').modal('show');
      });
    },

    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    getSoundClick() {
      soundClick();
    },
    initSwiper() {
      new Swiper(".packageSwiper", {
        slidesPerView: 2,
        spaceBetween: 30,
        slideToClickedSlide: true,
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true
        },
        mousewheel: true
      });
    },
  }
}
</script>

<style scoped>
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.slect-method [type="radio"]+label:before,
.slect-method [type="radio"]+label:after {
  display: none;
}

.slect-method [type=radio]:checked+label.payment-method-item {
  opacity: 1;
  border: 2px solid #40C0F6;
}

.package-content-1 img {
  filter: grayscale(1);
}

.select-upgrade-type [type=radio]:checked+label.package .package-content-1 img {
  filter: grayscale(0);
}

.slect-method label {
  display: block;
  /*width: 100%;*/
  /*height: 100%;*/
}

.slect-product [type=radio]:checked+label.slect-package {
  color: #ffffff;
  background: #e98437;
}

.payment-method-item {
  padding: 0;
}

.img-gate-box {
  height: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px 10px 25px;
}

.payment-method-text {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 0;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.d-flex-space-between {
  justify-content: space-between;
  margin-bottom: 15px;
}

.d-flex-space-between .price {
  font-weight: bold;
}

.input-promotion {
  width: 90%;
}

.btn-payment-change {
  margin: 30px 5px;
}

.flex-center {
  justify-content: center;
}

.btn-undo {
  background: #fff;
  color: #212529;
  width: 120px;
}
</style>
