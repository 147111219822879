<template>
    <div></div>
</template>
<script>
import axios from "axios";
import { LoginAsStudent } from '../models/user';
export default {
    name: "LoginAsStudent",
    data() {
        return {
        };
    },

    computed: {

    },
    created() {
        this.fetch();
    },
    mounted() {

    },
    methods: {
        fetch() {
            this.$store.commit("setLoading", true);
            const params = this.$route.params;
            axios.all([
                LoginAsStudent(params)
            ]).then(axios.spread((response) => {
                this.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    this.error.message = this.$i18n.t(response.data.error_code);
                    return false;
                }
                this.$store.commit('setAuth', true);
                localStorage.removeItem('checkedSubjects');
                localStorage.setItem('access_token', response.data.results.access_token);
                // location.href = '/home';
            }));
        },
    }
}
</script>

<style scoped></style>
