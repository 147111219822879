<template>
  <div class="loading" id="loading" v-if="isLoading">
    <img src="/themes/ito/images/loadding.gif" alt="" />
  </div>
</template>

<script>
export default {
  name: "LoadingLayout",

  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    },
    alert() {
      return this.$store.getters.alert
    }
  },

  created() {
    this.$store.dispatch("SET_USER_INFO");
    this.$store.commit("setLoading", true);
  },
}
</script>